body {
    margin: 0;
    padding: 0
}

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

nav ul,
nav ol {
    list-style: none none
}

img {
    border: 0
}

img,
object,
video,
embed {
    max-height: 100%;
    max-width: 100%
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 0
}

html {
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size-adjust: 100%
}

body {
    color: #333;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem
}

p {
    margin-top: 0rem;
    margin-bottom: 1rem
}

abbr[title] {
    border-bottom: 1px dotted #ccc;
    cursor: help
}

b,
strong {
    font-weight: 700
}

em,
i {
    font-style: italic
}

mark {
    background: #f6f6f6;
    color: #000
}

small,
.small {
    font-size: 12px
}

hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin-bottom: 20px;
    margin-top: 20px
}

sub,
sup {
    font-size: 71.42857143000001%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

dfn {
    font-style: italic
}

h1 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 0rem;
    margin-bottom: 2rem
}

h2 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem
}

h3 {
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-bottom: 1rem
}

h4 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem
}

h5 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem
}

h6 {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small {
    color: #333;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1
}

a,
.alink {
    color: #e62645;
    text-decoration: none
}

a:visited,
.alink:visited {
    color: #e62645e;
    text-decoration: none
}

a:hover,
.alink:hover {
    color: #e62645;
    text-decoration: underline
}

a:active,
.alink:active {
    color: #e62645;
    text-decoration: underline
}

ul,
ol {
    margin-top: 0rem;
    margin-bottom: 2.5rem
}

ul>li,
ol>li {
    margin-top: 0rem;
    margin-bottom: 1rem
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0
}

dl {
    margin-bottom: 20px;
    margin-top: 0
}

dt {
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0
}

dd {
    margin-bottom: 10px;
    margin-top: 0;
    margin-left: 0
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace
}

code {
    background: #f6f6f6;
    color: #111;
    padding: 2px 4px;
    font-size: 1.2rem;
    white-space: nowrap
}

kbd {
    background: #f6f6f6;
    color: #111;
    padding: 2px 4px;
    font-size: 1.2rem
}

pre {
    background: #f6f6f6;
    border: 1px solid #ccc;
    color: #111;
    line-height: 1.42857143;
    margin: 0 0 10px;
    padding: 10px;
    font-size: 1.2rem;
    display: block;
    word-wrap: break-word
}

pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap
}

blockquote {
    border-left: 0 solid #ccc;
    margin: 0 0 20px 40px;
    padding: 0;
    color: #333;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
    line-height: 1.42857143;
    font-size: 1.4rem
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0
}

blockquote footer,
blockquote small,
blockquote .small {
    color: #333;
    line-height: 1.42857143;
    font-size: 1rem;
    display: block
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0'
}

blockquote cite {
    font-style: normal
}

blockquote:before,
blockquote:after {
    content: ''
}

q {
    quotes: none
}

q:before,
q:after {
    content: '';
    content: none
}

cite {
    font-style: normal
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%
}

table th {
    text-align: left
}

table>tbody>tr>th,
table>tfoot>tr>th,
table>tbody>tr>td,
table>tfoot>tr>td {
    vertical-align: top
}

table>thead>tr>th,
table>thead>tr>td {
    vertical-align: bottom
}

table>thead>tr>th,
table>tbody>tr>th,
table>tfoot>tr>th,
table>thead>tr>td,
table>tbody>tr>td,
table>tfoot>tr>td {
    padding: 11px 10px
}

button {
    background-image: none;
    background: #e0e1e2;
    border: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

button:visited {
    color: #333
}

button:focus,
button:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

button:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

button.disabled,
button[disabled],
fieldset[disabled] button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

button::-moz-focus-inner {
    border: 0;
    padding: 0
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="url"]:disabled,
input[type="tel"]:disabled,
input[type="search"]:disabled,
input[type="number"]:disabled,
input[type="datetime"]:disabled,
input[type="email"]:disabled {
    opacity: .5
}

input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="datetime"]::-moz-placeholder,
input[type="email"]::-moz-placeholder {
    color: #575757
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder {
    color: #575757
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder {
    color: #575757
}

input[type="number"] {
    -moz-appearance: textfield
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type="search"] {
    -webkit-appearance: none
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none
}

select {
    background: #fff url('../images/select-bg.svg') no-repeat 100% 45%;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 4px 25px 5px 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box
}

select:disabled {
    opacity: .5;
    background: #fff
}

select[multiple] {
    height: auto;
    background-image: none
}

textarea {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: auto;
    line-height: 1.42857143;
    margin: 0;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    resize: vertical
}

textarea:disabled {
    opacity: .5
}

textarea::-moz-placeholder {
    color: #575757
}

textarea::-webkit-input-placeholder {
    color: #575757
}

textarea:-ms-input-placeholder {
    color: #575757
}

input[type="checkbox"] {
    margin: 2px 5px 0 0;
    position: relative;
    top: 2px
}

input[type="checkbox"]:disabled {
    opacity: .5
}

input[type="radio"] {
    margin: 2px 5px 0 0
}

input[type="radio"]:disabled {
    opacity: .5
}

input.mage-error,
select.mage-error,
textarea.mage-error {
    border-color: #ed8380
}

div.mage-error[generated] {
    color: #e02b27;
    font-size: 1.2rem
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button
}

input::-moz-focus-inner {
    border: 0;
    padding: 0
}

form {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

address {
    font-style: normal
}

*:focus {
    box-shadow: none;
    outline: 0
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: 0 0 3px 1px #c1c1c1
}

.bundle-options-container .block-bundle-summary .bundle.items,
.opc-wrapper .opc,
.opc-progress-bar,
.checkout-agreements-items,
.product-items,
.prices-tier,
.search-terms,
.order-details-items .items-qty,
.block-banners .banner-items,
.block-banners-inline .banner-items,
.block-event .slider-panel .slider {
    margin: 0;
    padding: 0;
    list-style: none none
}

.bundle-options-container .block-bundle-summary .bundle.items>li,
.opc-wrapper .opc>li,
.opc-progress-bar>li,
.checkout-agreements-items>li,
.product-items>li,
.prices-tier>li,
.search-terms>li,
.order-details-items .items-qty>li,
.block-banners .banner-items>li,
.block-banners-inline .banner-items>li,
.block-event .slider-panel .slider>li {
    margin: 0
}

.action-primary:visited {
    color: #fff
}

.action-primary:focus,
.action-primary:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.action-primary:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.action-primary.disabled,
.action-primary[disabled],
fieldset[disabled] .action-primary {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.abs-discount-block .actions-toolbar .action.primary,
.abs-discount-block .actions-toolbar .action.cancel,
.abs-discount-code .form-discount .actions-toolbar .primary .action,
.cart-summary .form.giftregistry .fieldset .action.primary,
.block-compare .action.primary,
.abs-discount-block .actions-toolbar .amshopby-button,
.cart-summary .form.giftregistry .fieldset .amshopby-button,
.block-compare .amshopby-button,
.abs-discount-block .actions-toolbar .am-filter-go,
.cart-summary .form.giftregistry .fieldset .am-filter-go,
.block-compare .am-filter-go,
.paypal-review-discount .actions-toolbar .action.primary,
.paypal-review-discount .actions-toolbar .action.cancel,
.cart-discount .actions-toolbar .action.primary,
.cart-discount .actions-toolbar .action.cancel {
    border-radius: 5px
}

.abs-discount-block .actions-toolbar .action.primary:not(:focus),
.abs-discount-block .actions-toolbar .action.cancel:not(:focus),
.abs-discount-code .form-discount .actions-toolbar .primary .action:not(:focus),
.cart-summary .form.giftregistry .fieldset .action.primary:not(:focus),
.block-compare .action.primary:not(:focus),
.abs-discount-block .actions-toolbar .amshopby-button:not(:focus),
.cart-summary .form.giftregistry .fieldset .amshopby-button:not(:focus),
.block-compare .amshopby-button:not(:focus),
.abs-discount-block .actions-toolbar .am-filter-go:not(:focus),
.cart-summary .form.giftregistry .fieldset .am-filter-go:not(:focus),
.block-compare .am-filter-go:not(:focus),
.paypal-review-discount .actions-toolbar .action.primary:not(:focus),
.paypal-review-discount .actions-toolbar .action.cancel:not(:focus),
.cart-discount .actions-toolbar .action.primary:not(:focus),
.cart-discount .actions-toolbar .action.cancel:not(:focus) {
    box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3)
}

.abs-discount-block .actions-toolbar .action.primary:active,
.abs-discount-block .actions-toolbar .action.cancel:active,
.abs-discount-code .form-discount .actions-toolbar .primary .action:active,
.cart-summary .form.giftregistry .fieldset .action.primary:active,
.block-compare .action.primary:active,
.abs-discount-block .actions-toolbar .amshopby-button:active,
.cart-summary .form.giftregistry .fieldset .amshopby-button:active,
.block-compare .amshopby-button:active,
.abs-discount-block .actions-toolbar .am-filter-go:active,
.cart-summary .form.giftregistry .fieldset .am-filter-go:active,
.block-compare .am-filter-go:active,
.paypal-review-discount .actions-toolbar .action.primary:active,
.paypal-review-discount .actions-toolbar .action.cancel:active,
.cart-discount .actions-toolbar .action.primary:active,
.cart-discount .actions-toolbar .action.cancel:active {
    box-shadow: inset 0 1px 0 0 rgba(204, 204, 204, 0.8), inset 0 -1px 0 0 rgba(204, 204, 204, 0.3)
}

.block-event .slider-panel .slider .item .action.event {
    background-image: none;
    background: #e0e1e2;
    border: 0;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px
}

.abs-action-link-button:visited,
.block-event .slider-panel .slider .item .action.event:visited {
    color: #333
}

.abs-action-link-button:focus,
.abs-action-link-button:hover,
.block-event .slider-panel .slider .item .action.event:focus,
.block-event .slider-panel .slider .item .action.event:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.abs-action-link-button:active,
.block-event .slider-panel .slider .item .action.event:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.abs-action-link-button.disabled,
.abs-action-link-button[disabled],
fieldset[disabled] .abs-action-link-button,
.block-event .slider-panel .slider .item .action.event.disabled,
.block-event .slider-panel .slider .item .action.event[disabled],
fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.abs-action-link-button:hover,
.abs-action-link-button:active,
.abs-action-link-button:focus,
.block-event .slider-panel .slider .item .action.event:hover,
.block-event .slider-panel .slider .item .action.event:active,
.block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none
}

.abs-action-remove,
.abs-discount-block .action.check,
.popup .actions-toolbar .action.cancel,
.paypal-button-widget .paypal-button,
.bundle-options-container .action.back,
.cart.table-wrapper .actions-toolbar>.action-delete,
.cart.table-wrapper .action.help.map,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup,
.opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show,
.gift-options .actions-toolbar .action-cancel,
.gift-summary .action-edit,
.gift-summary .action-delete,
.action-gift,
.gift-wrapping-title .action-remove,
.wishlist.split.button>.action,
.block.related .action.select,
.abs-add-fields .action.remove,
.paypal-review-discount .action.check,
.cart-discount .action.check,
.form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove,
.form-create-return .action.remove,
.form.send.friend .action.remove {
    line-height: 1.42857143;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    font-weight: 400;
    border-radius: 0
}

.abs-action-button-as-link:visited,
.abs-action-remove:visited,
.abs-discount-block .action.check:visited,
.popup .actions-toolbar .action.cancel:visited,
.paypal-button-widget .paypal-button:visited,
.bundle-options-container .action.back:visited,
.cart.table-wrapper .actions-toolbar>.action-delete:visited,
.cart.table-wrapper .action.help.map:visited,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:visited,
.opc-wrapper .edit-address-link:visited,
.opc-block-shipping-information .shipping-information-title .action-edit:visited,
.action-auth-toggle:visited,
.checkout-payment-method .checkout-billing-address .action-cancel:visited,
.checkout-agreements-block .action-show:visited,
.gift-options .actions-toolbar .action-cancel:visited,
.gift-summary .action-edit:visited,
.gift-summary .action-delete:visited,
.action-gift:visited,
.gift-wrapping-title .action-remove:visited,
.wishlist.split.button>.action:visited,
.block.related .action.select:visited,
.abs-add-fields .action.remove:visited,
.paypal-review-discount .action.check:visited,
.cart-discount .action.check:visited,
.form-giftregistry-share .action.remove:visited,
.form-giftregistry-edit .action.remove:visited,
.form-add-invitations .action.remove:visited,
.form-create-return .action.remove:visited,
.form.send.friend .action.remove:visited {
    color: #b74f0e;
    text-decoration: none
}

.abs-action-button-as-link:hover,
.abs-action-remove:hover,
.abs-discount-block .action.check:hover,
.popup .actions-toolbar .action.cancel:hover,
.paypal-button-widget .paypal-button:hover,
.bundle-options-container .action.back:hover,
.cart.table-wrapper .actions-toolbar>.action-delete:hover,
.cart.table-wrapper .action.help.map:hover,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:hover,
.opc-wrapper .edit-address-link:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.action-auth-toggle:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-agreements-block .action-show:hover,
.gift-options .actions-toolbar .action-cancel:hover,
.gift-summary .action-edit:hover,
.gift-summary .action-delete:hover,
.action-gift:hover,
.gift-wrapping-title .action-remove:hover,
.wishlist.split.button>.action:hover,
.block.related .action.select:hover,
.abs-add-fields .action.remove:hover,
.paypal-review-discount .action.check:hover,
.cart-discount .action.check:hover,
.form-giftregistry-share .action.remove:hover,
.form-giftregistry-edit .action.remove:hover,
.form-add-invitations .action.remove:hover,
.form-create-return .action.remove:hover,
.form.send.friend .action.remove:hover {
    color: #b74f0e;
    text-decoration: underline
}

.abs-action-button-as-link:active,
.abs-action-remove:active,
.abs-discount-block .action.check:active,
.popup .actions-toolbar .action.cancel:active,
.paypal-button-widget .paypal-button:active,
.bundle-options-container .action.back:active,
.cart.table-wrapper .actions-toolbar>.action-delete:active,
.cart.table-wrapper .action.help.map:active,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:active,
.opc-wrapper .edit-address-link:active,
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.action-auth-toggle:active,
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-agreements-block .action-show:active,
.gift-options .actions-toolbar .action-cancel:active,
.gift-summary .action-edit:active,
.gift-summary .action-delete:active,
.action-gift:active,
.gift-wrapping-title .action-remove:active,
.wishlist.split.button>.action:active,
.block.related .action.select:active,
.abs-add-fields .action.remove:active,
.paypal-review-discount .action.check:active,
.cart-discount .action.check:active,
.form-giftregistry-share .action.remove:active,
.form-giftregistry-edit .action.remove:active,
.form-add-invitations .action.remove:active,
.form-create-return .action.remove:active,
.form.send.friend .action.remove:active {
    color: #b74f0e;
    text-decoration: underline
}

.abs-action-button-as-link:hover,
.abs-action-remove:hover,
.abs-discount-block .action.check:hover,
.popup .actions-toolbar .action.cancel:hover,
.paypal-button-widget .paypal-button:hover,
.bundle-options-container .action.back:hover,
.cart.table-wrapper .actions-toolbar>.action-delete:hover,
.cart.table-wrapper .action.help.map:hover,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:hover,
.opc-wrapper .edit-address-link:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.action-auth-toggle:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-agreements-block .action-show:hover,
.gift-options .actions-toolbar .action-cancel:hover,
.gift-summary .action-edit:hover,
.gift-summary .action-delete:hover,
.action-gift:hover,
.gift-wrapping-title .action-remove:hover,
.wishlist.split.button>.action:hover,
.block.related .action.select:hover,
.abs-add-fields .action.remove:hover,
.paypal-review-discount .action.check:hover,
.cart-discount .action.check:hover,
.form-giftregistry-share .action.remove:hover,
.form-giftregistry-edit .action.remove:hover,
.form-add-invitations .action.remove:hover,
.form-create-return .action.remove:hover,
.form.send.friend .action.remove:hover {
    color: #b74f0e
}

.abs-action-button-as-link:hover,
.abs-action-button-as-link:active,
.abs-action-button-as-link:focus,
.abs-action-remove:hover,
.abs-action-remove:active,
.abs-action-remove:focus,
.abs-discount-block .action.check:hover,
.abs-discount-block .action.check:active,
.abs-discount-block .action.check:focus,
.popup .actions-toolbar .action.cancel:hover,
.popup .actions-toolbar .action.cancel:active,
.popup .actions-toolbar .action.cancel:focus,
.paypal-button-widget .paypal-button:hover,
.paypal-button-widget .paypal-button:active,
.paypal-button-widget .paypal-button:focus,
.bundle-options-container .action.back:hover,
.bundle-options-container .action.back:active,
.bundle-options-container .action.back:focus,
.cart.table-wrapper .actions-toolbar>.action-delete:hover,
.cart.table-wrapper .actions-toolbar>.action-delete:active,
.cart.table-wrapper .actions-toolbar>.action-delete:focus,
.cart.table-wrapper .action.help.map:hover,
.cart.table-wrapper .action.help.map:active,
.cart.table-wrapper .action.help.map:focus,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:hover,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:active,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:focus,
.opc-wrapper .edit-address-link:hover,
.opc-wrapper .edit-address-link:active,
.opc-wrapper .edit-address-link:focus,
.opc-block-shipping-information .shipping-information-title .action-edit:hover,
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.opc-block-shipping-information .shipping-information-title .action-edit:focus,
.action-auth-toggle:hover,
.action-auth-toggle:active,
.action-auth-toggle:focus,
.checkout-payment-method .checkout-billing-address .action-cancel:hover,
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-payment-method .checkout-billing-address .action-cancel:focus,
.checkout-agreements-block .action-show:hover,
.checkout-agreements-block .action-show:active,
.checkout-agreements-block .action-show:focus,
.gift-options .actions-toolbar .action-cancel:hover,
.gift-options .actions-toolbar .action-cancel:active,
.gift-options .actions-toolbar .action-cancel:focus,
.gift-summary .action-edit:hover,
.gift-summary .action-edit:active,
.gift-summary .action-edit:focus,
.gift-summary .action-delete:hover,
.gift-summary .action-delete:active,
.gift-summary .action-delete:focus,
.action-gift:hover,
.action-gift:active,
.action-gift:focus,
.gift-wrapping-title .action-remove:hover,
.gift-wrapping-title .action-remove:active,
.gift-wrapping-title .action-remove:focus,
.wishlist.split.button>.action:hover,
.wishlist.split.button>.action:active,
.wishlist.split.button>.action:focus,
.block.related .action.select:hover,
.block.related .action.select:active,
.block.related .action.select:focus,
.abs-add-fields .action.remove:hover,
.abs-add-fields .action.remove:active,
.abs-add-fields .action.remove:focus,
.paypal-review-discount .action.check:hover,
.paypal-review-discount .action.check:active,
.paypal-review-discount .action.check:focus,
.cart-discount .action.check:hover,
.cart-discount .action.check:active,
.cart-discount .action.check:focus,
.form-giftregistry-share .action.remove:hover,
.form-giftregistry-share .action.remove:active,
.form-giftregistry-share .action.remove:focus,
.form-giftregistry-edit .action.remove:hover,
.form-giftregistry-edit .action.remove:active,
.form-giftregistry-edit .action.remove:focus,
.form-add-invitations .action.remove:hover,
.form-add-invitations .action.remove:active,
.form-add-invitations .action.remove:focus,
.form-create-return .action.remove:hover,
.form-create-return .action.remove:active,
.form-create-return .action.remove:focus,
.form.send.friend .action.remove:hover,
.form.send.friend .action.remove:active,
.form.send.friend .action.remove:focus {
    background: none;
    border: 0
}

.abs-action-button-as-link.disabled,
.abs-action-button-as-link[disabled],
fieldset[disabled] .abs-action-button-as-link,
.abs-action-remove.disabled,
.abs-action-remove[disabled],
fieldset[disabled] .abs-action-remove,
.abs-discount-block .action.check.disabled,
.abs-discount-block .action.check[disabled],
fieldset[disabled] .abs-discount-block .action.check,
.popup .actions-toolbar .action.cancel.disabled,
.popup .actions-toolbar .action.cancel[disabled],
fieldset[disabled] .popup .actions-toolbar .action.cancel,
.paypal-button-widget .paypal-button.disabled,
.paypal-button-widget .paypal-button[disabled],
fieldset[disabled] .paypal-button-widget .paypal-button,
.bundle-options-container .action.back.disabled,
.bundle-options-container .action.back[disabled],
fieldset[disabled] .bundle-options-container .action.back,
.cart.table-wrapper .actions-toolbar>.action-delete.disabled,
.cart.table-wrapper .actions-toolbar>.action-delete[disabled],
fieldset[disabled] .cart.table-wrapper .actions-toolbar>.action-delete,
.cart.table-wrapper .action.help.map.disabled,
.cart.table-wrapper .action.help.map[disabled],
fieldset[disabled] .cart.table-wrapper .action.help.map,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup.disabled,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup[disabled],
fieldset[disabled] .checkout-index-index .modal-popup .modal-footer .action-hide-popup,
.opc-wrapper .edit-address-link.disabled,
.opc-wrapper .edit-address-link[disabled],
fieldset[disabled] .opc-wrapper .edit-address-link,
.opc-block-shipping-information .shipping-information-title .action-edit.disabled,
.opc-block-shipping-information .shipping-information-title .action-edit[disabled],
fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
.action-auth-toggle.disabled,
.action-auth-toggle[disabled],
fieldset[disabled] .action-auth-toggle,
.checkout-payment-method .checkout-billing-address .action-cancel.disabled,
.checkout-payment-method .checkout-billing-address .action-cancel[disabled],
fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
.checkout-agreements-block .action-show.disabled,
.checkout-agreements-block .action-show[disabled],
fieldset[disabled] .checkout-agreements-block .action-show,
.gift-options .actions-toolbar .action-cancel.disabled,
.gift-options .actions-toolbar .action-cancel[disabled],
fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
.gift-summary .action-edit.disabled,
.gift-summary .action-edit[disabled],
fieldset[disabled] .gift-summary .action-edit,
.gift-summary .action-delete.disabled,
.gift-summary .action-delete[disabled],
fieldset[disabled] .gift-summary .action-delete,
.action-gift.disabled,
.action-gift[disabled],
fieldset[disabled] .action-gift,
.gift-wrapping-title .action-remove.disabled,
.gift-wrapping-title .action-remove[disabled],
fieldset[disabled] .gift-wrapping-title .action-remove,
.wishlist.split.button>.action.disabled,
.wishlist.split.button>.action[disabled],
fieldset[disabled] .wishlist.split.button>.action,
.block.related .action.select.disabled,
.block.related .action.select[disabled],
fieldset[disabled] .block.related .action.select,
.abs-add-fields .action.remove.disabled,
.abs-add-fields .action.remove[disabled],
fieldset[disabled] .abs-add-fields .action.remove,
.paypal-review-discount .action.check.disabled,
.paypal-review-discount .action.check[disabled],
fieldset[disabled] .paypal-review-discount .action.check,
.cart-discount .action.check.disabled,
.cart-discount .action.check[disabled],
fieldset[disabled] .cart-discount .action.check,
.form-giftregistry-share .action.remove.disabled,
.form-giftregistry-share .action.remove[disabled],
fieldset[disabled] .form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove.disabled,
.form-giftregistry-edit .action.remove[disabled],
fieldset[disabled] .form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove.disabled,
.form-add-invitations .action.remove[disabled],
fieldset[disabled] .form-add-invitations .action.remove,
.form-create-return .action.remove.disabled,
.form-create-return .action.remove[disabled],
fieldset[disabled] .form-create-return .action.remove,
.form.send.friend .action.remove.disabled,
.form.send.friend .action.remove[disabled],
fieldset[disabled] .form.send.friend .action.remove {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.abs-action-remove:active,
.abs-action-remove:not(:focus),
.abs-discount-block .action.check:active,
.abs-discount-block .action.check:not(:focus),
.popup .actions-toolbar .action.cancel:active,
.popup .actions-toolbar .action.cancel:not(:focus),
.paypal-button-widget .paypal-button:active,
.paypal-button-widget .paypal-button:not(:focus),
.bundle-options-container .action.back:active,
.bundle-options-container .action.back:not(:focus),
.cart.table-wrapper .actions-toolbar>.action-delete:active,
.cart.table-wrapper .actions-toolbar>.action-delete:not(:focus),
.cart.table-wrapper .action.help.map:active,
.cart.table-wrapper .action.help.map:not(:focus),
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:active,
.checkout-index-index .modal-popup .modal-footer .action-hide-popup:not(:focus),
.opc-wrapper .edit-address-link:active,
.opc-wrapper .edit-address-link:not(:focus),
.opc-block-shipping-information .shipping-information-title .action-edit:active,
.opc-block-shipping-information .shipping-information-title .action-edit:not(:focus),
.action-auth-toggle:active,
.action-auth-toggle:not(:focus),
.checkout-payment-method .checkout-billing-address .action-cancel:active,
.checkout-payment-method .checkout-billing-address .action-cancel:not(:focus),
.checkout-agreements-block .action-show:active,
.checkout-agreements-block .action-show:not(:focus),
.gift-options .actions-toolbar .action-cancel:active,
.gift-options .actions-toolbar .action-cancel:not(:focus),
.gift-summary .action-edit:active,
.gift-summary .action-edit:not(:focus),
.gift-summary .action-delete:active,
.gift-summary .action-delete:not(:focus),
.action-gift:active,
.action-gift:not(:focus),
.gift-wrapping-title .action-remove:active,
.gift-wrapping-title .action-remove:not(:focus),
.wishlist.split.button>.action:active,
.wishlist.split.button>.action:not(:focus),
.block.related .action.select:active,
.block.related .action.select:not(:focus),
.abs-add-fields .action.remove:active,
.abs-add-fields .action.remove:not(:focus),
.paypal-review-discount .action.check:active,
.paypal-review-discount .action.check:not(:focus),
.cart-discount .action.check:active,
.cart-discount .action.check:not(:focus),
.form-giftregistry-share .action.remove:active,
.form-giftregistry-share .action.remove:not(:focus),
.form-giftregistry-edit .action.remove:active,
.form-giftregistry-edit .action.remove:not(:focus),
.form-add-invitations .action.remove:active,
.form-add-invitations .action.remove:not(:focus),
.form-create-return .action.remove:active,
.form-create-return .action.remove:not(:focus),
.form.send.friend .action.remove:active,
.form.send.friend .action.remove:not(:focus) {
    box-shadow: none
}

.abs-revert-to-action-secondary,
.paypal-review .block .actions-toolbar .action.primary,
.abs-discount-block .actions-toolbar .action.primary,
.abs-discount-block .actions-toolbar .action.cancel,
.paypal-review .block .actions-toolbar .amshopby-button,
.paypal-review .block .actions-toolbar .am-filter-go,
.abs-discount-code .form-discount .actions-toolbar .primary .action,
.cart-summary .form.giftregistry .fieldset .action.primary,
.block-compare .action.primary,
.abs-discount-block .actions-toolbar .amshopby-button,
.cart-summary .form.giftregistry .fieldset .amshopby-button,
.block-compare .amshopby-button,
.abs-discount-block .actions-toolbar .am-filter-go,
.cart-summary .form.giftregistry .fieldset .am-filter-go,
.block-compare .am-filter-go,
.paypal-review-discount .actions-toolbar .action.primary,
.paypal-review-discount .actions-toolbar .action.cancel,
.cart-discount .actions-toolbar .action.primary,
.cart-discount .actions-toolbar .action.cancel {
    background: #e0e1e2;
    border: 0;
    color: #333
}

.abs-revert-secondary-color:focus,
.abs-revert-secondary-color:hover,
.abs-revert-to-action-secondary:focus,
.abs-revert-to-action-secondary:hover,
.paypal-review .block .actions-toolbar .action.primary:focus,
.paypal-review .block .actions-toolbar .action.primary:hover,
.abs-discount-block .actions-toolbar .action.primary:focus,
.abs-discount-block .actions-toolbar .action.primary:hover,
.abs-discount-block .actions-toolbar .action.cancel:focus,
.abs-discount-block .actions-toolbar .action.cancel:hover,
.paypal-review .block .actions-toolbar .amshopby-button:focus,
.paypal-review .block .actions-toolbar .amshopby-button:hover,
.paypal-review .block .actions-toolbar .am-filter-go:focus,
.paypal-review .block .actions-toolbar .am-filter-go:hover,
.abs-discount-code .form-discount .actions-toolbar .primary .action:focus,
.abs-discount-code .form-discount .actions-toolbar .primary .action:hover,
.cart-summary .form.giftregistry .fieldset .action.primary:focus,
.cart-summary .form.giftregistry .fieldset .action.primary:hover,
.block-compare .action.primary:focus,
.block-compare .action.primary:hover,
.abs-discount-block .actions-toolbar .amshopby-button:focus,
.abs-discount-block .actions-toolbar .amshopby-button:hover,
.cart-summary .form.giftregistry .fieldset .amshopby-button:focus,
.cart-summary .form.giftregistry .fieldset .amshopby-button:hover,
.block-compare .amshopby-button:focus,
.block-compare .amshopby-button:hover,
.abs-discount-block .actions-toolbar .am-filter-go:focus,
.abs-discount-block .actions-toolbar .am-filter-go:hover,
.cart-summary .form.giftregistry .fieldset .am-filter-go:focus,
.cart-summary .form.giftregistry .fieldset .am-filter-go:hover,
.block-compare .am-filter-go:focus,
.block-compare .am-filter-go:hover,
.paypal-review-discount .actions-toolbar .action.primary:focus,
.paypal-review-discount .actions-toolbar .action.primary:hover,
.paypal-review-discount .actions-toolbar .action.cancel:focus,
.paypal-review-discount .actions-toolbar .action.cancel:hover,
.cart-discount .actions-toolbar .action.primary:focus,
.cart-discount .actions-toolbar .action.primary:hover,
.cart-discount .actions-toolbar .action.cancel:focus,
.cart-discount .actions-toolbar .action.cancel:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.abs-revert-secondary-color:active,
.abs-revert-to-action-secondary:active,
.paypal-review .block .actions-toolbar .action.primary:active,
.abs-discount-block .actions-toolbar .action.primary:active,
.abs-discount-block .actions-toolbar .action.cancel:active,
.paypal-review .block .actions-toolbar .amshopby-button:active,
.paypal-review .block .actions-toolbar .am-filter-go:active,
.abs-discount-code .form-discount .actions-toolbar .primary .action:active,
.cart-summary .form.giftregistry .fieldset .action.primary:active,
.block-compare .action.primary:active,
.abs-discount-block .actions-toolbar .amshopby-button:active,
.cart-summary .form.giftregistry .fieldset .amshopby-button:active,
.block-compare .amshopby-button:active,
.abs-discount-block .actions-toolbar .am-filter-go:active,
.cart-summary .form.giftregistry .fieldset .am-filter-go:active,
.block-compare .am-filter-go:active,
.paypal-review-discount .actions-toolbar .action.primary:active,
.paypal-review-discount .actions-toolbar .action.cancel:active,
.cart-discount .actions-toolbar .action.primary:active,
.cart-discount .actions-toolbar .action.cancel:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.products-grid.wishlist .product-item .box-tocart .tocart {
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 10px 15px
}

.form-address-edit .actions-toolbar .action.vertex-secondary,
.order-review-form .action.primary,
.bundle-actions .action.primary.customize,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.block-minicart .block-content>.actions>.primary .action.primary,
.box-tocart .action.instant-purchase,
.multicheckout .action.primary,
.box-tocart .action.tocart,
.form-address-edit .actions-toolbar .action.primary,
.order-review-form .amshopby-button,
.bundle-actions .amshopby-button.customize,
.bundle-options-container .block-bundle-summary .box-tocart .amshopby-button,
.block-minicart .block-content>.actions>.primary .amshopby-button,
.multicheckout .amshopby-button,
.form-address-edit .actions-toolbar .amshopby-button,
.order-review-form .am-filter-go,
.bundle-actions .am-filter-go.customize,
.bundle-options-container .block-bundle-summary .box-tocart .am-filter-go,
.block-minicart .block-content>.actions>.primary .am-filter-go,
.multicheckout .am-filter-go,
.form-address-edit .actions-toolbar .am-filter-go {
    line-height: 2.2rem;
    padding: 14px 17px;
    font-size: 1.8rem
}

.cart.table-wrapper .item-options dt,
.minicart-items .product-item-details .product .options.list dt,
.opc-block-summary .items-in-cart .product .item-options dt,
.block-giftregistry-shared-items .product .item-options dt,
.multicheckout .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0
}

.cart.table-wrapper .item-options dt:after,
.minicart-items .product-item-details .product .options.list dt:after,
.opc-block-summary .items-in-cart .product .item-options dt:after,
.block-giftregistry-shared-items .product .item-options dt:after,
.multicheckout .item-options dt:after {
    content: ': '
}

.cart.table-wrapper .item-options dd,
.minicart-items .product-item-details .product .options.list dd,
.opc-block-summary .items-in-cart .product .item-options dd,
.block-giftregistry-shared-items .product .item-options dd,
.multicheckout .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px
}

.actions-toolbar>.primary .action,
.actions-toolbar>.secondary .action,
.bundle-actions .action.primary.customize,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.box-tocart .action.instant-purchase,
.box-tocart .action.tocart,
.bundle-actions .amshopby-button.customize,
.bundle-options-container .block-bundle-summary .box-tocart .amshopby-button,
.bundle-actions .am-filter-go.customize,
.bundle-options-container .block-bundle-summary .box-tocart .am-filter-go {
    width: 100%
}

.cart.table-wrapper .product-image-wrapper,
.minicart-items .product-image-wrapper,
.block-giftregistry-shared-items .product-image-wrapper,
.products-list .product-image-wrapper {
    height: auto;
    padding: 0 !important
}

.cart.table-wrapper .product-image-wrapper .product-image-photo,
.minicart-items .product-image-wrapper .product-image-photo,
.block-giftregistry-shared-items .product-image-wrapper .product-image-photo,
.products-list .product-image-wrapper .product-image-photo {
    position: static
}

.product.media .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%
}

.giftregisty-dropdown,
.wishlist-dropdown {
    display: inline-block;
    position: relative
}

.abs-dropdown-simple:before,
.abs-dropdown-simple:after,
.giftregisty-dropdown:before,
.giftregisty-dropdown:after,
.wishlist-dropdown:before,
.wishlist-dropdown:after {
    content: '';
    display: table
}

.abs-dropdown-simple:after,
.giftregisty-dropdown:after,
.wishlist-dropdown:after {
    clear: both
}

.abs-dropdown-simple .action.toggle,
.giftregisty-dropdown .action.toggle,
.wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none
}

.abs-dropdown-simple .action.toggle:after,
.giftregisty-dropdown .action.toggle:after,
.wishlist-dropdown .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: inherit;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-dropdown-simple .action.toggle:hover:after,
.giftregisty-dropdown .action.toggle:hover:after,
.wishlist-dropdown .action.toggle:hover:after {
    color: inherit
}

.abs-dropdown-simple .action.toggle:active:after,
.giftregisty-dropdown .action.toggle:active:after,
.wishlist-dropdown .action.toggle:active:after {
    color: inherit
}

.abs-dropdown-simple .action.toggle.active,
.giftregisty-dropdown .action.toggle.active,
.wishlist-dropdown .action.toggle.active {
    display: inline-block;
    text-decoration: none
}

.abs-dropdown-simple .action.toggle.active:after,
.giftregisty-dropdown .action.toggle.active:after,
.wishlist-dropdown .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: inherit;
    color: inherit;
    content: '\f077';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 5px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-dropdown-simple .action.toggle.active:hover:after,
.giftregisty-dropdown .action.toggle.active:hover:after,
.wishlist-dropdown .action.toggle.active:hover:after {
    color: inherit
}

.abs-dropdown-simple .action.toggle.active:active:after,
.giftregisty-dropdown .action.toggle.active:active:after,
.wishlist-dropdown .action.toggle.active:active:after {
    color: inherit
}

.abs-dropdown-simple ul.dropdown,
.giftregisty-dropdown ul.dropdown,
.wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.abs-dropdown-simple ul.dropdown li,
.giftregisty-dropdown ul.dropdown li,
.wishlist-dropdown ul.dropdown li {
    margin: 0;
    padding: 5px 5px 5px 23px
}

.abs-dropdown-simple ul.dropdown li:hover,
.giftregisty-dropdown ul.dropdown li:hover,
.wishlist-dropdown ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer
}

.abs-dropdown-simple ul.dropdown:before,
.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.abs-dropdown-simple ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.abs-dropdown-simple ul.dropdown:before,
.giftregisty-dropdown ul.dropdown:before,
.wishlist-dropdown ul.dropdown:before {
    left: 10px;
    top: -12px
}

.abs-dropdown-simple ul.dropdown:after,
.giftregisty-dropdown ul.dropdown:after,
.wishlist-dropdown ul.dropdown:after {
    left: 9px;
    top: -14px
}

.abs-dropdown-simple.active,
.giftregisty-dropdown.active,
.wishlist-dropdown.active {
    overflow: visible
}

.abs-dropdown-simple.active ul.dropdown,
.giftregisty-dropdown.active ul.dropdown,
.wishlist-dropdown.active ul.dropdown {
    display: block
}

.giftregisty-dropdown ul.items,
.wishlist-dropdown ul.items {
    padding: 6px 0
}

.column .block-addbysku .qty .qty,
.bundle-options-container .input-text.qty,
.table-giftregistry-items .field.qty .qty,
.block-giftregistry-shared-items .qty .qty,
.table.grouped .col.qty .control.qty .qty,
.block-wishlist-info-items .input-text.qty,
.multicheckout .table-wrapper .col .qty .input-text,
.account .table-wrapper .data.table.wishlist .box-tocart .qty,
.products-grid.wishlist .product-item .box-tocart input.qty,
.box-tocart .input-text.qty,
.account .data.table-return-items .qty .input-text,
.sidebar .block-addbysku .fieldset .fields .field.qty .qty {
    text-align: center;
    width: 54px
}

.customer-review .product-details,
.block,
.block-banners,
.block-banners-inline {
    margin-bottom: 40px
}

.sidebar .product-items .action.delete,
.sidebar .block-compare .action.delete,
.table-comparison .cell.remove .action.delete,
.block-compare .action.delete,
.filter-current .action.remove {
    display: inline-block;
    text-decoration: none
}

.abs-remove-button-for-blocks>span,
.sidebar .product-items .action.delete>span,
.sidebar .block-compare .action.delete>span,
.table-comparison .cell.remove .action.delete>span,
.block-compare .action.delete>span,
.filter-current .action.remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.abs-remove-button-for-blocks:before,
.sidebar .product-items .action.delete:before,
.sidebar .block-compare .action.delete:before,
.table-comparison .cell.remove .action.delete:before,
.block-compare .action.delete:before,
.filter-current .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 15px;
    color: #757575;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-remove-button-for-blocks:hover:before,
.sidebar .product-items .action.delete:hover:before,
.sidebar .block-compare .action.delete:hover:before,
.table-comparison .cell.remove .action.delete:hover:before,
.block-compare .action.delete:hover:before,
.filter-current .action.remove:hover:before {
    color: #494949
}

.abs-remove-button-for-blocks:active:before,
.sidebar .product-items .action.delete:active:before,
.sidebar .block-compare .action.delete:active:before,
.table-comparison .cell.remove .action.delete:active:before,
.block-compare .action.delete:active:before,
.filter-current .action.remove:active:before {
    color: #999
}

.product-item-name,
.product.name a {
    font-weight: 400
}

.product-item-name>a,
.product.name a>a {
    color: #333;
    text-decoration: none
}

.abs-product-link>a:visited,
.product-item-name>a:visited,
.product.name a>a:visited {
    color: #333;
    text-decoration: underline
}

.abs-product-link>a:hover,
.product-item-name>a:hover,
.product.name a>a:hover {
    color: #333;
    text-decoration: underline
}

.abs-product-link>a:active,
.product-item-name>a:active,
.product.name a>a:active {
    color: #333;
    text-decoration: underline
}

.abs-like-link:visited {
    color: #b74f0e;
    text-decoration: none
}

.abs-like-link:hover {
    color: #b74f0e;
    text-decoration: underline
}

.abs-like-link:active {
    color: #b74f0e;
    text-decoration: underline
}

.form.giftregistry.items .actions-toolbar,
.form.wishlist.items .actions-toolbar {
    margin-left: 0
}

.abs-add-fields .action.remove,
.form-giftregistry-share .action.remove,
.form-giftregistry-edit .action.remove,
.form-add-invitations .action.remove,
.form-create-return .action.remove,
.form.send.friend .action.remove {
    line-height: normal;
    margin-left: 73%;
    position: absolute;
    top: 34px;
    width: auto
}

.form-giftregistry-share .fieldset .field .control,
.form-giftregistry-edit .fieldset .field .control,
.form-add-invitations .fieldset .field .control,
.form-create-return .fieldset .field .control,
.form.send.friend .fieldset .field .control {
    width: 70%
}

.form-giftregistry-share .fieldset .actions-toolbar>.secondary,
.form-giftregistry-edit .fieldset .actions-toolbar>.secondary,
.form-add-invitations .fieldset .actions-toolbar>.secondary,
.form-create-return .fieldset .actions-toolbar>.secondary,
.form.send.friend .fieldset .actions-toolbar>.secondary {
    float: left
}

.form-giftregistry-share .fieldset .actions-toolbar>.secondary .action.add,
.form-giftregistry-edit .fieldset .actions-toolbar>.secondary .action.add,
.form-add-invitations .fieldset .actions-toolbar>.secondary .action.add,
.form-create-return .fieldset .actions-toolbar>.secondary .action.add,
.form.send.friend .fieldset .actions-toolbar>.secondary .action.add {
    margin-top: 30px
}

.form-giftregistry-share .fieldset .fields .actions-toolbar,
.form-giftregistry-edit .fieldset .fields .actions-toolbar,
.form-add-invitations .fieldset .fields .actions-toolbar,
.form-create-return .fieldset .fields .actions-toolbar,
.form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0
}

.form-giftregistry-share .additional,
.form-giftregistry-edit .additional,
.form-add-invitations .additional,
.form-create-return .additional,
.form.send.friend .additional {
    margin-top: 55px;
    position: relative
}

.form-giftregistry-share .message.notice,
.form-giftregistry-edit .message.notice,
.form-add-invitations .message.notice,
.form-create-return .message.notice,
.form.send.friend .message.notice {
    margin: 30px 0 0
}

.order-details-items .order-items .order-gift-message:not(.expanded-content) {
    height: 0;
    visibility: hidden
}

.bundle-options-container .block-bundle-summary .product-image-container,
.bundle-options-container .block-bundle-summary .product.name,
.bundle-options-container .block-bundle-summary .stock,
.cart.table-wrapper .col.qty .label,
.checkout-payment-method .field-select-billing>.label,
.checkout-payment-method .payments .legend,
.checkout-payment-method .ccard .legend,
.block-balance .balance-price-label,
.page-product-downloadable .product-options-wrapper .fieldset .legend.links-title,
.page-product-giftcard .fieldset.giftcard>.legend,
.opc-wrapper .form-giftcard-account .field .label,
.form-add-invitations .fields .label,
.table-comparison .wishlist.split.button>.action.split span,
.product-items .wishlist.split.button>.action.split span,
.page-multiple-wishlist .page-title-wrapper .page-title,
.block-wishlist-search-form .form-wishlist-search .fieldset>.legend,
.multicheckout .table-wrapper .col .label,
.multicheckout .block .methods-shipping .item-content .fieldset>.legend,
.block.newsletter .label,
.review-date .review-details-label,
.customer-review .product-details .rating-average-label,
.products-grid.wishlist .product-item .comment-box .label,
.product-item-actions .actions-secondary>.action span,
.special-price .price-label,
.table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span,
.block.related .field.choice .label,
.fieldset .fullname>.label,
.field.street .field .label,
.filter-options-content .filter-count-label,
.block-reorder .product-item .label,
.opc-wrapper .form-discount .field .label,
.action.skip:not(:focus),
.page-header .switcher .label,
.page-footer .switcher .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.minicart-items .product.options .toggle>span,
.shipping-policy-block.field-tooltip .field-tooltip-action span {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.abs-add-clearfix:before,
.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:before,
.abs-add-fields .fieldset .actions-toolbar:after,
.payment-method-braintree .hosted-date-wrap:before,
.payment-method-braintree .hosted-date-wrap:after,
.bundle-options-container .product-options-wrapper .field.choice .label:before,
.bundle-options-container .product-options-wrapper .field.choice .label:after,
.cart.table-wrapper .actions-toolbar:before,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:before,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item>.product:before,
.minicart-items .product-item>.product:after,
.minicart-items .product-item-details .product .options.list:before,
.minicart-items .product-item-details .product .options.list:after,
.checkout-container:before,
.checkout-container:after,
.checkout-onepage-success:before,
.checkout-onepage-success:after,
.opc-wrapper .field.addresses:before,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:before,
.opc-block-summary .items-in-cart .product .item-options:after,
.page-product-downloadable .product-add-form:before,
.page-product-downloadable .product-add-form:after,
.gift-item-block .content:before,
.gift-item-block .content:after,
.order-details-items .order-gift-message:before,
.order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:before,
.order-details-items .block-order-details-gift-message:after,
.block-giftregistry-shared-items .product .item-options:before,
.block-giftregistry-shared-items .product .item-options:after,
.gift-wrapping-list:before,
.gift-wrapping-list:after,
.opc-wrapper .data.table .gift-wrapping .content:before,
.opc-wrapper .data.table .gift-wrapping .content:after,
.table-order-items .gift-wrapping .content:before,
.table-order-items .gift-wrapping .content:after,
.order-options .product:before,
.order-options .product:after,
.map-popup .map-info-price:before,
.map-popup .map-info-price:after,
.multicheckout .item-options:before,
.multicheckout .item-options:after,
.multicheckout .block:before,
.multicheckout .block:after,
.multicheckout .block-content:before,
.multicheckout .block-content:after,
.customer-review .product-details:before,
.customer-review .product-details:after,
.form-create-return .actions-toolbar:before,
.form-create-return .actions-toolbar:after,
.toolbar:before,
.toolbar:after,
.sidebar .product-items-images:before,
.sidebar .product-items-images:after,
.header.content:before,
.header.content:after,
.form-giftregistry-share .fieldset .actions-toolbar:before,
.form-giftregistry-share .fieldset .actions-toolbar:after,
.form-giftregistry-edit .fieldset .actions-toolbar:before,
.form-giftregistry-edit .fieldset .actions-toolbar:after,
.form-add-invitations .fieldset .actions-toolbar:before,
.form-add-invitations .fieldset .actions-toolbar:after,
.form-create-return .fieldset .actions-toolbar:before,
.form-create-return .fieldset .actions-toolbar:after,
.form.send.friend .fieldset .actions-toolbar:before,
.form.send.friend .fieldset .actions-toolbar:after {
    content: '';
    display: table
}

.abs-add-clearfix:after,
.abs-add-fields .fieldset .actions-toolbar:after,
.payment-method-braintree .hosted-date-wrap:after,
.bundle-options-container .product-options-wrapper .field.choice .label:after,
.cart.table-wrapper .actions-toolbar:after,
.cart.table-wrapper .item-options:after,
.minicart-items .product-item>.product:after,
.minicart-items .product-item-details .product .options.list:after,
.checkout-container:after,
.checkout-onepage-success:after,
.opc-wrapper .field.addresses:after,
.opc-block-summary .items-in-cart .product .item-options:after,
.page-product-downloadable .product-add-form:after,
.gift-item-block .content:after,
.order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after,
.block-giftregistry-shared-items .product .item-options:after,
.gift-wrapping-list:after,
.opc-wrapper .data.table .gift-wrapping .content:after,
.table-order-items .gift-wrapping .content:after,
.order-options .product:after,
.map-popup .map-info-price:after,
.multicheckout .item-options:after,
.multicheckout .block:after,
.multicheckout .block-content:after,
.customer-review .product-details:after,
.form-create-return .actions-toolbar:after,
.toolbar:after,
.sidebar .product-items-images:after,
.header.content:after,
.form-giftregistry-share .fieldset .actions-toolbar:after,
.form-giftregistry-edit .fieldset .actions-toolbar:after,
.form-add-invitations .fieldset .actions-toolbar:after,
.form-create-return .fieldset .actions-toolbar:after,
.form.send.friend .fieldset .actions-toolbar:after {
    clear: both
}

.abs-field-date .control,
.abs-field-tooltip,
.columns .column.main,
.columns .sidebar-main,
.columns .sidebar-additional,
.column .block-addbysku .sku,
.shipping-policy-block.field-tooltip .field-tooltip-content,
.sidebar .product-items-images .product-item,
.search-autocomplete,
.block-event .slider-panel .slider .item .action.show,
.sidebar .block-addbysku .fieldset .fields .field.sku,
.field .control._with-tooltip,
.form-giftregistry-edit .field.date .control,
.form.search.advanced .fields.range .field.date .control {
    box-sizing: border-box
}

.abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-split-button:before,
.abs-split-button:after {
    content: '';
    display: table
}

.abs-split-button:after {
    clear: both
}

.abs-split-button .action.split {
    float: left;
    margin: 0
}

.abs-split-button .action.toggle {
    float: right;
    margin: 0
}

.abs-split-button button.action.split {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.abs-split-button button+.action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0
}

.abs-split-button .action.toggle {
    display: inline-block;
    text-decoration: none
}

.abs-split-button .action.toggle>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.abs-split-button .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-split-button .action.toggle:hover:after {
    color: inherit
}

.abs-split-button .action.toggle:active:after {
    color: inherit
}

.abs-split-button .action.toggle.active {
    display: inline-block;
    text-decoration: none
}

.abs-split-button .action.toggle.active>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.abs-split-button .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: '\f077';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-split-button .action.toggle.active:hover:after {
    color: inherit
}

.abs-split-button .action.toggle.active:active:after {
    color: inherit
}

.abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.abs-split-button .items li {
    margin: 0;
    padding: 3px 5px
}

.abs-split-button .items li:hover {
    background: #e8e8e8;
    cursor: pointer
}

.abs-split-button .items:before,
.abs-split-button .items:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.abs-split-button .items:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.abs-split-button .items:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.abs-split-button .items:before {
    right: 10px;
    top: -12px
}

.abs-split-button .items:after {
    right: 9px;
    top: -14px
}

.abs-split-button.active {
    overflow: visible
}

.abs-split-button.active .items {
    display: block
}

.bundle-options-container .block-bundle-summary .product-addto-links>.action,
.wishlist.split.button>.action.split,
.product-social-links .action.mailto.friend,
.product-social-links .action.towishlist,
.block-bundle-summary .action.towishlist,
.product-item .action.towishlist,
.table-comparison .action.towishlist,
.product-item-actions .actions-secondary>.action,
.product-social-links .action.tocompare {
    color: #666;
    font-weight: 600;
    letter-spacing: .05em;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none
}

.abs-actions-addto:before,
.bundle-options-container .block-bundle-summary .product-addto-links>.action:before,
.wishlist.split.button>.action.split:before,
.product-social-links .action.mailto.friend:before,
.product-social-links .action.towishlist:before,
.block-bundle-summary .action.towishlist:before,
.product-item .action.towishlist:before,
.table-comparison .action.towishlist:before,
.product-item-actions .actions-secondary>.action:before,
.product-social-links .action.tocompare:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    font-family: 'Font Awesome 5 Pro';
    margin: -2px 5px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.bundle-options-container .block-bundle-summary .product-addto-links>.action:hover,
.wishlist.split.button>.action.split:hover,
.product-social-links .action.mailto.friend:hover,
.product-social-links .action.towishlist:hover,
.block-bundle-summary .action.towishlist:hover,
.product-item .action.towishlist:hover,
.table-comparison .action.towishlist:hover,
.product-item-actions .actions-secondary>.action:hover,
.product-social-links .action.tocompare:hover {
    color: #333;
    text-decoration: none
}

.bundle-options-container .block-bundle-summary .product-addto-links>.action:before,
.wishlist.split.button>.action.split:before,
.product-social-links .action.mailto.friend:before,
.product-social-links .action.towishlist:before,
.block-bundle-summary .action.towishlist:before,
.product-item .action.towishlist:before,
.table-comparison .action.towishlist:before,
.product-item-actions .actions-secondary>.action:before,
.product-social-links .action.tocompare:before {
    width: 18px
}

.bundle-options-container .block-bundle-summary .box-tocart,
.box-tocart {
    margin: 0 0 30px
}

.abs-cart-block>.title,
.minicart-items .product .toggle,
.abs-discount-block .block>.title,
.paypal-review-discount .block>.title,
.cart-discount .block>.title {
    border-top: 1px solid #ccc;
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
    padding: 10px 40px 10px 15px;
    display: block;
    text-decoration: none
}

.abs-cart-block>.title:after,
.minicart-items .product .toggle:after,
.abs-discount-block .block>.title:after,
.paypal-review-discount .block>.title:after,
.cart-discount .block>.title:after {
    position: absolute;
    right: 20px;
    top: 10px
}

.abs-toggling-title>span,
.abs-cart-block>.title>span,
.minicart-items .product .toggle>span,
.abs-discount-block .block>.title>span,
.paypal-review-discount .block>.title>span,
.cart-discount .block>.title>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.abs-toggling-title:after,
.abs-cart-block>.title:after,
.minicart-items .product .toggle:after,
.abs-discount-block .block>.title:after,
.paypal-review-discount .block>.title:after,
.cart-discount .block>.title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 3px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.abs-discount-block .block,
.paypal-review-discount .block,
.cart-discount .block {
    margin: 0
}

.abs-discount-block .block>.title:after,
.paypal-review-discount .block>.title:after,
.cart-discount .block>.title:after {
    color: #858585
}

.abs-discount-block .block .content,
.paypal-review-discount .block .content,
.cart-discount .block .content {
    display: none;
    padding: 5px 15px 23px;
    position: relative
}

.abs-cart-block.active>.title:after,
.abs-discount-block .block.active>.title:after,
.paypal-review-discount .block.active>.title:after,
.cart-discount .block.active>.title:after {
    content: '\f077'
}

.abs-discount-block .block.active .content,
.paypal-review-discount .block.active .content,
.cart-discount .block.active .content {
    display: block
}

.multicheckout .table-wrapper .cart-price {
    color: #7d7d7d;
    font-size: 1.6rem;
    font-weight: 700
}

.multicheckout .table-wrapper .product-item-name {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0
}

.abs-account-blocks .block-title,
.form-giftregistry-search .legend,
.block-giftregistry-results .block-title,
.block-giftregistry-shared-items .block-title,
.block-wishlist-search-form .block-title,
.block-wishlist-search-results .block-title,
.multicheckout .block-title,
.multicheckout .block-content .title,
.customer-review .review-details .title,
.paypal-review .block .block-title,
.multicheckout .block-title,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title,
.sales-guest-view .column.main .block:not(.widget) .block-title {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 25px;
    padding-bottom: 10px
}

.abs-account-blocks .block-title>strong,
.abs-account-blocks .block-title>span,
.form-giftregistry-search .legend>strong,
.form-giftregistry-search .legend>span,
.block-giftregistry-results .block-title>strong,
.block-giftregistry-results .block-title>span,
.block-giftregistry-shared-items .block-title>strong,
.block-giftregistry-shared-items .block-title>span,
.block-wishlist-search-form .block-title>strong,
.block-wishlist-search-form .block-title>span,
.block-wishlist-search-results .block-title>strong,
.block-wishlist-search-results .block-title>span,
.multicheckout .block-title>strong,
.multicheckout .block-title>span,
.multicheckout .block-content .title>strong,
.multicheckout .block-content .title>span,
.customer-review .review-details .title>strong,
.customer-review .review-details .title>span,
.paypal-review .block .block-title>strong,
.paypal-review .block .block-title>span,
.multicheckout .block-title>strong,
.multicheckout .block-title>span,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title>strong,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title>span,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title>strong,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title>span,
.sales-guest-view .column.main .block:not(.widget) .block-title>strong,
.sales-guest-view .column.main .block:not(.widget) .block-title>span {
    font-size: 2.2rem;
    font-weight: 300
}

.block-addresses-list address,
.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-address-billing .box-content,
.box-address-shipping .box-content,
.box-information .box-content,
.box-newsletter .box-content {
    line-height: 24px
}

.paypal-review .table-paypal-review-items .col.price .price-including-tax,
.paypal-review .table-paypal-review-items .col.price .price-excluding-tax,
.paypal-review .table-paypal-review-items .col.subtotal .price-including-tax,
.paypal-review .table-paypal-review-items .col.subtotal .price-excluding-tax,
.cart.table-wrapper .col.price .price-including-tax,
.cart.table-wrapper .col.price .price-excluding-tax,
.cart.table-wrapper .col.subtotal .price-including-tax,
.cart.table-wrapper .col.subtotal .price-excluding-tax,
.cart.table-wrapper .col.msrp .price-including-tax,
.cart.table-wrapper .col.msrp .price-excluding-tax {
    color: #666;
    font-size: 1.8rem;
    display: block;
    line-height: 1;
    white-space: nowrap
}

.paypal-review .table-paypal-review-items .col.price .price-including-tax .price,
.paypal-review .table-paypal-review-items .col.price .price-excluding-tax .price,
.paypal-review .table-paypal-review-items .col.subtotal .price-including-tax .price,
.paypal-review .table-paypal-review-items .col.subtotal .price-excluding-tax .price,
.cart.table-wrapper .col.price .price-including-tax .price,
.cart.table-wrapper .col.price .price-excluding-tax .price,
.cart.table-wrapper .col.subtotal .price-including-tax .price,
.cart.table-wrapper .col.subtotal .price-excluding-tax .price,
.cart.table-wrapper .col.msrp .price-including-tax .price,
.cart.table-wrapper .col.msrp .price-excluding-tax .price {
    font-weight: 700
}

.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax,
.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-excluding-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-excluding-tax,
.gift-summary .price-including-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-including-tax,
.gift-options .price-excluding-tax {
    font-size: 1.4rem;
    display: inline-block
}

.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax+.price-excluding-tax,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax+.price-excluding-tax,
.gift-summary .price-including-tax+.price-excluding-tax,
.gift-options .price-including-tax+.price-excluding-tax {
    font-size: 1.1rem;
    display: inline-block
}

.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax+.price-excluding-tax:before,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax+.price-excluding-tax:before,
.gift-summary .price-including-tax+.price-excluding-tax:before,
.gift-options .price-including-tax+.price-excluding-tax:before {
    content: '(' attr(data-label) ': '
}

.bundle-options-container .product-options-wrapper .fieldset-bundle-options .field.choice .price-notice .price-including-tax+.price-excluding-tax:after,
.bundle-options-container .product-options-wrapper .field.choice .price-notice .price-including-tax+.price-excluding-tax:after,
.gift-summary .price-including-tax+.price-excluding-tax:after,
.gift-options .price-including-tax+.price-excluding-tax:after {
    content: ')'
}

.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total,
.paypal-review .table-paypal-review-items .cart-tax-total,
.cart.table-wrapper .cart-tax-total,
.order-details-items .cart-tax-total {
    cursor: pointer;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    text-decoration: none
}

.abs-tax-total:after,
.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after,
.paypal-review .table-paypal-review-items .cart-tax-total:after,
.cart.table-wrapper .cart-tax-total:after,
.order-details-items .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 8px;
    line-height: 8px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.price-including-tax .cart-tax-total:after,
.price-excluding-tax .cart-tax-total:after,
.paypal-review .table-paypal-review-items .cart-tax-total:after,
.cart.table-wrapper .cart-tax-total:after,
.order-details-items .cart-tax-total:after {
    position: absolute;
    right: -2px;
    top: 4px
}

.abs-tax-total-expanded:after,
.price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after,
.paypal-review .table-paypal-review-items .cart-tax-total-expanded:after,
.cart.table-wrapper .cart-tax-total-expanded:after,
.order-details-items .cart-tax-total-expanded:after {
    content: '\f077'
}

.giftcard-account .form-giftcard-redeem .fieldset,
.form-giftregistry-create .fieldset {
    margin-bottom: 20px
}

.review-ratings .rating-summary,
.customer-review .review-details .customer-review-rating .rating-summary {
    display: table-row
}

.review-ratings .rating-label,
.customer-review .review-details .customer-review-rating .rating-label {
    display: table-cell;
    padding-bottom: 5px;
    padding-right: 25px;
    padding-top: 1px;
    vertical-align: top
}

.review-ratings .rating-result,
.customer-review .review-details .customer-review-rating .rating-result {
    display: table-cell;
    vertical-align: top
}

.block-addresses-list .items.addresses .item.actions .action:after,
.account .data.table .col.actions .action:after,
[class^='sales-guest-'] .data.table .col.actions .action:after,
.sales-guest-view .data.table .col.actions .action:after {
    border-left: 1px solid #a6a6a6;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 10px;
    vertical-align: -1px
}

.block-addresses-list .items.addresses .item.actions .action:last-child:after,
.account .data.table .col.actions .action:last-child:after,
[class^='sales-guest-'] .data.table .col.actions .action:last-child:after,
.sales-guest-view .data.table .col.actions .action:last-child:after {
    display: none
}

.paypal-review .block .block-title>.action,
.multicheckout .block-title>.action,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-title>.action,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-title>.action,
.sales-guest-view .column.main .block:not(.widget) .block-title>.action {
    margin-left: 15px
}

.paypal-review .block .box-title,
.multicheckout .box-title,
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title,
.sales-guest-view .column.main .block:not(.widget) .box-title {
    display: inline-block;
    margin: 0 0 10px
}

.paypal-review .block .box-title>span,
.multicheckout .box-title>span,
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title>span,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title>span,
.sales-guest-view .column.main .block:not(.widget) .box-title>span {
    font-size: 1.6rem
}

.paypal-review .block .box-title>.action,
.multicheckout .box-title>.action,
.magento-rma-guest-returns .column.main .block:not(.widget) .box-title>.action,
[class^='sales-guest-'] .column.main .block:not(.widget) .box-title>.action,
.sales-guest-view .column.main .block:not(.widget) .box-title>.action {
    font-weight: 400;
    margin-left: 10px
}

.block-minicart .subtotal .label:after,
.minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after,
.order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after,
.block-wishlist-management .wishlist-select .wishlist-name-label:after {
    content: ': '
}

.abs-dropdown-items-new .action.new,
.wishlist.split.button .action.new,
.wishlist-dropdown .action.new {
    display: inline-block;
    text-decoration: none
}

.abs-icon-add:before,
.abs-dropdown-items-new .action.new:before,
.wishlist.split.button .action.new:before,
.wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: '\e61c';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.wishlist.split.button .items .item:last-child:hover,
.wishlist-dropdown .items .item:last-child:hover {
    background: #e8e8e8
}

.wishlist.split.button .action.new:before,
.wishlist-dropdown .action.new:before {
    margin-left: -17px;
    margin-right: 5px
}

.abs-sidebar-totals .table-caption,
.minicart-items .product-item-details .weee[data-label] .label,
.page-product-giftcard .fieldset.giftcard>.legend+br,
.order-details-items .order-items .order-gift-message .action.close,
.multicheckout .block .methods-shipping .item-content .fieldset>.legend+br,
.multicheckout .hidden,
[class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle,
.sidebar .subtitle,
.no-display,
.cart-totals .table-caption,
.opc-block-summary .table-totals .table-caption {
    display: none
}

.return-status,
.order-status {
    border: 2px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    margin: 3px 0 0;
    padding: 2px 10px;
    text-transform: uppercase;
    vertical-align: top
}

.magento-rma-returns-returns .page-main .page-title-wrapper .page-title,
.magento-rma-returns-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title,
.account .page-main .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-main .page-title-wrapper .page-title,
.sales-guest-view .page-main .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-main .page-title-wrapper .page-title {
    margin-right: 25px
}

.magento-rma-returns-returns .page-main .page-title-wrapper .order-date,
.magento-rma-returns-view .page-main .page-title-wrapper .order-date,
.magento-rma-guest-returns .page-main .page-title-wrapper .order-date,
.account .page-main .page-title-wrapper .order-date,
[class^='sales-guest-'] .page-main .page-title-wrapper .order-date,
.sales-guest-view .page-main .page-title-wrapper .order-date,
.magento-rma-guest-returns .page-main .page-title-wrapper .order-date {
    font-size: 16px;
    margin-bottom: 10px
}

.magento-rma-returns-returns .page-main .page-title-wrapper .order-date .label,
.magento-rma-returns-view .page-main .page-title-wrapper .order-date .label,
.magento-rma-guest-returns .page-main .page-title-wrapper .order-date .label,
.account .page-main .page-title-wrapper .order-date .label,
[class^='sales-guest-'] .page-main .page-title-wrapper .order-date .label,
.sales-guest-view .page-main .page-title-wrapper .order-date .label,
.magento-rma-guest-returns .page-main .page-title-wrapper .order-date .label {
    display: none
}

.block-giftregistry-results .data.table,
.block-wishlist-search-results .data.table,
.account .column.main .order-details-items .table-wrapper .data.table,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table {
    border: none
}

.abs-table-striped>tbody>tr:nth-child(even)>td,
.abs-table-striped>tbody>tr:nth-child(even)>th,
.block-giftregistry-results .data.table>tbody>tr:nth-child(even)>td,
.block-giftregistry-results .data.table>tbody>tr:nth-child(even)>th,
.block-wishlist-search-results .data.table>tbody>tr:nth-child(even)>td,
.block-wishlist-search-results .data.table>tbody>tr:nth-child(even)>th,
.account .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>td,
.account .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>th,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>td,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>th,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>td,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>th,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>td,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tbody>tr:nth-child(even)>th {
    background: #f6f6f6
}

.abs-table-striped>thead>tr>th,
.abs-table-striped>tbody>tr>th,
.abs-table-striped>tfoot>tr>th,
.abs-table-striped>thead>tr>td,
.abs-table-striped>tbody>tr>td,
.abs-table-striped>tfoot>tr>td,
.block-giftregistry-results .data.table>thead>tr>th,
.block-giftregistry-results .data.table>tbody>tr>th,
.block-giftregistry-results .data.table>tfoot>tr>th,
.block-giftregistry-results .data.table>thead>tr>td,
.block-giftregistry-results .data.table>tbody>tr>td,
.block-giftregistry-results .data.table>tfoot>tr>td,
.block-wishlist-search-results .data.table>thead>tr>th,
.block-wishlist-search-results .data.table>tbody>tr>th,
.block-wishlist-search-results .data.table>tfoot>tr>th,
.block-wishlist-search-results .data.table>thead>tr>td,
.block-wishlist-search-results .data.table>tbody>tr>td,
.block-wishlist-search-results .data.table>tfoot>tr>td,
.account .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.account .column.main .order-details-items .table-wrapper .data.table>tbody>tr>th,
.account .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>th,
.account .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
.account .column.main .order-details-items .table-wrapper .data.table>tbody>tr>td,
.account .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>td,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tbody>tr>th,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>th,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tbody>tr>td,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>td,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tbody>tr>th,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>th,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tbody>tr>td,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>td,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tbody>tr>th,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>th,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tbody>tr>td,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>tfoot>tr>td {
    border: none
}

.abs-table-striped>thead>tr>th,
.abs-table-striped>thead>tr>td,
.block-giftregistry-results .data.table>thead>tr>th,
.block-giftregistry-results .data.table>thead>tr>td,
.block-wishlist-search-results .data.table>thead>tr>th,
.block-wishlist-search-results .data.table>thead>tr>td,
.account .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.account .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table>thead>tr>td,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>thead>tr>th,
.magento-rma-guest-returns .column.main .order-details-items .table-wrapper .data.table>thead>tr>td {
    border-bottom: 1px solid #ccc
}

.block-wishlist .counter,
.block-compare .counter {
    color: #6d6d6d;
    font-size: 1.2rem;
    vertical-align: middle;
    white-space: nowrap
}

.block-wishlist .counter:before,
.block-compare .counter:before {
    content: '('
}

.block-wishlist .counter:after,
.block-compare .counter:after {
    content: ')'
}

.block-wishlist .block-title,
.block-compare .block-title,
.block-reorder .block-title,
.widget .block-title {
    margin: 0 0 20px
}

.block-wishlist .block-title strong,
.block-compare .block-title strong,
.block-reorder .block-title strong,
.widget .block-title strong {
    font-size: 18px;
    font-weight: 300;
    vertical-align: middle
}

.block-cart-failed,
.cart-container .form-cart {
    margin-bottom: 20px
}

.block-cart-failed .actions.main .continue,
.block-cart-failed .actions.main .clear,
.cart-container .form-cart .actions.main .continue,
.cart-container .form-cart .actions.main .clear {
    display: none
}

.form-giftregistry-edit .field.date .control,
.form.search.advanced .fields.range .field.date .control {
    position: relative
}

.abs-field-date input,
.field.date ._has-datepicker,
.field-dob ._has-datepicker,
.form-giftregistry-search .fields-specific-options .datetime-picker,
.form-giftregistry-edit .field.date input,
.form.search.advanced .fields.range .field.date input {
    margin-right: 10px;
    width: calc(100% - 33px)
}

.field .control._with-tooltip {
    position: relative
}

.field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 36px)
}

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content,
.shipping-policy-block.field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto
}

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0
}

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before,
.shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #666
}

.checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after,
.shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px
}

.opc-block-summary>.title,
.checkout-agreements-items .checkout-agreements-item-title {
    padding-bottom: 10px;
    color: #333;
    font-weight: 700;
    font-size: 2.3rem
}

.cart-totals,
.opc-block-summary .table-totals {
    border-top: 1px solid #ccc;
    padding-top: 10px
}

.cart-totals tbody .mark,
.cart-totals tfoot .mark,
.opc-block-summary .table-totals tbody .mark,
.opc-block-summary .table-totals tfoot .mark {
    border: 0;
    font-weight: 400;
    padding: 6px 0
}

.cart-totals tbody .amount,
.cart-totals tfoot .amount,
.opc-block-summary .table-totals tbody .amount,
.opc-block-summary .table-totals tfoot .amount {
    border: 0;
    font-weight: 400;
    padding: 6px 0 6px 14px;
    text-align: right;
    white-space: nowrap
}

.cart-totals .grand th,
.cart-totals .grand td,
.opc-block-summary .table-totals .grand th,
.opc-block-summary .table-totals .grand td {
    padding: 11px 0
}

.cart-totals .grand strong,
.opc-block-summary .table-totals .grand strong {
    display: inline-block;
    font-weight: 600;
    padding: 3px 0 0
}

.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark {
    font-size: 1.8rem;
    border-top: 1px solid #ccc;
    padding-right: 10px
}

.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
    font-size: 1.8rem;
    border-top: 1px solid #ccc
}

.cart-totals .msrp,
.opc-block-summary .table-totals .msrp {
    margin-bottom: 10px
}

.cart-totals tbody tr:last-child td,
.opc-block-summary .table-totals tbody tr:last-child td {
    padding-bottom: 19px
}

.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .mark,
.opc-block-summary .table-totals .totals-tax-summary .amount {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    cursor: pointer
}

.cart-totals .totals-tax-summary .amount .price,
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
    padding-right: 20px;
    position: relative;
    display: inline-block;
    text-decoration: none
}

.abs-sidebar-totals .totals-tax-summary .amount .price>span,
.cart-totals .totals-tax-summary .amount .price>span,
.opc-block-summary .table-totals .totals-tax-summary .amount .price>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.abs-sidebar-totals .totals-tax-summary .amount .price:after,
.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.cart-totals .totals-tax-summary .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: 3px;
    top: 3px
}

.cart-totals .totals-tax-summary.expanded .mark,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
    border-bottom: 0
}

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after,
.cart-totals .totals-tax-summary.expanded .amount .price:after,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
    content: '\f077'
}

.cart-totals .totals-tax-details,
.opc-block-summary .table-totals .totals-tax-details {
    border-bottom: 1px solid #ccc;
    display: none
}

.cart-totals .totals-tax-details.shown,
.opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row
}

.cart-totals .table-wrapper,
.opc-block-summary .table-totals .table-wrapper {
    margin-bottom: 0
}

.paypal-review-discount .block>.title strong,
.cart-discount .block>.title strong {
    color: #b74f0e;
    font-weight: 400
}

.paypal-review-discount .actions-toolbar,
.cart-discount .actions-toolbar {
    display: table-cell;
    vertical-align: top;
    width: 1%
}

.abs-discount-block .actions-toolbar .amshopby-button,
.abs-discount-block .actions-toolbar .am-filter-go,
.paypal-review-discount .actions-toolbar .action.primary,
.paypal-review-discount .actions-toolbar .action.cancel,
.cart-discount .actions-toolbar .action.primary,
.cart-discount .actions-toolbar .action.cancel {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin: 0 0 0 -1px;
    white-space: nowrap;
    width: auto
}

.paypal-review-discount .actions-toolbar .secondary,
.cart-discount .actions-toolbar .secondary {
    bottom: 5px;
    left: 15px;
    position: absolute
}

.paypal-review-discount .action.check,
.cart-discount .action.check {
    font-weight: 400
}

.paypal-review-discount .fieldset,
.cart-discount .fieldset {
    display: table;
    width: 100%
}

.paypal-review-discount .fieldset>.field>.label,
.cart-discount .fieldset>.field>.label {
    display: none
}

.paypal-review-discount .field,
.cart-discount .field {
    display: table-cell
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-thin.woff2') format('woff2'), url('../fonts/lato-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-thinitalic.woff2') format('woff2'), url('../fonts/lato-thinitalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-light.woff2') format('woff2'), url('../fonts/lato-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-lightitalic.woff2') format('woff2'), url('../fonts/lato-lightitalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular.woff2') format('woff2'), url('../fonts/lato-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regularitalic.woff2') format('woff2'), url('../fonts/lato-regularitalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bold.woff2') format('woff2'), url('../fonts/lato-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bolditalic.woff2') format('woff2'), url('../fonts/lato-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-black.woff2') format('woff2'), url('../fonts/lato-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-blackitalic.woff2') format('woff2'), url('../fonts/lato-blackitalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap
}

h1 {
    font-weight: 700
}

.columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box
}

.columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden
}

.columns .column.main {
    padding-bottom: 40px;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100%
}

.columns .sidebar-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1
}

.columns .sidebar-additional {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2
}

.table-wrapper {
    margin-bottom: 20px
}

table>caption {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.table tfoot>tr:first-child th,
.table tfoot>tr:first-child td {
    border-top: 1px solid #ccc;
    padding-top: 20px
}

.table tfoot th,
.table tfoot td {
    border: 0
}

.table tfoot .mark {
    font-weight: 400;
    text-align: right
}

.message.info {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: #fdf0d5;
    border-color: #1b1c1d;
    color: #6f4400;
    padding-left: 45px;
    position: relative
}

.message.info a {
    color: #b74f0e
}

.message.info a:hover {
    color: #b74f0e
}

.message.info a:active {
    color: #b74f0e
}

.message.info>*:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 24px;
    color: #c07600;
    content: '\f071';
    font-family: 'Font Awesome 5 Pro';
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center
}

.message.error {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: none;
    border-color: #b74f0e;
    color: #e02b27;
    padding-left: 45px;
    position: relative
}

.message.error a {
    color: #b74f0e
}

.message.error a:hover {
    color: #b74f0e
}

.message.error a:active {
    color: #b74f0e
}

.message.error>*:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 24px;
    color: #b30000;
    content: '\f071';
    font-family: 'Font Awesome 5 Pro';
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center
}

.message.warning {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: #fdf0d5;
    border-color: #1b1c1d;
    color: #6f4400;
    padding-left: 45px;
    position: relative
}

.message.warning a {
    color: #b74f0e
}

.message.warning a:hover {
    color: #b74f0e
}

.message.warning a:active {
    color: #b74f0e
}

.message.warning>*:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 24px;
    color: #c07600;
    content: '\f071';
    font-family: 'Font Awesome 5 Pro';
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center
}

.message.notice {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: #fdf0d5;
    border-color: #1b1c1d;
    color: #6f4400;
    padding-left: 45px;
    position: relative
}

.message.notice a {
    color: #b74f0e
}

.message.notice a:hover {
    color: #b74f0e
}

.message.notice a:active {
    color: #b74f0e
}

.message.notice>*:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 24px;
    color: #c07600;
    content: '\f071';
    font-family: 'Font Awesome 5 Pro';
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center
}

.message.success {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: none;
    border-color: #809111;
    color: #006400;
    padding-left: 45px;
    position: relative
}

.message.success a {
    color: #b74f0e
}

.message.success a:hover {
    color: #b74f0e
}

.message.success a:active {
    color: #b74f0e
}

.message.success>*:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 24px;
    color: #006400;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    margin: -12px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    left: 0;
    top: 22px;
    width: 45px;
    position: absolute;
    text-align: center
}

.panel.header .links,
.panel.header .switcher {
    display: none
}

.nav-sections {
    background: #f0f0f0
}

.nav-toggle {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14
}

.nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #333;
    content: '\e609';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.nav-toggle:hover:before {
    color: #000
}

.nav-toggle>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.tooltip.wrapper {
    position: relative
}

.tooltip.wrapper .tooltip.content {
    background: #fff;
    border-radius: 5px;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%
}

.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0
}

.tooltip.wrapper .tooltip.content:after {
    border-width: 5px;
    border-color: transparent
}

.tooltip.wrapper .tooltip.content:before {
    border-width: 6px;
    border-color: transparent
}

.tooltip.wrapper .tooltip.content:after,
.tooltip.wrapper .tooltip.content:before {
    bottom: 100%
}

.tooltip.wrapper .tooltip.content:after {
    border-bottom-color: #fff;
    left: 15px;
    margin-left: -5px
}

.tooltip.wrapper .tooltip.content:before {
    border-bottom-color: #bbb;
    left: 15px;
    margin-left: -6px
}

.tooltip.wrapper .tooltip.toggle {
    cursor: help
}

.tooltip.wrapper .tooltip.toggle:hover+.tooltip.content,
.tooltip.wrapper .tooltip.toggle:focus+.tooltip.content,
.tooltip.wrapper:hover .tooltip.content {
    display: block
}

.tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0
}

.tooltip.wrapper .tooltip.content dd {
    white-space: normal
}

.tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px
}

.tooltip.wrapper .tooltip.content .label {
    margin-top: 10px
}

.tooltip.wrapper .tooltip.content .label:first-child {
    margin-top: 0
}

.tooltip.wrapper .tooltip.content .values {
    margin: 0
}

.ui-tooltip {
    position: absolute;
    z-index: 9999
}

.load.indicator {
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    position: absolute
}

.load.indicator:before {
    background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.load.indicator>span {
    display: none
}

.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.5)
}

.loading-mask .loader>img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100
}

.loading-mask .loader>p {
    display: none
}

body>.loading-mask {
    z-index: 9999
}

._block-content-loading {
    position: relative
}

[data-role='main-css-loader'] {
    display: none
}

.fieldset {
    border: 0;
    margin: 0 0 40px;
    padding: 0;
    letter-spacing: -.31em
}

.fieldset>* {
    letter-spacing: normal
}

.fieldset>.legend {
    margin: 0 0 20px;
    padding: 0 0 10px;
    width: 100%;
    box-sizing: border-box;
    float: left;
    font-weight: 300;
    line-height: 1.2;
    font-size: 1.8rem
}

.fieldset>.legend+br {
    clear: both;
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden
}

.fieldset:last-child {
    margin-bottom: 20px
}

.fieldset>.field,
.fieldset>.fields>.field {
    margin: 0 0 20px
}

.fieldset>.field>.label,
.fieldset>.fields>.field>.label {
    margin: 0 0 8px;
    display: inline-block
}

.fieldset>.field:last-child,
.fieldset>.fields>.field:last-child {
    margin-bottom: 0
}

.fieldset>.field>.label,
.fieldset>.fields>.field>.label {
    font-weight: 600
}

.fieldset>.field>.label+br,
.fieldset>.fields>.field>.label+br {
    display: none
}

.fieldset>.field .choice input,
.fieldset>.fields>.field .choice input {
    vertical-align: top
}

.fieldset>.field .fields.group:before,
.fieldset>.fields>.field .fields.group:before,
.fieldset>.field .fields.group:after,
.fieldset>.fields>.field .fields.group:after {
    content: '';
    display: table
}

.fieldset>.field .fields.group:after,
.fieldset>.fields>.field .fields.group:after {
    clear: both
}

.fieldset>.field .fields.group .field,
.fieldset>.fields>.field .fields.group .field {
    box-sizing: border-box;
    float: left
}

.fieldset>.field .fields.group.group-2 .field,
.fieldset>.fields>.field .fields.group.group-2 .field {
    width: 50% !important
}

.fieldset>.field .fields.group.group-3 .field,
.fieldset>.fields>.field .fields.group.group-3 .field {
    width: 33.3% !important
}

.fieldset>.field .fields.group.group-4 .field,
.fieldset>.fields>.field .fields.group.group-4 .field {
    width: 25% !important
}

.fieldset>.field .fields.group.group-5 .field,
.fieldset>.fields>.field .fields.group.group-5 .field {
    width: 20% !important
}

.fieldset>.field .addon,
.fieldset>.fields>.field .addon {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%
}

.fieldset>.field .addon textarea,
.fieldset>.fields>.field .addon textarea,
.fieldset>.field .addon select,
.fieldset>.fields>.field .addon select,
.fieldset>.field .addon input,
.fieldset>.fields>.field .addon input {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: inline-block;
    margin: 0;
    width: auto
}

.fieldset>.field .addon .addbefore,
.fieldset>.fields>.field .addon .addbefore,
.fieldset>.field .addon .addafter,
.fieldset>.fields>.field .addon .addafter {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: auto
}

.fieldset>.field .addon .addbefore:disabled,
.fieldset>.fields>.field .addon .addbefore:disabled,
.fieldset>.field .addon .addafter:disabled,
.fieldset>.fields>.field .addon .addafter:disabled {
    opacity: .5
}

.fieldset>.field .addon .addbefore::-moz-placeholder,
.fieldset>.fields>.field .addon .addbefore::-moz-placeholder,
.fieldset>.field .addon .addafter::-moz-placeholder,
.fieldset>.fields>.field .addon .addafter::-moz-placeholder {
    color: #575757
}

.fieldset>.field .addon .addbefore::-webkit-input-placeholder,
.fieldset>.fields>.field .addon .addbefore::-webkit-input-placeholder,
.fieldset>.field .addon .addafter::-webkit-input-placeholder,
.fieldset>.fields>.field .addon .addafter::-webkit-input-placeholder {
    color: #575757
}

.fieldset>.field .addon .addbefore:-ms-input-placeholder,
.fieldset>.fields>.field .addon .addbefore:-ms-input-placeholder,
.fieldset>.field .addon .addafter:-ms-input-placeholder,
.fieldset>.fields>.field .addon .addafter:-ms-input-placeholder {
    color: #575757
}

.fieldset>.field .addon .addbefore,
.fieldset>.fields>.field .addon .addbefore {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1
}

.fieldset>.field .additional,
.fieldset>.fields>.field .additional {
    margin-top: 10px
}

.fieldset>.field.required>.label:after,
.fieldset>.fields>.field.required>.label:after,
.fieldset>.field._required>.label:after,
.fieldset>.fields>.field._required>.label:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px
}

.fieldset>.field .note,
.fieldset>.fields>.field .note {
    font-size: 1.2rem;
    margin: 3px 0 0;
    padding: 0;
    display: inline-block;
    text-decoration: none
}

.fieldset>.field .note:before,
.fieldset>.fields>.field .note:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 12px;
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.fieldset>.field.no-label>.label,
.fieldset>.fields>.field.no-label>.label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.fieldset>.field.choice .label,
.fieldset>.fields>.field.choice .label {
    display: inline;
    font-weight: normal;
    margin: 0
}

.column:not(.main) .fieldset>.field .label,
.column:not(.main) .fieldset>.fields>.field .label {
    font-weight: normal
}

.fieldset>.field .field.choice,
.fieldset>.fields>.field .field.choice {
    margin-bottom: 10px
}

.fieldset>.field .field.choice:last-child,
.fieldset>.fields>.field .field.choice:last-child {
    margin-bottom: 0
}

.legend+.fieldset,
.legend+div {
    clear: both
}

.legend>span {
    margin-right: 5px
}

fieldset.field {
    border: 0;
    padding: 0
}

.field.date .time-picker {
    display: inline-block;
    margin-top: 10px;
    white-space: nowrap
}

.field .message.warning {
    margin-top: 10px
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-size: 30px 60px;
    text-indent: .01em;
    text-overflow: ''
}

select::-ms-expand {
    display: none
}

select.admin__control-multiselect {
    height: auto
}

.field-error,
div.mage-error[generated] {
    margin-top: 7px
}

.field-error {
    color: #e02b27;
    font-size: 1.2rem
}

.product-options-wrapper .date.required div[for*='options'].mage-error {
    display: none !important
}

.field .tooltip {
    position: relative
}

.field .tooltip .tooltip-content {
    background: #fff;
    border-radius: 5px;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0
}

.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0
}

.field .tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent
}

.field .tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent
}

.field .tooltip .tooltip-content:after,
.field .tooltip .tooltip-content:before {
    right: 100%
}

.field .tooltip .tooltip-content:after {
    border-right-color: #fff;
    margin-top: -5px;
    top: 15px
}

.field .tooltip .tooltip-content:before {
    border-right-color: #bbb;
    margin-top: -6px;
    top: 15px
}

.field .tooltip .tooltip-toggle {
    cursor: help
}

.field .tooltip .tooltip-toggle:hover+.tooltip-content,
.field .tooltip .tooltip-toggle:focus+.tooltip-content,
.field .tooltip:hover .tooltip-content {
    display: block
}

.field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal
}

input:focus~.tooltip .tooltip-content,
select:focus~.tooltip .tooltip-content {
    display: block
}

._has-datepicker~.ui-datepicker-trigger {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: block;
    text-decoration: none;
    display: inline-block;
    margin-top: -4px;
    vertical-align: middle
}

._has-datepicker~.ui-datepicker-trigger:focus,
._has-datepicker~.ui-datepicker-trigger:hover {
    background: none;
    border: none
}

._has-datepicker~.ui-datepicker-trigger:active {
    background: none;
    border: none
}

._has-datepicker~.ui-datepicker-trigger.disabled,
._has-datepicker~.ui-datepicker-trigger[disabled],
fieldset[disabled] ._has-datepicker~.ui-datepicker-trigger {
    pointer-events: none;
    opacity: .5
}

._has-datepicker~.ui-datepicker-trigger>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

._has-datepicker~.ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 23px;
    line-height: 23px;
    color: #7d7d7d;
    content: '\e612';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

._has-datepicker~.ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0
}

.sidebar .fieldset {
    margin: 0
}

.sidebar .fieldset>.field:not(.choice):not(:last-child),
.sidebar .fieldset .fields>.field:not(:last-child) {
    margin: 0 0 20px
}

.sidebar .fieldset>.field:not(.choice) .label,
.sidebar .fieldset .fields>.field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%
}

.sidebar .fieldset>.field:not(.choice) .control,
.sidebar .fieldset .fields>.field .control {
    width: 100%
}

/*
@font-face {
    font-family: 'Font Awesome 5 Pro';
    src: url('../fonts/font-awesome-5.woff2') format('woff2'), url('../fonts/font-awesome-5.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto
}*/

button,
a.action.primary,
a.amshopby-button,
a.am-filter-go {
    border-radius: 5px
}

body:not(._keyfocus) button:focus {
    box-shadow: none
}

a.action.primary,
a.amshopby-button,
a.am-filter-go {
    display: inline-block;
    text-decoration: none
}

a.action.primary:hover,
a.action.primary:active,
a.action.primary:focus,
a.amshopby-button:hover,
a.amshopby-button:active,
a.amshopby-button:focus,
a.am-filter-go:hover,
a.am-filter-go:active,
a.am-filter-go:focus {
    text-decoration: none
}

.action.added-to-cart{
    background-image: none;
    background: #5cb85c !important;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.action.primary,
.amshopby-button,
.am-filter-go {
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.action.primary:visited,
.amshopby-button:visited,
.am-filter-go:visited {
    color: #fff
}

.action.primary:focus,
.action.primary:hover,
.amshopby-button:focus,
.amshopby-button:hover,
.am-filter-go:focus,
.am-filter-go:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.action.primary:active,
.amshopby-button:active,
.am-filter-go:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.action.primary.disabled,
.action.primary[disabled],
fieldset[disabled] .action.primary,
.amshopby-button.disabled,
.amshopby-button[disabled],
fieldset[disabled] .amshopby-button,
.am-filter-go.disabled,
.am-filter-go[disabled],
fieldset[disabled] .am-filter-go {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.action.success {
    background-image: none;
    background: #b5cc18;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.action.success:visited {
    color: #fff
}

.action.success:focus,
.action.success:hover {
    background: #95a714;
    border: 0;
    color: #fff
}

.action.success:active {
    background: #809111;
    border: 0;
    color: #fff
}

.action.success.disabled,
.action.success[disabled],
fieldset[disabled] .action.success {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.product.data.items {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px
}

.product.data.items>.item.title {
    box-sizing: border-box;
    float: none;
    width: 100%
}

.product.data.items>.item.title>.switch {
    display: block
}

.product.data.items>.item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0
}

.product.data.items>.item.content:before,
.product.data.items>.item.content:after {
    content: '';
    display: table
}

.product.data.items>.item.content:after {
    clear: both
}

.product.data.items>.item.content.active {
    display: block
}

.product.data.items>.item.title {
    margin: 0
}

.product.data.items>.item.title>.switch {
    border-top: 1px solid #ccc;
    height: 40px;
    padding: 1px 10px 1px 10px;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.4rem;
    color: #333;
    text-decoration: none
}

.product.data.items>.item.title>.switch:visited {
    color: #6d6d6d;
    text-decoration: none
}

.product.data.items>.item.title>.switch:hover {
    color: #333;
    text-decoration: none
}

.product.data.items>.item.title>.switch:active {
    color: #333;
    text-decoration: none
}

.product.data.items>.item.title:not(.disabled)>.switch:active,
.product.data.items>.item.title.active>.switch,
.product.data.items>.item.title.active>.switch:focus,
.product.data.items>.item.title.active>.switch:hover {
    color: #333;
    text-decoration: none;
    padding-bottom: 1px
}

.product.data.items>.item.content {
    background: #fff;
    margin: 0;
    padding: 10px 10px 30px
}

.product.data.items .item.title a {
    position: relative;
    text-decoration: none
}

.product.data.items .item.title a:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: inherit;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.product.data.items .item.title a:after {
    position: absolute;
    right: 10px;
    top: 1px
}

.product.data.items .item.title.active a:after {
    content: '\f077'
}

.product.data.items .value p:last-child {
    margin-bottom: 0
}

.product.data.items .table-wrapper {
    margin: 0
}

.pages>.label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.pages .items {
    font-size: 0;
    letter-spacing: -1px;
    line-height: 0;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    list-style: none none;
    display: inline-block;
    font-weight: 700
}

.pages .item,
.dataTables_paginate .paginate_button.previous,
.dataTables_paginate .paginate_button.next,
.dataTables_paginate span .paginate_button {
    font-size: 1.6rem;
    line-height: 3rem;
    letter-spacing: normal;
    margin: 0 6px 0 0;
    display: inline-block
}

.pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.pages a.page,
.dataTables_paginate span .paginate_button {
    background: #fff;
    border: 2px solid #1b1c1d;
    color: #1b1c1d;
    display: inline-block;
    padding: 0 5px;
    min-width: 34px;
    text-decoration: none
}

.pages a.page:visited,
.dataTables_paginate span .paginate_button:visited {
    color: #1b1c1d
}

.pages a.page:hover,
.dataTables_paginate span .paginate_button:hover {
    border: 2px solid #4a4a4a;
    color: #4a4a4a;
    text-decoration: none
}

.pages a.page:active,
.dataTables_paginate span .paginate_button:active {
    border: 2px solid #3d3d3d;
    color: #3d3d3d
}

.pages strong.page,
.dataTables_paginate span .paginate_button.current {
    background: #4a4a4a;
    border: 2px solid #4a4a4a;
    font-size: 1.6rem;
    line-height: 3rem;
    letter-spacing: normal;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    padding: 0 5px;
    min-width: 34px
}

.pages .action,
.dataTables_paginate .paginate_button.previous,
.dataTables_paginate .paginate_button.next {
    background: #fff;
    border: 0;
    color: #e62645;
    display: inline-block;
    padding: 0;
    text-decoration: none
}

.pages .action:visited {
    color: #e62645;
}

.pages .action:hover {
    background: #fff;
    color: #e43866;
    text-decoration: none
}

.pages .action:active {
    background: #fff;
    color: #e43866;
}

.pages .action.next,
.dataTables_paginate .paginate_button.next {
    display: inline-block;
    text-decoration: none
}

.pages .action.next:visited:before {
    color: #e62645;
}

.pages .action.next:active:before {
    color: #e43866;
}

.pages .action.next>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.pages .action.next:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 32px;
    color: #e62645;
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.pages .action.next:hover:before {
    color: #9b440e
}

.pages .action.next:active:before {
    color: #e43866;
}

.pages .action.previous,
.dataTables_paginate .paginate_button.previous {
    display: inline-block;
    text-decoration: none
}

.pages .action.previous:visited:before {
    color: #e62645;
}

.pages .action.previous:active:before {
    color: #e43866;
}

.pages .action.previous>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.pages .action.previous:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 32px;
    color: #e62645;
    content: '\f104';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.pages .action.previous:hover:before {
    color: #9b440e
}

.pages .action.previous:active:before {
    color: #e43866;
}

.pages .page,
.dataTables_paginate span .paginate_button {
    border-radius: 5px
}

.pages .item,
.dataTables_paginate .paginate_button.previous,
.dataTables_paginate .paginate_button.next,
.dataTables_paginate span .paginate_button {
    vertical-align: middle;
    text-align: center
}

.pages .pages-item-prev {
    margin-left: 0
}

.pages .pages-item-next {
    margin-right: 0
}

.pages .pages-item-next .action,
.pages .pages-item-previous .action,
.pages .pages-item-next .action:hover,
.pages .pages-item-previous .action:hover,
.pages .pages-item-next .action:focus,
.pages .pages-item-previous .action:focus,
.pages .pages-item-next .action:active,
.pages .pages-item-previous .action:active {
    background: none
}

.pages .action,
.dataTables_paginate .paginate_button.previous,
.dataTables_paginate .paginate_button.next {
    border-radius: 3px;
    padding: 0 8px
}

.pages .action.previous,
.pages .action.next,
.dataTables_paginate .paginate_button.previous,
.dataTables_paginate .paginate_button.next {
    vertical-align: middle
}

.pages .action.previous,
.dataTables_paginate .paginate_button.previous {
    margin-right: 8px
}

.pages .action.next,
.dataTables_paginate .paginate_button.next {
    margin-left: 8px
}

.actions-toolbar>.primary,
.actions-toolbar>.secondary {
    margin-bottom: 10px;
    text-align: center
}

.actions-toolbar>.primary .action,
.actions-toolbar>.secondary .action {
    margin-bottom: 10px
}

.actions-toolbar>.primary .action:last-child,
.actions-toolbar>.secondary .action:last-child {
    margin-bottom: 0
}

.actions-toolbar>.primary:last-child,
.actions-toolbar>.secondary:last-child {
    margin-bottom: 0
}

.actions-toolbar>.secondary .action.back {
    display: none
}

.breadcrumbs {
    margin: 0 0 15px
}

.breadcrumbs .items {
    font-size: 1.2rem;
    color: #707070;
    margin: 0;
    padding: 0;
    list-style: none none
}

.breadcrumbs .items>li {
    display: inline-block;
    vertical-align: top
}

.breadcrumbs .item {
    margin: 0
}

.breadcrumbs a {
    color: #b74f0e;
    text-decoration: none
}

.breadcrumbs a:visited {
    color: #b74f0e;
    text-decoration: none
}

.breadcrumbs a:hover {
    color: #b74f0e;
    text-decoration: underline
}

.breadcrumbs a:active {
    color: #b74f0e;
    text-decoration: none
}

.breadcrumbs strong {
    font-weight: 400
}

.breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none
}

.breadcrumbs .item:not(:last-child):after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 18px;
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 10px;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.ui-dialog.popup .action.close {
    position: absolute;
    height: 40px;
    right: 0;
    top: 0;
    width: 40px;
    z-index: 1001;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.ui-dialog.popup .action.close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 16px;
    color: #8c8c8c;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.ui-dialog.popup .action.close:hover:before {
    color: #737373
}

.ui-dialog.popup .action.close:active:before {
    color: #737373
}

.ui-dialog.popup .action.close:focus,
.ui-dialog.popup .action.close:hover {
    background: none;
    border: none
}

.ui-dialog.popup .action.close:active {
    background: none;
    border: none
}

.ui-dialog.popup .action.close.disabled,
.ui-dialog.popup .action.close[disabled],
fieldset[disabled] .ui-dialog.popup .action.close {
    pointer-events: none;
    opacity: .5
}

.popup .actions-toolbar .action.cancel {
    font-weight: 400
}

.popup-pointer {
    margin-left: -14px;
    position: absolute;
    top: -14px
}

.popup-pointer:before,
.popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid
}

.popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.popup-pointer:after {
    left: 1px;
    top: 0px;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98
}

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
    display: inline
}

.price-including-tax,
.price-excluding-tax {
    display: block;
    font-size: 1.8rem;
    line-height: 1
}

.price-including-tax .price,
.price-excluding-tax .price {
    font-weight: 700
}

.price-including-tax+.price-excluding-tax,
.weee[data-label] {
    display: block;
    font-size: 1.8rem
}

.price-including-tax+.price-excluding-tax:before,
.weee[data-label]:before {
    content: attr(data-label) ': ';
    font-size: 1.1rem
}

.price-including-tax+.price-excluding-tax .price,
.weee[data-label] .price {
    font-size: 1.1rem
}

body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%
}

.modals-overlay {
    z-index: 899
}

.modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s .3s, opacity .3s ease;
    transition: visibility 0s .3s, opacity .3s ease
}

.modal-slide._show,
.modal-popup._show {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease
}

.modal-slide._show .modal-inner-wrap,
.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
    background-color: #fff;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto
}

.modal-slide {
    left: 44px;
    z-index: 900
}

.modal-slide._show .modal-inner-wrap {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    width: auto
}

.modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.modal-slide._inner-scroll .modal-header,
.modal-slide._inner-scroll .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0
}

.modal-slide._inner-scroll .modal-content {
    overflow-y: auto
}

.modal-slide._inner-scroll .modal-footer {
    margin-top: auto
}

.modal-slide .modal-header,
.modal-slide .modal-content,
.modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem
}

.modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem
}

.modal-popup {
    z-index: 900;
    left: 0;
    overflow-y: auto
}

.modal-popup.confirm .modal-inner-wrap {
    max-width: 50rem
}

.modal-popup.confirm .modal-inner-wrap .modal-content {
    padding-right: 7rem
}

.modal-popup._show .modal-inner-wrap {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.modal-popup .modal-inner-wrap {
    margin: 20px auto;
    width: 75%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
    -webkit-transition: -webkit-transform .2s ease;
    transition: transform .2s ease
}

.modal-popup._inner-scroll {
    overflow-y: visible
}

.ie11 .modal-popup._inner-scroll {
    overflow-y: auto
}

.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 90%
}

.ie11 .modal-popup._inner-scroll .modal-inner-wrap {
    max-height: none
}

.modal-popup._inner-scroll .modal-content {
    overflow-y: auto
}

.modal-popup .modal-header,
.modal-popup .modal-content,
.modal-popup .modal-footer {
    padding-left: 20px;
    padding-right: 20px
}

.modal-popup .modal-header,
.modal-popup .modal-footer {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0
}

.modal-popup .modal-header {
    padding-bottom: 8px;
    padding-top: 20px
}

.modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 20px;
    padding-top: 20px
}

.modal-popup .modal-footer-actions {
    text-align: right
}

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
    font-size: 20px
}

.modal-custom .action-close:focus,
.modal-popup .action-close:focus,
.modal-slide .action-close:focus,
.modal-custom .action-close:hover,
.modal-popup .action-close:hover,
.modal-slide .action-close:hover {
    background: none;
    border: none
}

.modal-custom .action-close:active,
.modal-popup .action-close:active,
.modal-slide .action-close:active {
    background: none;
    border: none
}

.modal-custom .action-close.disabled,
.modal-popup .action-close.disabled,
.modal-slide .action-close.disabled,
.modal-custom .action-close[disabled],
.modal-popup .action-close[disabled],
.modal-slide .action-close[disabled],
fieldset[disabled] .modal-custom .action-close,
fieldset[disabled] .modal-popup .action-close,
fieldset[disabled] .modal-slide .action-close {
    pointer-events: none;
    opacity: .5
}

.modal-custom .action-close>span,
.modal-popup .action-close>span,
.modal-slide .action-close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
    line-height: 1;
    color: #333;
    content: '\f057';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: inherit
}

.modal-custom .action-close:active:before,
.modal-popup .action-close:active:before,
.modal-slide .action-close:active:before {
    color: inherit
}

.modal-custom .action-close:hover:before,
.modal-popup .action-close:hover:before,
.modal-slide .action-close:hover:before {
    color: #1a1a1a
}

.modal-popup {
    pointer-events: none
}

.modal-popup .modal-title {
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word
}

.modal-popup .action-close {
    margin-top: 10px;
    margin-right: 10px;
    padding: 15px 10px
}

.modal-slide .page-main-actions {
    margin-bottom: -12.9rem;
    margin-top: 2.1rem
}

.modals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0
}

body._has-modal-custom .modal-custom-overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 899
}

.login-container .g-recaptcha,
.form-login .g-recaptcha {
    margin-bottom: 10px !important
}

.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px
}

.review-form .field-recaptcha {
    margin-bottom: 10px
}

.form.send.friend .g-recaptcha {
    margin-top: 40px
}

.columns .column.main {
    max-width: 100%
}

.amslider * {
    margin: 0;
    padding: 0
}

.amslider {
    position: relative;
    margin: 30px auto
}

.amslider .swiper-pagination {
    position: static;
    margin-top: 15px
}

.amslider .swiper-container>.wrapper {
    text-align: center
}

.amslider .swiper-slide .brand-title {
    margin-top: 10px;
    min-height: 40px;
    color: #4f4f4f;
    word-break: break-all;
    word-break: break-word;
    font-size: 15px;
    line-height: 1.2
}

.amslider .swiper-pagination-bullet {
    margin: 0 5px;
    width: 12px;
    height: 12px
}

.amslider .swiper-pagination-bullet-active {
    background: #4f4f4f
}

.amslider .swiper-button-next,
.amslider .swiper-button-prev {
    z-index: 2
}

.amslider .swiper-button-next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iNDMiIHZpZXdCb3g9IjAgMCAyNSA0MyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGU+VmVjdG9yPC90aXRsZT48ZGVzYz5DcmVhdGVkIHVzaW5nIEZpZ21hPC9kZXNjPjxnIGlkPSJDYW52YXMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0NTMgNzkwKSI+PGcgaWQ9IlZlY3RvciI+PHVzZSB4bGluazpocmVmPSIjcGF0aDBfc3Ryb2tlIiB0cmFuc2Zvcm09Im1hdHJpeCgtMSAwIDAgMSAtMTQzMC4zNSAtNzg4LjY0NikiIGZpbGw9IiM0RjRGNEYiLz48L2c+PC9nPjxkZWZzPjxwYXRoIGlkPSJwYXRoMF9zdHJva2UiIGQ9Ik0gNi4xMzcwNGUtMDUgMTkuOTM5M0wgLTEuMDQxOTYgMTguODYwNEwgLTIuMTM5OSAxOS45MjA3TCAtMS4wNjA2IDIxTCA2LjEzNzA0ZS0wNSAxOS45MzkzWk0gMTkuNjA0NCAtMS4wNzg5N0wgLTEuMDQxOTYgMTguODYwNEwgMS4wNDIwOSAyMS4wMTgzTCAyMS42ODg1IDEuMDc4OTdMIDE5LjYwNDQgLTEuMDc4OTdaTSAtMS4wNjA2IDIxTCAxOC45MzkzIDQxTCAyMS4wNjA3IDM4Ljg3ODdMIDEuMDYwNzIgMTguODc4N0wgLTEuMDYwNiAyMVoiLz48L2RlZnM+PC9zdmc+)
}

.amslider .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iNDMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjx1c2UgeGxpbms6aHJlZj0iI3BhdGgwX3N0cm9rZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMi43MSAxLjM1NCkiIGZpbGw9IiM0RjRGNEYiLz48ZGVmcz48cGF0aCBpZD0icGF0aDBfc3Ryb2tlIiBkPSJNMCAxOS45NGwtMS4wNDItMS4wOC0xLjA5OCAxLjA2TC0xLjA2IDIxIDAgMTkuOTR6TTE5LjYwNC0xLjA4bC0yMC42NDYgMTkuOTQgMi4wODQgMi4xNTdMMjEuNjkgMS4wOGwtMi4wODUtMi4xNTh6TS0xLjA2IDIxbDIwIDIwIDIuMTIyLTIuMTIxLTIwLTIwTC0xLjA2MSAyMXoiLz48L2RlZnM+PC9zdmc+)
}

.amslider .swiper-button-next,
.amslider .swiper-button-prev {
    z-index: 0
}

.amslider .swiper-slide {
    text-align: center;
    font-size: 18px
}

.amslider .category-item .category-item-title {
    margin: auto
}

.amslider .slide .category-item-title,
.amslider .swiper-slide .swiper-category-item {
    font-size: 15px;
    line-height: 1.2;
    word-break: break-word
}

.amslider .wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center
}

.amslider .no-img {
    margin: auto
}

.brands-filters {
    display: inline-block;
    margin: 0 0 10px;
    width: 100%;
    min-height: 50px
}

.brands-filters .letter {
    display: block;
    float: left;
    margin: 0 5px 10px;
    padding: 2px 5px;
    min-width: 17px;
    min-height: 20px;
    text-align: center;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #e2e2e2
}

.brands-filters .letter-all {
    min-width: 90px;
    margin-right: 20px
}

.brands-filters .letters {
    margin-top: 4px
}

.brands-filters .letter.-disabled {
    opacity: 0.6;
    pointer-events: none;
    background-color: #f1f1f1
}

.brands-filters .letter.hide {
    display: none
}

.brands-filters .letter:hover,
.brands-filters .letter.-active {
    background: #fed285
}

.ambrands-search-wrapper {
    position: relative;
    display: block;
    margin: 0 auto
}

.ambrands-search-wrapper .ambrands-search-input {
    display: block;
    margin: 0 0 10px;
    max-width: 100%
}

.ambrands-search-wrapper #livesearch {
    position: absolute;
    display: none;
    width: 365px;
    max-width: 100%;
    max-height: 250px;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid #c2c2c2;
    border-top: 2px solid #309ef3;
    z-index: 99
}

.ambrands-search-wrapper #livesearch a {
    display: block;
    float: left;
    padding: 5px 10px;
    width: 100%;
    color: #000;
    box-sizing: border-box
}

.ambrands-search-wrapper #livesearch a:hover {
    background-color: #e8e8e8;
    text-decoration: none
}

.brand-images .brands-letter {
    width: 100%;
    vertical-align: top;
    box-sizing: border-box
}

.brand-images .brands-letter .bare-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0
}

.brand-images .brands-letter .letter {
    border-bottom: 1px solid #b2b2b2;
    font-size: 18px;
    font-weight: 400
}

.brand-images .brands-letter .letter .title {
    padding: 0 2px 1px;
    text-transform: uppercase;
    background: #e2e2e2
}

.brands-letter .brand-item {
    position: relative;
    margin: 0 5px 20px
}

.brands-letter .brand-item .item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #333;
    -webkit-justify-content: flex-start
}

.brands-letter .brand-item .item:hover {
    text-decoration: none
}

.brands-letter .brand-item .no-img {
    color: #e2e2e2
}

.brands-letter .brand-item .count {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    box-sizing: border-box;
    min-width: 25px;
    height: 25px;
    padding: 0 3px;
    line-height: 25px;
    text-align: center;
    background: #f58c12;
    border-radius: 20px;
    color: #fff
}

.brands-letter .brand-item .label {
    position: relative;
    margin: 5px 0 0;
    padding-top: 5px;
    width: 100%;
    word-wrap: break-word
}

.brands-letter .brand-item .label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 25%;
    right: 25%;
    display: block;
    height: 1px;
    background: #b2b2b2
}

.brands-letter .brand-item .item:hover .label:before {
    left: 0;
    right: 0;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    transition: all ease .3s
}

.brands-letter .brand-item .am-image-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center
}

.brands-letter .brand-item .am-image-wrapper .am-image {
    max-width: 100%;
    max-height: 100%
}

.bare-list .ambrands-image {
    pointer-events: none
}

.amslider-header-container {
    padding: 5px 0 5px 35px;
    height: 35px;
    font-size: 22px;
    line-height: 35px
}

.icon .brand-label {
    float: left
}

.brand-images li.brand-item {
    list-style-type: none;
    text-align: center
}

ul.bare-list img {
    display: block;
    margin: 0 auto
}

.children-category .amslider {
    position: relative;
    left: inherit;
    margin: 0 50px;
    width: 100%;
    transform: inherit;
    display: block
}

.amslider.am-brand-slider {
    max-width: 100%;
    padding: 0
}

.amslider.am-brand-slider .swiper-brand {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    flex-grow: 1
}

.amslider.am-brand-slider .am-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 0 auto
}

.amslider.am-brand-slider .am-image-wrapper .am-image {
    max-width: 100%;
    max-height: 100%
}

.amslider.am-brand-slider .slide {
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch
}

.amslider.am-brand-slider .slide .am-image-wrapper {
    margin-bottom: 10px;
    overflow: hidden
}

.amslider.am-brand-slider .swiper-slide {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
    height: auto
}

.amslider .slide {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    margin: 0 9px 20px 0;
    max-width: 30%;
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    text-align: center;
    color: #4f4f4f
}

.amslider .slide .category-item {
    position: relative;
    padding: 10px;
    width: 100%;
    border: 1px solid #fff
}

.amslider .slide .category-item:hover,
.amslider .slide .category-item.active {
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
    border-color: #bbb;
    position: relative;
    z-index: 2;
    padding: 10px
}

.amslider .slide .category-item.category-item-label img {
    margin-bottom: 25px
}

.amslider .slide .swiper-brand .am-image {
    height: auto
}

.children-category-container.swiper-container {
    width: calc(100% - 2 * 27px);
    margin: auto
}

.children-category-container .swiper-wrapper {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline
}

.amslider .swiper-slide {
    padding: 10px;
    box-sizing: border-box;
    color: #4f4f4f
}

.amslider .swiper-slide .swiper-category-item {
    padding: 10px 0;
    border: 1px solid #fff
}

.amslider .swiper-slide .swiper-category-item img {
    height: auto
}

.amslider .swiper-slide .swiper-category-item.category-item-label img {
    margin-bottom: 25px
}

.amslider .swiper-slide .swiper-category-item:hover,
.amslider .swiper-slide .swiper-category-item.active {
    background: #ffffff;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid #bbb;
    margin: 0
}

.amslider .swiper-slide .swiper-brand .no-img {
    display: inline-block;
    margin: 0 auto;
    color: #e2e2e2
}

.amslider .swiper-slide:hover,
.amslider .slide:hover {
    text-decoration: none
}

#category-carousel .swiper-button-next {
    right: 0
}

#category-carousel .swiper-button-prev {
    left: 0
}

#category-carousel .swiper-button-next,
#category-carousel .swiper-button-prev {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .3;
    height: 100%;
    margin: 0
}

#category-carousel .swiper-button-next:hover,
#category-carousel .swiper-button-prev:hover {
    opacity: 1;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    transition: all ease .3s
}

.amslider .swiper-slide .swiper-category-item:not(:root:root),
.amslider .swiper-slide .swiper-brand:not(:root:root),
.amslider .slide .category-item:not(:root:root) {
    padding: 10px
}

.amshopby-morefrom .products-grid .product-item {
    width: auto
}

.amshopby-morefrom .product-image-container {
    min-width: auto
}

.ambrands-menu-item.parent .ambrands-link.level-top:after {
    content: ''
}

@media screen and (-ms-high-contrast:active),
screen and (-ms-high-contrast:none) {
    .swiper-brand {
        width: 100%
    }

    .swiper-brand .no-img {
        width: 100%
    }
}

.ambrands-list-popup,
.ambrands-menu-item .ambrands-arrow {
    display: none
}

.ui-tooltip.ui-widget {
    padding: 5px 10px;
    max-width: 250px;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 1px 0 3px #ccc;
    word-wrap: break-word
}

.ui-tooltip .arrow {
    position: absolute;
    bottom: -15px;
    width: 20px;
    height: 15px
}

.ui-tooltip .arrow:after {
    position: absolute;
    border-top: 10px solid #ccc;
    content: ''
}

.ui-tooltip .arrow:before {
    position: absolute;
    top: -1px;
    z-index: 9;
    border-top: 9px solid #fff;
    content: ''
}

.ui-tooltip .arrow.top {
    top: -15px;
    transform: scale(1, -1)
}

.ui-tooltip .arrow.left {
    left: 15px
}

.ui-tooltip .arrow.left:before {
    left: 1px;
    border-right: 19px solid transparent
}

.ui-tooltip .arrow.left:after {
    border-right: 20px solid transparent
}

.ui-tooltip .arrow.right {
    right: 15px
}

.ui-tooltip .arrow.right:before {
    right: 1px;
    border-left: 19px solid transparent
}

.ui-tooltip .arrow.right:after {
    border-left: 20px solid transparent
}

.ui-tooltip .am-brand-small_image {
    display: block;
    margin: auto;
    max-width: 150px
}

.ui-tooltip .am-brand-image {
    display: block;
    margin: auto;
    max-width: 400px
}

.ui-tooltip .ui-tooltip-content h3 {
    margin: 5px 0
}

.am-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0
}

.am-word-break {
    word-wrap: break-word;
    word-break: break-word
}

.ie11 .am-word-break {
    word-break: break-all
}

.ui-tooltip .arrow {
    left: 8px;
    width: 15px
}

.ui-tooltip .arrow:after {
    border-right: 15px solid transparent
}

.ui-tooltip .arrow:before {
    border-right: 14px solid transparent
}

.amslider-container {
    margin: auto;
    margin-bottom: 15px;
    width: 100%
}

.am-rating-up {
    font-size: smaller
}

.amshopby-highlighted {
    background-color: #ff0
}

.amshopby-item .filter-value {
    margin-left: 5px
}

.amshopby-filter-tooltip {
    display: inline-block;
    margin-left: 6px;
    max-width: 16px;
    max-height: 16px;
    vertical-align: top
}

.amshopby-filter-value .swatch-option.image,
.amshopby-filter-value .swatch-attribute.size .swatch-option,
.amshopby-filter-value .swatch-attribute.manufacturer .swatch-option {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.filter-options-item .filter-options-title:after {
    font-size: 14px
}

.range.am-fromto-widget .am-filter-go {
    margin: 0 10px;
    vertical-align: top
}

.amshopby-button {
    word-wrap: break-word;
    word-break: break-word;
    min-height: 40px;
    border-radius: 3px;
    background: #1979c3;
    color: #fff;
    text-align: center;
    letter-spacing: -.015em;
    font-size: 16px;
    line-height: 22px;
    transition: background .2s ease-in-out, border-color .2s ease-in-out
}

.ie11 .amshopby-button {
    word-break: break-all
}

.amshopby-button:not(.primary) {
    border-radius: 3px
}

.amshopby-button:not(.primary),
.amshopby-button:not(.primary):active {
    box-shadow: none
}

.amshopby-button:hover {
    border-color: #399ae6;
    background: #399ae6
}

.amshopby-button:active,
.amshopby-button:focus {
    border-color: #135d96;
    background: #135d96
}

.am-filter-go {
    word-wrap: break-word;
    word-break: break-word;
    min-height: 40px;
    border-radius: 3px;
    background: #1979c3;
    color: #fff;
    text-align: center;
    letter-spacing: -.015em;
    font-size: 16px;
    line-height: 22px;
    transition: background .2s ease-in-out, border-color .2s ease-in-out;
    margin: 15px 0 0;
    width: 25%
}

.ie11 .am-filter-go {
    word-break: break-all
}

.am-filter-go:not(.primary) {
    border-radius: 3px
}

.am-filter-go:not(.primary),
.am-filter-go:not(.primary):active {
    box-shadow: none
}

.am-filter-go:hover {
    border-color: #399ae6;
    background: #399ae6
}

.am-filter-go:active,
.am-filter-go:focus {
    border-color: #135d96;
    background: #135d96
}

input[type='text'].am-filter-price.-to.right {
    float: right
}

.am-swatch-options {
    display: flex;
    flex-direction: column
}

.am-swatch-options .swatch-option {
    display: inline-block;
    margin: 0 10px 0 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.am-swatch-options>.item {
    padding-bottom: 4px
}

.am-swatch-options>.item:hover {
    background-color: transparent
}

.am-swatch-options .am-shopby-swatch-label {
    display: inline-block;
    max-width: 70%;
    vertical-align: middle;
    word-wrap: break-word
}

.filter-options .am-swatch-wrapper {
    margin: 0 0 5px !important;
    line-height: initial
}

.catalog-topnav .filter-options {
    flex-wrap: wrap;
    min-height: 40px
}

.catalog-topnav .filter-options .filter-options-item {
    display: inline-block;
    border: 0
}

.catalog-topnav .filter-options .filter-options-content {
    position: absolute;
    z-index: 9999;
    margin-top: 12px;
    min-width: 100px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, 0.21), 0 6px 25px rgba(190, 199, 219, 0.28)
}

.catalog-topnav .filter-options .am-filter-items-rating li {
    margin: 0 0 10px
}

.catalog-topnav .filter-options .am-filter-items-rating li:last-child {
    margin: 0
}

.catalog-topnav .block-actions {
    display: none
}

.catalog-topnav {
    display: none
}

.filter-options .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 5px;
    right: 8px
}

.page-layout-1column .filter-options-item.active .filter-options-content {
    padding: 10px 15px
}

.page-layout-1column .filter-options-content li.item {
    padding: 0
}

.filter [data-role='collapsible']:not([data-collapsible='true']) [data-role='content'] {
    display: none
}

div.swatch-option-tooltip {
    z-index: 9999
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    background-size: contain !important
}

.filter-options .am-labels-folding .item {
    position: relative
}

.filter-options .ui-menu {
    display: block;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none
}

.filter-options .ui-menu .ui-menu {
    position: absolute
}

.filter-options .ui-menu .ui-menu-item {
    margin: 0;
    list-style: none;
    cursor: pointer
}

.filter-options .ui-menu .ui-menu-item-wrapper {
    position: relative;
    padding: 3px 1em 3px .4em
}

.filter-options .ui-menu .ui-menu-divider {
    margin: 5px 0;
    height: 0;
    border-width: 1px 0 0;
    font-size: 0;
    line-height: 0
}

.filter-options-content .am-swatch-wrapper {
    display: inline-block
}

.filter-options-content .am-swatch-wrapper:hover .am-swatch-link {
    background: none
}

.filter-options-content .am-swatch-wrapper>.input.-hidden {
    display: none
}

.am-filter-items-attr_price {
    min-width: 130px;
    max-width: 350px
}

.am-filter-items-attr_color .swatch-option.text {
    padding: 1px 2px;
    width: 30px
}

.amasty-catalog-topnav.block.filter {
    flex-basis: auto
}

.amasty-catalog-topnav .filter-options .item:last-child {
    margin-bottom: 0
}

.amshopby-category-dropdown {
    display: block;
    overflow: hidden;
    padding-right: 3.6rem;
    min-height: 3.2rem;
    border: 1px solid #adadad;
    background-color: #fff;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 400;
    cursor: pointer
}

.amshopby-category-dropdown:after {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    box-sizing: border-box;
    margin-top: -.2rem;
    width: 0;
    height: 0;
    border-width: .5rem .4rem 0;
    border-style: solid;
    border-color: #000 transparent transparent;
    content: '';
    transition: all .2s linear
}

.amshopby-category-dropdown:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3.2rem;
    border: 1px solid #adadad;
    background-color: #e3e3e3;
    content: ''
}

.am-category-wrapper {
    position: relative;
    display: block;
    user-select: none
}

.am-category-wrapper._active .amshopby-category-dropdown:after {
    transform: rotate(180deg)
}

.am-category-wrapper .amshopby-checkbox,
.am-category-wrapper .amshopby-radio {
    margin-left: 20px
}

.am-category-wrapper .amshopby-filter-parent {
    padding-left: 0
}

.am-category-wrapper .am-multiselect-crumb {
    position: relative;
    display: inline-block;
    margin: .3rem 0 .3rem .3rem;
    padding: .3rem 2.4rem .4rem 1rem;
    border: 1px solid #a79d95;
    border-radius: 1px;
    background-color: #f5f5f5;
    font-size: 1.2rem;
    transition: border-color .1s linear
}

.am-category-wrapper .am-multiselect-crumb .action-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 2rem;
    height: auto;
    border: none;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    color: #514943;
    font-size: .5em;
    line-height: 1
}

.am-category-wrapper .am-multiselect-crumb .action-close:before {
    content: 'x';
    font-size: 10px;
    transition: color .1s linear
}

.am-category-wrapper .am-multiselect-crumb .action-close>span {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    clip: rect(0, 0, 0, 0)
}

.filter-options .am-labels-folding .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 0 0 20px
}

.filter-options .am-labels-folding .item .am-input,
.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) input[type='radio'],
.filter-options .amshopby-category-dropdown+form .item input[type='radio'],
.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) input[type='checkbox'],
.filter-options .amshopby-category-dropdown+form .item input[type='checkbox'] {
    position: absolute;
    top: 4px;
    left: 0;
    margin: 0 5px 0 0
}

.filter-options .am-labels-folding .item .am-collapse-icon~.am-input {
    left: 20px
}

.filter-options .am-labels-folding .item .am-collapse-icon~[class*='am-filter-item-'] {
    padding-left: 20px
}

.filter-options .amshopby-category-dropdown+form .item {
    flex-wrap: wrap
}

.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered),
.filter-options .amshopby-category-dropdown+form .item {
    position: relative;
    display: block
}

.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~.am-input,
.filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~.am-input,
.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='radio'],
.filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='radio'],
.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='checkbox'],
.filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='checkbox'],
.filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='checkbox']+.amshopby-choice,
.filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='checkbox']+.amshopby-choice {
    left: 20px
}

.filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .items-children.-folding {
    padding-left: 20px
}

.am-labels-folding .am-collapse-icon,
.filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .am-collapse-icon {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    align-self: flex-start
}

.am-labels-folding .am-collapse-icon:after,
.filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .am-collapse-icon:after,
.am-labels-folding .am-show-more:before {
    position: static;
    margin: 0
}

.am-collapse-icon {
    position: absolute;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    cursor: pointer;
    -webkit-font-smoothing: antialiased
}

.am-collapse-icon:after,
.am-show-more:before {
    position: absolute;
    right: 0;
    width: 12px;
    height: 20px;
    background: url("../images/arrow-bottom.svg") no-repeat 100% / contain;
    content: ''
}

.am-collapse-icon._active:after {
    content: '';
    transform: rotate(180deg)
}

.amshopby-option-link {
    display: inline-block;
    clear: both;
    margin-right: 5px
}

.amshopby-option-link img {
    float: left;
    margin: 0 5px 5px 0
}

.amshopby-option-link img:not([src*='/resized']) {
    max-width: 30px;
    max-height: 30px
}

.am-item-removed {
    text-decoration: none
}

.am-category-image {
    vertical-align: middle
}

.amshopby-filter-current .filter-label {
    vertical-align: middle
}

.amshopby-filter-current .amshopby-items {
    padding: 0 0 0 10px
}

.amshopby-filter-current .amshopby-item.item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 0 0 20px
}

.amshopby-filter-current .amshopby-filter-name {
    display: flex;
    padding: 0 5px 0 0;
    font-weight: bold
}

.amshopby-filter-current .amshopby-filter-name:after {
    content: ':'
}

.ie11 .amshopby-filter-current .amshopby-filter-name:after {
    padding-left: 1px
}

.amshopby-filter-current .amshopby-filter-value {
    flex-grow: 1
}

.amshopby-filter-current .am-swatch-link {
    display: flex;
    align-items: center
}

.amshopby-filter-current .amshopby-filter-value .am-swatch-options .swatch-option {
    margin: 0
}

.amshopby-filter-current .amshopby-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px
}

.ie11 .amshopby-filter-current .amshopby-remove {
    top: calc(50% - (12px / 2))
}

.amshopby-filter-current .amshopby-remove:hover:after,
.amshopby-filter-current .amshopby-remove:hover:before {
    background-color: #494949
}

.amshopby-filter-current .amshopby-remove:hover~* {
    opacity: .5
}

.amshopby-filter-current .amshopby-remove:after,
.amshopby-filter-current .amshopby-remove:before {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #c7c7c7;
    content: ''
}

.amshopby-filter-current .amshopby-remove:after {
    transform: rotate(45deg)
}

.amshopby-filter-current .amshopby-remove:before {
    transform: rotate(-45deg)
}

.amshopby-filter-current .am-shopby-swatch-label {
    margin-left: 10px
}

.amshopby-filter-current .swatch-option-link-layered {
    display: flex;
    align-items: center;
    flex-basis: 100%
}

.amshopby-filter-current .item {
    padding-bottom: 0
}

.am-dropdown .am-select,
.am-dropdown .chosen-container .chosen-choices {
    min-height: 32px;
    border-color: #ccc;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.am-dropdown .chosen-container .chosen-choices {
    background-image: none
}

.am-dropdown .chosen-container-multi .chosen-choices li.search-field input[type='text'] {
    min-width: 100px;
    color: #000;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif
}

.am-porto-cmtb .amshopby-filter-current.filter-current .amshopby-item.item {
    display: flex;
    align-items: center;
    padding: 0 0 10px 20px
}

.am-porto-cmtb .amshopby-filter-current.filter-current .items {
    padding: 15px
}

.am-porto-cmtb .amshopby-filter-current.filter-current .am-show-more {
    padding: 12px 25px 0 0
}

.am-porto-cmtb .amshopby-filter-current.filter-current .category-image .image {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto
}

.am-porto-cmtb .filter-options-content .swatch-option {
    min-width: 22px;
    width: 22px;
    height: 22px;
    border: none;
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2)
}

.am-porto-cmtb .page-wrapper>.page-header {
    z-index: 900
}

.am-porto-cmtb .page-main {
    z-index: 9
}

.am-porto-cmtb .am-show-more:hover {
    color: #006bb4 !important
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper {
    margin: 0 10px
}

.amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-slider-wrapper,
.filter-options-item .filter-options-content .amshopby-slider-wrapper>.items:not(.items-children):not(.am-category-view),
.filter-options-item .filter-options-content .amshopby-slider-wrapper .amshopby-fromto-wrap {
    margin: 0
}

.amshopby-slider-container {
    margin: 10px 0 5px
}

.amshopby-slider-container .ui-slider-range {
    position: absolute;
    z-index: 2
}

.amshopby-slider-container .am-slider:not(.-loaded) {
    visibility: hidden;
    opacity: 0
}

.amshopby-slider-container .am-slider:before,
.amshopby-slider-container .am-slider:after {
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    content: ''
}

.amshopby-slider-container .am-slider .ui-slider-handle {
    overflow: inherit;
    margin: 0;
    padding: 0
}

.amshopby-slider-container .amshopby-slider-display {
    margin: 10px 0 0;
    text-align: center
}

.amshopby-slider-container .ui-slider-handle.ui-state-hover~.ui-slider-handle,
.amshopby-slider-container .ui-slider-handle.ui-state-active~.ui-slider-handle {
    pointer-events: none;
    user-select: none
}

.amshopby-slider-container.-default .am-slider {
    margin: 0 10px;
    height: 10px;
    background: #dadada
}

.amshopby-slider-container.-default .am-slider:before,
.amshopby-slider-container.-default .am-slider:after {
    width: 20px;
    height: 10px;
    background: #dadada
}

.amshopby-slider-container.-default .am-slider:before {
    left: -10px
}

.amshopby-slider-container.-default .am-slider:after {
    right: -10px
}

.amshopby-slider-container.-default .ui-slider-range {
    height: 10px;
    background: #b6b6b6
}

.amshopby-slider-container.-default .am-slider .ui-slider-handle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f98b25;
    transform: translate(-10px, -5px)
}

.filter-options-item .filter-options-content .amshopby-fromto-wrap .am-fromto-widget {
    flex-wrap: wrap;
    margin-top: 10px
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper .amshopby-fromto-wrap .am-fromto-widget {
    margin-top: 25px
}

.filter-options-item .filter-options-content .amshopby-slider-wrapper.-volumetric .amshopby-fromto-wrap .am-fromto-widget {
    margin-top: 35px
}

.amasty-catalog-topnav .amshopby-fromto-wrap .am-fromto-widget {
    max-width: 210px
}

.amshopby-fromto-wrap .am-fromto-widget>.amshopby-input-wrapper {
    position: relative;
    max-width: 50%;
    width: calc(50% - 7px)
}

.amshopby-fromto-wrap .am-fromto-widget .am-filter-price {
    width: 100%;
    height: 40px;
    border: 1px solid #dfdedd;
    background-clip: padding-box;
    color: #494949;
    text-align: center;
    letter-spacing: .03em;
    font-size: 14px
}

.amshopby-fromto-wrap .am-fromto-widget>.delimiter {
    margin: 0;
    width: 14px;
    line-height: 40px
}

.amshopby-fromto-wrap .am-fromto-widget>.am-filter-go {
    margin: 15px 0 0;
    width: 100%
}

.amshopby-fromto-wrap .am-fromto-widget>.am-filter-go:not(.primary),
.amshopby-fromto-wrap .am-fromto-widget>.am-filter-go:active {
    box-shadow: none
}

.amshopby-input-wrapper .amshopby-currency {
    position: absolute;
    bottom: calc(100% - 10px);
    left: 4px;
    padding: 2px;
    background: #fff;
    color: #494949;
    letter-spacing: .03em;
    font-size: 14px;
    line-height: 16px
}

.amshopby-slider-tooltip {
    transition: all .2s ease-in-out
}

.ui-slider-handle:not(.ui-state-hover):not(.ui-state-active) .amshopby-slider-tooltip {
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, -3px)
}

.amshopby-slider-container.-improved {
    margin-top: 30px
}

.amshopby-slider-container.-improved .am-slider {
    margin: 0 8px;
    height: 4px;
    background: #dfdedd
}

.amshopby-slider-container.-improved .am-slider:before,
.amshopby-slider-container.-improved .am-slider:after {
    width: 16px;
    height: 4px;
    background: #dfdedd
}

.amshopby-slider-container.-improved .am-slider:before {
    left: -8px
}

.amshopby-slider-container.-improved .am-slider:after {
    right: -8px
}

.amshopby-slider-container.-improved .ui-slider-range {
    height: 4px;
    background: #ff5502
}

.amshopby-slider-container.-improved .am-slider .ui-slider-handle {
    top: -6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ff5502;
    transform: translateX(-8px)
}

.amshopby-slider-container.-improved .am-slider .ui-slider-handle:hover,
.amshopby-slider-container.-improved .am-slider .ui-slider-handle:active {
    background: #ff7735
}

.amshopby-slider-container.-improved .amshopby-slider-tooltip {
    position: absolute;
    bottom: calc(100% + 7px);
    left: 8px;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #ff5502;
    border-radius: 2px;
    background: #ff5502;
    color: #fff;
    letter-spacing: .03em;
    font-size: 12px;
    line-height: 1;
    transform: translateX(-50%)
}

.amshopby-slider-container.-improved .amshopby-slider-tooltip:before,
.amshopby-slider-container.-improved .amshopby-slider-tooltip:after {
    position: absolute;
    top: calc(100% - 2px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    border-bottom-right-radius: 2px;
    background: #ff5502;
    content: '';
    transform: rotate(45deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-improved .amshopby-slider-tooltip:after {
    top: calc(100% - 3px);
    background: #ff5502
}

.amshopby-slider-container.-volumetric {
    margin-top: 20px
}

.amshopby-slider-container.-volumetric .am-slider {
    margin: 0 4px;
    height: 4px;
    border-radius: 20px;
    background: #dfdedd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25)
}

.amshopby-slider-container.-volumetric .am-slider:before,
.amshopby-slider-container.-volumetric .am-slider:after {
    display: none
}

.amshopby-slider-container.-volumetric .ui-slider-range {
    height: 4px;
    border-radius: 20px;
    background: linear-gradient(270deg, #ff5502 0%, #f8ac87 100%);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25)
}

.amshopby-slider-container.-volumetric .am-slider .ui-slider-handle {
    width: 8px;
    height: 12px;
    border-radius: 2px;
    background: linear-gradient(159.86deg, #f58b57 41.01%, #ff5502 93.75%);
    box-shadow: 0 3px 4px rgba(255, 85, 2, 0.2), 0 1px 2px rgba(255, 85, 2, 0.3);
    transform: translate(-4px, 10px)
}

.amshopby-slider-container.-volumetric .am-slider .ui-slider-handle:before {
    position: absolute;
    bottom: calc(100% - 4px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    border-bottom-right-radius: 2px;
    background: #f58b57;
    content: '';
    transform: rotate(-135deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-volumetric .amshopby-slider-tooltip {
    position: absolute;
    bottom: calc(100% + 7px);
    left: 8px;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #ff7e3f;
    border-radius: 2px;
    background: linear-gradient(136.07deg, #ff7e3f 4.64%, #ff5502 75.47%);
    color: #fff;
    letter-spacing: .03em;
    font-size: 12px;
    line-height: 1;
    transform: translateX(-50%);
    bottom: calc(100% + 17px);
    left: 4px
}

.amshopby-slider-container.-volumetric .amshopby-slider-tooltip:before,
.amshopby-slider-container.-volumetric .amshopby-slider-tooltip:after {
    position: absolute;
    top: calc(100% - 2px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    border-bottom-right-radius: 2px;
    background: #ff7e3f;
    content: '';
    transform: rotate(45deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-volumetric .amshopby-slider-tooltip:after {
    top: calc(100% - 3px);
    background: linear-gradient(136.07deg, #ff7e3f 4.64%, #ff5502 75.47%)
}

.amshopby-slider-container.-volumetric .amshopby-slider-display {
    margin-top: 20px
}

.amshopby-slider-container.-light {
    margin-top: 30px
}

.amshopby-slider-container.-light .am-slider {
    margin: 0 8px;
    height: 1px;
    background: #dfdedd
}

.amshopby-slider-container.-light .am-slider:before,
.amshopby-slider-container.-light .am-slider:after {
    width: 16px;
    height: 1px;
    background: #dfdedd
}

.amshopby-slider-container.-light .am-slider:before {
    left: -8px
}

.amshopby-slider-container.-light .am-slider:after {
    right: -8px
}

.amshopby-slider-container.-light .ui-slider-range {
    height: 1px;
    background: #ff5502
}

.amshopby-slider-container.-light .am-slider .ui-slider-handle {
    top: -8px;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 1px solid #dfdedd;
    border-radius: 50%;
    background: #fff;
    transform: translateX(-8px)
}

.amshopby-slider-container.-light .amshopby-slider-tooltip {
    position: absolute;
    bottom: calc(100% + 7px);
    left: 8px;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #dfdedd;
    border-radius: 2px;
    background: #fff;
    color: #ff5502;
    letter-spacing: .03em;
    font-size: 12px;
    line-height: 1;
    transform: translateX(-50%);
    bottom: calc(100% + 8px)
}

.amshopby-slider-container.-light .amshopby-slider-tooltip:before,
.amshopby-slider-container.-light .amshopby-slider-tooltip:after {
    position: absolute;
    top: calc(100% - 2px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    border-bottom-right-radius: 2px;
    background: #dfdedd;
    content: '';
    transform: rotate(45deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-light .amshopby-slider-tooltip:after {
    top: calc(100% - 3px);
    background: #fff
}

.amshopby-slider-container.-dark {
    margin-top: 30px
}

.amshopby-slider-container.-dark .am-slider {
    margin: 0 5px;
    height: 3px;
    background: #4a4948
}

.amshopby-slider-container.-dark .am-slider:before,
.amshopby-slider-container.-dark .am-slider:after {
    width: 9px;
    height: 3px;
    background: #4a4948
}

.amshopby-slider-container.-dark .am-slider:before {
    left: -4px
}

.amshopby-slider-container.-dark .am-slider:after {
    right: -4px
}

.amshopby-slider-container.-dark .ui-slider-range {
    height: 3px;
    background: #ff5502
}

.amshopby-slider-container.-dark .am-slider .ui-slider-handle {
    top: -6px;
    width: 9px;
    height: 15px;
    border-radius: 2px;
    background: #ff5502;
    box-shadow: 0 2px 4px rgba(255, 85, 2, 0.2), 0 1px 2px rgba(255, 85, 2, 0.3);
    transform: translateX(-4.5px)
}

.amshopby-slider-container.-dark .amshopby-slider-tooltip {
    position: absolute;
    bottom: calc(100% + 7px);
    left: 8px;
    z-index: 1;
    display: block;
    box-sizing: border-box;
    padding: 3px;
    border: 1px solid #ff5502;
    border-radius: 2px;
    background: #ff5502;
    color: #fff;
    letter-spacing: .03em;
    font-size: 12px;
    line-height: 1;
    transform: translateX(-50%);
    left: 4.5px
}

.amshopby-slider-container.-dark .amshopby-slider-tooltip:before,
.amshopby-slider-container.-dark .amshopby-slider-tooltip:after {
    position: absolute;
    top: calc(100% - 2px);
    left: calc(50% - 3px);
    display: block;
    width: 6px;
    height: 6px;
    border-bottom-right-radius: 2px;
    background: #ff5502;
    content: '';
    transform: rotate(45deg);
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%)
}

.amshopby-slider-container.-dark .amshopby-slider-tooltip:after {
    top: calc(100% - 3px);
    background: #ff5502
}

.filter-options-item .filter-options-content {
    padding-bottom: 5px
}

.filter-options-item .filter-options-content .am-swatch-wrapper .swatch-option.image,
.filter-options-item .filter-options-content .swatch-attribute.size .swatch-option.selected,
.filter-options-item .filter-options-content .swatch-attribute.manufacturer .swatch-option.selected {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}

.filter-options-item .filter-options-content .range {
    display: flex;
    align-items: center;
    margin-top: 15px;
    text-align: center
}

.filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view),
.filter-options-item .filter-options-content .am-category-wrapper,
.filter-options-item .filter-options-content .amshopby-fromto-wrap {
    margin: 0 10px
}

.filter-options-item .filter-options-content .am-shopby-form {
    margin: 0 7px
}

.filter-options-item .filter-options-content .items [class*='am-filter-item'] {
    margin-left: 0;
    padding: 0
}

.filter-options-item .filter-options-content .count {
    display: inline-flex;
    padding-left: 0
}

.filter-options-item .filter-options-content .am-shopby-swatch-label .count {
    margin: 0;
    padding: 0
}

.filter-options-item .filter-options-content .am-shopby-swatch-label .label {
    margin-right: 5px
}

.filter-options-item .filter-options-content .am-shopby-form {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: -15px;
    padding: 3px
}

.filter-options-item .filter-options-content .am-shopby-form .swatch-attribute .swatch-option-link-layered:hover {
    background-color: unset
}

.amasty-catalog-topnav .filter-options-item .filter-options-content {
    padding-bottom: 10px
}

.amasty-catalog-topnav .filter-options-item .filter-options-content .items:not(.items-children):not(.am-category-view),
.amasty-catalog-topnav .filter-options-item .filter-options-content .am-shopby-form,
.amasty-catalog-topnav .filter-options-item .filter-options-content .am-category-wrapper,
.amasty-catalog-topnav .filter-options-item .filter-options-content .amshopby-fromto-wrap {
    margin: 0
}

.filter-options .items.-am-overflow {
    overflow-x: hidden;
    overflow-y: auto
}

.filter-options .items .items-children.-folding {
    padding-left: 20px;
    width: 100%;
    list-style: none
}

.filter-options .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent {
    padding-left: 20px
}

.filter-options .items.-am-multiselect .item.-is-expanded .items-children.-folding {
    padding-left: 0
}

.filter-options .items .items-children.-folding .item {
    margin: 5px 0
}

.filter-options .item.-empty-value {
    opacity: .5;
    pointer-events: none;
    user-select: none
}

.filter-options .ui-widget-overlay {
    background: #aaa;
    opacity: .3
}

.filter-options .ui-widget-shadow {
    box-shadow: 0 0 5px #666
}

.filter-options .am-category-view {
    position: absolute;
    z-index: 9;
    display: none;
    overflow-y: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    max-height: 300px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    white-space: nowrap
}

.filter-options .items.am-category-view,
.filter-options .am-category-view ol.items {
    margin: 0
}

.filter-options .am-category-view.-am-singleselect .am-collapse-icon {
    top: 0
}

.filter-options .am-category-view.-am-singleselect .am-collapse-icon:after,
.filter-options .am-category-view.-am-singleselect .am-show-more:before {
    right: inherit;
    left: 0
}

.filter-options .am-category-view .item a.amshopby-filter-parent {
    padding-left: 20px
}

.filter-options .am-category-view.-am-multiselect .item>.am-collapse-icon~a.amshopby-filter-parent {
    padding-left: 40px
}

.filter-options .am-category-view [class*='am-filter-item'] {
    display: inline-block;
    white-space: initial
}

.filter-options .filter-options-content .rating-summary {
    display: inline-block;
    vertical-align: sub
}

.filter-options .amshopby-search-box {
    margin: 0 0 15px
}

.filter-options .am-labels-folding.-am-singleselect .item .am-input,
.filter-options [class*='am-filter-items-'].am-labels-folding.-am-singleselect .item:not(.swatch-option-link-layered) input[type='radio'],
.filter-options .amshopby-category-dropdown+form .-am-singleselect .item input[type='radio'],
.filter-options .am-category-view input[type='radio'] {
    display: none
}

.filter-options .am-labels-folding.-am-singleselect .item {
    padding: 0
}

.filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
.filter-options .items:not(.items-children)>.item.-is-by-click.-filter-parent~.item.-is-expanded {
    margin-left: 20px
}

.filter-options-content .am-ranges .items.-am-multiselect .item {
    position: relative
}

.filter-options-content .am-ranges .items.-am-multiselect .item [class*='am-filter-item'] {
    padding-left: 20px
}

.filter-actions .filter-clear {
    color: #1979c3;
    font-size: 14px;
    line-height: 19px
}

.filter-actions .filter-clear:hover {
    color: #399ae6;
    text-decoration: none
}

.filter-actions .filter-clear:focus,
.filter-actions .filter-clear:active {
    color: #135d96
}

.filter-options-content .item a:hover {
    background-color: transparent;
    color: #1979c3
}

.filter-options-content .item a:hover .count {
    color: #1979c3
}

.filter-options-content .item a:active,
.filter-options-content .item a:active .count {
    color: #135d96;
    text-decoration: none
}

.filter-options-content .item:first-child {
    margin-top: 0
}

.filter-options-content .item:last-child {
    margin-bottom: 0
}

.filter-options-content .item>a {
    display: block
}

.filter-options-content .items.-am-singleselect .item>input[type='radio'] {
    display: none
}

.filter-options-content .items.-am-singleselect a.am_shopby_link_selected,
.filter-options-content .swatch-option.selected+.am-shopby-swatch-label {
    color: #1979c3
}

.filter-options-content .items.-am-singleselect a.am_shopby_link_selected .count,
.filter-options-content .swatch-option.selected+.am-shopby-swatch-label .count {
    color: #1979c3
}

.am-show-more,
.filter-options-content .am-show-more {
    position: relative;
    display: none;
    margin: 10px 0 0;
    padding: 0 22px 0 0;
    color: #070605;
    letter-spacing: .03em;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer
}

.amasty-catalog-topnav .filter-options-content .am-show-more {
    padding-left: 0
}

.filter-options-content .am-show-more {
    padding-left: 10px
}

.am-show-more:hover,
.filter-options-content .am-show-more:hover {
    background-color: transparent;
    color: #838382;
    text-decoration: none
}

.am-show-more:active,
.filter-options-content .am-show-more:active {
    color: #070605
}

.am-show-more:before {
    top: 0
}

.am-show-more.-active {
    display: inline-flex;
    align-items: center
}

.am-show-more .am-counter {
    display: inline-block;
    margin: 0 2px;
    opacity: .5
}

.am-show-more[data-is-hide='false']:before {
    transform: rotate(180deg)
}

.am_shopby_link_selected {
    color: #1979c3
}

.items[class*='am-filter-items-attr_category']:not(.items-children)>.item>a {
    font-weight: 700
}

.filter-options .items:not(.items-children).-am-multiselect>.item.-is-by-click .items-children.-folding {
    padding-left: 0
}

.filter-options .items:not(.items-children).-am-multiselect>.item.-is-by-click .items-children.-folding .item.-is-expanded {
    margin-left: 20px
}

.am-filter-items-stock .am_shopby_link_selected,
.am-filter-items-rating .am_shopby_link_selected {
    text-decoration: none
}

.filter-content [class*='am-filter-item']:hover~.amshopby-choice:before {
    border-color: #1979c3
}

.filter-content .items.-am-multiselect [class*='am-filter-item']:hover {
    color: inherit
}

.filter-content .items.-am-multiselect [class*='am-filter-item']:hover .count {
    color: #757575
}

.amshopby-fly-out-view.ui-menu,
.amshopby-flyout-block .items.amshopby-fly-out-view .item .items-children {
    position: relative;
    padding: 13px 0;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(190, 199, 219, 0.21), 0 6px 25px rgba(190, 199, 219, 0.28)
}

.amshopby-fly-out-view .ui-menu {
    width: 100%
}

.amshopby-fly-out-view .items-children:not(.ui-menu) {
    display: none
}

.amshopby-fly-out-view .am-input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.amshopby-fly-out-view .am-input[type='radio']+.amshopby-choice {
    display: none
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin: 0;
    padding: 4px 20px;
    font-size: 14px;
    line-height: 18px
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item:hover {
    background-color: #f8f8f9
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item .items-children {
    position: absolute;
    z-index: 995
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item>.amshopby-choice {
    position: relative;
    top: inherit;
    left: inherit;
    margin: 3px 0;
    padding-left: 22px
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*='am-filter-item-'] {
    display: inline-block;
    width: 100%
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*='am-filter-item-']:hover {
    background-color: transparent;
    color: inherit
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*='am-filter-item-']:hover>.count {
    color: #757575
}

.amshopby-flyout-block .items.amshopby-fly-out-view .item .amshopby-choice:hover:before,
.amshopby-flyout-block .items.amshopby-fly-out-view .item [class*='am-filter-item-']:hover~.amshopby-choice:before {
    border-color: #e3e3e3
}

.am_shopby_apply_filters {
    position: fixed;
    bottom: -80px;
    z-index: 999;
    width: 100%;
    transition: left .2s ease-in-out
}

.am_shopby_apply_filters.visible {
    bottom: 0
}

.am-show-button {
    z-index: 211;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 0;
    width: 100%;
    border-top: 1px solid #ccc;
    background: #fff;
    text-align: center
}

.am-show-button>.am-items {
    margin: 0;
    padding: 0 10px;
    color: #070605;
    font-size: 14px;
    line-height: 22px
}

.am-show-button>.am-items.-loading {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('../images/loader.svg') center / 30px no-repeat;
    vertical-align: middle
}

.am-show-button>.amshopby-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    min-width: 90px;
    line-height: 1
}

.ie11 .am-show-button>.amshopby-button {
    padding: 10px
}

#amasty-shopby-product-list {
    z-index: 1
}

#amasty-shopby-product-list .products.wrapper {
    position: relative
}

.amshopby-overlay-block {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 230;
    display: none;
    width: 100%;
    background: rgba(255, 255, 255, 0.5)
}

.amshopby-overlay-block>.amshopby-loader {
    position: absolute;
    top: 10%;
    left: 50%;
    display: block;
    width: 100px;
    height: 100px;
    background: url('../images/loader.svg') center / 100px no-repeat;
    transform: translate(-50%, -10%)
}

.amrelated-grid-wrapper .amshopby-option-link {
    margin-bottom: 10px
}

.opc-block-summary .vertex-message td,
.cart-summary .vertex-message td {
    padding: 0;
    border-top: none
}

.klarna-payments-method-cell {
    display: table-cell;
    vertical-align: middle
}

label.klarna-payments-method-cell>span {
    padding-left: 5px
}

span.klarna-payments-method-cell>img {
    display: block
}

.form-address-edit .actions-toolbar .vertex-update-address {
    margin-top: 10px
}

.vertex__address-suggestion {
    margin: 5px 0;
    padding-left: 10px;
    font-weight: 500;
    border-left: 2px solid #808080
}

.payment-method-braintree .hosted-control {
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    width: 225px
}

.payment-method-braintree .hosted-control:disabled {
    opacity: .5
}

.payment-method-braintree .hosted-control::-moz-placeholder {
    color: #575757
}

.payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #575757
}

.payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #575757
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #b74f0e
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused)~.hosted-error {
    height: auto;
    opacity: 1
}

.payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400
}

.payment-method-braintree .hosted-control.hosted-cid {
    width: 5rem
}

.payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 6rem
}

.payment-method-braintree .hosted-control.hosted-date:first-of-type {
    margin-right: 50px
}

.payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
    content: '/';
    display: inline;
    padding-bottom: inherit;
    padding-top: inherit;
    position: absolute;
    right: -25px;
    top: 5px
}

.payment-method-braintree .field-tooltip {
    right: 0
}

.payment-method-braintree .hosted-error {
    clear: both;
    color: #e02b27;
    font-size: 1.2rem;
    height: 0;
    margin-top: 7px;
    opacity: 0
}

.braintree-paypal-logo {
    margin-top: 10px;
    text-align: center
}

.braintree-paypal-account {
    padding: 15px 0 15px 50px;
    position: relative;
    display: inline-block
}

.braintree-paypal-account:before {
    background-image: url('../images/paypal-small.png');
    background-position-x: 0;
    background-position-y: 0;
    line-height: 16px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block
}

.braintree-paypal-account:before {
    left: 17px;
    margin-top: -8px;
    position: absolute;
    top: 50%
}

.braintree-paypal-account .payment-method-type {
    font-weight: 700
}

.braintree-paypal-account .payment-method-description {
    color: #575757
}

.braintree-paypal-fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

.action-braintree-paypal-logo {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.action-braintree-paypal-logo:focus,
.action-braintree-paypal-logo:hover {
    background: none;
    border: none
}

.action-braintree-paypal-logo:active {
    background: none;
    border: none
}

.action-braintree-paypal-logo.disabled,
.action-braintree-paypal-logo[disabled],
fieldset[disabled] .action-braintree-paypal-logo {
    pointer-events: none;
    opacity: .5
}

.action-braintree-paypal-logo img {
    margin: 0;
    width: 114px
}

.payment-method .payment-method-content .actions-toolbar.braintree-paypal-actions {
    margin-left: 0
}

.account .table .col.paypal-account img {
    vertical-align: middle
}

.account .table .col.paypal-account+.actions {
    vertical-align: middle
}

.block-category-event {
    background: #f0f0f0;
    color: #333;
    padding: 10px 0 15px;
    text-align: center
}

.block-category-event.block:last-child {
    margin-bottom: 10px
}

.block-category-event .block-title {
    margin: 0 0 10px
}

.block-category-event .block-title strong {
    font-size: 1.5rem;
    font-weight: 300
}

.block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none
}

.block-category-event .ticker li {
    display: none;
    margin: 0 15px
}

.block-category-event .ticker .value {
    font-size: 1.8rem;
    font-weight: 300
}

.block-category-event .ticker .label {
    font-size: 1rem;
    display: block;
    text-transform: lowercase
}

.block-category-event .dates {
    display: table;
    margin: 0 auto
}

.block-category-event .dates .start,
.block-category-event .dates .end {
    display: table-cell;
    vertical-align: top
}

.block-category-event .dates .date {
    font-size: 1.8rem
}

.block-category-event .dates .time {
    display: block
}

.block-category-event .dates .start {
    padding-right: 40px;
    position: relative
}

.block-category-event .dates .start:after {
    font-size: 1.8rem;
    content: '\2013';
    display: block;
    position: absolute;
    right: 15px;
    top: 0
}

.contact-index-index .column:not(.sidebar-main) .form.contact {
    float: none;
    width: 50%
}

.contact-index-index .column:not(.sidebar-additional) .form.contact {
    float: none;
    width: 50%
}

.table-wrapper.billing-agreements {
    margin-bottom: 40px
}

.paypal {
    display: inline-block;
    vertical-align: top
}

.block-minicart .paypal,
.cart-summary .paypal {
    display: block
}

.paypal img {
    display: block;
    margin: 5px auto 0
}

.opc .paypal {
    vertical-align: middle
}

.paypal.acceptance {
    display: block;
    margin: 0 0 20px
}

.map-form-addtocart .paypal {
    display: block;
    margin: 10px auto 5px
}

.cart-summary .paypal+.paypal {
    margin-top: 25px
}

.paypal-review .actions-toolbar {
    margin-top: 10px
}

.paypal-review .paypal-review-title {
    padding: 0 0 10px
}

.paypal-review .paypal-review-title>strong {
    font-size: 2.4rem;
    font-weight: 300
}

.paypal-review .paypal-review-title .action {
    display: inline-block;
    margin: 12px 0 0 30px
}

.paypal-review .table-paypal-review-items .col.name {
    padding-top: 16px
}

.paypal-review .table-paypal-review-items .col.qty {
    text-align: center
}

.paypal-review .item-options dt {
    display: inline-block
}

.paypal-review .item-options dt:after {
    content: ': '
}

.paypal-review .item-options dd {
    margin: 0
}

.paypal-review-discount {
    border-bottom: 1px solid #ccc;
    margin: 0 0 40px;
    padding: 10px 0 0
}

.fotorama-video-container:after {
    background: url(../images/gallery-sprite.png) bottom right;
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px
}

.fotorama-video-container .magnify-lens {
    display: none !important
}

.fotorama-video-container.video-unplayed:hover img {
    opacity: .6
}

.fotorama-video-container.video-unplayed:hover:after {
    transform: scale(1.25)
}

.video-thumb-icon:after {
    background: url(../images/gallery-sprite.png) bottom left;
    bottom: 0;
    content: '';
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 49px
}

.product-video {
    bottom: 0;
    height: 75%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%
}

.product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999
}

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px
}

.fotorama__product-video--loading:after {
    visibility: hidden
}

@media only screen and (min-device-width:320px) and (max-device-width:780px) and (orientation:landscape) {
    .product-video {
        height: 100%;
        width: 81%
    }
}

.swatch-attribute-label {
    font-weight: 700;
    position: relative
}

.swatch-attribute-label.required {
    padding-right: 10px
}

.swatch-attribute-label[data-required='1']:after {
    color: #e02b27;
    content: '*';
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    right: -11px;
    top: -2px
}

.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px
}

.swatch-attribute-options {
    margin: 10px 0
}

.swatch-attribute-options:focus {
    box-shadow: none
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    color: #282828;
    bottom: -5px;
    height: 20px;
    left: 0;
    margin-bottom: 10px;
    position: absolute;
    text-align: center;
    width: 100%
}

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
    background: #f0f0f0;
    color: #949494
}

.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
    background: #fff;
    border: 1px solid #fff;
    color: #000
}

.swatch-option {
    border: 1px solid #dadada;
    cursor: pointer;
    float: left;
    height: 20px;
    margin: 0 10px 5px 0;
    max-width: 100%;
    min-width: 30px;
    overflow: hidden;
    padding: 1px 2px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis
}

.swatch-option:focus {
    box-shadow: 0 0 3px 1px #c1c1c1
}

.swatch-option.text {
    background: #f0f0f0;
    color: #686868;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin-right: 7px;
    min-width: 22px;
    padding: 4px 8px
}

.swatch-option.text.selected {
    background-color: #fff !important
}

.swatch-option.selected {
    outline: 2px solid #4a4a4a;
    border: 1px solid #fff;
    color: #333
}

.swatch-option:not(.disabled):hover {
    border: 1px solid #fff;
    color: #333;
    outline: 1px solid #999
}

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    border: 1px solid #fff;
    outline: 2px solid #2b2b2b
}

.swatch-option.disabled {
    box-shadow: unset;
    cursor: default;
    pointer-events: none
}

.swatch-option.disabled:after {
    background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(42%, rgba(255, 255, 255, 0)), color-stop(43%, #fff), color-stop(46%, #fff), color-stop(47%, #ff5216), color-stop(53%, #ff5216), color-stop(54%, #fff), color-stop(57%, #fff), color-stop(58%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    content: '';
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=#00ffffff, endColorstr=#00ffffff, GradientType=1)';
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.swatch-option-disabled {
    border: 0;
    cursor: default;
    outline: none !important
}

.swatch-option-disabled:after {
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
    content: '';
    height: 2px;
    left: -4px;
    position: absolute;
    top: 10px;
    width: 42px;
    z-index: 995;
    background: #e02b27
}

.swatch-option-loading {
    content: url('../images/loader-2.gif')
}

.swatch-option-tooltip {
    border: 1px solid #dadada;
    color: #949494;
    display: none;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    padding: 5px;
    position: absolute;
    text-align: center;
    z-index: 999;
    background: #fff
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    bottom: 0;
    height: 8px;
    left: 40%;
    position: absolute
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before,
.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    border-style: solid;
    content: '';
    font-size: 1px;
    height: 0;
    position: relative;
    width: 0
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    border-color: #adadad transparent transparent transparent;
    border-width: 8px 8.5px 0 8.5px;
    left: 0;
    top: 2px
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    border-color: #fff transparent transparent transparent;
    border-width: 7px 7.5px 0 7.5px;
    left: -15px;
    top: 1px
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    margin: 0 auto;
    width: 130px
}

.swatch-option-tooltip-layered {
    border: 1px solid #dadada;
    color: #949494;
    background: #fff;
    display: none;
    left: -47px;
    position: absolute;
    width: 140px;
    z-index: 999
}

.swatch-option-tooltip .title {
    color: #282828;
    display: block;
    max-height: 200px;
    min-height: 20px;
    overflow: hidden;
    text-align: center
}

.swatch-option-link-layered {
    margin: 0 !important;
    padding: 0 !important;
    position: relative
}

.swatch-option-link-layered:focus>div {
    box-shadow: 0 0 3px 1px #c1c1c1
}

.swatch-option-link-layered:hover>.swatch-option-tooltip-layered {
    display: block
}

.swatch-opt {
    margin: 20px 0
}

.swatch-opt-listing {
    margin-bottom: 10px
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    padding: 2px;
    position: static;
    z-index: 1
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden
}

.clearfix:after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden
}

.minilist .weee {
    display: table-row;
    font-size: 1.1rem
}

.minilist .weee:before {
    display: none
}

.price-container.weee:before {
    display: none
}

.column .block-addbysku .fieldset {
    margin: 0
}

.column .block-addbysku .fieldset .fields {
    position: relative
}

.column .block-addbysku .fieldset .fields .field {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top
}

.column .block-addbysku .fieldset .fields .actions-toolbar {
    position: absolute;
    right: 0;
    top: 7px
}

.column .block-addbysku .sku {
    margin-right: -155px;
    padding-right: 160px;
    width: 100%
}

.column .block-addbysku .qty .qty+.mage-error {
    width: 80px
}

.column .block-addbysku .block-content .actions-toolbar {
    clear: both;
    margin: 0 0 20px;
    text-align: left
}

.column .block-addbysku .block-content .actions-toolbar:before,
.column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table
}

.column .block-addbysku .block-content .actions-toolbar:after {
    clear: both
}

.column .block-addbysku .block-content .actions-toolbar .primary {
    float: left
}

.column .block-addbysku .block-content .actions-toolbar .primary,
.column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block
}

.column .block-addbysku .block-content .actions-toolbar .primary a.action,
.column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    display: inline-block
}

.column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 15px 0 0
}

.column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px
}

.column .block-addbysku .block-content .actions-toolbar .primary,
.column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none
}

.column .block-addbysku .block-content .actions-toolbar>.secondary {
    text-align: left
}

.column .block-addbysku .action.add {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 4px;
    width: auto
}

.column .block-addbysku .action.add>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.column .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: inherit;
    color: inherit;
    content: '\e61c';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.column .block-addbysku .action.add:hover:before {
    color: inherit
}

.column .block-addbysku .action.add:active:before {
    color: inherit
}

.column .block-addbysku .reset {
    margin: 7px 0
}

.column .block-addbysku .note {
    display: block;
    margin: 0 0 20px;
    padding-top: 10px
}

.block-cart-failed {
    margin: 40px 0
}

.block-cart-failed .block-title {
    font-size: 2.1rem
}

.block-cart-failed .block-title strong {
    font-weight: 700
}

.block-cart-failed .actions {
    text-align: center
}

.block-cart-failed .actions .action {
    display: block;
    margin: 0 auto 10px
}

.catalogsearch-advanced-result .message.error {
    margin-top: -60px
}

.bundle-actions {
    margin: 0 0 30px
}

.bundle-options-container {
    clear: both;
    margin-bottom: 40px
}

.bundle-options-container .legend.title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
    border: 0;
    padding: 0
}

.bundle-options-container .product-add-form {
    display: none
}

.bundle-options-container .product-options-wrapper {
    margin-bottom: 30px
}

.bundle-options-container .product-options-wrapper .fieldset>.field {
    border-top: 1px #e4e4e4 solid;
    padding-top: 20px
}

.bundle-options-container .product-options-wrapper .fieldset>.field:first-of-type {
    border-top: 0;
    padding-top: 0
}

.bundle-options-container .product-options-wrapper .field.choice input {
    float: left;
    margin-top: 4px
}

.bundle-options-container .product-options-wrapper .field.choice .label {
    display: block;
    margin-left: 24px
}

.bundle-options-container .product-options-wrapper .field.choice .price-excluding-tax {
    display: inline-block
}

.bundle-options-container .action.back {
    margin-bottom: 30px
}

.bundle-options-container .block-bundle-summary>.title {
    margin-bottom: 15px
}

.bundle-options-container .block-bundle-summary>.title>strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem
}

.bundle-options-container .block-bundle-summary>.title,
.bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
    border-bottom: 1px #e4e4e4 solid;
    margin-bottom: 25px;
    padding-bottom: 16px
}

.bundle-options-container .block-bundle-summary .price-box {
    margin-bottom: 20px
}

.bundle-options-container .block-bundle-summary .price-box .price-label {
    display: block;
    margin-bottom: 5px
}

.bundle-options-container .block-bundle-summary .bundle-summary {
    margin-top: 30px
}

.bundle-options-container .block-bundle-summary .bundle-summary.empty {
    display: none
}

.bundle-options-container .block-bundle-summary .bundle-summary>.subtitle {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    display: block
}

.bundle-options-container .block-bundle-summary .bundle.items>li {
    margin-bottom: 20px
}

.bundle-options-container .block-bundle-summary .product-addto-links {
    text-align: center
}

.bundle-options-container .block-bundle-summary .product-addto-links>.action {
    margin-right: 5%
}

.bundle-options-container .block-bundle-summary .product-addto-links>.action.tocompare:before {
    content: '\f516'
}

.bundle-options-container p.required {
    color: #e02b27
}

.bundle-options-container .nested .field.qty {
    margin: 0 0 20px;
    margin-top: 20px
}

.bundle-options-container .nested .field.qty>.label {
    margin: 0 0 8px;
    display: inline-block
}

.bundle-options-container .nested .field.qty .label {
    font-weight: 600
}

.bundle-options-container .nested .field.qty:last-child {
    margin-bottom: 0
}

.bundle-options-container .price {
    font-weight: 600
}

.bundle-options-container .product-options-bottom {
    border-top: 1px solid #ccc;
    clear: left;
    margin: 0 0 40px;
    padding-top: 20px
}

.checkout-cart-index .page-title-wrapper {
    padding-left: 15px;
    padding-right: 15px
}

.checkout-cart-index .page-title {
    text-align: center;
    position: relative
}

.cart-discount {
    margin-top: 4rem
}

.quote-form-break {
    margin-top: 30px
}

#custom-quote-form button {
    margin-top: 20px;
    display: block
}

#custom-quote-form h2 {
    margin: 0
}

.checkout-cart-index .header-right .minicart-wrapper .action.showcart {
    display: none
}

.cart-summary {
    background: #ec6d26;
    color: #fff
}

.cart-summary .cart-totals {
    display: none
}

.cart-summary .checkout-methods-items {
    list-style: none;
    padding: 0;
    margin: 0
}

.cart-summary .checkout-methods-items>li {
    padding: 0;
    margin: 0
}

.cart-summary .checkout-methods-items .columns-summary {
    display: none
}

.cart-summary p {
    margin-bottom: 20px
}

.cart-summary p:first-child {
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 1
}

.cart-summary p:first-child strong {
    font-size: 3.5rem
}

.cart-summary .action.primary.checkout,
.cart-summary .amshopby-button.checkout,
.cart-summary .am-filter-go.checkout {
    background: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 20px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px
}

.cart-summary .action.primary.checkout:focus,
.cart-summary .action.primary.checkout:hover,
.cart-summary .amshopby-button.checkout:focus,
.cart-summary .amshopby-button.checkout:hover,
.cart-summary .am-filter-go.checkout:focus,
.cart-summary .am-filter-go.checkout:hover {
    background: none;
    border: none
}

.cart-summary .action.primary.checkout:active,
.cart-summary .amshopby-button.checkout:active,
.cart-summary .am-filter-go.checkout:active {
    background: none;
    border: none
}

.cart-summary .action.primary.checkout.disabled,
.cart-summary .action.primary.checkout[disabled],
fieldset[disabled] .cart-summary .action.primary.checkout,
.cart-summary .amshopby-button.checkout.disabled,
.cart-summary .amshopby-button.checkout[disabled],
fieldset[disabled] .cart-summary .amshopby-button.checkout,
.cart-summary .am-filter-go.checkout.disabled,
.cart-summary .am-filter-go.checkout[disabled],
fieldset[disabled] .cart-summary .am-filter-go.checkout {
    pointer-events: none;
    opacity: .5
}

.cart-summary .action.primary.checkout:before,
.cart-summary .amshopby-button.checkout:before,
.cart-summary .am-filter-go.checkout:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.cart-summary .action.primary.checkout:hover:before,
.cart-summary .amshopby-button.checkout:hover:before,
.cart-summary .am-filter-go.checkout:hover:before {
    color: inherit
}

.cart-summary .action.primary.checkout:active:before,
.cart-summary .amshopby-button.checkout:active:before,
.cart-summary .am-filter-go.checkout:active:before {
    color: inherit
}

.cart-summary .action.primary.checkout:visited,
.cart-summary .amshopby-button.checkout:visited,
.cart-summary .am-filter-go.checkout:visited {
    color: #333
}

.cart-summary .action.primary.checkout:focus,
.cart-summary .action.primary.checkout:hover,
.cart-summary .amshopby-button.checkout:focus,
.cart-summary .amshopby-button.checkout:hover,
.cart-summary .am-filter-go.checkout:focus,
.cart-summary .am-filter-go.checkout:hover {
    background: #e43866;
    border: 0;
}

.cart-summary .action.primary.checkout:active,
.cart-summary .amshopby-button.checkout:active,
.cart-summary .am-filter-go.checkout:active {
    background: #ebebeb;
    border: 0;
    color: #333
}

.cart-summary .action.primary.checkout.disabled,
.cart-summary .action.primary.checkout[disabled],
fieldset[disabled] .cart-summary .action.primary.checkout,
.cart-summary .amshopby-button.checkout.disabled,
.cart-summary .amshopby-button.checkout[disabled],
fieldset[disabled] .cart-summary .amshopby-button.checkout,
.cart-summary .am-filter-go.checkout.disabled,
.cart-summary .am-filter-go.checkout[disabled],
fieldset[disabled] .cart-summary .am-filter-go.checkout {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.cart-summary .action.multicheckout {
    color: #fff
}

.cart-summary li.item:not(:first-child) {
    margin-top: 20px
}

.cart-totals tbody .mark,
.cart-totals tfoot .mark {
    text-align: left
}

.cart-totals .discount.coupon {
    display: none
}

.cart .cart-item {
    display: flex;
    flex-wrap: wrap
}

.cart .cart-item:not(:last-child) {
    border-bottom: 2px solid #ccc
}

.cart .parent-item-top {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

.cart .cart-item-buttons {
    display: flex
}

.cart .parent-item-details {
    margin-bottom: 10px
}

.cart .parent-item-details a {
    text-decoration: none
}

.cart .parent-item-details h2 {
    color: #4a4a4a;
    margin: 0
}

.cart .parent-item-details p {
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0
}

.cart .shopping-cart-table {
    width: 100%
}

.cart .shopping-cart-table tbody>tr>td,
.cart .shopping-cart-table thead>tr>td,
.cart .shopping-cart-table tfoot>tr>td,
.cart .shopping-cart-table tbody>tr>th,
.cart .shopping-cart-table thead>tr>th,
.cart .shopping-cart-table tfoot>tr>th {
    vertical-align: middle;
    padding: 10px
}

.cart .shopping-cart-table tbody>tr>td:first-child,
.cart .shopping-cart-table thead>tr>td:first-child,
.cart .shopping-cart-table tfoot>tr>td:first-child,
.cart .shopping-cart-table tbody>tr>th:first-child,
.cart .shopping-cart-table thead>tr>th:first-child,
.cart .shopping-cart-table tfoot>tr>th:first-child {
    padding-left: 0
}

.cart .shopping-cart-table tbody>tr>td:last-child,
.cart .shopping-cart-table thead>tr>td:last-child,
.cart .shopping-cart-table tfoot>tr>td:last-child,
.cart .shopping-cart-table tbody>tr>th:last-child,
.cart .shopping-cart-table thead>tr>th:last-child,
.cart .shopping-cart-table tfoot>tr>th:last-child {
    padding-right: 0;
    text-align: right
}

.cart .shopping-cart-table tbody>tr>td:not(:first-child),
.cart .shopping-cart-table thead>tr>td:not(:first-child),
.cart .shopping-cart-table tfoot>tr>td:not(:first-child),
.cart .shopping-cart-table tbody>tr>th:not(:first-child),
.cart .shopping-cart-table thead>tr>th:not(:first-child),
.cart .shopping-cart-table tfoot>tr>th:not(:first-child) {
    width: 1px
}

.cart .shopping-cart-table thead>tr {
    border-bottom: 1px solid #ccc
}

.cart .shopping-cart-table tbody>tr {
    border-bottom: 1px solid transparent
}

.cart .shopping-cart-table tbody>tr:hover {
    border-color: #ccc
}

.cart .shopping-cart-table .price-including-tax .price,
.cart .shopping-cart-table .price-excluding-tax .price {
    font-weight: normal;
    font-size: 14px
}

.cart .shopping-cart-table .actions-toolbar {
    text-align: inherit
}

.cart .table-columns {
    min-width: 100%
}

.cart .table-columns .table-column {
    overflow: auto
}

.cart .table-columns .table-column:after {
    content: "";
    display: block
}

.cart .view-molecule {
    background-image: none;
    background: #fff;
    border: 1px solid #333;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px
}

.cart .view-molecule:hover,
.cart .view-molecule:active,
.cart .view-molecule:focus {
    text-decoration: none
}

.cart .view-molecule:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f610';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.cart .view-molecule:hover:before {
    color: inherit
}

.cart .view-molecule:active:before {
    color: inherit
}

.cart .view-molecule:visited {
    color: #333
}

.cart .view-molecule:focus,
.cart .view-molecule:hover {
    background: #f2f2f2;
    border: 1px solid #333;
    color: #333
}

.cart .view-molecule:active {
    background: #ebebeb;
    border: 1px solid #333;
    color: #333
}

.cart .view-molecule.disabled,
.cart .view-molecule[disabled],
fieldset[disabled] .cart .view-molecule {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.cart .action-delete {
    background-image: none;
    background: #fff;
    border: 1px solid #e62645;
    color: #e62645;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 0 0 10px;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px
}

.cart .action-delete:hover,
.cart .action-delete:active,
.cart .action-delete:focus {
    text-decoration: none
}

.cart .action-delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.cart .action-delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.cart .action-delete:hover:before {
    color: inherit
}

.cart .action-delete:active:before {
    color: inherit
}

.cart .action-delete:visited {
    color: #e62645;
}

.cart .action-delete:focus,
.cart .action-delete:hover {
    background: #fce6ec;
    border: 1px solid #e43866;
}

.cart .action-delete:active {
    background: #fce6ec;
    border: 1px solid #e43866;
}

.cart .action-delete.disabled,
.cart .action-delete[disabled],
fieldset[disabled] .cart .action-delete {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.cart.table-wrapper .cart thead tr th.col {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    padding-top: 24px
}

.cart.table-wrapper .cart tbody td {
    border: 0
}

.cart.table-wrapper .cart>.item {
    border-bottom: 1px solid #ccc;
    position: relative
}

.cart.table-wrapper .col {
    padding-top: 15px
}

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp {
    padding: 20px 11px 10px;
    text-align: center
}

.cart.table-wrapper .col.qty {
    padding: 20px 11px 10px;
    text-align: center
}

.cart.table-wrapper .col.qty .input-text {
    height: 36px;
    margin-top: -7px;
    text-align: center;
    width: 60px
}

.cart.table-wrapper .col>.price {
    color: #7d7d7d;
    font-size: 1.8rem;
    font-weight: 700
}

.cart.table-wrapper .item-actions td {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    white-space: normal
}

.cart.table-wrapper .item .col.item {
    display: block;
    min-height: 75px;
    padding: 15px 15px 10px 90px;
    position: relative
}

.cart.table-wrapper .actions-toolbar {
    min-height: 20px;
    padding-bottom: 15px;
    position: relative
}

.cart.table-wrapper .actions-toolbar>.action-edit,
.cart.table-wrapper .actions-toolbar>.action-delete {
    position: absolute;
    right: 16px;
    top: 0;
    display: inline-block;
    text-decoration: none
}

.cart.table-wrapper .actions-toolbar>.action-edit>span,
.cart.table-wrapper .actions-toolbar>.action-delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.cart.table-wrapper .actions-toolbar>.action-edit:before,
.cart.table-wrapper .actions-toolbar>.action-delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\f040';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.cart.table-wrapper .actions-toolbar>.action-edit:hover:before,
.cart.table-wrapper .actions-toolbar>.action-delete:hover:before {
    color: #333
}

.cart.table-wrapper .actions-toolbar>.action-edit:active:before,
.cart.table-wrapper .actions-toolbar>.action-delete:active:before {
    color: #333
}

.cart.table-wrapper .actions-toolbar>.action-delete {
    right: 0
}

.cart.table-wrapper .actions-toolbar>.action-delete:before {
    content: '\e604'
}

.cart.table-wrapper .action {
    margin-right: 25px
}

.cart.table-wrapper .action:last-child {
    margin-right: 0
}

.cart.table-wrapper .action.help.map {
    font-weight: 400
}

.cart.table-wrapper .product-item-photo {
    display: block;
    left: 15px;
    max-width: 65px;
    padding: 0;
    position: absolute;
    top: 15px;
    width: 100%
}

.cart.table-wrapper .product-item-name {
    font-size: 1.8rem;
    display: block;
    margin: -3px 0 5px
}

.cart.table-wrapper .gift-registry-name-label:after {
    content: ':'
}

.cart.table-wrapper .item-options {
    margin-bottom: 0
}

.cart.table-wrapper .product-item-name+.item-options {
    margin-top: 20px
}

.cart.table-wrapper .action.configure {
    display: inline-block;
    margin: 10px 0 0
}

.cart.table-wrapper .item .message {
    margin-top: 20px
}

.cart-products-toolbar .toolbar-amount {
    left: inherit;
    margin: 25px 0 15px;
    padding: 0;
    position: relative;
    text-align: center;
    top: inherit
}

.cart-products-toolbar-top .pager {
    display: none
}

.cart-discount {
    border-bottom: 1px solid #ccc;
    clear: left
}

.cart-empty {
    padding-left: 15px;
    padding-right: 15px
}

.cart-tax-info+.cart-tax-total,
.cart .cart-tax-info+.cart-tax-total {
    display: block
}

.cart.table-wrapper .col.price,
.order-items.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.order-items.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.msrp {
    text-align: right
}

.block.crosssell {
    padding: 0 15px;
    margin-top: 70px
}

.block.crosssell .product-item-info {
    width: 200px
}

.block-minicart .items-total {
    float: left;
    margin: 0 10px
}

.block-minicart .items-total .count {
    font-weight: 700
}

.block-minicart .subtotal {
    margin: 0 10px 10px;
    text-align: right
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-size: 18px;
    font-weight: 700
}

.block-minicart .subtitle {
    display: none
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center
}

.block-minicart dl.product.options.list {
    display: inline-block;
    vertical-align: top
}

.block-minicart .text.empty {
    text-align: center
}

.block-minicart .block-content>.actions {
    margin-top: 15px
}

.block-minicart .block-content>.actions>.secondary {
    text-align: center
}

.block-minicart .block-content>.actions>.primary {
    margin: 0 10px 15px
}

.block-minicart .block-content>.actions>.primary .action.primary,
.block-minicart .block-content>.actions>.primary .amshopby-button,
.block-minicart .block-content>.actions>.primary .am-filter-go {
    display: block;
    width: 100%
}

.block-minicart .block-content>.actions .paypal-logo {
    margin-top: 15px;
    text-align: center
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center
}

.minicart-wrapper {
    margin-right: 10px;
    display: inline-block;
    position: relative
}

.minicart-wrapper:before,
.minicart-wrapper:after {
    content: '';
    display: table
}

.minicart-wrapper:after {
    clear: both
}

.minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none
}

.minicart-wrapper .action.showcart:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 28px;
    color: #333;
    content: '\f07a';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-wrapper .action.showcart:hover:before {
    color: #000
}

.minicart-wrapper .action.showcart:active:before {
    color: #000
}

.minicart-wrapper .action.showcart.active {
    display: inline-block;
    text-decoration: none
}

.minicart-wrapper .action.showcart.active:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 28px;
    color: #333;
    content: '\f07a';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-wrapper .action.showcart.active:hover:before {
    color: #000
}

.minicart-wrapper .action.showcart.active:active:before {
    color: #000
}

.minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    width: 320px;
    z-index: 101;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.minicart-wrapper .block-minicart li {
    margin: 0
}

.minicart-wrapper .block-minicart li:hover {
    cursor: pointer
}

.minicart-wrapper .block-minicart:before,
.minicart-wrapper .block-minicart:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.minicart-wrapper .block-minicart:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.minicart-wrapper .block-minicart:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.minicart-wrapper .block-minicart:before {
    right: 12px;
    top: -12px
}

.minicart-wrapper .block-minicart:after {
    right: 11px;
    top: -14px
}

.minicart-wrapper.active {
    overflow: visible
}

.minicart-wrapper.active .block-minicart {
    display: block
}

.minicart-wrapper .block-minicart {
    padding: 25px 20px
}

.minicart-wrapper .block-minicart .block-title {
    display: none
}

.minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0
}

.minicart-wrapper .product .actions>.primary,
.minicart-wrapper .product .actions>.secondary {
    display: inline
}

.minicart-wrapper .product .actions>.primary:not(:last-child),
.minicart-wrapper .product .actions>.secondary:not(:last-child) {
    margin-right: 15px
}

.minicart-wrapper .action.close {
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none
}

.minicart-wrapper .action.close:focus,
.minicart-wrapper .action.close:hover {
    background: none;
    border: none
}

.minicart-wrapper .action.close:active {
    background: none;
    border: none
}

.minicart-wrapper .action.close.disabled,
.minicart-wrapper .action.close[disabled],
fieldset[disabled] .minicart-wrapper .action.close {
    pointer-events: none;
    opacity: .5
}

.minicart-wrapper .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 14px;
    color: #333;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-wrapper .action.close:hover:before {
    color: inherit
}

.minicart-wrapper .action.close:active:before {
    color: inherit
}

.minicart-wrapper .action.showcart {
    white-space: nowrap;
    font-weight: 700;
    color: #333;
    text-decoration: none
}

.minicart-wrapper .action.showcart:visited {
    color: #333;
    text-decoration: none
}

.minicart-wrapper .action.showcart:hover {
    color: #000;
    text-decoration: none
}

.minicart-wrapper .action.showcart:active {
    color: #000;
    text-decoration: none
}

.minicart-wrapper .action.showcart::before {
    font-weight: 900
}

.minicart-wrapper .action.showcart .text {
    line-height: 28px;
    padding-left: 5px
}

.minicart-wrapper .action.showcart .counter.qty {
    line-height: 28px;
    padding-left: 10px
}

.minicart-wrapper .action.showcart .counter.qty.empty {
    display: none
}

.minicart-wrapper .action.showcart .counter.qty .loader>img {
    max-width: 24px
}

.minicart-wrapper .action.showcart .counter-number {
    text-shadow: 0 0 7px #000
}

.minicart-wrapper .minicart-widgets {
    margin-top: 15px
}

.minicart-items-wrapper {
    border: 1px solid #ccc;
    margin: 0 -20px;
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px
}

.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none
}

.minicart-items .product-item {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: left
}

.minicart-items .product-item:first-child {
    padding-top: 0
}

.minicart-items .product-image-wrapper {
    width: 100px
}

.minicart-items .product-item-pricing .label {
    display: inline-block
}

.minicart-items .price-minicart {
    margin-bottom: 5px
}

.minicart-items .product-item-name {
    font-weight: 700;
    font-size: 1.7rem;
    margin: 0;
    color: #4a4a4a
}

.minicart-items .product-item-details .price {
    font-weight: 700
}

.minicart-items .product-item-details .price-including-tax,
.minicart-items .product-item-details .price-excluding-tax {
    margin: 5px 0
}

.minicart-items .product-item-details .weee[data-label] {
    font-size: 1.1rem
}

.minicart-items .product-item-details .subtotal {
    font-weight: bold;
    display: inline-block;
    margin-top: 10px
}

.minicart-items .product {
    display: flex;
    margin: 0 0 20px 0
}

.minicart-items .product .product-image-container {
    border: 0
}

.minicart-items .product .toggle {
    border: 0;
    padding: 0 40px 5px 0
}

.minicart-items .product .toggle:after {
    color: #8f8f8f;
    margin: 0 0 0 5px;
    position: static
}

.minicart-items .product .active>.toggle:after {
    content: '\f077'
}

.minicart-items .product.pricing {
    margin-top: 3px
}

.minicart-items .product.options .toggle.tooltip {
    display: inline-block;
    text-decoration: none
}

.minicart-items .product.options .toggle.tooltip>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.minicart-items .product.options .toggle.tooltip:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: -3px 0 0 7px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-items .product.options .details {
    display: none
}

.minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 60px
}

.minicart-items .update-cart-item {
    font-size: 1.1rem;
    vertical-align: top
}

.minicart-items .subtitle {
    display: none
}

.minicart-items .action.edit,
.minicart-items .action.delete {
    display: inline-block;
    text-decoration: none
}

.minicart-items .action.edit>span,
.minicart-items .action.delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.minicart-items .action.edit:before,
.minicart-items .action.delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\f040';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.minicart-items .action.edit:hover:before,
.minicart-items .action.delete:hover:before {
    color: #333
}

.minicart-items .action.edit:active:before,
.minicart-items .action.delete:active:before {
    color: #333
}

.minicart-items .action.delete:before {
    content: '\e604'
}

.file-uploader-area {
    position: relative
}

.file-uploader-area input[type='file'] {
    cursor: pointer;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 0
}

.file-uploader-area input[type='file']:focus+.file-uploader-button {
    box-shadow: 0 0 0 1px #006bb4
}

.file-uploader-area input[type='file']:disabled+.file-uploader-button {
    cursor: default;
    opacity: .5;
    pointer-events: none
}

.file-uploader-summary {
    display: inline-block;
    vertical-align: top
}

.file-uploader-button {
    background: #eee;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin: 0;
    padding: 7px 15px;
    vertical-align: middle
}

.file-uploader-button._is-dragover {
    background: #d4d4d4;
    border: 1px solid #006bb4
}

.file-uploader-spinner {
    background-image: url('../images/loader-1.gif');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    display: none;
    height: 30px;
    margin-left: 10px;
    vertical-align: top;
    width: 15px
}

.file-uploader-preview .action-remove {
    bottom: 4px;
    cursor: pointer;
    display: block;
    height: 27px;
    left: 6px;
    padding: 2px;
    position: absolute;
    text-decoration: none;
    width: 25px;
    z-index: 2
}

.file-uploader-preview .action-remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.file-uploader-preview .action-remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    line-height: inherit;
    color: #514943;
    content: '\e604';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .action-remove:hover:before {
    color: #736963
}

.file-uploader-preview:hover .preview-image img,
.file-uploader-preview:hover .preview-link:before {
    opacity: .7
}

.file-uploader-preview .preview-link {
    display: block;
    height: 100%
}

.file-uploader-preview .preview-image img {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.file-uploader-preview .preview-video {
    display: inline-block;
    text-decoration: none
}

.file-uploader-preview .preview-video:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 4rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .preview-video:hover:before {
    color: #ccc
}

.file-uploader-preview .preview-video:before {
    left: 0;
    margin-top: -2rem;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2
}

.file-uploader-preview .preview-document {
    display: inline-block;
    text-decoration: none
}

.file-uploader-preview .preview-document:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 7rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-preview .preview-document:hover:before {
    color: #ccc
}

.file-uploader-preview .preview-document:before {
    left: 0;
    margin-top: -3.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2
}

.file-uploader-preview,
.file-uploader-placeholder {
    background: #fff;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 150px;
    line-height: 1;
    margin: 10px 25px 10px 0;
    overflow: hidden;
    position: relative;
    width: 150px
}

.file-uploader._loading .file-uploader-spinner {
    display: inline-block
}

.file-uploader .admin__field-note,
.file-uploader .admin__field-error {
    margin-bottom: 10px
}

.file-uploader .file-uploader-filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    word-break: break-all
}

.file-uploader .file-uploader-filename:first-child {
    margin-bottom: 10px
}

.file-uploader .file-uploader-meta {
    color: #9e9e9e
}

.file-uploader .admin__field-fallback-reset {
    margin-left: 10px
}

._keyfocus .file-uploader .action-remove:focus {
    box-shadow: 0 0 0 1px #006bb4
}

.file-uploader-placeholder.placeholder-document {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-document:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 5rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-document:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-document:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2
}

.file-uploader-placeholder.placeholder-image {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-image:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 5rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-image:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-image:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 2
}

.file-uploader-placeholder.placeholder-video {
    display: inline-block;
    text-decoration: none
}

.file-uploader-placeholder.placeholder-video:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 3rem;
    line-height: inherit;
    color: #ccc;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.file-uploader-placeholder.placeholder-video:hover:before {
    color: #ccc
}

.file-uploader-placeholder.placeholder-video:before {
    left: 0;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 2
}

.file-uploader-placeholder-text {
    bottom: 0;
    color: #008bdb;
    font-size: 1.1rem;
    left: 0;
    line-height: 1.42857143;
    margin-bottom: 15%;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: center
}

.data-grid-file-uploader {
    min-width: 7rem
}

.data-grid-file-uploader._loading .file-uploader-spinner {
    display: block
}

.data-grid-file-uploader._loading .file-uploader-button:before {
    display: none
}

.data-grid-file-uploader .file-uploader-image {
    background: transparent;
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.data-grid-file-uploader .file-uploader-image+.file-uploader-area .file-uploader-button:before {
    display: none
}

.data-grid-file-uploader .file-uploader-area {
    z-index: 2
}

.data-grid-file-uploader .file-uploader-spinner {
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.data-grid-file-uploader .file-uploader-button {
    height: 48px;
    text-align: center;
    display: block;
    text-decoration: none
}

.data-grid-file-uploader .file-uploader-button>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.data-grid-file-uploader .file-uploader-button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.3rem;
    line-height: 48px;
    color: #8a837f;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.data-grid-file-uploader .file-uploader-button:hover:before {
    color: #666
}

.data-grid-file-uploader .action-select-wrap {
    float: left
}

.data-grid-file-uploader .action-select-wrap .action-select {
    border: 1px solid #cacaca;
    display: block;
    height: 5rem;
    margin-left: -1px;
    padding: 0;
    width: 2rem
}

.data-grid-file-uploader .action-select-wrap .action-select:after {
    border-color: #8a837f transparent transparent transparent;
    left: 50%;
    margin: 0 0 0 -5px
}

.data-grid-file-uploader .action-select-wrap .action-select:hover:after {
    border-color: #666 transparent transparent transparent
}

.data-grid-file-uploader .action-select-wrap .action-select>span {
    display: none
}

.data-grid-file-uploader .action-select-wrap .action-menu {
    left: 4rem;
    right: auto;
    z-index: 2
}

.data-grid-file-uploader-inner {
    border: 1px solid #cacaca;
    float: left;
    height: 5rem;
    position: relative;
    width: 5rem
}

.checkout-index-index .page-title-wrapper {
    text-align: center
}

.checkout-container {
    margin: 0 0 20px
}

.opc-main-wrapper {
    max-width: 1024px;
    margin: 0 auto
}

.opc-main-wrapper.no-summary {
    padding-right: 0
}

.opc-main-wrapper.no-summary .sidebar-container {
    width: 100%
}

.opc-main-wrapper.no-summary ol.opc li {
    padding: 20px;
    background: #f2f2f2;
    border-radius: 5px
}

.opc-main-wrapper .minicart-items-wrapper {
    padding: 0;
    border: 0;
    overflow: auto
}

.opc-main-wrapper .step-title {
    border-bottom: 0;
    margin: 0 0 10px
}

.opc-main-wrapper .checkout-payment-method {
    padding: 20px;
    background: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 40px
}

.opc-main-wrapper .checkout-payment-method .step-title:before {
    content: ""
}

.opc-main-wrapper .opc-phone {
    margin-top: 5px;
    display: inline-block
}

.opc-main-wrapper .opc-phone:before {
    content: "Phone: "
}

.opc-main-wrapper div[name="order_comment"] {
    background: #f2f2f2;
    padding: 10px
}

.opc-main-wrapper div[name="order_comment"] label {
    margin-left: 10px;
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem
}

.opc-main-wrapper div[name="order_comment"] .control {
    margin-top: 10px
}

.opc-wrapper .opc {
    counter-reset: section
}

.opc-wrapper .step-title:before {
    counter-increment: section;
    content: counter(section) ". ";
    color: #e62645;
}

.opc-wrapper .actions-toolbar {
    text-align: center;
    overflow: auto
}

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
    display: none
}

.checkout-index-index .logo {
    margin-left: 0
}

.checkout-onepage-success .page-title {
    color: #b5cc18;
    font-size: 4rem
}

.checkout-onepage-success .page-main {
    text-align: center
}

.checkout-onepage-success .page-main .actions-toolbar {
    text-align: center;
    margin: 20px 0
}

.checkout-onepage-success .page-main .actions-toolbar>.primary {
    float: none
}

.checkout-onepage-success .page-main .actions-toolbar>.primary .action {
    width: auto;
    margin: 0 5px
}

.abs-discount-code .form-discount {
    display: table;
    width: 100%
}

.abs-discount-code .form-discount .actions-toolbar {
    display: table-cell;
    vertical-align: top;
    width: 1%
}

.abs-discount-code .form-discount .actions-toolbar .primary {
    float: left
}

.abs-discount-code .form-discount .actions-toolbar .primary .action {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin: 0 0 0 -2px;
    white-space: nowrap;
    width: auto
}

.abs-discount-code .form-discount>.field>.label {
    display: none
}

.opc-totals {
    padding: 20px;
    background: #f2f2f2;
    border-radius: 5px
}

.opc-totals .table>tbody>tr>th,
.opc-totals .table>tbody>tr>td {
    border-top: 1px solid #ccc
}

.opc-totals .table>tbody>tr:first-child>th,
.opc-totals .table>tbody>tr:first-child>td {
    border-top: 0
}

.opc-totals .totals.shipping .value {
    display: block;
    font-weight: 400
}

.opc-estimated-wrapper {
    display: none
}

.opc-progress-bar {
    margin: 0 0 20px 0;
    padding: 0;
    counter-reset: i;
    display: flex;
    font-size: 0
}

.opc-progress-bar-item {
    margin: 0 0 10px;
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top
}

.opc-progress-bar-item:before {
    background: #d2d2d2;
    border: 1px solid #4a4a4a;
    top: 16px;
    transition: background .3s;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%
}

.opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px
}

.opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0
}

.opc-progress-bar-item>span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #666;
    font-weight: 700;
    font-size: 1.3rem
}

.opc-progress-bar-item>span:before,
.opc-progress-bar-item>span:after {
    background: #d2d2d2;
    border: 1px solid #4a4a4a;
    height: 38px;
    margin-left: -19px;
    transition: background .3s;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0
}

.opc-progress-bar-item>span:after {
    background: #fff;
    height: 26px;
    margin-left: -13px;
    top: 6px;
    width: 26px;
    content: counter(i);
    counter-increment: i;
    color: #333;
    font-weight: 600;
    line-height: 26px;
    font-size: 1.3rem
}

.opc-progress-bar-item>span[data-step="Shipping"]:after,
.opc-progress-bar-item>span[data-step="Review & Payments"]:after {
    font-family: 'Font Awesome 5 Pro'
}

.opc-progress-bar-item>span[data-step="Shipping"]:after {
    content: '\f48b'
}

.opc-progress-bar-item>span[data-step="Review & Payments"]:after {
    content: '\f38a'
}

.opc-progress-bar-item._complete {
    cursor: pointer
}

.opc-progress-bar-item._complete:hover:before {
    background: #c5c5c5
}

.opc-progress-bar-item._complete:hover>span:before {
    background: #c5c5c5
}

.opc-progress-bar-item._complete>span {
    color: #b74f0e
}

.opc-progress-bar-item._complete>span:after {
    content: '\f00c'
}

.opc-progress-bar-item._active:before {
    background: #4a4a4a;
    border-color: #4a4a4a
}

.opc-progress-bar-item._active>span {
    color: #333;
    font-weight: 700
}

.opc-progress-bar-item._active>span:before {
    background: #4a4a4a;
    border-color: #4a4a4a
}

.opc-progress-bar-item._active>span:after {
    border-color: #4a4a4a
}

.field.choice .field-tooltip {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -3px
}

.field.choice .field-tooltip-action {
    line-height: 21px
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border-color: #ed8380
}

.checkout-index-index .modal-popup .fieldset .field .label {
    font-weight: 400
}

.checkout-index-index .modal-popup .modal-footer .action-hide-popup {
    margin-top: 8px
}

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px
}

.field-tooltip._active {
    z-index: 100
}

.field-tooltip._active .field-tooltip-content {
    display: block
}

.field-tooltip._active .field-tooltip-action:before {
    color: #333
}

.field-tooltip .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none
}

.field-tooltip .field-tooltip-action>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.field-tooltip .field-tooltip-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 21px;
    line-height: inherit;
    color: #bbb;
    content: '\f059';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.field-tooltip .field-tooltip-action:hover:before {
    color: #333
}

.field-tooltip .field-tooltip-action:before {
    padding-left: 1px
}

._keyfocus .field-tooltip .field-tooltip-action:focus {
    z-index: 100
}

._keyfocus .field-tooltip .field-tooltip-action:focus+.field-tooltip-content {
    display: block
}

._keyfocus .field-tooltip .field-tooltip-action:focus:before {
    color: #333
}

.field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2
}

.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    border-right-color: #f4f4f4;
    left: -21px;
    top: 12px;
    content: '';
    display: block;
    position: absolute;
    z-index: 3
}

.field-tooltip .field-tooltip-content:before {
    border-right-color: #666
}

.field-tooltip .field-tooltip-content:after {
    border-right-color: #f4f4f4;
    width: 1px;
    z-index: 4
}

@media only screen and (max-width:768px) {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: -10px;
        top: 40px
    }

    .field-tooltip .field-tooltip-content::before,
    .field-tooltip .field-tooltip-content::after {
        border: 10px solid transparent;
        height: 0;
        left: auto;
        margin-top: -21px;
        right: 10px;
        top: 0;
        width: 0
    }

    .field-tooltip .field-tooltip-content::before {
        border-bottom-color: #999
    }

    .field-tooltip .field-tooltip-content::after {
        border-bottom-color: #f4f4f4;
        top: 1px
    }
}

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    margin-top: 28px;
    margin-bottom: 28px
}

.opc-wrapper .form-login .fieldset .field .label,
.opc-wrapper .form-shipping-address .fieldset .field .label {
    font-weight: 400
}

.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px
}

.opc-wrapper .shipping-address-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px
}

.opc-wrapper .shipping-address-item {
    padding: 20px;
    margin: 0 10px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    position: relative
}

.opc-wrapper .shipping-address-item.selected-item {
    border-color: #4a4a4a;
    background: #fff
}

.opc-wrapper .shipping-address-item.selected-item :after {
    content: '\f00c';
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: #4a4a4a;
    font-family: 'Font Awesome 5 Pro';
    color: #fff;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    border-bottom-left-radius: 5px
}

.opc-wrapper .shipping-address-item:not(.selected-item) {
    cursor: pointer
}

.opc-wrapper .shipping-address-item:not(.selected-item):hover,
.opc-wrapper .shipping-address-item:not(.selected-item):focus {
    border-color: #b8b8b8
}

.opc-wrapper .shipping-address-item:not(.selected-item):active {
    border-color: #ababab
}

.opc-wrapper .action-show-popup {
    margin: 10px 0 0
}

.opc-wrapper .action-show-popup>span:before {
    content: '+';
    padding-right: 5px
}

.opc-wrapper .action-select-shipping-item {
    float: right;
    margin: 20px 0 0
}

.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: 26px 5px 0 0
}

.checkout-shipping-method .no-quotes-block {
    margin: 20px 0
}

.methods-shipping .actions-toolbar .action.primary,
.methods-shipping .actions-toolbar .amshopby-button,
.methods-shipping .actions-toolbar .am-filter-go {
    margin: 20px 0 0
}

.table-checkout-shipping-method thead th {
    display: none
}

.table-checkout-shipping-method tbody td {
    border-top: none;
    padding-bottom: 10px;
    padding-top: 10px
}

.table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px
}

.table-checkout-shipping-method tbody tr:first-child td {
    border-top: none
}

.table-checkout-shipping-method tbody .col-price {
    font-weight: 600
}

.table-checkout-shipping-method tbody .row-error td {
    border-top: none;
    padding-bottom: 10px;
    padding-top: 0
}

#opc-new-shipping-address .field input,
#opc-new-shipping-address .field select {
    border-radius: 5px
}

.table-checkout-shipping-method {
    margin-bottom: 10px;
    width: auto
}

.checkout-shipping-method {
    position: relative
}

.shipping-policy-block.field-tooltip {
    top: 12px
}

.shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #b74f0e;
    cursor: pointer
}

.shipping-policy-block.field-tooltip .field-tooltip-action:before {
    display: none
}

.shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px
}

.opc-block-shipping-information .shipping-information {
    padding: 20px;
    background: #f2f2f2;
    border-radius: 5px
}

.opc-block-shipping-information .shipping-information .shipping-information-flex {
    display: flex;
    flex-wrap: wrap
}

.opc-block-shipping-information .shipping-information-title {
    border-bottom: none;
    position: relative
}

.opc-block-shipping-information .shipping-information-title .action-edit {
    top: 0;
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0
}

.opc-block-shipping-information .shipping-information-title .action-edit>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.opc-block-shipping-information .shipping-information-title .action-edit:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\f040';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
    color: #333
}

.opc-block-shipping-information .shipping-information-title .action-edit:active:before {
    color: #333
}

.opc-block-shipping-information .shipping-information-content {
    line-height: inherit
}

.opc-block-shipping-information .shipping-information-content .actions-toolbar {
    margin-left: 0
}

.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
    flex-grow: 1
}

.opc-sidebar {
    margin-bottom: 10px
}

.opc-block-summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-grow: 1
}

.opc-block-summary>.title {
    display: block;
    width: 100%;
    font-size: 2.3rem
}

.opc-block-summary .table-totals tbody .mark {
    font-weight: 700
}

.opc-block-summary .mark .value {
    color: #999;
    display: block
}

.opc-block-summary .discount.coupon {
    display: none
}

.opc-block-summary .grand.incl+.grand.excl .mark,
.opc-block-summary .grand.incl+.grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0
}

.opc-block-summary .grand.incl+.grand.excl .mark strong,
.opc-block-summary .grand.incl+.grand.excl .amount strong {
    font-weight: 400
}

.opc-block-summary .not-calculated {
    font-style: italic
}

.opc-block-summary .items-in-cart.block {
    width: 100%;
    margin-bottom: 0;
    max-height: 500px;
    overflow: auto
}

.opc-block-summary .items-in-cart>.title {
    border-bottom: 1px solid #ccc;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative
}

.opc-block-summary .items-in-cart>.title>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.opc-block-summary .items-in-cart>.title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: 12px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 3px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.opc-block-summary .items-in-cart>.title:after {
    position: absolute;
    right: 0;
    top: 10px
}

.opc-block-summary .items-in-cart>.title strong {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0
}

.opc-block-summary .items-in-cart.active>.title:after {
    content: '\f077'
}

.opc-block-summary .items-in-cart .product {
    position: relative;
    display: flex;
    flex-wrap: wrap
}

.opc-block-summary .minicart-items-wrapper {
    margin: 0 -15px 0 0;
    padding: 0 15px 0 0;
    border: 0
}

.column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
    width: 100%
}

.opc-block-summary .product-item {
    padding: 10px 0
}

.opc-block-summary .product-item .product-item-name {
    color: #4a4a4a;
    font-weight: 700;
    margin: 0
}

.opc-block-summary .product-item .details-qty {
    margin-top: 0;
    font-weight: bold;
    color: #333
}

.opc-block-summary .product-item .product-image-container {
    float: none;
    max-height: 80px;
    max-width: 100px
}

.opc-block-summary .product-item .product-image-container img {
    height: 80px;
    width: 100px
}

.opc-block-summary .product-item .product-item-details {
    padding: 0;
    text-align: left
}

.opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 700
}

.opc-block-summary .product-item .price-including-tax+.price-excluding-tax {
    margin: 0
}

.opc-block-summary .product-item .price-including-tax+.price-excluding-tax .price {
    font-size: 1rem
}

.opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1
}

.opc-block-summary .actions-toolbar .secondary {
    border-top: 1px solid #ccc;
    display: block;
    float: none;
    margin: -1px 0 0;
    padding: 15px 0 0;
    text-align: left
}

.opc-block-summary .actions-toolbar .secondary .action {
    margin: 0
}

.authentication-dropdown {
    box-sizing: border-box
}

.authentication-dropdown .modal-inner-wrap {
    padding: 25px
}

.authentication-wrapper {
    display: none
}

.block-authentication .block-title {
    font-size: 1.7rem;
    border-bottom: 0;
    margin-bottom: 25px
}

.block-authentication .block-title strong {
    font-weight: 300
}

.block-authentication .block-title h2 {
    margin-top: 0
}

.block-authentication .fieldset>.field:not(.note) {
    text-align: left;
    max-width: 320px;
    margin: 20px auto 0;
    width: 100%
}

.block-authentication .field .label {
    font-weight: 400
}

.block-authentication .actions-toolbar {
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    width: 100%;
    margin: 20px auto
}

.block-authentication .actions-toolbar:before,
.block-authentication .actions-toolbar:after {
    display: none
}

.block-authentication .actions-toolbar .primary .action {
    margin: 0
}

.block-authentication .block[class] {
    margin: 0
}

.block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px
}

.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
    float: none;
    width: auto
}

.block-authentication .block[class]+.block {
    position: relative
}

.block-authentication .block-customer-login {
    border-top: 0;
    text-align: center
}

.block-authentication .block-customer-login .logo {
    width: 150px;
    margin: 20px auto
}

.block-authentication .block-customer-login h1 {
    margin-bottom: 40px
}

.block-authentication .aw-ca-creat-wrapper {
    margin-top: 20px;
    border-top: 1px solid #ccc
}

.block-authentication .aw-ca-creat-wrapper .actions-toolbar {
    justify-content: center
}

.checkout-payment-method .payment-method:first-child .payment-method-title {
    border-top: 0
}

.checkout-payment-method .payment-method._active .payment-method-content {
    display: block
}

.checkout-payment-method .payment-method+.payment-method .payment-method-title {
    border-top: none
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary {
    margin: 0;
    float: none;
    width: 100%
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success {
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    width: 100%;
    margin: 0
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success:visited {
    color: #fff
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success:focus,
.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success.disabled,
.checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success[disabled],
fieldset[disabled] .checkout-payment-method .payment-method-content>.actions-toolbar>.primary .action.success {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.checkout-payment-method .payment-method-title {
    padding: 10px 0;
    margin: 0
}

.checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle
}

.checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px
}

.checkout-payment-method .payment-method-content {
    display: none;
    padding: 0
}

.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px
}

.checkout-payment-method .payment-group+.payment-group .step-title {
    margin: 20px 0 0
}

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
    max-width: 100%
}

.checkout-payment-method .billing-address-same-as-shipping-block {
    margin: 0 0 10px
}

.checkout-payment-method .checkout-billing-address {
    margin: 0 0 20px
}

.checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0
}

.checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: inherit;
    padding: 0 0 0 23px
}

.checkout-payment-method .payment-method-note+.checkout-billing-address {
    margin-top: 20px
}

.checkout-payment-method .payment-method-iframe {
    background-color: transparent;
    display: none;
    width: 100%
}

.checkout-payment-method .no-payments-block {
    margin: 20px 0
}

.checkout-payment-method .fieldset:last-child {
    margin-bottom: 0
}

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
    content: '\f077'
}

.checkout-payment-method .payment-option._collapsible .payment-option-title {
    cursor: pointer
}

.checkout-payment-method .payment-option._collapsible .payment-option-content {
    display: none
}

.checkout-payment-method .payment-option-title {
    border-top: none;
    padding: 10px 0
}

.checkout-payment-method .payment-option-title h3 {
    margin: 0
}

.checkout-payment-method .payment-option-title .action-toggle {
    color: #b74f0e;
    display: inline-block;
    text-decoration: none
}

.checkout-payment-method .payment-option-title .action-toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 16px;
    color: #333;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.checkout-payment-method .payment-option-title .action-toggle:hover:after {
    color: #333
}

.checkout-payment-method .payment-option-title .action-toggle:active:after {
    color: #333
}

.checkout-payment-method .payment-option-content {
    padding: 0 0 20px 0
}

.checkout-payment-method .payment-option-inner {
    margin: 0 0 20px
}

.checkout-payment-method .form-discount .payment-option-inner {
    margin-bottom: 10px
}

.checkout-payment-method .form-discount input {
    border-radius: 5px
}

.checkout-payment-method .form-discount .actions-toolbar .primary .action.action-apply {
    margin-right: 0
}

.checkout-payment-method .credit-card-types {
    padding: 0
}

.checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top
}

.checkout-payment-method .credit-card-types .item._active {
    font-weight: 700
}

.checkout-payment-method .credit-card-types .item._active img {
    -webkit-filter: grayscale(0%)
}

.checkout-payment-method .credit-card-types .item._inactive {
    opacity: .4
}

.checkout-payment-method .credit-card-types .item span {
    display: inline-block;
    padding-top: 6px;
    vertical-align: top
}

.checkout-payment-method .credit-card-types img {
    -webkit-filter: grayscale(100%);
    -webkit-transition: all .6s ease
}

.checkout-payment-method .ccard .fields>.year {
    padding-left: 5px
}

.checkout-payment-method .ccard .fields .select {
    padding-left: 5px;
    padding-right: 5px
}

.checkout-payment-method .ccard .month .select {
    width: 140px
}

.checkout-payment-method .ccard .year .select {
    width: 80px
}

.checkout-payment-method .ccard .captcha .input-text,
.checkout-payment-method .ccard .number .input-text {
    width: 225px
}

.checkout-payment-method .ccard>.field.cvv>.control {
    padding-right: 20px;
    width: auto
}

.checkout-payment-method .ccard .cvv .input-text {
    width: 55px
}

.checkout-payment-method .ccard.fieldset>.field .fields.group.group-2 .field {
    width: auto !important
}

.checkout-agreements-block .checkout-agreements {
    margin-bottom: 20px
}

.checkout-agreements-block .action-show {
    vertical-align: baseline
}

.checkout-agreements-items {
    padding-bottom: 30px
}

.checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px
}

.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0
}

.checkout-agreement-item-content {
    overflow: auto
}

.block-balance .price {
    margin-top: -10px;
    font-size: 3.2rem;
    display: inline-block;
    line-height: 32px
}

.table-downloadable-products .product-name {
    font-weight: 400;
    margin-right: 15px
}

.table-downloadable-products .action.download {
    display: inline-block;
    text-decoration: none
}

.table-downloadable-products .action.download:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\e626';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.page-product-downloadable .product-add-form {
    clear: both;
    margin-bottom: 25px
}

.page-product-downloadable .product-options-bottom .field.qty {
    display: none
}

.samples-item-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 10px
}

.samples-item {
    margin: 0 0 10px
}

.field.downloads {
    margin-bottom: 10px
}

.page-product-downloadable .product-add-form .product-options-wrapper .field.downloads>.control {
    width: 100%
}

.field.downloads .price-container {
    display: inline;
    white-space: nowrap
}

.field.downloads .price-container:before {
    content: ' + ';
    display: inline
}

.field.downloads .price-excluding-tax {
    display: inline
}

.field.downloads .price-excluding-tax:before {
    content: ' (' attr(data-label) ': '
}

.field.downloads .price-excluding-tax:after {
    content: ')'
}

.field.downloads .price-including-tax {
    display: inline;
    font-weight: 600;
    font-size: 1.4rem
}

.field.downloads .field.choice {
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    position: relative;
    width: 100%;
    z-index: 1
}

.field.downloads .field.choice input {
    left: 0;
    position: absolute;
    top: 2px
}

.field.downloads .field.downloads-all {
    border-bottom: 0;
    padding-left: 0
}

.field.downloads .sample.link {
    float: right
}

.field.downloads-all .label {
    color: #b74f0e;
    text-decoration: none;
    cursor: pointer
}

.field.downloads-all .label:visited {
    color: #b74f0e;
    text-decoration: none
}

.field.downloads-all .label:hover {
    color: #b74f0e;
    text-decoration: underline
}

.field.downloads-all .label:active {
    color: #b74f0e;
    text-decoration: underline
}

.field.downloads-all input {
    opacity: 0
}

.field.downloads.downloads-no-separately .field.choice {
    padding-left: 0
}

.page-product-giftcard .giftcard-amount {
    border-top: 1px #c1c1c1 solid;
    clear: both;
    margin-bottom: 0;
    padding-top: 15px;
    text-align: left
}

.page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px !important
}

.page-product-giftcard .product-info-main .price-box .old-price {
    font-size: 20px
}

.page-product-giftcard .product-info-main .price-box .price-container.price-final>span {
    display: block;
    margin-bottom: 5px
}

.page-product-giftcard .product-info-main .price-box .price-container.price-final>span:last-child {
    margin-bottom: 0
}

.page-product-giftcard .product-info-main .price-box .price-container.price-final .price {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px
}

.page-product-giftcard .product-info-main .price-box+.giftcard.form {
    border-top: 1px #c1c1c1 solid;
    padding-top: 15px
}

.page-product-giftcard .product-info-main .alert {
    clear: left
}

.page-product-giftcard .product-info-stock-sku {
    display: block
}

.page-product-giftcard .product-add-form {
    border: 0;
    clear: none;
    padding-top: 0
}

.page-product-giftcard .product-add-form .product-options-wrapper .field:not(.text) {
    margin-bottom: 20px;
    margin-top: 20px
}

.page-product-giftcard .giftcard.form {
    clear: both
}

.page-product-giftcard .price-box {
    margin-bottom: 10px
}

.page-product-giftcard .product-info-price {
    border: 0;
    display: block;
    float: right;
    white-space: nowrap;
    width: auto
}

.giftcard-account .please-wait {
    display: none
}

.giftcard-account .form-giftcard-redeem .giftcard.info {
    margin-bottom: 30px
}

.opc-wrapper .form-giftcard-account {
    max-width: 500px
}

.opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px
}

.opc-wrapper .giftcard-account-info {
    margin: 0 0 20px
}

.opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px
}

.gift-message .field {
    margin-bottom: 20px
}

.gift-message .field .label {
    color: #666;
    font-weight: 400
}

.gift-options {
    margin: 20px 0
}

.gift-options .actions-toolbar .action-cancel {
    display: none
}

.gift-options-title {
    font-weight: 600;
    margin: 0 0 20px
}

.gift-options-content .fieldset {
    margin: 0 0 20px
}

.gift-summary {
    margin-top: 10px;
    position: relative
}

.gift-summary .actions-toolbar>.secondary .action {
    margin: 0 0 0 20px;
    width: auto
}

.gift-summary .action-edit,
.gift-summary .action-delete {
    display: inline-block;
    text-decoration: none
}

.gift-summary .action-edit>span,
.gift-summary .action-delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.gift-summary .action-edit:before,
.gift-summary .action-delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\f040';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.gift-summary .action-edit:hover:before,
.gift-summary .action-delete:hover:before {
    color: #333
}

.gift-summary .action-edit:active:before,
.gift-summary .action-delete:active:before {
    color: #333
}

.gift-summary .action-delete:before {
    content: '\e604'
}

.action-gift {
    display: inline-block;
    text-decoration: none
}

.action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    line-height: 20px;
    color: #333;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.action-gift:hover:after {
    color: #333
}

.action-gift:active:after {
    color: #333
}

.action-gift.active:after,
.action-gift._active:after {
    content: '\f077'
}

.gift-summary .actions-toolbar,
.cart.table-wrapper .gift-summary .actions-toolbar {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0
}

.cart.table-wrapper .gift-content {
    box-sizing: border-box;
    clear: left;
    display: none;
    float: left;
    margin-right: -100%;
    padding: 20px 0;
    width: 100%
}

.cart.table-wrapper .gift-content._active {
    display: block
}

.cart.table-wrapper .item-gift._active {
    border-top: 1px solid #c1c1c1
}

.cart.table-wrapper .item-gift .action {
    margin-right: 0
}

.gift-item-block {
    border-bottom: 1px solid #c1c1c1;
    margin: 0
}

.gift-item-block._active .title:after {
    content: '\f077'
}

.gift-item-block .title {
    color: #b74f0e;
    cursor: pointer;
    margin: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none
}

.gift-item-block .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    line-height: 20px;
    color: #858585;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.gift-item-block .title:hover:after {
    color: #333
}

.gift-item-block .content {
    padding: 10px 0 20px;
    position: relative
}

.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
    content: ''
}

.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
    clear: left
}

.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
    font-weight: 400;
    margin: 10px 0
}

.gift-messages-order {
    margin-bottom: 25px
}

.gift-message-summary {
    padding-right: 7rem
}

.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
    margin-bottom: 40px
}

.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
    width: 100%
}

.giftregisty-dropdown .action.toggle {
    color: #b74f0e
}

.table-giftregistry-items .product-image {
    margin-right: 10px;
    float: left
}

.cart-summary .form.giftregistry .fieldset {
    margin: 15px 0 5px;
    text-align: left
}

.cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px
}

.cart-summary .form.giftregistry .fieldset .field .control {
    float: none;
    width: auto
}

.form-giftregistry-search {
    margin-bottom: 60px
}

.form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0
}

.block-giftregistry .actions-toolbar {
    margin: 17px 0 0
}

.block-giftregistry-shared .item-options .item-value {
    margin: 0 0 25px
}

.block-giftregistry-shared-items .item .col {
    border: 0
}

.block-giftregistry-shared-items th {
    border-bottom: 1px solid #ccc
}

.block-giftregistry-shared-items .col {
    padding: 20px 11px 10px
}

.block-giftregistry-shared-items .col .price-box {
    display: inline-block
}

.block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap
}

.block-giftregistry-shared-items .product .item-options {
    margin: 0
}

.block-giftregistry-shared-items .product-item-photo {
    display: block;
    max-width: 65px;
    padding: 10px 0
}

.block-giftregistry-shared-items .product-item-name {
    font-size: 1.8rem;
    display: block;
    margin: 0 0 5px
}

.gift-wrapping .label {
    margin: 0 0 8px;
    display: inline-block
}

.gift-wrapping .field {
    margin-bottom: 10px
}

.gift-wrapping-info {
    font-weight: 300;
    font-size: 18px;
    margin: 20px 0
}

.gift-wrapping-list {
    list-style: none;
    margin: 20px 0 10px;
    padding: 0
}

.gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none
}

.gift-wrapping-list .no-image>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.gift-wrapping-list .no-image:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 4rem;
    color: #494949;
    content: '\e62a';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.gift-wrapping-list .no-image:after {
    display: block
}

.gift-wrapping-preview,
.gift-wrapping-item>span {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.gift-wrapping-item {
    float: left;
    margin: 0 10px 10px 0
}

.gift-wrapping-item:last-child {
    margin-right: 0
}

.gift-wrapping-item._active span:before {
    border: 3px solid #333;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2
}

.gift-wrapping-item>span {
    height: 4rem;
    width: 4rem
}

.gift-wrapping-title {
    margin: 10px 0
}

.gift-wrapping-title .action-remove {
    margin: -5px 0 0 5px;
    display: inline-block;
    text-decoration: none
}

.gift-wrapping-title .action-remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.gift-wrapping-title .action-remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\e604';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.gift-wrapping-title .action-remove:hover:before {
    color: #333
}

.gift-wrapping-title .action-remove:active:before {
    color: #333
}

.gift-options-content .fieldset .field {
    margin: 10px 0
}

.gift-wrapping-summary {
    padding-right: 7rem
}

.gift-wrapping-summary .gift-wrapping-title {
    border: 0;
    display: inline-block;
    padding: 0
}

.opc-wrapper .fieldset.gift-message .field.gift-wrapping {
    padding: 0;
    width: 100%
}

.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping {
    margin: 20px 0 0
}

.opc-wrapper .data.table .gift-wrapping .title,
.table-order-items .gift-wrapping .title {
    float: none
}

.opc-wrapper .data.table .gift-wrapping .content,
.table-order-items .gift-wrapping .content {
    margin: 0
}

.opc-wrapper .data.table .gift-wrapping .content>img,
.table-order-items .gift-wrapping .content>img {
    float: left;
    margin: 0 10px 0 0;
    max-height: 50px;
    max-width: 50px
}

.gift-summary .price-box,
.gift-options .price-box {
    margin-left: 22px
}

.gift-summary .regular-price:before,
.gift-options .regular-price:before {
    content: attr(data-label) ': '
}

.gift-summary .regular-price .price,
.gift-options .regular-price .price {
    font-weight: 700
}

.order-options .item {
    padding-bottom: 20px
}

.order-options .product {
    margin-bottom: 10px
}

.order-options .product-image-container {
    float: left;
    padding-right: 10px
}

.order-options .gift-wrapping {
    margin-bottom: 10px
}

.table-wrapper.grouped {
    width: auto
}

.table.grouped .row-tier-price>td {
    border-top: 0
}

.table.grouped .col.item {
    width: 90%
}

.table.grouped .col.qty {
    width: 10%
}

.table.grouped .product-item-name {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 10px
}

.table.grouped .price-box p {
    margin: 0
}

.table.grouped .price-box .price {
    font-weight: 700
}

.table.grouped .price-box .old-price .price {
    font-weight: normal
}

.table.grouped .stock.unavailable {
    font-weight: normal;
    margin: 0;
    text-transform: none;
    white-space: nowrap
}

.page-product-grouped .product-info-main .alert {
    clear: left
}

.page-product-grouped .box-tocart {
    margin-top: 10px
}

.box-tocart .action.instant-purchase {
    vertical-align: top
}

.box-tocart .action.instant-purchase:not(:last-child) {
    margin-bottom: 15px
}

.form-add-invitations .additional,
.form-add-invitations .field.message {
    margin-top: 20px
}

.form-add-invitations .action.remove {
    top: 4px
}

.form-add-invitations .action.add {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 4px;
    width: auto
}

.form-add-invitations .action.add>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: inherit;
    color: inherit;
    content: '\e61c';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.form-add-invitations .action.add:hover:before {
    color: inherit
}

.form-add-invitations .action.add:active:before {
    color: inherit
}

.map-popup-wrapper.popup .action.close {
    top: 10px
}

.map-popup {
    background: #fff;
    border: 1px solid #adadad;
    padding: 22px;
    width: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    transition: opacity .3s linear;
    left: 0;
    margin-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1001;
    display: none;
    opacity: 0;
    box-sizing: border-box
}

.map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 16px
}

.map-popup .popup-header .title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem
}

.map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px
}

.map-popup .popup-actions .action.close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.map-popup .popup-actions .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 16px;
    color: #8c8c8c;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.map-popup .popup-actions .action.close:hover:before {
    color: #737373
}

.map-popup .popup-actions .action.close:active:before {
    color: #737373
}

.map-popup .popup-actions .action.close:focus,
.map-popup .popup-actions .action.close:hover {
    background: none;
    border: none
}

.map-popup .popup-actions .action.close:active {
    background: none;
    border: none
}

.map-popup .popup-actions .action.close.disabled,
.map-popup .popup-actions .action.close[disabled],
fieldset[disabled] .map-popup .popup-actions .action.close {
    pointer-events: none;
    opacity: .5
}

.map-popup.active {
    opacity: 1
}

.map-popup .map-info-price {
    margin: 0 0 20px
}

.map-popup .map-info-price .price-box {
    margin: 0 0 20px
}

.map-popup .map-info-price .price-box .label:after {
    content: ': '
}

.map-popup .action.primary,
.map-popup .amshopby-button,
.map-popup .am-filter-go {
    margin-right: 10px
}

.map-popup .map-msrp {
    margin-bottom: 10px
}

.map-popup .actual-price {
    margin: 3px 0 15px
}

.map-popup .regular-price .price-label {
    display: none
}

.map-popup .popup-header {
    display: none
}

.map-old-price {
    text-decoration: none
}

.map-old-price .price-wrapper {
    text-decoration: line-through
}

.map-fallback-price {
    display: none
}

.map-old-price,
.product-item .map-old-price,
.product-info-price .map-show-info {
    display: inline-block;
    min-width: 10rem
}

.map-old-price:not(:last-child),
.product-item .map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
    margin-right: 10px
}

.product-options-bottom .price-box .old-price .price-container>span,
.product-info-price .price-box .old-price .price-container>span {
    display: inline
}

.map-form-addtocart {
    display: inline-block;
    text-align: center
}

.map-form-addtocart img {
    display: block;
    margin: 0 auto
}

button.map-show-info {
    background-image: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline
}

button.map-show-info:focus,
button.map-show-info:hover {
    background: none;
    border: none
}

button.map-show-info:active {
    background: none;
    border: none
}

button.map-show-info.disabled,
button.map-show-info[disabled],
fieldset[disabled] button.map-show-info {
    pointer-events: none;
    opacity: .5
}

button.map-show-info:visited {
    color: #b74f0e;
    text-decoration: none
}

button.map-show-info:hover {
    color: #b74f0e;
    text-decoration: underline
}

button.map-show-info:active {
    color: #b74f0e;
    text-decoration: underline
}

button.map-show-info:hover {
    color: #b74f0e
}

button.map-show-info:hover,
button.map-show-info:active,
button.map-show-info:focus {
    background: none;
    border: 0
}

button.map-show-info.disabled,
button.map-show-info[disabled],
fieldset[disabled] button.map-show-info {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.wishlist.split.button {
    display: inline-block;
    position: relative;
    margin-right: 5%;
    vertical-align: middle
}

.wishlist.split.button:before,
.wishlist.split.button:after {
    content: '';
    display: table
}

.wishlist.split.button:after {
    clear: both
}

.wishlist.split.button .action.split {
    float: left;
    margin: 0
}

.wishlist.split.button .action.toggle {
    float: right;
    margin: 0
}

.wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none
}

.wishlist.split.button .action.toggle>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.wishlist.split.button .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: inherit;
    color: #666;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.wishlist.split.button .action.toggle:hover:after {
    color: #333
}

.wishlist.split.button .action.toggle:active:after {
    color: inherit
}

.wishlist.split.button .action.toggle.active {
    display: inline-block;
    text-decoration: none
}

.wishlist.split.button .action.toggle.active>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.wishlist.split.button .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: inherit;
    color: #666;
    content: '\f077';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.wishlist.split.button .action.toggle.active:hover:after {
    color: #333
}

.wishlist.split.button .action.toggle.active:active:after {
    color: inherit
}

.wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.wishlist.split.button .items li {
    margin: 0;
    padding: 5px 5px 5px 23px
}

.wishlist.split.button .items li:hover {
    background: #e8e8e8;
    cursor: pointer
}

.wishlist.split.button .items:before,
.wishlist.split.button .items:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.wishlist.split.button .items:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.wishlist.split.button .items:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.wishlist.split.button .items:before {
    left: 10px;
    top: -12px
}

.wishlist.split.button .items:after {
    left: 9px;
    top: -14px
}

.wishlist.split.button.active {
    overflow: visible
}

.wishlist.split.button.active .items {
    display: block
}

.wishlist.split.button .items {
    padding: 6px 0;
    text-align: left
}

.wishlist.split.button .items .item>span {
    display: block;
    padding: 5px 5px 5px 23px
}

.wishlist.split.button .items li {
    padding: 0
}

.wishlist.split.button>.action.split {
    margin-right: 5px
}

.wishlist.split.button>.action.split:before {
    content: '\e600'
}

.wishlist.split.button>.action.split:before {
    margin: 0;
    width: 18px
}

.wishlist.split.button>.action:active,
.wishlist.split.button>.action:focus,
.wishlist.split.button>.action:hover {
    color: #333
}

.wishlist.split.button>.action.toggle:before {
    border-left: 1px solid #666;
    content: '';
    float: left;
    height: 15px;
    margin: 3px 4px 0 0
}

.cart.table-wrapper .wishlist.split.button {
    vertical-align: baseline
}

.cart.table-wrapper .wishlist.split.button>.action.split {
    font-weight: normal;
    letter-spacing: normal;
    text-transform: none
}

.cart.table-wrapper .wishlist.split.button>.action.toggle:after {
    color: #b74f0e
}

.cart.table-wrapper .wishlist.split.button>.action.split,
.cart.table-wrapper .wishlist.split.button>.action.toggle {
    color: #b74f0e
}

.cart.table-wrapper .wishlist.split.button>.action.split:before,
.cart.table-wrapper .wishlist.split.button>.action.toggle:before {
    display: none
}

.wishlist.window.popup {
    background: #fff;
    border: 1px solid #adadad;
    padding: 22px;
    width: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    transition: opacity .3s linear;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1001;
    display: none;
    opacity: 0;
    box-sizing: border-box
}

.wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 16px
}

.wishlist.window.popup .popup-header .title {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem
}

.wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px
}

.wishlist.window.popup .popup-actions .action.close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.wishlist.window.popup .popup-actions .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 16px;
    color: #8c8c8c;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.wishlist.window.popup .popup-actions .action.close:hover:before {
    color: #737373
}

.wishlist.window.popup .popup-actions .action.close:active:before {
    color: #737373
}

.wishlist.window.popup .popup-actions .action.close:focus,
.wishlist.window.popup .popup-actions .action.close:hover {
    background: none;
    border: none
}

.wishlist.window.popup .popup-actions .action.close:active {
    background: none;
    border: none
}

.wishlist.window.popup .popup-actions .action.close.disabled,
.wishlist.window.popup .popup-actions .action.close[disabled],
fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
    pointer-events: none;
    opacity: .5
}

.wishlist.window.popup.active {
    opacity: 1
}

.wishlist.window.popup.active {
    display: block
}

.wishlist.overlay {
    transition: opacity .15s linear;
    background: #8c8c8c;
    z-index: 899;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0
}

.wishlist.overlay.active {
    opacity: .7;
    filter: alpha(opacity=70)
}

.wishlist.overlay.active {
    display: block
}

.wishlist-dropdown.move .action.toggle,
.wishlist-dropdown.copy .action.toggle {
    color: #b74f0e
}

.page-multiple-wishlist .actions-toolbar>.primary {
    display: block;
    float: none;
    text-align: left
}

.page-multiple-wishlist .actions-toolbar>.primary .action.remove {
    float: right
}

.block-wishlist-management {
    position: relative
}

.block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600
}

.block-wishlist-management .wishlist-title {
    margin-bottom: 10px
}

.block-wishlist-management .wishlist-title strong {
    font-weight: 300;
    margin-right: 10px
}

.block-wishlist-management .wishlist-notice {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 20px
}

.block-wishlist-management .wishlist-notice.private {
    display: inline-block;
    text-decoration: none
}

.block-wishlist-management .wishlist-notice.private:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: #a3a3a3;
    content: '\e629';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: text-bottom;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.block-wishlist-management .wishlist-toolbar-select,
.block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block
}

.block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px
}

.block-wishlist-management .wishlist-toolbar .copy .dropdown {
    right: 0
}

.block-wishlist-management .wishlist-toolbar .copy .dropdown:before {
    left: auto;
    right: 11px
}

.block-wishlist-management .wishlist-toolbar .copy .dropdown:after {
    left: auto;
    right: 10px
}

.block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px
}

.block-wishlist-search-form .form-wishlist-search {
    margin-bottom: 60px;
    max-width: 500px
}

.block-wishlist-search-results .table-wrapper {
    border-top: none
}

.block-wishlist-info-items .block-title {
    margin-bottom: 20px;
    font-size: 2.2rem
}

.block-wishlist-info-items .block-title>strong {
    font-weight: 300
}

.block-wishlist-info-items .product-item {
    width: 100%
}

.block-wishlist-info-items .product-item-photo {
    margin-left: 20px
}

.block-wishlist-info-items .product-item-info {
    width: auto
}

.block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible
}

.block-wishlist-info-items .price-wrapper {
    display: block;
    margin: 0 0 10px
}

.block-wishlist-info-items .split {
    clear: both;
    padding: 10px 0;
    visibility: hidden
}

.block-wishlist-info-items .wishlist-dropdown .change {
    color: #b74f0e
}

.block-wishlist-info-items .action.towishlist {
    padding: 10px 0
}

.block-wishlist-info-items .input-text.qty {
    margin-right: 10px
}

.multicheckout.results h3,
.multicheckout.success h3 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    margin-top: 30px
}

.multicheckout.results h3 a,
.multicheckout.success h3 a {
    color: #333
}

.multicheckout.results h3 a:hover,
.multicheckout.success h3 a:hover {
    text-decoration: none
}

.multicheckout.results ul.orders-list,
.multicheckout.success ul.orders-list {
    list-style: none;
    padding-left: 0
}

.multicheckout.results .orders-list,
.multicheckout.success .orders-list {
    margin-top: 25px;
    padding-left: 16px
}

.multicheckout.results .orders-list .shipping-list .shipping-item,
.multicheckout.success .orders-list .shipping-list .shipping-item {
    margin-left: 84px
}

.multicheckout.results .orders-list .shipping-list .shipping-label,
.multicheckout.success .orders-list .shipping-list .shipping-label {
    font-weight: 700;
    margin-right: 10px
}

.multicheckout.results .orders-list .shipping-list .shipping-address,
.multicheckout.success .orders-list .shipping-list .shipping-address {
    font-weight: 400
}

.multicheckout.results .orders-list .shipping-list .error-block,
.multicheckout.success .orders-list .shipping-list .error-block {
    color: #e02b27
}

.multicheckout.results .orders-list .shipping-list .error-block .error-label,
.multicheckout.success .orders-list .shipping-list .error-block .error-label {
    font-weight: 700;
    margin-right: 10px
}

.multicheckout.results .orders-list .shipping-list .error-block .error-description,
.multicheckout.success .orders-list .shipping-list .error-block .error-description {
    font-weight: 400
}

.multicheckout.results .orders-succeed .orders-list,
.multicheckout.success .orders-succeed .orders-list {
    margin-top: 0
}

.multicheckout.results .orders-succeed .orders-list .shipping-list .order-id,
.multicheckout.success .orders-succeed .orders-list .shipping-list .order-id {
    float: left
}

.multicheckout.results .orders-succeed .orders-list .shipping-list .shipping-item,
.multicheckout.success .orders-succeed .orders-list .shipping-list .shipping-item {
    margin-left: 100px
}

.multicheckout .title {
    margin-bottom: 30px
}

.multicheckout .title strong {
    font-weight: 400
}

.multicheckout .table-wrapper {
    margin-bottom: 0
}

.multicheckout .table-wrapper .action.delete {
    display: inline-block
}

.multicheckout .table-wrapper .col .qty {
    display: inline-block
}

.multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px
}

.multicheckout:not(.address) .table-wrapper .product-item-name {
    margin: 0
}

.multicheckout>.actions-toolbar {
    margin-top: 40px
}

.multicheckout .actions-toolbar>.secondary {
    display: block
}

.multicheckout .actions-toolbar>.secondary .action {
    margin-bottom: 25px
}

.multicheckout .actions-toolbar>.secondary .action.back {
    display: block;
    margin-left: 0
}

.multicheckout .actions-toolbar>.primary {
    margin-right: 10px
}

.multicheckout .item-options {
    margin: 10px 0 0
}

.multicheckout .block .methods-shipping .item-content .fieldset>.field:before {
    display: none
}

.multicheckout .block .methods-shipping .item-content .fieldset>.field .control {
    display: inline-block
}

.multicheckout .block-title,
.multicheckout .block-content .title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px
}

.multicheckout .block-title strong,
.multicheckout .block-content .title strong {
    font-weight: 400
}

.multicheckout .block-title strong span,
.multicheckout .block-content .title strong span {
    color: #a6a6a6
}

.multicheckout .block-content .title {
    border-bottom: none;
    padding-bottom: 0
}

.multicheckout.order-review .block-title>strong {
    font-size: 2.4rem
}

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
    margin-bottom: 40px
}

.multicheckout.order-review .error-description {
    color: #e02b27;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: -10px
}

.multicheckout .box-title span {
    margin-right: 10px
}

.multicheckout .box-title>.action {
    margin: 0
}

.multicheckout .box-shipping-method .price {
    font-weight: 700
}

.multicheckout .box-billing-method .fieldset {
    margin: 0
}

.multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px
}

.multicheckout .checkout-review .grand.totals {
    font-size: 2.1rem;
    margin-bottom: 40px
}

.multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400
}

[class^='multishipping-'] .logo {
    margin-left: 0
}

.multishipping-checkout-success .nav-sections {
    display: block
}

.block.newsletter {
    margin-bottom: 20px
}

.block.newsletter .form.subscribe {
    display: table;
    width: 100%
}

.block.newsletter .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top
}

.block.newsletter .field {
    margin: 0
}

.block.newsletter .field .control {
    display: inline-block;
    text-decoration: none;
    display: block
}

.block.newsletter .field .control:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 32px;
    color: #575757;
    content: '\e61d';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 0 0 8px;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.block.newsletter .field .control:before {
    position: absolute
}

.block.newsletter input {
    margin-right: 35px;
    padding: 0 0 0 35px
}

.block.newsletter .title {
    display: none
}

.block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%
}

.block.newsletter .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
    white-space: nowrap
}

.data.switch .counter {
    color: #7d7d7d
}

.data.switch .counter:before {
    content: '('
}

.data.switch .counter:after {
    content: ')'
}

.rating-summary {
    overflow: hidden;
    white-space: nowrap
}

.rating-summary .rating-result {
    width: 88px;
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.rating-summary .rating-result:before {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    color: #c7c7c7;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none
}

.rating-summary .rating-result>span {
    display: block;
    overflow: hidden
}

.rating-summary .rating-result>span:before {
    position: relative;
    z-index: 2;
    -webkit-font-smoothing: antialiased;
    color: #4a4a4a;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    font-style: normal;
    font-weight: normal;
    speak: none
}

.rating-summary .rating-result>span span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.review-control-vote {
    overflow: hidden
}

.review-control-vote:before {
    color: #c7c7c7;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    content: '\e605''\e605''\e605''\e605''\e605';
    display: block;
    position: absolute;
    z-index: 1
}

.review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.review-control-vote input[type="radio"]:hover+label:before,
.review-control-vote input[type="radio"]:checked+label:before {
    opacity: 1
}

.review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute
}

.review-control-vote label span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.review-control-vote label:before {
    color: #4a4a4a;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    height: 16px;
    letter-spacing: 2px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    opacity: 0
}

.review-control-vote label:hover:before {
    opacity: 1
}

.review-control-vote label:hover~label:before {
    opacity: 0
}

.review-control-vote .rating-5 {
    z-index: 2
}

.review-control-vote .rating-5:before {
    content: '\e605''\e605''\e605''\e605''\e605'
}

.review-control-vote .rating-4 {
    z-index: 3
}

.review-control-vote .rating-4:before {
    content: '\e605''\e605''\e605''\e605'
}

.review-control-vote .rating-3 {
    z-index: 4
}

.review-control-vote .rating-3:before {
    content: '\e605''\e605''\e605'
}

.review-control-vote .rating-2 {
    z-index: 5
}

.review-control-vote .rating-2:before {
    content: '\e605''\e605'
}

.review-control-vote .rating-1 {
    z-index: 6
}

.review-control-vote .rating-1:before {
    content: '\e605'
}

.review-control-vote:before {
    content: '\e625''\e625''\e625''\e625''\e625'
}

.products-reviews-toolbar {
    position: relative
}

.products-reviews-toolbar .toolbar-amount {
    position: relative;
    text-align: center
}

.review-toolbar {
    border-top: 1px solid #c9c9c9;
    margin: 0 0 20px;
    padding: 0;
    text-align: center
}

.review-toolbar:first-child {
    display: none
}

.review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center
}

.review-toolbar .limiter {
    display: none
}

.review-add .block-title {
    display: none
}

.review-form .action.submit.primary {
    background: #e0e1e2;
    border: 0;
    color: #333;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 10px 15px;
    margin: 0
}

.review-form .action.submit.primary:focus,
.review-form .action.submit.primary:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.review-form .action.submit.primary:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.review-form .actions-toolbar .actions-primary {
    padding: 0
}

.fieldset .review-legend.legend {
    border-bottom: 0;
    line-height: 1.3;
    margin-bottom: 20px;
    padding: 0
}

.fieldset .review-legend.legend span {
    margin: 0
}

.fieldset .review-legend.legend strong {
    display: block;
    font-weight: 600
}

.fieldset .review-field-ratings>.label {
    font-weight: 600;
    margin-bottom: 10px;
    padding: 0
}

.review-control-vote label:before,
.review-control-vote:before {
    font-size: 32px;
    height: 32px;
    letter-spacing: 10px;
    line-height: 32px
}

.review-field-rating .label {
    font-weight: 600
}

.review-field-rating .control {
    margin-bottom: 48px;
    margin-top: 10px
}

.review-list {
    margin-bottom: 30px
}

.review-list .block-title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 2.1rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem
}

.review-item {
    border-bottom: 1px solid #c9c9c9;
    margin: 0;
    padding: 20px 0
}

.review-item:after {
    clear: both;
    content: '';
    display: table
}

.review-item:last-child {
    border-width: 0
}

.review-ratings {
    display: table;
    margin-bottom: 10px;
    max-width: 100%
}

.review-author {
    display: inline
}

.review-author .review-details-value {
    font-weight: 400
}

.review-date {
    display: inline
}

.review-title {
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin: 0 0 20px
}

.review-content {
    margin-bottom: 20px
}

.page-main .column .review-add {
    margin-bottom: 0
}

.data.table.reviews .rating-summary {
    margin-top: -4px
}

.customer.review.view .review.title,
.customer.review.view .ratings.summary.items,
.customer.review.view .product.details {
    margin-bottom: 20px
}

.product-reviews-summary {
    margin-bottom: 5px
}

.products.wrapper.list .product-reviews-summary {
    margin: 0 auto
}

.product-reviews-summary .rating-summary {
    display: inline-block;
    vertical-align: middle
}

.product-reviews-summary .reviews-actions {
    display: inline-block;
    font-size: 11px;
    vertical-align: middle
}

.product-reviews-summary .reviews-actions a:not(:last-child) {
    margin-right: 30px
}

.product-reviews-summary .reviews-actions .action.add {
    white-space: nowrap
}

.product-info-main .rating-summary,
.customer-review .rating-summary {
    margin-right: 30px
}

.block-reviews-dashboard .items .item {
    margin-bottom: 20px
}

.block-reviews-dashboard .items .item:last-child {
    margin-bottom: 0
}

.block-reviews-dashboard .product-name {
    display: inline-block
}

.block-reviews-dashboard .product-name:not(:last-child) {
    margin-bottom: 5px
}

.block-reviews-dashboard .rating-summary .label {
    font-weight: 600;
    margin-right: 10px;
    vertical-align: middle
}

.table-reviews .product-name,
.block-reviews-dashboard .product-name {
    font-weight: 400
}

.table-reviews .rating-summary {
    display: inline-block;
    vertical-align: baseline
}

.customer-review .product-media {
    float: left;
    margin-right: 3%;
    max-width: 285px;
    width: 30%
}

.customer-review .review-details {
    margin: 0
}

.customer-review .review-details .customer-review-rating {
    margin-bottom: 20px
}

.customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px
}

.customer-review .review-details .customer-review-rating .item:last-child {
    margin-bottom: 0
}

.customer-review .review-details .review-title {
    line-height: 1.1;
    font-size: 1.7rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    margin-bottom: 20px
}

.customer-review .review-details .review-content {
    margin: 0;
    margin-bottom: 20px
}

.customer-review .review-details .review-date {
    color: #a6a6a6
}

.block-reward-info .reward-balance {
    font-size: 1.8rem;
    margin-bottom: 25px
}

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
    margin-bottom: 25px
}

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
    display: inline-block;
    margin-bottom: 10px
}

.order-details-items.rma .order-title {
    display: none
}

.order-details-items.rma .order-title+.toolbar {
    display: none
}

.form-create-return .additional:first-child {
    margin-top: 0
}

.form-create-return .field.comment {
    margin-top: 55px
}

.table-wrapper.table-returns .returns-details.hidden {
    display: none
}

.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
    margin: 0
}

.block-returns-comments .returns-comments dt {
    font-weight: 400
}

.block-returns-comments .returns-comments dd {
    margin-bottom: 15px
}

.block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0
}

.block-returns-comments .fieldset {
    margin-bottom: 20px
}

.magento-rma-guest-create .order-links .item a {
    line-height: 1.42857143;
    margin: 0 30px 0 0;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline
}

.magento-rma-guest-create .order-links .item a:visited {
    color: #b74f0e;
    text-decoration: none
}

.magento-rma-guest-create .order-links .item a:hover {
    color: #b74f0e;
    text-decoration: underline
}

.magento-rma-guest-create .order-links .item a:active {
    color: #b74f0e;
    text-decoration: underline
}

.magento-rma-guest-create .order-links .item a:hover {
    color: #b74f0e
}

.magento-rma-guest-create .order-links .item a:hover,
.magento-rma-guest-create .order-links .item a:active,
.magento-rma-guest-create .order-links .item a:focus {
    background: none;
    border: 0
}

.magento-rma-guest-create .order-links .item a.disabled,
.magento-rma-guest-create .order-links .item a[disabled],
fieldset[disabled] .magento-rma-guest-create .order-links .item a {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.form.send.friend .fieldset .field .control {
    width: 100%
}

.product-social-links .action.mailto.friend:before {
    content: '\e61d'
}

.my-credit-cards .status {
    font-style: italic
}

.my-credit-cards .action.delete {
    background-image: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline
}

.my-credit-cards .action.delete:focus,
.my-credit-cards .action.delete:hover {
    background: none;
    border: none
}

.my-credit-cards .action.delete:active {
    background: none;
    border: none
}

.my-credit-cards .action.delete.disabled,
.my-credit-cards .action.delete[disabled],
fieldset[disabled] .my-credit-cards .action.delete {
    pointer-events: none;
    opacity: .5
}

.my-credit-cards .action.delete:visited {
    color: #b74f0e;
    text-decoration: none
}

.my-credit-cards .action.delete:hover {
    color: #b74f0e;
    text-decoration: underline
}

.my-credit-cards .action.delete:active {
    color: #b74f0e;
    text-decoration: underline
}

.my-credit-cards .action.delete:hover {
    color: #b74f0e
}

.my-credit-cards .action.delete:hover,
.my-credit-cards .action.delete:active,
.my-credit-cards .action.delete:focus {
    background: none;
    border: 0
}

.my-credit-cards .action.delete.disabled,
.my-credit-cards .action.delete[disabled],
fieldset[disabled] .my-credit-cards .action.delete {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.my-credit-cards-popup .modal-footer {
    text-align: right
}

.my-credit-cards-popup .action.secondary {
    background-image: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    margin-right: 20px
}

.my-credit-cards-popup .action.secondary:focus,
.my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none
}

.my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none
}

.my-credit-cards-popup .action.secondary.disabled,
.my-credit-cards-popup .action.secondary[disabled],
fieldset[disabled] .my-credit-cards-popup .action.secondary {
    pointer-events: none;
    opacity: .5
}

.my-credit-cards-popup .action.secondary:visited {
    color: #b74f0e;
    text-decoration: none
}

.my-credit-cards-popup .action.secondary:hover {
    color: #b74f0e;
    text-decoration: underline
}

.my-credit-cards-popup .action.secondary:active {
    color: #b74f0e;
    text-decoration: underline
}

.my-credit-cards-popup .action.secondary:hover {
    color: #b74f0e
}

.my-credit-cards-popup .action.secondary:hover,
.my-credit-cards-popup .action.secondary:active,
.my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0
}

.my-credit-cards-popup .action.secondary.disabled,
.my-credit-cards-popup .action.secondary[disabled],
fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.toolbar.wishlist-toolbar .limiter {
    float: right
}

.toolbar.wishlist-toolbar .main .pages {
    display: inline-block;
    position: relative;
    z-index: 0
}

.toolbar.wishlist-toolbar .toolbar-amount,
.toolbar.wishlist-toolbar .limiter {
    display: inline-block;
    z-index: 1
}

.product-social-links .action.towishlist:before,
.block-bundle-summary .action.towishlist:before,
.product-item .action.towishlist:before,
.table-comparison .action.towishlist:before {
    content: '\e600'
}

.account .table-wrapper .data.table.wishlist>thead>tr>th,
.account .table-wrapper .data.table.wishlist>tbody>tr>th,
.account .table-wrapper .data.table.wishlist>tfoot>tr>th,
.account .table-wrapper .data.table.wishlist>thead>tr>td,
.account .table-wrapper .data.table.wishlist>tbody>tr>td,
.account .table-wrapper .data.table.wishlist>tfoot>tr>td {
    border-top: 1px solid #ccc
}

.account .table-wrapper .data.table.wishlist>caption+thead>tr:first-child>th,
.account .table-wrapper .data.table.wishlist>colgroup+thead>tr:first-child>th,
.account .table-wrapper .data.table.wishlist>thead:first-child>tr:first-child>th,
.account .table-wrapper .data.table.wishlist>caption+thead>tr:first-child>td,
.account .table-wrapper .data.table.wishlist>colgroup+thead>tr:first-child>td,
.account .table-wrapper .data.table.wishlist>thead:first-child>tr:first-child>td {
    border-top: 0
}

.account .table-wrapper .data.table.wishlist>tbody+tbody {
    border-top: 1px solid #ccc
}

.account .table-wrapper .data.table.wishlist thead>tr>th {
    border-bottom: 0
}

.account .table-wrapper .data.table.wishlist tbody>tr:last-child>td {
    border-bottom: 1px solid #ccc
}

.account .table-wrapper .data.table.wishlist .product.name {
    display: inline-block;
    margin-bottom: 10px
}

.account .table-wrapper .data.table.wishlist .box-tocart {
    margin: 10px 0
}

.account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle
}

.account .table-wrapper .data.table.wishlist .col.item {
    width: 50%
}

.account .table-wrapper .data.table.wishlist .col.photo {
    max-width: 150px
}

.account .table-wrapper .data.table.wishlist .col.selector {
    max-width: 15px
}

.account .table-wrapper .data.table.wishlist textarea {
    margin: 10px 0
}

.account .table-wrapper .data.table.wishlist .input-text.qty {
    margin-bottom: 10px
}

.account .table-wrapper .data.table.wishlist .action.primary,
.account .table-wrapper .data.table.wishlist .amshopby-button,
.account .table-wrapper .data.table.wishlist .am-filter-go {
    vertical-align: top
}

.account .table-wrapper .data.table.wishlist .price {
    font-weight: 700
}

.link.wishlist .counter {
    color: #7d7d7d;
    white-space: nowrap
}

.link.wishlist .counter:before {
    content: '('
}

.link.wishlist .counter:after {
    content: ')'
}

.block-wishlist .product-item-name {
    margin-right: 25px
}

.products-grid.wishlist .product-item-photo {
    display: block;
    margin-bottom: 10px
}

.products-grid.wishlist .product-item-name {
    margin-top: 0
}

.products-grid.wishlist .product-item .price-box {
    margin: 0
}

.products-grid.wishlist .product-item-comment {
    display: block;
    height: 42px;
    margin: 10px 0
}

.products-grid.wishlist .product-item-actions>* {
    margin-right: 15px
}

.products-grid.wishlist .product-item-actions>*:last-child {
    margin-right: 0
}

.products-grid.wishlist .product-item-actions .action.edit,
.products-grid.wishlist .product-item-actions .action.delete {
    display: inline-block;
    text-decoration: none
}

.products-grid.wishlist .product-item-actions .action.edit>span,
.products-grid.wishlist .product-item-actions .action.delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.products-grid.wishlist .product-item-actions .action.edit:before,
.products-grid.wishlist .product-item-actions .action.delete:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 20px;
    color: #333;
    content: '\f040';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.products-grid.wishlist .product-item-actions .action.edit:hover:before,
.products-grid.wishlist .product-item-actions .action.delete:hover:before {
    color: #333
}

.products-grid.wishlist .product-item-actions .action.edit:active:before,
.products-grid.wishlist .product-item-actions .action.delete:active:before {
    color: #333
}

.products-grid.wishlist .product-item-actions .action.delete:before {
    content: '\e604'
}

.jstree-node,
.jstree-children,
.jstree-container-ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none
}

.jstree-node {
    white-space: nowrap
}

.jstree-anchor {
    display: inline-block;
    color: black;
    white-space: nowrap;
    padding: 0 4px 0 1px;
    margin: 0;
    vertical-align: top
}

.jstree-anchor:focus {
    outline: 0
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
    text-decoration: none;
    color: inherit
}

.jstree-icon {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-icon:empty {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-ocl {
    cursor: pointer
}

.jstree-leaf>.jstree-ocl {
    cursor: default
}

.jstree .jstree-open>.jstree-children {
    display: block
}

.jstree .jstree-closed>.jstree-children,
.jstree .jstree-leaf>.jstree-children {
    display: none
}

.jstree-anchor>.jstree-themeicon {
    margin-right: 2px
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor>.jstree-themeicon-hidden {
    display: none
}

.jstree-hidden,
.jstree-node.jstree-hidden {
    display: none
}

.jstree-rtl .jstree-anchor {
    padding: 0 1px 0 4px
}

.jstree-rtl .jstree-anchor>.jstree-themeicon {
    margin-left: 2px;
    margin-right: 0
}

.jstree-rtl .jstree-node {
    margin-left: 0
}

.jstree-rtl .jstree-container-ul>.jstree-node {
    margin-right: 0
}

.jstree-wholerow-ul {
    position: relative;
    display: inline-block;
    min-width: 100%
}

.jstree-wholerow-ul .jstree-leaf>.jstree-ocl {
    cursor: pointer
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
    position: relative
}

.jstree-wholerow-ul .jstree-wholerow {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.jstree-contextmenu .jstree-anchor {
    -webkit-user-select: none;
    -webkit-touch-callout: none
}

.vakata-context {
    display: none
}

.vakata-context,
.vakata-context ul {
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999
}

.vakata-context ul {
    list-style: none;
    left: 100%;
    margin-top: -2.7em;
    margin-left: -4px
}

.vakata-context .vakata-context-right ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context li {
    list-style: none
}

.vakata-context li>a {
    display: block;
    padding: 0 2em 0 2em;
    text-decoration: none;
    width: auto;
    color: black;
    white-space: nowrap;
    line-height: 2.4em;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 1px
}

.vakata-context li>a:hover {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
    background-position: right center;
    background-repeat: no-repeat
}

.vakata-context li>a:focus {
    outline: 0
}

.vakata-context .vakata-context-hover>a {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context .vakata-context-separator>a,
.vakata-context .vakata-context-separator>a:hover {
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
    color: silver;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0
}

.vakata-context .vakata-contextmenu-disabled>a>i {
    filter: grayscale(100%)
}

.vakata-context li>a>i {
    text-decoration: none;
    display: inline-block;
    width: 2.4em;
    height: 2.4em;
    background: transparent;
    margin: 0 0 0 -2em;
    vertical-align: top;
    text-align: center;
    line-height: 2.4em
}

.vakata-context li>a>i:empty {
    width: 2.4em;
    line-height: 2.4em
}

.vakata-context li>a .vakata-contextmenu-sep {
    display: inline-block;
    width: 1px;
    height: 2.4em;
    background: white;
    margin: 0 .5em 0 0;
    border-left: 1px solid #e2e3e3
}

.vakata-context .vakata-contextmenu-shortcut {
    font-size: 0.8em;
    color: silver;
    opacity: 0.5;
    display: none
}

.vakata-context-rtl ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context-rtl li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
    background-position: left center;
    background-repeat: no-repeat
}

.vakata-context-rtl .vakata-context-separator>a {
    margin: 0 2.4em 0 0;
    border-left: 0;
    border-right: 1px solid #e2e3e3
}

.vakata-context-rtl .vakata-context-left ul {
    right: auto;
    left: 100%;
    margin-left: -4px;
    margin-right: auto
}

.vakata-context-rtl li>a>i {
    margin: 0 -2em 0 0
}

.vakata-context-rtl li>a .vakata-contextmenu-sep {
    margin: 0 0 0 .5em;
    border-left-color: white;
    background: #e2e3e3
}

#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0
}

#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px
}

#jstree-dnd .jstree-ok {
    background: green
}

#jstree-dnd .jstree-er {
    background: red
}

#jstree-dnd .jstree-copy {
    margin: 0 2px 0 2px
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
    background-repeat: no-repeat;
    background-color: transparent
}

.jstree-default .jstree-anchor,
.jstree-default .jstree-animated,
.jstree-default .jstree-wholerow {
    transition: background-color .15s, box-shadow .15s
}

.jstree-default .jstree-hovered {
    background: #e7f4f9;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #ccc
}

.jstree-default .jstree-context {
    background: #e7f4f9;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #ccc
}

.jstree-default .jstree-clicked {
    background: #beebff;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #999
}

.jstree-default .jstree-no-icons .jstree-anchor>.jstree-themeicon {
    display: none
}

.jstree-default .jstree-disabled {
    background: transparent;
    color: #666666
}

.jstree-default .jstree-disabled.jstree-hovered {
    background: transparent;
    box-shadow: none
}

.jstree-default .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default .jstree-disabled>.jstree-icon {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default .jstree-search {
    font-style: italic;
    color: #8b0000;
    font-weight: bold
}

.jstree-default .jstree-no-checkboxes .jstree-checkbox {
    display: none !important
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked {
    background: transparent;
    box-shadow: none
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
    background: #e7f4f9
}

.jstree-default.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked {
    background: transparent
}

.jstree-default.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
    background: #e7f4f9
}

.jstree-default>.jstree-striped {
    min-width: 100%;
    display: inline-block;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat
}

.jstree-default>.jstree-wholerow-ul .jstree-hovered,
.jstree-default>.jstree-wholerow-ul .jstree-clicked {
    background: transparent;
    box-shadow: none;
    border-radius: 0
}

.jstree-default .jstree-wholerow {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.jstree-default .jstree-wholerow-hovered {
    background: #e7f4f9
}

.jstree-default .jstree-wholerow-clicked {
    background: #beebff;
    background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
    background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%)
}

.jstree-default .jstree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 24px;
    min-width: 24px
}

.jstree-default .jstree-anchor {
    line-height: 24px;
    height: 24px
}

.jstree-default .jstree-icon {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default .jstree-icon:empty {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default.jstree-rtl .jstree-node {
    margin-right: 24px
}

.jstree-default .jstree-wholerow {
    height: 24px
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default .jstree-node {
    background-position: -292px -4px;
    background-repeat: repeat-y
}

.jstree-default .jstree-last {
    background: transparent
}

.jstree-default .jstree-open>.jstree-ocl {
    background-position: -132px -4px
}

.jstree-default .jstree-closed>.jstree-ocl {
    background-position: -100px -4px
}

.jstree-default .jstree-leaf>.jstree-ocl {
    background-position: -68px -4px
}

.jstree-default .jstree-themeicon {
    background-position: -260px -4px
}

.jstree-default>.jstree-no-dots .jstree-node,
.jstree-default>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -4px
}

.jstree-default>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -4px
}

.jstree-default .jstree-disabled {
    background: transparent
}

.jstree-default .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default .jstree-checkbox {
    background-position: -164px -4px
}

.jstree-default .jstree-checkbox:hover {
    background-position: -164px -36px
}

.jstree-default.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default .jstree-checked>.jstree-checkbox {
    background-position: -228px -4px
}

.jstree-default.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default .jstree-checked>.jstree-checkbox:hover {
    background-position: -228px -36px
}

.jstree-default .jstree-anchor>.jstree-undetermined {
    background-position: -196px -4px
}

.jstree-default .jstree-anchor>.jstree-undetermined:hover {
    background-position: -196px -36px
}

.jstree-default .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default>.jstree-striped {
    background-size: auto 48px
}

.jstree-default.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -132px -36px
}

.jstree-default.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -100px -36px
}

.jstree-default.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -68px -36px
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -36px
}

.jstree-default.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -36px
}

.jstree-default .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default .jstree-file {
    background: url(../images/32px.png) -100px -68px no-repeat
}

.jstree-default .jstree-folder {
    background: url(../images/32px.png) -260px -4px no-repeat
}

.jstree-default>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default {
    line-height: 24px;
    padding: 0 4px
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default i {
    background: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px
}

#jstree-dnd.jstree-default .jstree-ok {
    background-position: -4px -68px
}

#jstree-dnd.jstree-default .jstree-er {
    background-position: -36px -68px
}

.jstree-default .jstree-ellipsis {
    overflow: hidden
}

.jstree-default .jstree-ellipsis .jstree-anchor {
    width: calc(71%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-small .jstree-node {
    min-height: 18px;
    line-height: 18px;
    margin-left: 18px;
    min-width: 18px
}

.jstree-default-small .jstree-anchor {
    line-height: 18px;
    height: 18px
}

.jstree-default-small .jstree-icon {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-small .jstree-icon:empty {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-small.jstree-rtl .jstree-node {
    margin-right: 18px
}

.jstree-default-small .jstree-wholerow {
    height: 18px
}

.jstree-default-small .jstree-node,
.jstree-default-small .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-small .jstree-node {
    background-position: -295px -7px;
    background-repeat: repeat-y
}

.jstree-default-small .jstree-last {
    background: transparent
}

.jstree-default-small .jstree-open>.jstree-ocl {
    background-position: -135px -7px
}

.jstree-default-small .jstree-closed>.jstree-ocl {
    background-position: -103px -7px
}

.jstree-default-small .jstree-leaf>.jstree-ocl {
    background-position: -71px -7px
}

.jstree-default-small .jstree-themeicon {
    background-position: -263px -7px
}

.jstree-default-small>.jstree-no-dots .jstree-node,
.jstree-default-small>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-small>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -7px
}

.jstree-default-small>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -7px
}

.jstree-default-small .jstree-disabled {
    background: transparent
}

.jstree-default-small .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-small .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-small .jstree-checkbox {
    background-position: -167px -7px
}

.jstree-default-small .jstree-checkbox:hover {
    background-position: -167px -39px
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-small .jstree-checked>.jstree-checkbox {
    background-position: -231px -7px
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-small .jstree-checked>.jstree-checkbox:hover {
    background-position: -231px -39px
}

.jstree-default-small .jstree-anchor>.jstree-undetermined {
    background-position: -199px -7px
}

.jstree-default-small .jstree-anchor>.jstree-undetermined:hover {
    background-position: -199px -39px
}

.jstree-default-small .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-small>.jstree-striped {
    background-size: auto 36px
}

.jstree-default-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-small.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -135px -39px
}

.jstree-default-small.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -103px -39px
}

.jstree-default-small.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -71px -39px
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -39px
}

.jstree-default-small.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -39px
}

.jstree-default-small .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-small>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-small .jstree-file {
    background: url(../images/32px.png) -103px -71px no-repeat
}

.jstree-default-small .jstree-folder {
    background: url(../images/32px.png) -263px -7px no-repeat
}

.jstree-default-small>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-small {
    line-height: 18px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-small i {
    background: transparent;
    width: 18px;
    height: 18px;
    line-height: 18px
}

#jstree-dnd.jstree-default-small .jstree-ok {
    background-position: -7px -71px
}

#jstree-dnd.jstree-default-small .jstree-er {
    background-position: -39px -71px
}

.jstree-default-small .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-small .jstree-ellipsis .jstree-anchor {
    width: calc(77%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-large .jstree-node {
    min-height: 32px;
    line-height: 32px;
    margin-left: 32px;
    min-width: 32px
}

.jstree-default-large .jstree-anchor {
    line-height: 32px;
    height: 32px
}

.jstree-default-large .jstree-icon {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-large .jstree-icon:empty {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-large.jstree-rtl .jstree-node {
    margin-right: 32px
}

.jstree-default-large .jstree-wholerow {
    height: 32px
}

.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-large .jstree-node {
    background-position: -288px 0px;
    background-repeat: repeat-y
}

.jstree-default-large .jstree-last {
    background: transparent
}

.jstree-default-large .jstree-open>.jstree-ocl {
    background-position: -128px 0px
}

.jstree-default-large .jstree-closed>.jstree-ocl {
    background-position: -96px 0px
}

.jstree-default-large .jstree-leaf>.jstree-ocl {
    background-position: -64px 0px
}

.jstree-default-large .jstree-themeicon {
    background-position: -256px 0px
}

.jstree-default-large>.jstree-no-dots .jstree-node,
.jstree-default-large>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-large>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px 0px
}

.jstree-default-large>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px 0px
}

.jstree-default-large .jstree-disabled {
    background: transparent
}

.jstree-default-large .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-large .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-large .jstree-checkbox {
    background-position: -160px 0px
}

.jstree-default-large .jstree-checkbox:hover {
    background-position: -160px -32px
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-large .jstree-checked>.jstree-checkbox {
    background-position: -224px 0px
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-large .jstree-checked>.jstree-checkbox:hover {
    background-position: -224px -32px
}

.jstree-default-large .jstree-anchor>.jstree-undetermined {
    background-position: -192px 0px
}

.jstree-default-large .jstree-anchor>.jstree-undetermined:hover {
    background-position: -192px -32px
}

.jstree-default-large .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-large>.jstree-striped {
    background-size: auto 64px
}

.jstree-default-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-large.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-large.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -128px -32px
}

.jstree-default-large.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -96px -32px
}

.jstree-default-large.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -64px -32px
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px -32px
}

.jstree-default-large.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px -32px
}

.jstree-default-large .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-large>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-large .jstree-file {
    background: url(../images/32px.png) -96px -64px no-repeat
}

.jstree-default-large .jstree-folder {
    background: url(../images/32px.png) -256px 0px no-repeat
}

.jstree-default-large>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-large {
    line-height: 32px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-large i {
    background: transparent;
    width: 32px;
    height: 32px;
    line-height: 32px
}

#jstree-dnd.jstree-default-large .jstree-ok {
    background-position: 0px -64px
}

#jstree-dnd.jstree-default-large .jstree-er {
    background-position: -32px -64px
}

.jstree-default-large .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-large .jstree-ellipsis .jstree-anchor {
    width: calc(63%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-large.jstree-rtl .jstree-last {
    background: transparent
}

@media (max-width:768px) {
    #jstree-dnd.jstree-dnd-responsive {
        line-height: 40px;
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    #jstree-dnd.jstree-dnd-responsive>i {
        background: transparent;
        width: 40px;
        height: 40px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-ok {
        background-image: url(../images/40px.png);
        background-position: 0 -200px;
        background-size: 120px 240px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-er {
        background-image: url(../images/40px.png);
        background-position: -40px -200px;
        background-size: 120px 240px
    }

    #jstree-marker.jstree-dnd-responsive {
        border-left-width: 10px;
        border-top-width: 10px;
        border-bottom-width: 10px;
        margin-top: -10px
    }
}

@media (max-width:768px) {
    .jstree-default-responsive .jstree-icon {
        background-image: url(../images/40px.png)
    }

    .jstree-default-responsive .jstree-node,
    .jstree-default-responsive .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-responsive .jstree-node {
        min-height: 40px;
        line-height: 40px;
        margin-left: 40px;
        min-width: 40px;
        white-space: nowrap
    }

    .jstree-default-responsive .jstree-anchor {
        line-height: 40px;
        height: 40px
    }

    .jstree-default-responsive .jstree-icon,
    .jstree-default-responsive .jstree-icon:empty {
        width: 40px;
        height: 40px;
        line-height: 40px
    }

    .jstree-default-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0
    }

    .jstree-default-responsive.jstree-rtl .jstree-node {
        margin-left: 0;
        margin-right: 40px;
        background: transparent
    }

    .jstree-default-responsive.jstree-rtl .jstree-container-ul>.jstree-node {
        margin-right: 0
    }

    .jstree-default-responsive .jstree-ocl,
    .jstree-default-responsive .jstree-themeicon,
    .jstree-default-responsive .jstree-checkbox {
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-leaf>.jstree-ocl,
    .jstree-default-responsive.jstree-rtl .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-responsive .jstree-open>.jstree-ocl {
        background-position: 0 0 !important
    }

    .jstree-default-responsive .jstree-closed>.jstree-ocl {
        background-position: 0 -40px !important
    }

    .jstree-default-responsive.jstree-rtl .jstree-closed>.jstree-ocl {
        background-position: -40px 0 !important
    }

    .jstree-default-responsive .jstree-themeicon {
        background-position: -40px -40px
    }

    .jstree-default-responsive .jstree-checkbox,
    .jstree-default-responsive .jstree-checkbox:hover {
        background-position: -40px -80px
    }

    .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
    .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
    .jstree-default-responsive .jstree-checked>.jstree-checkbox,
    .jstree-default-responsive .jstree-checked>.jstree-checkbox:hover {
        background-position: 0 -80px
    }

    .jstree-default-responsive .jstree-anchor>.jstree-undetermined,
    .jstree-default-responsive .jstree-anchor>.jstree-undetermined:hover {
        background-position: 0 -120px
    }

    .jstree-default-responsive .jstree-anchor {
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    .jstree-default-responsive>.jstree-striped {
        background: transparent
    }

    .jstree-default-responsive .jstree-wholerow {
        border-top: 1px solid rgba(255, 255, 255, 0.7);
        border-bottom: 1px solid rgba(64, 64, 64, 0.2);
        background: #ebebeb;
        height: 40px
    }

    .jstree-default-responsive .jstree-wholerow-hovered {
        background: #e7f4f9
    }

    .jstree-default-responsive .jstree-wholerow-clicked {
        background: #beebff
    }

    .jstree-default-responsive .jstree-children .jstree-last>.jstree-wholerow {
        box-shadow: inset 0 -6px 3px -5px #666
    }

    .jstree-default-responsive .jstree-children .jstree-open>.jstree-wholerow {
        box-shadow: inset 0 6px 3px -5px #666;
        border-top: 0
    }

    .jstree-default-responsive .jstree-children .jstree-open+.jstree-open {
        box-shadow: none
    }

    .jstree-default-responsive .jstree-node,
    .jstree-default-responsive .jstree-icon,
    .jstree-default-responsive .jstree-node>.jstree-ocl,
    .jstree-default-responsive .jstree-themeicon,
    .jstree-default-responsive .jstree-checkbox {
        background-image: url(../images/40px.png);
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-node {
        background-position: -80px 0;
        background-repeat: repeat-y
    }

    .jstree-default-responsive .jstree-last {
        background: transparent
    }

    .jstree-default-responsive .jstree-leaf>.jstree-ocl {
        background-position: -40px -120px
    }

    .jstree-default-responsive .jstree-last>.jstree-ocl {
        background-position: -40px -160px
    }

    .jstree-default-responsive .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0
    }

    .jstree-default-responsive .jstree-file {
        background: url(../images/40px.png) 0 -160px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-responsive .jstree-folder {
        background: url(../images/40px.png) -40px -40px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0;
        margin-right: 0
    }
}

.jstree-node,
.jstree-children,
.jstree-container-ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none
}

.jstree-node {
    white-space: nowrap
}

.jstree-anchor {
    display: inline-block;
    color: black;
    white-space: nowrap;
    padding: 0 4px 0 1px;
    margin: 0;
    vertical-align: top
}

.jstree-anchor:focus {
    outline: 0
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
    text-decoration: none;
    color: inherit
}

.jstree-icon {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-icon:empty {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center
}

.jstree-ocl {
    cursor: pointer
}

.jstree-leaf>.jstree-ocl {
    cursor: default
}

.jstree .jstree-open>.jstree-children {
    display: block
}

.jstree .jstree-closed>.jstree-children,
.jstree .jstree-leaf>.jstree-children {
    display: none
}

.jstree-anchor>.jstree-themeicon {
    margin-right: 2px
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor>.jstree-themeicon-hidden {
    display: none
}

.jstree-hidden,
.jstree-node.jstree-hidden {
    display: none
}

.jstree-rtl .jstree-anchor {
    padding: 0 1px 0 4px
}

.jstree-rtl .jstree-anchor>.jstree-themeicon {
    margin-left: 2px;
    margin-right: 0
}

.jstree-rtl .jstree-node {
    margin-left: 0
}

.jstree-rtl .jstree-container-ul>.jstree-node {
    margin-right: 0
}

.jstree-wholerow-ul {
    position: relative;
    display: inline-block;
    min-width: 100%
}

.jstree-wholerow-ul .jstree-leaf>.jstree-ocl {
    cursor: pointer
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
    position: relative
}

.jstree-wholerow-ul .jstree-wholerow {
    width: 100%;
    cursor: pointer;
    position: absolute;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.jstree-contextmenu .jstree-anchor {
    -webkit-user-select: none;
    -webkit-touch-callout: none
}

.vakata-context {
    display: none
}

.vakata-context,
.vakata-context ul {
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999
}

.vakata-context ul {
    list-style: none;
    left: 100%;
    margin-top: -2.7em;
    margin-left: -4px
}

.vakata-context .vakata-context-right ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context li {
    list-style: none
}

.vakata-context li>a {
    display: block;
    padding: 0 2em 0 2em;
    text-decoration: none;
    width: auto;
    color: black;
    white-space: nowrap;
    line-height: 2.4em;
    text-shadow: 1px 1px 0 #fff;
    border-radius: 1px
}

.vakata-context li>a:hover {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
    background-position: right center;
    background-repeat: no-repeat
}

.vakata-context li>a:focus {
    outline: 0
}

.vakata-context .vakata-context-hover>a {
    position: relative;
    background-color: #e8eff7;
    box-shadow: 0 0 2px #0a6aa1
}

.vakata-context .vakata-context-separator>a,
.vakata-context .vakata-context-separator>a:hover {
    background: white;
    border: 0;
    border-top: 1px solid #e2e3e3;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    padding: 0;
    margin: 0 0 0 2.4em;
    border-left: 1px solid #e0e0e0;
    text-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
    border-radius: 0
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
    color: silver;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0
}

.vakata-context .vakata-contextmenu-disabled>a>i {
    filter: grayscale(100%)
}

.vakata-context li>a>i {
    text-decoration: none;
    display: inline-block;
    width: 2.4em;
    height: 2.4em;
    background: transparent;
    margin: 0 0 0 -2em;
    vertical-align: top;
    text-align: center;
    line-height: 2.4em
}

.vakata-context li>a>i:empty {
    width: 2.4em;
    line-height: 2.4em
}

.vakata-context li>a .vakata-contextmenu-sep {
    display: inline-block;
    width: 1px;
    height: 2.4em;
    background: white;
    margin: 0 .5em 0 0;
    border-left: 1px solid #e2e3e3
}

.vakata-context .vakata-contextmenu-shortcut {
    font-size: 0.8em;
    color: silver;
    opacity: 0.5;
    display: none
}

.vakata-context-rtl ul {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -4px
}

.vakata-context-rtl li>a.vakata-context-parent {
    background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
    background-position: left center;
    background-repeat: no-repeat
}

.vakata-context-rtl .vakata-context-separator>a {
    margin: 0 2.4em 0 0;
    border-left: 0;
    border-right: 1px solid #e2e3e3
}

.vakata-context-rtl .vakata-context-left ul {
    right: auto;
    left: 100%;
    margin-left: -4px;
    margin-right: auto
}

.vakata-context-rtl li>a>i {
    margin: 0 -2em 0 0
}

.vakata-context-rtl li>a .vakata-contextmenu-sep {
    margin: 0 0 0 .5em;
    border-left-color: white;
    background: #e2e3e3
}

#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0
}

#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
    display: inline-block;
    text-decoration: none;
    margin: 0 2px 0 0;
    padding: 0;
    width: 16px;
    height: 16px
}

#jstree-dnd .jstree-ok {
    background: green
}

#jstree-dnd .jstree-er {
    background: red
}

#jstree-dnd .jstree-copy {
    margin: 0 2px 0 2px
}

.jstree-default-dark .jstree-node,
.jstree-default-dark .jstree-icon {
    background-repeat: no-repeat;
    background-color: transparent
}

.jstree-default-dark .jstree-anchor,
.jstree-default-dark .jstree-animated,
.jstree-default-dark .jstree-wholerow {
    transition: background-color .15s, box-shadow .15s
}

.jstree-default-dark .jstree-hovered {
    background: #555;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #555
}

.jstree-default-dark .jstree-context {
    background: #555;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #555
}

.jstree-default-dark .jstree-clicked {
    background: #5fa2db;
    border-radius: 2px;
    box-shadow: inset 0 0 1px #666
}

.jstree-default-dark .jstree-no-icons .jstree-anchor>.jstree-themeicon {
    display: none
}

.jstree-default-dark .jstree-disabled {
    background: transparent;
    color: #666666
}

.jstree-default-dark .jstree-disabled.jstree-hovered {
    background: transparent;
    box-shadow: none
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
    background: #333333
}

.jstree-default-dark .jstree-disabled>.jstree-icon {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark .jstree-search {
    font-style: italic;
    color: #ffffff;
    font-weight: bold
}

.jstree-default-dark .jstree-no-checkboxes .jstree-checkbox {
    display: none !important
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked {
    background: transparent;
    box-shadow: none
}

.jstree-default-dark.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
    background: #555
}

.jstree-default-dark.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked {
    background: transparent
}

.jstree-default-dark.jstree-checkbox-no-clicked>.jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
    background: #555
}

.jstree-default-dark>.jstree-striped {
    min-width: 100%;
    display: inline-block;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat
}

.jstree-default-dark>.jstree-wholerow-ul .jstree-hovered,
.jstree-default-dark>.jstree-wholerow-ul .jstree-clicked {
    background: transparent;
    box-shadow: none;
    border-radius: 0
}

.jstree-default-dark .jstree-wholerow {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.jstree-default-dark .jstree-wholerow-hovered {
    background: #555
}

.jstree-default-dark .jstree-wholerow-clicked {
    background: #5fa2db;
    background: -webkit-linear-gradient(top, #5fa2db 0%, #5fa2db 100%);
    background: linear-gradient(to bottom, #5fa2db 0%, #5fa2db 100%)
}

.jstree-default-dark .jstree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 24px;
    min-width: 24px
}

.jstree-default-dark .jstree-anchor {
    line-height: 24px;
    height: 24px
}

.jstree-default-dark .jstree-icon {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default-dark .jstree-icon:empty {
    width: 24px;
    height: 24px;
    line-height: 24px
}

.jstree-default-dark.jstree-rtl .jstree-node {
    margin-right: 24px
}

.jstree-default-dark .jstree-wholerow {
    height: 24px
}

.jstree-default-dark .jstree-node,
.jstree-default-dark .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark .jstree-node {
    background-position: -292px -4px;
    background-repeat: repeat-y
}

.jstree-default-dark .jstree-last {
    background: transparent
}

.jstree-default-dark .jstree-open>.jstree-ocl {
    background-position: -132px -4px
}

.jstree-default-dark .jstree-closed>.jstree-ocl {
    background-position: -100px -4px
}

.jstree-default-dark .jstree-leaf>.jstree-ocl {
    background-position: -68px -4px
}

.jstree-default-dark .jstree-themeicon {
    background-position: -260px -4px
}

.jstree-default-dark>.jstree-no-dots .jstree-node,
.jstree-default-dark>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -4px
}

.jstree-default-dark>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -4px
}

.jstree-default-dark .jstree-disabled {
    background: transparent
}

.jstree-default-dark .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark .jstree-checkbox {
    background-position: -164px -4px
}

.jstree-default-dark .jstree-checkbox:hover {
    background-position: -164px -36px
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark .jstree-checked>.jstree-checkbox {
    background-position: -228px -4px
}

.jstree-default-dark.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark .jstree-checked>.jstree-checkbox:hover {
    background-position: -228px -36px
}

.jstree-default-dark .jstree-anchor>.jstree-undetermined {
    background-position: -196px -4px
}

.jstree-default-dark .jstree-anchor>.jstree-undetermined:hover {
    background-position: -196px -36px
}

.jstree-default-dark .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark>.jstree-striped {
    background-size: auto 48px
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -132px -36px
}

.jstree-default-dark.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -100px -36px
}

.jstree-default-dark.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -68px -36px
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -36px -36px
}

.jstree-default-dark.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -4px -36px
}

.jstree-default-dark .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark .jstree-file {
    background: url(../images/32px.png) -100px -68px no-repeat
}

.jstree-default-dark .jstree-folder {
    background: url(../images/32px.png) -260px -4px no-repeat
}

.jstree-default-dark>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark {
    line-height: 24px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark .jstree-ok,
#jstree-dnd.jstree-default-dark .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark i {
    background: transparent;
    width: 24px;
    height: 24px;
    line-height: 24px
}

#jstree-dnd.jstree-default-dark .jstree-ok {
    background-position: -4px -68px
}

#jstree-dnd.jstree-default-dark .jstree-er {
    background-position: -36px -68px
}

.jstree-default-dark .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark .jstree-ellipsis .jstree-anchor {
    width: calc(71%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small .jstree-node {
    min-height: 18px;
    line-height: 18px;
    margin-left: 18px;
    min-width: 18px
}

.jstree-default-dark-small .jstree-anchor {
    line-height: 18px;
    height: 18px
}

.jstree-default-dark-small .jstree-icon {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-dark-small .jstree-icon:empty {
    width: 18px;
    height: 18px;
    line-height: 18px
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    margin-right: 18px
}

.jstree-default-dark-small .jstree-wholerow {
    height: 18px
}

.jstree-default-dark-small .jstree-node,
.jstree-default-dark-small .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark-small .jstree-node {
    background-position: -295px -7px;
    background-repeat: repeat-y
}

.jstree-default-dark-small .jstree-last {
    background: transparent
}

.jstree-default-dark-small .jstree-open>.jstree-ocl {
    background-position: -135px -7px
}

.jstree-default-dark-small .jstree-closed>.jstree-ocl {
    background-position: -103px -7px
}

.jstree-default-dark-small .jstree-leaf>.jstree-ocl {
    background-position: -71px -7px
}

.jstree-default-dark-small .jstree-themeicon {
    background-position: -263px -7px
}

.jstree-default-dark-small>.jstree-no-dots .jstree-node,
.jstree-default-dark-small>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-small>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -7px
}

.jstree-default-dark-small>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -7px
}

.jstree-default-dark-small .jstree-disabled {
    background: transparent
}

.jstree-default-dark-small .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark-small .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark-small .jstree-checkbox {
    background-position: -167px -7px
}

.jstree-default-dark-small .jstree-checkbox:hover {
    background-position: -167px -39px
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark-small .jstree-checked>.jstree-checkbox {
    background-position: -231px -7px
}

.jstree-default-dark-small.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark-small .jstree-checked>.jstree-checkbox:hover {
    background-position: -231px -39px
}

.jstree-default-dark-small .jstree-anchor>.jstree-undetermined {
    background-position: -199px -7px
}

.jstree-default-dark-small .jstree-anchor>.jstree-undetermined:hover {
    background-position: -199px -39px
}

.jstree-default-dark-small .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark-small>.jstree-striped {
    background-size: auto 36px
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -135px -39px
}

.jstree-default-dark-small.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -103px -39px
}

.jstree-default-dark-small.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -71px -39px
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -39px -39px
}

.jstree-default-dark-small.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: -7px -39px
}

.jstree-default-dark-small .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark-small>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark-small .jstree-file {
    background: url(../images/32px.png) -103px -71px no-repeat
}

.jstree-default-dark-small .jstree-folder {
    background: url(../images/32px.png) -263px -7px no-repeat
}

.jstree-default-dark-small>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark-small {
    line-height: 18px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark-small .jstree-ok,
#jstree-dnd.jstree-default-dark-small .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark-small i {
    background: transparent;
    width: 18px;
    height: 18px;
    line-height: 18px
}

#jstree-dnd.jstree-default-dark-small .jstree-ok {
    background-position: -7px -71px
}

#jstree-dnd.jstree-default-dark-small .jstree-er {
    background-position: -39px -71px
}

.jstree-default-dark-small .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark-small .jstree-ellipsis .jstree-anchor {
    width: calc(77%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large .jstree-node {
    min-height: 32px;
    line-height: 32px;
    margin-left: 32px;
    min-width: 32px
}

.jstree-default-dark-large .jstree-anchor {
    line-height: 32px;
    height: 32px
}

.jstree-default-dark-large .jstree-icon {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-dark-large .jstree-icon:empty {
    width: 32px;
    height: 32px;
    line-height: 32px
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    margin-right: 32px
}

.jstree-default-dark-large .jstree-wholerow {
    height: 32px
}

.jstree-default-dark-large .jstree-node,
.jstree-default-dark-large .jstree-icon {
    background-image: url(../images/32px.png)
}

.jstree-default-dark-large .jstree-node {
    background-position: -288px 0px;
    background-repeat: repeat-y
}

.jstree-default-dark-large .jstree-last {
    background: transparent
}

.jstree-default-dark-large .jstree-open>.jstree-ocl {
    background-position: -128px 0px
}

.jstree-default-dark-large .jstree-closed>.jstree-ocl {
    background-position: -96px 0px
}

.jstree-default-dark-large .jstree-leaf>.jstree-ocl {
    background-position: -64px 0px
}

.jstree-default-dark-large .jstree-themeicon {
    background-position: -256px 0px
}

.jstree-default-dark-large>.jstree-no-dots .jstree-node,
.jstree-default-dark-large>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-large>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px 0px
}

.jstree-default-dark-large>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px 0px
}

.jstree-default-dark-large .jstree-disabled {
    background: transparent
}

.jstree-default-dark-large .jstree-disabled.jstree-hovered {
    background: transparent
}

.jstree-default-dark-large .jstree-disabled.jstree-clicked {
    background: #efefef
}

.jstree-default-dark-large .jstree-checkbox {
    background-position: -160px 0px
}

.jstree-default-dark-large .jstree-checkbox:hover {
    background-position: -160px -32px
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
.jstree-default-dark-large .jstree-checked>.jstree-checkbox {
    background-position: -224px 0px
}

.jstree-default-dark-large.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
.jstree-default-dark-large .jstree-checked>.jstree-checkbox:hover {
    background-position: -224px -32px
}

.jstree-default-dark-large .jstree-anchor>.jstree-undetermined {
    background-position: -192px 0px
}

.jstree-default-dark-large .jstree-anchor>.jstree-undetermined:hover {
    background-position: -192px -32px
}

.jstree-default-dark-large .jstree-checkbox-disabled {
    opacity: 0.8;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%)
}

.jstree-default-dark-large>.jstree-striped {
    background-size: auto 64px
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
    background-position: 100% 1px;
    background-repeat: repeat-y
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl .jstree-open>.jstree-ocl {
    background-position: -128px -32px
}

.jstree-default-dark-large.jstree-rtl .jstree-closed>.jstree-ocl {
    background-position: -96px -32px
}

.jstree-default-dark-large.jstree-rtl .jstree-leaf>.jstree-ocl {
    background-position: -64px -32px
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-node,
.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-leaf>.jstree-ocl {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-open>.jstree-ocl {
    background-position: -32px -32px
}

.jstree-default-dark-large.jstree-rtl>.jstree-no-dots .jstree-closed>.jstree-ocl {
    background-position: 0px -32px
}

.jstree-default-dark-large .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0
}

.jstree-default-dark-large>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../images/throbber.gif) center center no-repeat
}

.jstree-default-dark-large .jstree-file {
    background: url(../images/32px.png) -96px -64px no-repeat
}

.jstree-default-dark-large .jstree-folder {
    background: url(../images/32px.png) -256px 0px no-repeat
}

.jstree-default-dark-large>.jstree-container-ul>.jstree-node {
    margin-left: 0;
    margin-right: 0
}

#jstree-dnd.jstree-default-dark-large {
    line-height: 32px;
    padding: 0 4px
}

#jstree-dnd.jstree-default-dark-large .jstree-ok,
#jstree-dnd.jstree-default-dark-large .jstree-er {
    background-image: url(../images/32px.png);
    background-repeat: no-repeat;
    background-color: transparent
}

#jstree-dnd.jstree-default-dark-large i {
    background: transparent;
    width: 32px;
    height: 32px;
    line-height: 32px
}

#jstree-dnd.jstree-default-dark-large .jstree-ok {
    background-position: 0px -64px
}

#jstree-dnd.jstree-default-dark-large .jstree-er {
    background-position: -32px -64px
}

.jstree-default-dark-large .jstree-ellipsis {
    overflow: hidden
}

.jstree-default-dark-large .jstree-ellipsis .jstree-anchor {
    width: calc(63%);
    text-overflow: ellipsis;
    overflow: hidden
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

@media (max-width:768px) {
    #jstree-dnd.jstree-dnd-responsive {
        line-height: 40px;
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    #jstree-dnd.jstree-dnd-responsive>i {
        background: transparent;
        width: 40px;
        height: 40px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-ok {
        background-image: url(../images/40px.png);
        background-position: 0 -200px;
        background-size: 120px 240px
    }

    #jstree-dnd.jstree-dnd-responsive>.jstree-er {
        background-image: url(../images/40px.png);
        background-position: -40px -200px;
        background-size: 120px 240px
    }

    #jstree-marker.jstree-dnd-responsive {
        border-left-width: 10px;
        border-top-width: 10px;
        border-bottom-width: 10px;
        margin-top: -10px
    }
}

@media (max-width:768px) {
    .jstree-default-dark-responsive .jstree-icon {
        background-image: url(../images/40px.png)
    }

    .jstree-default-dark-responsive .jstree-node,
    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-node {
        min-height: 40px;
        line-height: 40px;
        margin-left: 40px;
        min-width: 40px;
        white-space: nowrap
    }

    .jstree-default-dark-responsive .jstree-anchor {
        line-height: 40px;
        height: 40px
    }

    .jstree-default-dark-responsive .jstree-icon,
    .jstree-default-dark-responsive .jstree-icon:empty {
        width: 40px;
        height: 40px;
        line-height: 40px
    }

    .jstree-default-dark-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-node {
        margin-left: 0;
        margin-right: 40px;
        background: transparent
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-container-ul>.jstree-node {
        margin-right: 0
    }

    .jstree-default-dark-responsive .jstree-ocl,
    .jstree-default-dark-responsive .jstree-themeicon,
    .jstree-default-dark-responsive .jstree-checkbox {
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl,
    .jstree-default-dark-responsive.jstree-rtl .jstree-leaf>.jstree-ocl {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-open>.jstree-ocl {
        background-position: 0 0 !important
    }

    .jstree-default-dark-responsive .jstree-closed>.jstree-ocl {
        background-position: 0 -40px !important
    }

    .jstree-default-dark-responsive.jstree-rtl .jstree-closed>.jstree-ocl {
        background-position: -40px 0 !important
    }

    .jstree-default-dark-responsive .jstree-themeicon {
        background-position: -40px -40px
    }

    .jstree-default-dark-responsive .jstree-checkbox,
    .jstree-default-dark-responsive .jstree-checkbox:hover {
        background-position: -40px -80px
    }

    .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox,
    .jstree-default-dark-responsive.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox:hover,
    .jstree-default-dark-responsive .jstree-checked>.jstree-checkbox,
    .jstree-default-dark-responsive .jstree-checked>.jstree-checkbox:hover {
        background-position: 0 -80px
    }

    .jstree-default-dark-responsive .jstree-anchor>.jstree-undetermined,
    .jstree-default-dark-responsive .jstree-anchor>.jstree-undetermined:hover {
        background-position: 0 -120px
    }

    .jstree-default-dark-responsive .jstree-anchor {
        font-weight: bold;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff
    }

    .jstree-default-dark-responsive>.jstree-striped {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-wholerow {
        border-top: 1px solid #666;
        border-bottom: 1px solid #000;
        background: #333333;
        height: 40px
    }

    .jstree-default-dark-responsive .jstree-wholerow-hovered {
        background: #555
    }

    .jstree-default-dark-responsive .jstree-wholerow-clicked {
        background: #5fa2db
    }

    .jstree-default-dark-responsive .jstree-children .jstree-last>.jstree-wholerow {
        box-shadow: inset 0 -6px 3px -5px #111
    }

    .jstree-default-dark-responsive .jstree-children .jstree-open>.jstree-wholerow {
        box-shadow: inset 0 6px 3px -5px #111;
        border-top: 0
    }

    .jstree-default-dark-responsive .jstree-children .jstree-open+.jstree-open {
        box-shadow: none
    }

    .jstree-default-dark-responsive .jstree-node,
    .jstree-default-dark-responsive .jstree-icon,
    .jstree-default-dark-responsive .jstree-node>.jstree-ocl,
    .jstree-default-dark-responsive .jstree-themeicon,
    .jstree-default-dark-responsive .jstree-checkbox {
        background-image: url(../images/40px.png);
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-node {
        background-position: -80px 0;
        background-repeat: repeat-y
    }

    .jstree-default-dark-responsive .jstree-last {
        background: transparent
    }

    .jstree-default-dark-responsive .jstree-leaf>.jstree-ocl {
        background-position: -40px -120px
    }

    .jstree-default-dark-responsive .jstree-last>.jstree-ocl {
        background-position: -40px -160px
    }

    .jstree-default-dark-responsive .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0
    }

    .jstree-default-dark-responsive .jstree-file {
        background: url(../images/40px.png) 0 -160px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive .jstree-folder {
        background: url(../images/40px.png) -40px -40px no-repeat;
        background-size: 120px 240px
    }

    .jstree-default-dark-responsive>.jstree-container-ul>.jstree-node {
        margin-left: 0;
        margin-right: 0
    }
}

.jstree-default-dark {
    background: #333
}

.jstree-default-dark .jstree-anchor {
    color: #999;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5)
}

.jstree-default-dark .jstree-clicked,
.jstree-default-dark .jstree-checked {
    color: white
}

.jstree-default-dark .jstree-hovered {
    color: white
}

#jstree-marker.jstree-default-dark {
    border-left-color: #999;
    background: transparent
}

.jstree-default-dark .jstree-anchor>.jstree-icon {
    opacity: 0.75
}

.jstree-default-dark .jstree-clicked>.jstree-icon,
.jstree-default-dark .jstree-hovered>.jstree-icon,
.jstree-default-dark .jstree-checked>.jstree-icon {
    opacity: 1
}

.jstree-default-dark.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==")
}

.jstree-default-dark.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-small.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==")
}

.jstree-default-dark-small.jstree-rtl .jstree-last {
    background: transparent
}

.jstree-default-dark-large.jstree-rtl .jstree-node {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAACZmZl+9SADAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==")
}

.jstree-default-dark-large.jstree-rtl .jstree-last {
    background: transparent
}

.aw_ca-company-create.page-layout-3columns .column.main,
.cms-register-organisation.page-layout-3columns .column.main {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    display: block
}

.aw_ca-company-create .page-title-wrapper,
.cms-register-organisation .page-title-wrapper {
    text-align: center
}

.login-container .aw-ca-creat-wrapper.block .block {
    float: none;
    width: 100%
}

.aw-create-customer .aw-block-new-company {
    margin-top: 50px
}

.aw-create-customer .aw-block-new-company .actions-toolbar {
    margin-top: 25px
}

.aw-create-customer .aw-block-new-company .block-title {
    border-bottom: 1px solid #e8e8e8;
    font-size: 1.8rem;
    margin-bottom: 15px;
    padding-bottom: 12px
}

.aw-create-customer .aw-block-new-company .block-title strong {
    font-weight: 500
}

.aw-ca__form .action-basic {
    margin: 10px auto 0;
    display: block
}

.aw-ca__form .tree .jstree-anchor>.jstree-themeicon {
    display: none
}

.aw-ca__form .tree.jstree-default .jstree-ocl {
    position: relative
}

.aw-ca__form .tree.jstree-default .jstree-ocl::before {
    content: '';
    display: block;
    position: absolute
}

.aw-ca__form .tree.jstree-default .jstree-open>.jstree-ocl::before {
    border: 7px solid transparent;
    border-top: 10px solid #333;
    left: 4px;
    top: 8px
}

.aw-ca__form .tree.jstree-default .jstree-closed>.jstree-ocl::before {
    border: 7px solid transparent;
    border-left: 10px solid #333;
    left: 8px;
    top: 5px
}

.aw-ca__form .tree.jstree-default .jstree-checkbox {
    position: relative
}

.aw-ca__form .tree.jstree-default .jstree-checkbox::before {
    content: '';
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    border: 1px solid #1e1e1e;
    background: #fff;
    left: 4px;
    top: 4px
}

.aw-ca__form .tree.jstree-default .jstree-checkbox.jstree-undetermined::before {
    border: 1px solid #1979c3
}

.aw-ca__form .tree.jstree-default .jstree-checkbox.jstree-undetermined::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 8px;
    top: 8px;
    background: #1979c3
}

.aw-ca__form .tree.jstree-default .jstree-hovered {
    background: none;
    border-radius: 0;
    box-shadow: none
}

.aw-ca__form .tree.jstree-default .jstree-clicked>.jstree-checkbox::before {
    background: #1979c3;
    border: 1px solid #1979c3
}

.aw-ca__form .tree.jstree-default .jstree-clicked>.jstree-checkbox::after {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+');
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 6px;
    top: 6px;
    background-size: 100% 100%
}

.aw-ca__form .consent-fieldset-wrapper .title {
    display: none
}

.aw-ca__form .consent-fieldset-wrapper .fieldset>.field {
    width: 100%;
    text-align: center
}

.aw-ca__form .consent-fieldset-wrapper .fieldset>.field._required>label {
    display: none
}

.aw-ca-role-create .aw-ca__form .fieldset-wrapper-title {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 25px;
    padding-bottom: 10px
}

.aw-ca-role-create .aw-ca__form .fieldset-wrapper-title strong {
    font-size: 21px;
    font-weight: normal
}

.aw-ca-role-create .aw-ca__form .fieldset-wrapper {
    margin-bottom: 35px
}

.aw-ca-company-create .aw-ca__form {
    counter-reset: section
}

.aw-ca-company-create .aw-ca__form .fieldset-wrapper-title h2 {
    margin: 0 0 20px
}

.aw-ca-company-create .aw-ca__form .fieldset-wrapper-title h2:before {
    counter-increment: section;
    content: counter(section) ". ";
    color: #e62645;
}

.aw-ca-company-create .aw-ca__form .fieldset-wrapper .fieldset {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px
}

.aw-ca-company-create .aw-ca__form .fieldset-wrapper .fieldset>.field {
    padding: 0 10px;
    margin: 0 0 10px
}

.box-legal-address .box-content {
    line-height: 1.9
}

.aw-ca__customer-btn-wrapper {
    text-align: right;
    margin-bottom: 20px
}

.products {
    margin: 30px 0
}

.product-items {
    display: flex;
    flex-wrap: wrap
}

.product-item {
    text-align: center;
    position: relative
}

.product-item .product-price-list-container {
    display: none;
    overflow: hidden;
    width: 97%;
    margin: 0 auto;
    padding: 10px;
    background: #fff;
    border-radius: 0 5px 5px 0;
    position: absolute;
    top: 0;
    z-index: 99;
    border: 2px solid #e0e1e2;
    right: calc(-1 * (97% - 20px - 2px));
    text-align: left
}

.product-item .product-price-list thead {
    font-weight: 700
}

.product-item .product-price-list thead>tr>td,
.product-item .product-price-list tbody>tr>td {
    padding: 0 0 5px;
    text-align: left
}

.product-item .product-price-list .price-box {
    margin: 0
}

.product-item .product-price-list .price-box .price {
    font-weight: 400;
    font-size: inherit
}

.product-item-name {
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    display: block;
    hyphens: auto;
    margin: 10px 0;
    word-wrap: break-word;
    font-size: 17px
}

.product-item-actions {
    font-size: 0
}

.product-item-actions>* {
    font-size: 1.4rem
}

.product-item-actions .action i {
    margin-right: 5px
}

.product-item-actions .action.view {
    text-decoration: none;
    background-image: none;
    background: #1b1c1d;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 5px
}

.product-item-actions .action.view:hover,
.product-item-actions .action.view:active,
.product-item-actions .action.view:focus {
    text-decoration: none
}

.product-item-actions .action.view:visited {
    color: #fff
}

.product-item-actions .action.view:focus,
.product-item-actions .action.view:hover {
    background: #343637;
    border: 0;
    color: #fff
}

.product-item-actions .action.view:active {
    background: #404245;
    border: 0;
    color: #fff
}

.product-item-actions .action.view.disabled,
.product-item-actions .action.view[disabled],
fieldset[disabled] .product-item-actions .action.view {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.product-item-actions .actions-secondary {
    display: inline-block;
    font-size: 1.4rem;
    vertical-align: middle
}

.product-item-actions .actions-secondary>button.action {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.product-item-actions .actions-secondary>button.action:focus,
.product-item-actions .actions-secondary>button.action:hover {
    background: none;
    border: none
}

.product-item-actions .actions-secondary>button.action:active {
    background: none;
    border: none
}

.product-item-actions .actions-secondary>button.action.disabled,
.product-item-actions .actions-secondary>button.action[disabled],
fieldset[disabled] .product-item-actions .actions-secondary>button.action {
    pointer-events: none;
    opacity: .5
}

.product-item-actions .actions-secondary>.action {
    line-height: 35px;
    text-align: center;
    width: 35px
}

.product-item-actions .actions-secondary>.action:before {
    margin: 0
}

.product-item-description {
    margin: 25px 0
}

.product-item .product-image-container {
    border: 2px solid transparent;
    border-radius: 5px;
    width: 100% !important;
    padding: 20px 10px
}

.product-item:hover .product-image-container,
.product-item:focus .product-image-container,
.product-item:focus-within .product-image-container {
    border-color: #e0e1e2
}

.product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0
}

.product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px
}

.product-item .price-box {
    margin: 10px 0 25px
}

.product-item .price-box .price {
    font-weight: 700;
    white-space: nowrap
}

.product-item .price-box .price-label {
    color: #666;
    font-size: 12px
}

.product-item .old-price {
    margin: 5px 0
}

.product-item .old-price .price {
    font-weight: normal
}

.product-item .regular-price .price-label {
    display: none
}

.product-item .minimal-price .price-container {
    display: block
}

.product-item .minimal-price-link {
    margin-top: 5px
}

.product-item .price-from,
.product-item .price-to {
    margin: 0
}

.product-item .tocompare:before {
    content: '\f516'
}

.price-container .price {
    font-size: 1.4rem
}

.price-container .price-including-tax+.price-excluding-tax,
.price-container .weee {
    margin-top: 5px
}

.price-container .price-including-tax+.price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax+.price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee+.price-excluding-tax:before,
.price-container .weee+.price-excluding-tax .price {
    font-size: 1.1rem
}

.price-container .weee:before {
    content: '(' attr(data-label) ': '
}

.price-container .weee:after {
    content: ')'
}

.price-container .weee+.price-excluding-tax:before {
    content: attr(data-label) ': '
}

.products-list .product-item {
    display: table;
    width: 100%
}

.products-list .product-item-info {
    display: table-row
}

.products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%
}

.products-list .product-item-details {
    display: table-cell;
    vertical-align: top
}

@media only screen and (min-width:768px) and (max-width:calc(1440px - 1px)) {

    .product-item:nth-child(3n+0):hover .product-image-container,
    .product-item:nth-child(3n+0):focus .product-image-container,
    .product-item:nth-child(3n+0):focus-within .product-image-container {
        border-radius: 0 5px 5px 0
    }

    .product-item:nth-child(3n+0) .product-price-list-container {
        left: calc(-1 * (97% - 20px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

.page-products .columns {
    padding-top: 10px;
    position: relative;
    z-index: 1
}

.toolbar {
    margin-bottom: 30px;
    text-align: center
}

.toolbar select {
    border-radius: 3px
}

.toolbar .sorter-action {
    position: relative;
    top: -2px
}

.toolbar-amount {
    left: 0;
    line-height: 18px;
    margin: 0;
    padding: 7px 0;
    text-align: left;
    top: 0;
    vertical-align: middle
}

.products.wrapper~.toolbar .toolbar-amount {
    display: none
}

.page-with-filter .toolbar-amount {
    position: static
}

.toolbar-products {
    background-color: transparent
}

.toolbar-products .pages {
    display: none
}

.products.wrapper~.toolbar-products .pages {
    display: block
}

.toolbar .pages {
    margin-bottom: 20px
}

.products.wrapper~.toolbar .limiter {
    display: none
}

.sorter {
    float: right
}

.page-products .sorter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

.products.wrapper~.toolbar .sorter {
    display: none
}

.sorter-options {
    margin: 0 5px 0 7px;
    width: auto
}

.sorter-action {
    display: inline-block;
    text-decoration: none
}

.sorter-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: #333;
    content: '\e613';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.sorter-action:hover:before {
    color: #000
}

.sorter-action>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sorter-action.sort-desc:before {
    content: '\f0d7'
}

.modes {
    display: none
}

.limiter {
    float: left
}

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
    min-width: 63px;
}

.limiter-label {
    font-weight: 400
}

.limiter .control {
    display: inline-block
}

.catalog-product-view .product-reviews-summary,
.catalog-product-view .product-social-links,
.catalog-product-view .product.data.items {
    display: none
}

.old-price,
.old.price {
    color: #666
}

.old-price .price-label,
.old.price .price-label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.old-price .price-wrapper,
.old.price .price-wrapper {
    margin-left: 5px;
    text-decoration: line-through
}

.prices-tier {
    margin-bottom: 15px
}

.prices-tier .item {
    margin-bottom: 10px
}

.prices-tier .item:last-child {
    margin-bottom: 0
}

.prices-tier .price-container .price-including-tax+.price-excluding-tax:before {
    content: '(' attr(data-label) ':'
}

.prices-tier .price-container .price-including-tax+.price-excluding-tax:last-child:after {
    content: ')'
}

.prices-tier .price-container .weee[data-label] {
    display: inline
}

.prices-tier .price-container .weee[data-label]:before {
    content: ' +' attr(data-label) ':'
}

.prices-tier .price-excluding-tax,
.prices-tier .price-including-tax {
    display: inline-block
}

.actual-price {
    font-weight: 700
}

.category-image .image {
    display: block;
    height: auto;
    max-width: 100%
}

.category-cms,
.category-image,
.category-description {
    margin-bottom: 20px
}

.product-image-container {
    display: inline-block;
    max-width: 100%
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0
}

.product-info-top {
    display: flex;
    justify-content: center;
    margin: 0 auto 20px;
    width: 100%
}

.product.media .gallery-placeholder {
    max-width: 450px;
    margin: 0 auto
}

.product.media .gallery-placeholder .gallery-placeholder__image {
    width: 100%
}

.product.media .placeholder .photo.container {
    max-width: 100%
}

.product.media .notice {
    margin: 10px 0;
    color: #7d7d7d;
    font-size: 1.2rem
}

.product.media .product.thumbs {
    margin: 10px 0 25px
}

.product.media .items.thumbs {
    margin: 0;
    padding: 0;
    list-style: none none
}

.product.media .items.thumbs>li {
    display: inline-block;
    vertical-align: top
}

.product.media .items.thumbs>li {
    margin: 1rem 0 0
}

.product.media .items.thumbs img {
    display: block
}

.product.media .items.thumbs .active {
    display: block;
    line-height: 1
}

.product.media .fotorama__stage__frame .fotorama__img {
    width: 100%
}

.product.info.detailed {
    clear: both;
    margin-bottom: 0
}

.product.info.detailed .additional-attributes {
    width: auto;
    border: none
}

.product.info.detailed .additional-attributes>thead>tr>th,
.product.info.detailed .additional-attributes>tbody>tr>th,
.product.info.detailed .additional-attributes>tfoot>tr>th,
.product.info.detailed .additional-attributes>thead>tr>td,
.product.info.detailed .additional-attributes>tbody>tr>td,
.product.info.detailed .additional-attributes>tfoot>tr>td {
    border: none
}

.product.info.detailed .additional-attributes>thead>tr>td,
.product.info.detailed .additional-attributes>tbody>tr>td,
.product.info.detailed .additional-attributes>tfoot>tr>td {
    padding: 5.5px 5px 10px 5px
}

.product.info.detailed .additional-attributes>thead>tr>th,
.product.info.detailed .additional-attributes>tbody>tr>th,
.product.info.detailed .additional-attributes>tfoot>tr>th {
    padding: 5.5px 30px 10px 0
}

.product-info-main {
    max-width: 450px
}

.product-info-main .page-title-wrapper .page-title {
    word-break: break-word
}

.product-info-main .page-title-wrapper .page-title-long {
    font-size: 0.9em
}

.product-info-main .product-info-price {
    color: #575757;
    border-bottom: 1px solid #c1c1c1;
    display: table;
    margin-bottom: 15px;
    width: 100%
}

.product-info-main .product-info-price .price-box {
    display: table-cell;
    vertical-align: top;
    width: 1px
}

.product-info-main .product-info-price .price-box .price-container>span {
    display: block;
    margin-bottom: 5px
}

.product-info-main .product-info-price .price-box .price-container>span:last-child {
    margin-bottom: 0
}

.product-info-main .product-info-price .price-including-tax+.price-excluding-tax,
.product-info-main .product-info-price .weee+.price-excluding-tax,
.product-info-main .product-info-price .weee {
    font-size: 1.4rem
}

.product-info-main .product-info-price .price-including-tax+.price-excluding-tax .price,
.product-info-main .product-info-price .weee+.price-excluding-tax .price,
.product-info-main .product-info-price .weee .price {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 16px
}

.product-info-main .product-info-price .price-wrapper .price {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 22px
}

.product-info-main .product-info-price .old-price .price-wrapper .price {
    font-size: 2rem;
    font-weight: 300
}

.product-info-main .product-info-price .special-price .price-label:after {
    content: ': '
}

.product-info-main .product-info-price .price {
    white-space: nowrap
}

.product-info-main .product-price .total,
.product-info-main .product-price .price {
    color: #4a4a4a;
    font-weight: 700
}

.product-info-main .product-price .total {
    font-size: 14px
}

.product-info-main .product-price .price {
    font-size: 28px
}

.product-info-main .product-info-stock-sku {
    display: table-cell;
    padding-bottom: 10px;
    padding-left: 10%;
    text-align: right;
    vertical-align: top
}

.product-info-main .stock {
    margin: 0 0 3px
}

.product-info-main .stock.available,
.product-info-main .stock.unavailable {
    font-weight: 700;
    text-transform: uppercase
}

.product-info-main .product.attribute.sku {
    word-break: break-all;
    word-wrap: break-word
}

.product-info-main .product.attribute.sku .type {
    font-weight: normal;
    margin-right: 5px
}

.product-info-main .product.attribute.sku .type:after {
    content: '#:'
}

.product-info-main .product.attribute.sku .value {
    display: inline-block
}

.product-info-main .product.attribute.overview {
    margin: 20px 0
}

.product-info-main .product.alert {
    margin: 10px 0
}

.product-info-main .product-add-form {
    clear: both;
    padding-top: 15px
}

.product-info-main .configurable-table {
    width: 100%
}

.product-info-main .configurable-table-qty {
    width: 1px;
    text-align: right
}

.product-info-main .configurable-table tbody>tr>td,
.product-info-main .configurable-table thead>tr>td,
.product-info-main .configurable-table tfoot>tr>td,
.product-info-main .configurable-table tbody>tr>th,
.product-info-main .configurable-table thead>tr>th,
.product-info-main .configurable-table tfoot>tr>th {
    vertical-align: middle;
    padding: 10px
}

.product-info-main .configurable-table tbody>tr>td:first-child,
.product-info-main .configurable-table thead>tr>td:first-child,
.product-info-main .configurable-table tfoot>tr>td:first-child,
.product-info-main .configurable-table tbody>tr>th:first-child,
.product-info-main .configurable-table thead>tr>th:first-child,
.product-info-main .configurable-table tfoot>tr>th:first-child {
    padding-left: 0
}

.product-info-main .configurable-table tbody>tr>td:last-child,
.product-info-main .configurable-table thead>tr>td:last-child,
.product-info-main .configurable-table tfoot>tr>td:last-child,
.product-info-main .configurable-table tbody>tr>th:last-child,
.product-info-main .configurable-table thead>tr>th:last-child,
.product-info-main .configurable-table tfoot>tr>th:last-child {
    padding-right: 0
}

.product-info-main .product-reviews-summary {
    float: left
}

.product-info-main .product-options-bottom {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px 0
}

.product-info-main .product-options-bottom .button-sketcher {
    background-image: none;
    background: #fff;
    border: 1px solid #1b1c1d;
    color: #1b1c1d;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 15px 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    border-radius: 5px;
    text-align: center
}

.product-info-main .product-options-bottom .button-sketcher:hover,
.product-info-main .product-options-bottom .button-sketcher:active,
.product-info-main .product-options-bottom .button-sketcher:focus {
    text-decoration: none
}

.product-info-main .product-options-bottom .button-sketcher:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f044';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.product-info-main .product-options-bottom .button-sketcher:hover:before {
    color: inherit
}

.product-info-main .product-options-bottom .button-sketcher:active:before {
    color: inherit
}

.product-info-main .product-options-bottom .button-sketcher:visited {
    color: #1b1c1d
}

.product-info-main .product-options-bottom .button-sketcher:focus,
.product-info-main .product-options-bottom .button-sketcher:hover {
    background: #f2f2f2;
    border: 1px solid #343637;
    color: #343637
}

.product-info-main .product-options-bottom .button-sketcher:active {
    background: #e0e1e2;
    border: 1px solid #404245;
    color: #404245
}

.product-info-main .product-options-bottom .button-sketcher.disabled,
.product-info-main .product-options-bottom .button-sketcher[disabled],
fieldset[disabled] .product-info-main .product-options-bottom .button-sketcher {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.product-info-main .product-options-bottom .box-tocart {
    margin: 5px;
    display: initial
}

.product-info-main .product-options-bottom .box-tocart .fieldset {
    margin: 0;
    letter-spacing: initial
}

.product-info-main .product-options-bottom .box-tocart button {
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 15px 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none
}

.product-info-main .product-options-bottom .box-tocart button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f07a';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.product-info-main .product-options-bottom .box-tocart button:hover:before {
    color: inherit
}

.product-info-main .product-options-bottom .box-tocart button:active:before {
    color: inherit
}

.product-info-main .product-options-bottom .box-tocart button:visited {
    color: #fff
}

.product-info-main .product-options-bottom .box-tocart button:focus,
.product-info-main .product-options-bottom .box-tocart button:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.product-info-main .product-options-bottom .box-tocart button:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.product-info-main .product-options-bottom .box-tocart button.disabled,
.product-info-main .product-options-bottom .box-tocart button[disabled],
fieldset[disabled] .product-info-main .product-options-bottom .box-tocart button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.product-info-main .product-options-bottom .button-goto {
    background-image: none;
    background: #4a4a4a;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 15px 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
    text-align: center
}

.product-info-main .product-options-bottom .button-goto:hover,
.product-info-main .product-options-bottom .button-goto:active,
.product-info-main .product-options-bottom .button-goto:focus {
    text-decoration: none
}

.product-info-main .product-options-bottom .button-goto:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    content: '\f07a';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.product-info-main .product-options-bottom .button-goto:hover:before {
    color: inherit
}

.product-info-main .product-options-bottom .button-goto:active:before {
    color: inherit
}

.product-info-main .product-options-bottom .button-goto:visited {
    color: #fff
}

.product-info-main .product-options-bottom .button-goto:focus,
.product-info-main .product-options-bottom .button-goto:hover {
    background: #3d3d3d;
    border: 0;
    color: #fff
}

.product-info-main .product-options-bottom .button-goto:active {
    background: #363636;
    border: 0;
    color: #fff
}

.product-info-main .product-options-bottom .button-goto.disabled,
.product-info-main .product-options-bottom .button-goto[disabled],
fieldset[disabled] .product-info-main .product-options-bottom .button-goto {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.product-info-main .product-options-bottom .box-tocart,
.product-info-main .product-options-bottom .button-goto {
    width: calc(50% - 10px)
}

.product-info {
    color: #333;
    font-size: 16px
}

.product-options-wrapper .fieldset:focus {
    box-shadow: none
}

.product-options-wrapper .fieldset-product-options-inner .legend {
    font-weight: 600;
    font-size: 1.4rem;
    border: none;
    display: inline-block;
    float: none;
    margin: 0 0 8px;
    padding: 0
}

.product-options-wrapper .fieldset-product-options-inner input.datetime-picker~select.datetime-picker {
    margin-top: 10px
}

.product-options-wrapper .fieldset-product-options-inner.required .legend:after,
.product-options-wrapper .fieldset-product-options-inner._required .legend:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem
}

.product-options-wrapper .field .note {
    display: block;
    margin-top: 5px
}

.product-options-wrapper .field .note.mage-error {
    color: #e02b27
}

.product-options-bottom .price-box,
.product-info-price .price-box {
    color: #575757;
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top
}

.product-options-bottom .price-box .old-price,
.product-info-price .price-box .old-price {
    font-size: 20px;
    font-weight: 300
}

.product-options-bottom .price-box .old-price .price-container>span,
.product-info-price .price-box .old-price .price-container>span {
    display: inline-block
}

.product-options-bottom .price-box .price-container>span,
.product-info-price .price-box .price-container>span {
    display: block;
    margin-bottom: 5px
}

.product-options-bottom .price-box .price-container>span:last-child,
.product-info-price .price-box .price-container>span:last-child {
    margin-bottom: 0
}

.product-options-bottom .price-box .price-container .price,
.product-info-price .price-box .price-container .price {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px
}

.product-options-bottom .price-box .price-container .price-including-tax+.price-excluding-tax,
.product-info-price .price-box .price-container .price-including-tax+.price-excluding-tax,
.product-options-bottom .price-box .price-container .weee+.price-excluding-tax,
.product-info-price .price-box .price-container .weee+.price-excluding-tax,
.product-options-bottom .price-box .price-container .weee,
.product-info-price .price-box .price-container .weee {
    font-size: 1.2rem;
    line-height: 14px
}

.product-options-bottom .price-box .price-container .price-including-tax+.price-excluding-tax .price,
.product-info-price .price-box .price-container .price-including-tax+.price-excluding-tax .price,
.product-options-bottom .price-box .price-container .weee+.price-excluding-tax .price,
.product-info-price .price-box .price-container .weee+.price-excluding-tax .price,
.product-options-bottom .price-box .price-container .weee .price,
.product-info-price .price-box .price-container .weee .price {
    font-size: 1.2rem;
    font-weight: 700
}

.box-tocart .action.tocart {
    vertical-align: top
}

.product-addto-links {
    display: inline
}

.product-addto-links .action {
    margin-right: 5%
}

.product-social-links {
    margin: 0 0 20px;
    text-align: center
}

.product-social-links .action.tocompare:before {
    content: '\f516'
}

.minimal-price-link,
.price-excluding-tax,
.price-including-tax {
    display: block;
    white-space: nowrap
}

.ui-dialog-titlebar-close {
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
    color: #b74f0e;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline
}

.ui-dialog-titlebar-close:visited {
    color: #b74f0e;
    text-decoration: none
}

.ui-dialog-titlebar-close:hover {
    color: #b74f0e;
    text-decoration: underline
}

.ui-dialog-titlebar-close:active {
    color: #b74f0e;
    text-decoration: underline
}

.ui-dialog-titlebar-close:hover {
    color: #b74f0e
}

.ui-dialog-titlebar-close:hover,
.ui-dialog-titlebar-close:active,
.ui-dialog-titlebar-close:focus {
    background: none;
    border: 0
}

.ui-dialog-titlebar-close.disabled,
.ui-dialog-titlebar-close[disabled],
fieldset[disabled] .ui-dialog-titlebar-close {
    color: #b74f0e;
    opacity: .5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline
}

.product-tabs {
    max-width: 1440px;
    margin: 0 auto
}

.product-tabs .product-links {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-x: auto;
    overflow-y: hidden
}

.product-tabs .product-links li {
    color: #1b1c1d;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1rem 1rem 2rem;
    position: relative;
    flex: none
}

.product-tabs .product-links li a,
.product-tabs .product-links li a:hover,
.product-tabs .product-links li a:focus,
.product-tabs .product-links li a:active {
    color: inherit;
    text-decoration: none
}

.product-tabs .product-links li.active {
    color: #4a4a4a;
    cursor: default
}

.product-tabs .product-links li.active::after {
    background: #ccc;
    content: '';
    position: absolute;
    bottom: calc(-8px);
    left: calc(34%);
    width: 16px;
    height: 16px;
    transform: rotate(45deg)
}

.product-tabs .product-content div {
    display: none
}

.product-tabs .product-content div.active {
    display: block
}

.product-tabs .product-content-table {
    table-layout: fixed
}

.product-tabs .product-content-table tr {
    border-top: 1px solid #ccc
}

.product-tabs .product-content-table tr:last-child {
    border-bottom: 1px solid #ccc
}

.product-tabs .product-content-table tr:nth-child(even) th,
.product-tabs .product-content-table tr:nth-child(even) td {
    background-color: #FAFAFB
}

.product-tabs .product-content-table tr th:first-child {
    border-left: 1px solid #ccc
}

.product-tabs .product-content-table tr td:last-child {
    border-right: 1px solid #ccc
}

.product-tabs .product-content-table tr td {
    word-wrap: break-word
}

.product-tabs .product-content-table.product-content-table--regulatory tr td:first-child {
    border-left: 1px solid #ccc
}

.product-tabs .product-content-table.product-content-table--regulatory tr th:last-child {
    border-right: 1px solid #ccc
}

.product-tabs #tab-hazards>button {
    margin: 0 0 10px;
    width: 100%;
    background: #ccc
}

.sidebar .product-items .product-item {
    margin-bottom: 20px;
    position: relative
}

.sidebar .product-items .product-item-info {
    position: relative;
    width: auto
}

.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0
}

.sidebar .product-items .product-item-name {
    margin-top: 0
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px
}

.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: 10px
}

.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0
}

.sidebar .product-items .text {
    margin-right: 8px
}

.sidebar .product-items .counter {
    color: #7d7d7d;
    font-size: 12px;
    white-space: nowrap
}

.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0
}

.sidebar .product-items .minilist .weee:before {
    display: inline-block
}

.sidebar .product-items .action.delete {
    position: absolute;
    right: 0;
    top: 0
}

.sidebar .block-compare .action.delete {
    right: initial
}

.sidebar .action.tocart {
    border-radius: 0
}

.sidebar .product-items-images {
    margin-left: -5px
}

.sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px
}

.sidebar .product-items-names .product-item {
    display: flex;
    margin-bottom: 10px
}

.sidebar .product-items-names .product-item-name {
    margin: 0
}

.catalog-category-view.page-layout-1column .column.main {
    min-height: inherit
}

body.catalog-product-compare-index .action.print {
    float: right;
    margin: 15px 0
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
    position: relative
}

.table-wrapper.comparison .table-comparison>tbody>tr>th,
.table-wrapper.comparison .table-comparison>tbody>tr>td {
    border-top: 0
}

.table-comparison {
    table-layout: fixed
}

.table-comparison .cell {
    padding: 15px;
    width: 180px
}

.table-comparison .cell.label {
    border-right: 1px solid #ccc
}

.table-comparison .cell.label .attribute.label {
    display: block;
    width: 100%;
    word-wrap: break-word
}

.table-comparison .cell.product.info,
.table-comparison .cell.product.label {
    border-bottom: 1px solid #ccc
}

.table-comparison .cell.attribute {
    font-size: 1.3rem
}

.table-comparison .cell.attribute img {
    height: auto;
    max-width: 100%
}

.table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right
}

.table-comparison .cell.remove .action.delete {
    margin-right: .6rem
}

.table-comparison .cell .attribute.value {
    overflow: hidden;
    width: 100%
}

.table-comparison td:last-child {
    border-right: 1px solid #ccc
}

.table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px
}

.table-comparison .product-image-photo {
    margin-left: 0
}

.table-comparison .product-item-actions,
.table-comparison .price-box,
.table-comparison .product.rating,
.table-comparison .product-item-name {
    display: block;
    margin: 15px 0
}

.table-comparison .product-addto-links {
    margin-top: 15px
}

.table-comparison .product-addto-links .action.toggle {
    padding: 0
}

.table-comparison .product-addto-links .action.split,
.table-comparison .product-addto-links .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem
}

.table-comparison .action.tocart {
    white-space: nowrap
}

.comparison.headings {
    background: #fff;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2
}

.block-compare .product-item .product-item-name {
    margin-left: 22px
}

.block-compare .action.delete {
    left: 0;
    position: absolute;
    top: 0
}

.block-compare .actions-toolbar {
    margin: 17px 0 0
}

.block.related .action.select {
    vertical-align: top
}

.block.related .product-item-details {
    position: relative;
    z-index: 1
}

.block.related .related-available .product-item-name {
    margin-left: 20px
}

.block.related .field.choice {
    left: 0;
    position: absolute;
    top: 2px
}

.search .fieldset .control .addon input {
    flex-basis: auto;
    width: 100%
}

.block-search {
    margin-bottom: 0;
    position: relative;
    z-index: 4
}

.block-search .block-title {
    display: none
}

.block-search .block-content {
    margin-bottom: 0
}

.block-search .label.active+.control input {
    position: static
}

.block-search .action.search {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1
}

.block-search .action.search:focus,
.block-search .action.search:hover {
    background: none;
    border: none
}

.block-search .action.search:active {
    background: none;
    border: none
}

.block-search .action.search.disabled,
.block-search .action.search[disabled],
fieldset[disabled] .block-search .action.search {
    pointer-events: none;
    opacity: .5
}

.block-search .action.search:focus:before {
    color: #333
}

.block-search .control {
    clear: both;
    margin: 0 -15px -1px;
    padding: 0 15px;
    position: relative
}

.block-search .search-tooltip {
    position: relative;
    position: absolute
}

.block-search .search-tooltip .tooltip-content {
    background: #fff;
    border-radius: 5px;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%
}

.block-search .search-tooltip .tooltip-content:after,
.block-search .search-tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0
}

.block-search .search-tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent
}

.block-search .search-tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent
}

.block-search .search-tooltip .tooltip-content:after,
.block-search .search-tooltip .tooltip-content:before {
    bottom: 100%
}

.block-search .search-tooltip .tooltip-content:after {
    border-bottom-color: #fff;
    left: 15px;
    margin-left: -5px
}

.block-search .search-tooltip .tooltip-content:before {
    border-bottom-color: #bbb;
    left: 15px;
    margin-left: -6px
}

.block-search .search-tooltip .tooltip-toggle {
    cursor: help
}

.block-search .search-tooltip .tooltip-toggle:hover+.tooltip-content,
.block-search .search-tooltip .tooltip-toggle:focus+.tooltip-content,
.block-search .search-tooltip:hover .tooltip-content {
    display: block
}

.block-search .search-tooltip .tooltip-content {
    border-radius: 12px;
    left: -6px
}

.block-search input[aria-haspopup="true"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 3px 1px #c1c1c1
}

.block-search .nested {
    display: none
}

.search-links {
    margin: 0 -5px;
    list-style-type: none;
    padding: 10px 0 0
}

.search-links>li {
    display: inline-block;
    margin: 0
}

.search-links>li:last-child a:after {
    content: none
}

.search-links>li a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 0 5px 5px
}

.search-links>li a:visited {
    color: #333;
    text-decoration: none
}

.search-links>li a:hover {
    color: #000;
    text-decoration: underline
}

.search-links>li a:active {
    color: #000;
    text-decoration: underline
}

.search-links>li a:after {
    content: "|";
    display: inline-block;
    left: 5px;
    position: relative
}

.search-links .current {
    text-decoration: underline;
    font-weight: 700
}

.search-autocomplete {
    display: none;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    box-shadow: 0px 2px 3px 1px #c1c1c1
}

.search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none
}

.search-autocomplete ul:not(:empty) {
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    padding-bottom: 5px
}

.search-autocomplete ul li {
    border-top: 1px solid #e5e5e5;
    cursor: pointer;
    margin: 0;
    padding: 5px 50px 5px 20px;
    position: relative;
    text-align: left;
    white-space: normal
}

.search-autocomplete ul li:first-child {
    border-top: none
}

.search-autocomplete ul li:hover,
.search-autocomplete ul li.selected {
    background: #e8e8e8
}

.search-autocomplete ul li .amount {
    color: #999;
    position: absolute
}

.form.search.advanced .fields.range .field:first-child {
    position: relative
}

.form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px
}

.form.search.advanced .fields.range .field:first-child .control:after {
    content: ' \2013 ';
    display: inline-block;
    position: absolute;
    right: 0;
    text-align: center;
    top: 6px;
    width: 25px
}

.form.search.advanced .fields.range .field:last-child {
    position: relative
}

.form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px
}

.form.search.advanced .fields.range .field.with-addon .control {
    padding-right: 45px
}

.form.search.advanced .group.price .addon .addafter {
    background: none;
    border: 0;
    padding-top: 6px;
    position: absolute;
    right: 0;
    top: 0
}

.search-terms {
    line-height: 2em
}

.search-terms>li {
    display: inline-block;
    margin-right: 10px
}

.search.found {
    margin-bottom: 10px
}

.login-container {
    display: flex;
    background: #fff
}

.login-container-right {
    text-align: center
}
.page-wrapper.route-login #maincontent {
    background-image: url('../images/dougnewbg.png');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.login-container .login-cover {
    background-image: url('../images/dougnewbg.png');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%
}

.login-container .block-new-customer .actions-toolbar {
    margin-top: 25px
}

.login-container .fieldset:after {
    margin-top: 35px
}

.login-container .fieldset>.field:not(.note) {
    text-align: left;
    max-width: 320px;
    margin: 20px auto 0
}

.login-container .actions-toolbar {
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    margin: 20px auto
}

.login-container .actions-toolbar:before,
.login-container .actions-toolbar:after {
    display: none
}

.login-container .actions-toolbar .primary .action {
    margin: 0
}

.login-container .logo {
    width: 150px;
    margin: 20px auto
}

.login-container h1 {
    margin-bottom: 40px
}

.login-container .aw-ca-creat-wrapper {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #ccc
}

.login-container .aw-ca-creat-wrapper h2 {
    margin: 0 0 20px
}

.login-container .aw-ca-creat-wrapper .actions-toolbar {
    justify-content: center
}

.block-addresses-list .items.addresses>.item {
    margin-bottom: 20px
}

.block-addresses-list .items.addresses>.item:last-child {
    margin-bottom: 0
}

.block-addresses-list .items.addresses .item.actions {
    margin-top: 5px
}

.form-address-edit .region_id {
    display: none
}

.form-edit-account .fieldset.password {
    display: none
}

.form-create-account .fieldset-fullname .fields .field {
    float: none
}

.form-create-account .fieldset.create.account:after {
    margin: 10px 0 0;
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    word-spacing: normal;
    color: #e02b27;
    font-size: 1.2rem
}

.form-create-account .fieldset.create.account:after {
    margin-top: 35px
}

.form.password.forget .fieldset:after {
    margin: 10px 0 0;
    content: attr(data-hasrequired);
    display: block;
    letter-spacing: normal;
    word-spacing: normal;
    color: #e02b27;
    font-size: 1.2rem
}

.form.password.forget .fieldset:after {
    margin-top: 35px
}

.account .columns>.column.main {
    order: 1
}

.account .columns>.sidebar.sidebar-main {
    order: 0
}

.account .page-title-wrapper .page-title {
    display: inline-block
}

.account .messages {
    margin-bottom: 20px
}

.account .column.main {
    margin-bottom: 30px
}

.account .column.main h2 {
    margin-top: 0;
    margin-bottom: 10px
}

.account .column.main h3.box-title {
    margin-top: 0;
    margin-bottom: 5px
}

.account .sidebar-additional {
    margin-top: 40px
}

.account .table-wrapper {
    margin-bottom: 0;
    overflow-x: auto
}

.account .table-wrapper:last-child {
    margin-bottom: 0
}

.account .legend {
    font-size: 2.1rem;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
    border: 0
}

.account .data.table .col.actions .action.delete,
[class^='sales-guest-'] .data.table .col.actions .action.delete,
.sales-guest-view .data.table .col.actions .action.delete {
    color: #d10029
}

.column.main .block:last-child {
    margin-bottom: 0
}

.block .title {
    display: block;
    margin-bottom: 10px
}

.block .title strong {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem
}

.block p:last-child {
    margin: 0
}

.block .box-actions {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: -5px;
    margin-right: -5px
}

.block .box-actions .action {
    margin: 5px
}

.block .address-actions,
.block .orders-actions {
    margin: 10px 0;
    text-align: right
}

.field.password .control {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.field.password .control .mage-error {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2
}

.field.password .control .input-text {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
    z-index: 2
}

.password-strength-meter {
    background-color: #f4f4f4;
    height: 32px;
    line-height: 32px;
    padding: 0 9px;
    position: relative;
    z-index: 1
}

.password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1
}

.password-none .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%
}

.password-weak .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%
}

.password-medium .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%
}

.password-strong .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%
}

.password-very-strong .password-strength-meter:before {
    background-color: #81b562;
    width: 100%
}

.control.captcha-image {
    margin-top: 10px
}

.control.captcha-image .captcha-img {
    vertical-align: middle
}

.order-products-toolbar,
.customer-addresses-toolbar {
    position: relative
}

.order-products-toolbar .toolbar-amount,
.customer-addresses-toolbar .toolbar-amount {
    position: relative;
    text-align: center
}

.order-products-toolbar .pages,
.customer-addresses-toolbar .pages {
    position: relative
}

.order-products-toolbar .pager,
.customer-addresses-toolbar .pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center
}

.order-products-toolbar .pager .limiter,
.customer-addresses-toolbar .pager .limiter {
    order: 0
}

.order-products-toolbar .pager .pages,
.customer-addresses-toolbar .pager .pages {
    order: 1
}

.order-products-toolbar .pager .limiter,
.customer-addresses-toolbar .pager .limiter,
.order-products-toolbar .pager .pages,
.customer-addresses-toolbar .pager .pages {
    margin: 0
}

.filter-title strong {
    background-color: #f0f0f0;
    box-shadow: inset 0 1px 0 0 #fff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
    border: 1px solid #ccc;
    border-radius: 3px;
    font-weight: 400;
    left: 0;
    line-height: 16px;
    padding: 7px 10px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 2
}

.filter-title strong[data-count]:after {
    color: #fff;
    background: #4a4a4a;
    border-radius: 2px;
    content: attr(data-count);
    display: inline-block;
    font-size: .8em;
    line-height: 1;
    margin: 0 5px;
    min-width: 1em;
    padding: 2px
}

.filter-title strong.disabled {
    opacity: .5
}

.filter .block-subtitle {
    font-size: 26px;
    margin: 0 0 20px
}

.filter-subtitle {
    display: none
}

.filter-current {
    margin: 0
}

.filter-current .items {
    padding: 5px 10px
}

.filter-current .item {
    padding-left: 17px;
    position: relative;
    z-index: 1
}

.filter .filter-current-subtitle {
    border: none;
    display: block;
    padding-bottom: 10px
}

.filter-current .action.remove {
    left: -2px;
    position: absolute;
    top: -1px
}

.filter-actions {
    margin-bottom: 25px;
    padding: 0 10px
}

.filter-label {
    font-weight: 700
}

.filter-label:after {
    content: ': '
}

.filter-value {
    color: #757575
}

.filter-options {
    display: none;
    margin: 0
}

.filter-options-item {
    padding-bottom: 10px
}

.filter-options-title {
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding: 10px 30px 0 10px;
    position: relative;
    z-index: 1;
    display: block;
    text-decoration: none
}

.filter-options-title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    line-height: inherit;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.filter-options-title:after {
    position: absolute;
    right: 13px;
    top: 12px;
    color: #e62645;
}

.filter-options-title:hover {
    color: #000
}

.active>.filter-options-title:after {
    content: '\f077'
}

.filter-options-content {
    margin: 0;
    padding: 10px
}

.filter-options-content .item {
    line-height: 1.5em;
    margin: 10px 0
}

.filter-options-content a {
    color: #494949;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 7px
}

.filter-options-content a:hover {
    background-color: #e8e8e8;
    text-decoration: none
}

.filter-options-content a:hover+.count {
    background-color: #e8e8e8
}

.filter-options-content .count {
    color: #757575;
    font-weight: 300;
    padding-left: 5px;
    padding-right: 5px
}

.filter-options-content .count:before {
    content: '('
}

.filter-options-content .count:after {
    content: ')'
}

.order-links {
    list-style: none;
    padding: 0
}

.order-links .item {
    line-height: 40px;
    margin: 0 0 -1px
}

.order-links .item strong {
    background: #fff;
    border: 1px solid #ccc;
    display: block;
    font-weight: 400;
    padding: 0 20px
}

.order-links .item a {
    background: #f6f6f6;
    color: #6d6d6d;
    border: 1px solid #ccc;
    display: block;
    font-weight: 400;
    padding: 0 20px
}

.order-links .item a:hover {
    background: #fff;
    text-decoration: none
}

.order-details-items {
    border: 1px solid #ccc;
    margin-bottom: 40px;
    padding: 10px
}

.order-details-items .order-title {
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px
}

.order-details-items .order-title>h2 {
    display: inline-block
}

.order-details-items .order-title .action {
    display: inline-block
}

.order-details-items .table-wrapper {
    margin: 0
}

.order-details-items .table-wrapper+.actions-toolbar {
    display: none
}

.order-details-items .table-order-items>thead>tr>th {
    border-bottom: 1px solid #ccc
}

.order-details-items .table-order-items tbody+tbody {
    border-top: 1px solid #ccc
}

.account .order-details-items .table-order-items tbody tr:nth-child(even) td {
    background: none
}

.order-details-items .table-order-items tbody td {
    padding-bottom: 20px;
    padding-top: 20px
}

.order-details-items .table-order-items tbody .col.label {
    font-weight: 700;
    padding-bottom: 5px;
    padding-top: 0
}

.order-details-items .table-order-items tbody .col.options {
    padding: 10px 10px 15px
}

.order-details-items .table-order-items tbody>.item-parent td {
    padding-bottom: 5px
}

.order-details-items .table-order-items tbody>.item-parent+tr td {
    padding-top: 5px
}

.order-details-items .table-order-items tbody .item-options-container td {
    padding-bottom: 15px;
    padding-top: 0
}

.order-details-items .table-order-items .product-item-name {
    margin: 0 0 10px
}

.order-details-items .item-options {
    margin: 0
}

.order-details-items .item-options dt {
    margin: 0
}

.order-details-items .item-options dd {
    margin: 0 0 15px
}

.order-details-items .item-options dd:last-child {
    margin-bottom: 0
}

.order-details-items .item-options.links dt {
    display: inline-block
}

.order-details-items .item-options.links dt:after {
    content: ': '
}

.order-details-items .item-options.links dd {
    margin: 0
}

.order-details-items thead .col.qty {
    text-align: center
}

.order-details-items .col.price .price-including-tax,
.order-details-items .col.subtotal .price-including-tax,
.order-details-items .col.price .price-excluding-tax,
.order-details-items .col.subtotal .price-excluding-tax {
    font-size: 1.6rem
}

.order-details-items .col.name {
    padding-top: 16px
}

.order-details-items .action.show {
    display: inline-block;
    text-decoration: none;
    color: #333
}

.order-details-items .action.show>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.order-details-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.order-details-items .action.show.expanded:after {
    content: '\f077'
}

.order-details-items .product-item-name {
    font-size: 1.6rem
}

.order-details-items .items-qty .item {
    white-space: nowrap
}

.order-details-items .items-qty .title:after {
    content: ': '
}

.block-order-details-comments {
    margin: 0 0 40px
}

.block-order-details-comments .comment-date {
    font-weight: 600
}

.block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px
}

.block-order-details-view .block-content .box {
    margin-bottom: 30px
}

.block-order-details-view .box-title {
    font-weight: 600
}

.block-order-details-view .box-content .payment-method .title {
    font-weight: 400
}

.block-order-details-view .box-content .payment-method .content {
    margin: 0
}

.block-order-details-view .box-content .payment-method .content>strong {
    font-weight: 400
}

.block-order-details-view .box-content .payment-method .content>strong:after {
    content: ': '
}

.column.main .order-details-items .block-order-details-gift-message {
    margin: 40px 0 0
}

.block-order-details-gift-message+.actions-toolbar {
    display: none
}

.order-tracking {
    margin: 0;
    padding: 20px 0
}

.order-title+.order-tracking {
    border-bottom: 1px solid #ccc
}

.order-tracking .tracking-title {
    display: inline-block
}

.order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px
}

.order-actions-toolbar {
    margin-bottom: 25px
}

.order-actions-toolbar .action {
    margin-right: 30px
}

.order-actions-toolbar .action.print {
    display: none;
    margin: 0
}

.form-orders-search .field:last-child {
    margin-bottom: 20px
}

.block-reorder .actions-toolbar {
    margin: 17px 0
}

.block-reorder .product-item-name {
    float: left;
    width: calc(80%)
}

.block-reorder .product-item::after {
    clear: both;
    content: '';
    display: table
}

.block-reorder .product-item .field.item {
    float: left;
    width: 20px
}

.opc-wrapper .form-discount .actions-toolbar>.primary {
    float: right
}

.block-collapsible-nav .content {
    background: #f2f2f2;
    padding: 15px 0
}

.block-collapsible-nav .items {
    margin: 0;
    padding: 0;
    list-style: none none
}

.block-collapsible-nav .item {
    margin: 3px 0 0
}

.block-collapsible-nav .item:first-child {
    margin-top: 0
}

.block-collapsible-nav .item a,
.block-collapsible-nav .item>strong {
    border-left: 3px solid transparent;
    color: #575757;
    display: block;
    padding: 5px 18px 5px 15px
}

.block-collapsible-nav .item a {
    text-decoration: none
}

.block-collapsible-nav .item a:hover {
    background: #e8e8e8
}

.block-collapsible-nav .item.current a,
.block-collapsible-nav .item.current>strong {
    border-color: #e62645;
    color: #000;
    font-weight: 600
}

.block-collapsible-nav .item.current a {
    border-color: #e62645;
}

.block-collapsible-nav .item .delimiter {
    border-top: 1px solid #d1d1d1;
    display: block;
    margin: 10px 1.8rem
}

body {
    background-color: #fff
}

.page-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    height: 0
}

.page-main {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    flex-shrink: 0
}

.page-main>.page-title-wrapper .page-title {
    hyphens: auto
}

.page-main>.page-title-wrapper .page-title+.action {
    margin-top: 30px
}

.page-header .panel.wrapper {
    background-color: #6e716e;
    color: #fff
}

.page-header .switcher .options ul.dropdown {
    right: 0
}

.page-header .switcher .options ul.dropdown:before {
    left: auto;
    right: 10px
}

.page-header .switcher .options ul.dropdown:after {
    left: auto;
    right: 9px
}

.header.content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    position: relative;
    align-items: center
}

.header.content .header-left,
.header.content .header-right {
    padding-bottom: 10px
}

.header.content .header-left {
    order: 1
}

.header.content .header-right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.header.content .header-search {
    order: 3
}

.links {
    margin: 0;
    padding: 0;
    list-style: none
}

.links>li {
    padding: 0;
    margin: 0
}

.customer-name {
    display: none
}

.logo {
    display: block
}

.logo img {
    display: block
}

.page-print .logo {
    display: inline-block;
    float: none
}

.page-print .nav-toggle {
    display: none
}

.action.skip:focus {
    background: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15
}

.action-skip-wrapper {
    height: 0;
    position: relative
}

.message.global p {
    margin: 0
}

.message.global.noscript,
.message.global.cookie {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: #fafafa;
    border-color: #d6ca8e;
    color: #333;
    padding: 30px 20px;
    margin: 0
}

.message.global.noscript a,
.message.global.cookie a {
    color: #b74f0e
}

.message.global.noscript a:hover,
.message.global.cookie a:hover {
    color: #b74f0e
}

.message.global.noscript a:active,
.message.global.cookie a:active {
    color: #b74f0e
}

.message.global.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;
    text-align: center
}

.message.global.cookie .actions {
    margin-top: 10px
}

.message.global.cookie .actions .action {
    padding: 10px 15px
}

.message.global.demo {
    margin: 0 0 10px;
    padding: 12px 20px 12px 25px;
    display: block;
    font-weight: 700;
    font-size: 1.3rem;
    border: 1px solid;
    background: #ff0101;
    border-color: none;
    color: #fff;
    margin-bottom: 0;
    text-align: center
}

.message.global.demo a {
    color: #b74f0e
}

.message.global.demo a:hover {
    color: #b74f0e
}

.message.global.demo a:active {
    color: #b74f0e
}

.page-footer {
    margin-top: 25px;
    position: relative
}

.page-footer.footer-has-drawer .footer .footer-bottom.footer-row {
    padding-bottom: 0
}

.footer {
    color: #575757;
    margin-left: calc(env(safe-area-inset-left) * -1);
    margin-right: calc(env(safe-area-inset-right) * -1)
}

.footer-top,
.footer-row {
    display: flex
}

.footer-row {
    background-color: #f2f2f2;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    flex-grow: 1
}

.footer-bottom.footer-row {
    padding-bottom: env(safe-area-inset-bottom)
}

.footer-logo p {
    margin: 0;
    font-size: 12px
}

.footer-logo .fluorochem-logo {
    width: 100px;
    display: block
}

.footer .start-tour {
    background: #e0e1e2;
    color: #b74f0e;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    font-weight: 700;
    padding: 10px
}

.footer .start-tour:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    line-height: inherit;
    color: #333;
    content: '\f279';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.footer .start-tour:hover:before {
    color: #333
}

.footer .start-tour:active:before {
    color: #333
}

.footer .start-tour:visited {
    color: #b74f0e
}

.footer .start-tour:focus,
.footer .start-tour:hover {
    background: #cbcdce;
    border: 0;
    color: #b74f0e
}

.footer .start-tour:active {
    background: #bec0c2;
    border: 0;
    color: #b74f0e
}

.footer .start-tour.disabled,
.footer .start-tour[disabled],
fieldset[disabled] .footer .start-tour {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.footer .start-tour:focus,
.footer .start-tour:hover {
    background: none;
    border: none
}

.footer .start-tour:active {
    background: none;
    border: none
}

.footer .start-tour.disabled,
.footer .start-tour[disabled],
fieldset[disabled] .footer .start-tour {
    pointer-events: none;
    opacity: .5
}

.footer .start-tour:hover,
.footer .start-tour:focus,
.footer .start-tour:active {
    text-decoration: underline
}

.footer .start-tour:before {
    font-weight: normal;
    margin-right: 3px
}

.footer-links {
    margin: 0;
    padding: 0;
    list-style: none none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 0
}

.footer-links>li {
    margin: 0
}

.footer-links>li a,
.footer-links>li span {
    color: #575757;
    text-decoration: none;
    position: relative;
    padding: 5px 10px;
    display: inline-block
}

.footer-links>li a:visited,
.footer-links>li span:visited {
    color: #575757;
    text-decoration: none
}

.footer-links>li a:hover,
.footer-links>li span:hover {
    color: #333;
    text-decoration: underline
}

.footer-links>li a:active,
.footer-links>li span:active {
    color: #333;
    text-decoration: underline
}

.footer-links>li a.active,
.footer-links>li span.active {
    text-decoration: none;
    font-weight: 700
}

.footer-links>li a.active,
.footer-links>li span.active,
.footer-links>li a.active:focus,
.footer-links>li span.active:focus,
.footer-links>li a.active:hover,
.footer-links>li span.active:hover,
.footer-links>li a.active:active,
.footer-links>li span.active:active {
    color: #575757
}

.copyright {
    padding: 10px
}

.copyright p {
    margin: 0
}

.page-header .switcher .options,
.page-footer .switcher .options {
    display: inline-block;
    position: relative
}

.page-header .switcher .options:before,
.page-footer .switcher .options:before,
.page-header .switcher .options:after,
.page-footer .switcher .options:after {
    content: '';
    display: table
}

.page-header .switcher .options:after,
.page-footer .switcher .options:after {
    clear: both
}

.page-header .switcher .options .action.toggle,
.page-footer .switcher .options .action.toggle {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none
}

.page-header .switcher .options .action.toggle>span,
.page-footer .switcher .options .action.toggle>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.page-header .switcher .options .action.toggle:after,
.page-footer .switcher .options .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 22px;
    color: inherit;
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.page-header .switcher .options .action.toggle:hover:after,
.page-footer .switcher .options .action.toggle:hover:after {
    color: inherit
}

.page-header .switcher .options .action.toggle:active:after,
.page-footer .switcher .options .action.toggle:active:after {
    color: inherit
}

.page-header .switcher .options .action.toggle.active,
.page-footer .switcher .options .action.toggle.active {
    display: inline-block;
    text-decoration: none
}

.page-header .switcher .options .action.toggle.active>span,
.page-footer .switcher .options .action.toggle.active>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.page-header .switcher .options .action.toggle.active:after,
.page-footer .switcher .options .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 22px;
    color: inherit;
    content: '\f077';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.page-header .switcher .options .action.toggle.active:hover:after,
.page-footer .switcher .options .action.toggle.active:hover:after {
    color: inherit
}

.page-header .switcher .options .action.toggle.active:active:after,
.page-footer .switcher .options .action.toggle.active:active:after {
    color: inherit
}

.page-header .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 160px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15)
}

.page-header .switcher .options ul.dropdown li,
.page-footer .switcher .options ul.dropdown li {
    margin: 0;
    padding: 0
}

.page-header .switcher .options ul.dropdown li:hover,
.page-footer .switcher .options ul.dropdown li:hover {
    background: #e8e8e8;
    cursor: pointer
}

.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before,
.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0
}

.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    border: 6px solid;
    border-color: transparent transparent #fff transparent;
    z-index: 99
}

.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    border: 7px solid;
    border-color: transparent transparent #bbb transparent;
    z-index: 98
}

.page-header .switcher .options ul.dropdown:before,
.page-footer .switcher .options ul.dropdown:before {
    left: 10px;
    top: -12px
}

.page-header .switcher .options ul.dropdown:after,
.page-footer .switcher .options ul.dropdown:after {
    left: 9px;
    top: -14px
}

.page-header .switcher .options.active,
.page-footer .switcher .options.active {
    overflow: visible
}

.page-header .switcher .options.active ul.dropdown,
.page-footer .switcher .options.active ul.dropdown {
    display: block
}

.page-header .switcher .options ul.dropdown a,
.page-footer .switcher .options ul.dropdown a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 8px
}

.page-header .switcher .options ul.dropdown a:visited,
.page-footer .switcher .options ul.dropdown a:visited {
    color: #333;
    text-decoration: none
}

.page-header .switcher .options ul.dropdown a:hover,
.page-footer .switcher .options ul.dropdown a:hover {
    color: #333;
    text-decoration: none
}

.page-header .switcher .options ul.dropdown a:active,
.page-footer .switcher .options ul.dropdown a:active {
    color: #333;
    text-decoration: none
}

.page-header .switcher strong,
.page-footer .switcher strong {
    font-weight: 400
}

.page-header .widget.block,
.page-footer .widget.block {
    margin: 20px 0
}

.page-header .links .widget.block,
.page-footer .links .widget.block {
    margin: 0
}

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
    margin-bottom: 40px
}

.widget {
    clear: both
}

.ui-datepicker td {
    padding: 0
}

.ui-tooltip {
    background: #fff;
    border: 1px solid #bbb;
    padding: 10px
}

.block-banners .banner-item,
.block-banners-inline .banner-item {
    display: block
}

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
    margin-bottom: 20px
}

.block-banners .banner-item-content img,
.block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto
}

.block-product-link.widget,
.block-category-link.widget {
    display: block;
    margin-bottom: 20px
}

.block-product-link-inline.widget {
    margin: 0
}

.block.widget .products-grid .product-item {
    margin-left: 2%;
    width: calc((100% - 2%)/2)
}

.block.widget .products-grid .product-item:nth-child(2n + 1) {
    margin-left: 0
}

.block.widget .product-item-info {
    width: auto
}

.block.widget .pager {
    padding: 0
}

.block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem
}

.block.widget .pager .pages-item-previous {
    padding-left: 0
}

.block.widget .pager .pages-item-next {
    position: relative
}

.block.widget .pager .items {
    white-space: nowrap
}

.columns .block-event {
    position: relative;
    z-index: 2
}

.block-event .block-content {
    position: relative
}

.block-event .block-content>.action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3
}

.block-event .block-content>.action.backward,
.block-event .block-content>.action.forward {
    opacity: .5;
    overflow: hidden;
    width: 20px;
    display: inline-block;
    text-decoration: none
}

.block-event .block-content>.action.backward>span,
.block-event .block-content>.action.forward>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.block-event .block-content>.action.backward:before,
.block-event .block-content>.action.forward:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 55px;
    line-height: 30px;
    color: inherit;
    content: '\f104';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.block-event .block-content>.action.backward:before,
.block-event .block-content>.action.forward:before {
    margin-left: -15px
}

.block-event .block-content>.action.backward {
    left: 0
}

.block-event .block-content>.action.forward {
    right: 0
}

.block-event .block-content>.action.forward:before {
    content: '\f105'
}

.block-event .block-content>.action.forward:before {
    margin-left: -20px
}

.block-event .block-content>.action.inactive {
    cursor: default;
    display: none
}

.block-event .block-content>.action:hover {
    opacity: 1
}

.block-event .slider-panel {
    overflow: hidden;
    position: relative;
    z-index: 2
}

.block-event .slider-panel .slider {
    white-space: nowrap
}

.block-event .slider-panel .slider .item {
    background: #f0f0f0;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    white-space: normal;
    width: 16.35%
}

.sidebar-main .block-event .slider-panel .slider .item,
.sidebar-additional .block-event .slider-panel .slider .item {
    width: 100%
}

.block-event .slider-panel .slider .item img {
    max-width: 100%
}

.block-event .slider-panel .slider .item .category-name {
    display: inline-block;
    font-size: 1.8rem;
    padding: 15px 5px 0;
    word-break: break-all
}

.block-event .slider-panel .slider .item .ticker {
    margin: 0;
    padding: 0;
    list-style: none none
}

.block-event .slider-panel .slider .item .ticker li {
    display: none;
    margin: 0 5px
}

.block-event .slider-panel .slider .item .ticker .value {
    font-size: 2.6rem;
    font-weight: 300
}

.block-event .slider-panel .slider .item .ticker .label {
    display: block;
    font-size: 1.3rem
}

.block-event .slider-panel .slider .item .dates {
    font-weight: 300
}

.block-event .slider-panel .slider .item .dates .start,
.block-event .slider-panel .slider .item .dates .end,
.block-event .slider-panel .slider .item .dates .date {
    display: block
}

.block-event .slider-panel .slider .item .dates .start:after {
    font-size: 1.8rem;
    content: '\2013';
    display: block
}

.block-event .slider-panel .slider .item .dates .date {
    font-size: 1.8rem;
    font-weight: 300
}

.block-event .slider-panel .slider .item .dates .time {
    font-size: 1.3rem
}

.block-event .slider-panel .slider .item .box-event .box-content {
    padding: 5px 0 10px
}

.block-event .slider-panel .slider .item .box-event .box-title {
    display: block;
    font-weight: 300;
    margin: 10px 0 0
}

.block-event .slider-panel .slider .item .action.show {
    display: block;
    padding: 0
}

.block-event .slider-panel .slider .item .action.show .category.name {
    display: inline-block;
    padding: 30px 10px 0
}

.block-event .slider-panel .slider .item .action.show img {
    display: block
}

.block-event .slider-panel .slider .item .action.event {
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    margin-top: 15px
}

.block-event .slider-panel .slider .item .action.event:visited {
    color: #fff
}

.block-event .slider-panel .slider .item .action.event:focus,
.block-event .slider-panel .slider .item .action.event:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.block-event .slider-panel .slider .item .action.event:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.block-event .slider-panel .slider .item .action.event.disabled,
.block-event .slider-panel .slider .item .action.event[disabled],
fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.block-event .slider-panel .slider .item .action.event:hover {
    text-decoration: none
}

.block-static-block.widget,
.block-cms-link.widget {
    margin-bottom: 20px
}

.links .block-static-block.widget,
.links .block-cms-link.widget {
    margin-bottom: 0
}

.block-cms-link-inline.widget {
    margin: 0
}

.block-wishlist-search .form-wishlist-search {
    margin: 20px 0 0
}

.block-cms-hierarchy-link.widget {
    display: block;
    margin-bottom: 20px
}

.block-cms-hierarchy-link-inline.widget {
    margin: 0
}

.sidebar .block-addbysku .fieldset {
    margin: 0
}

.sidebar .block-addbysku .fieldset .fields {
    position: relative
}

.sidebar .block-addbysku .fieldset .fields .field {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top
}

.sidebar .block-addbysku .fieldset .fields .field.sku {
    margin-right: -85px;
    padding-right: 90px;
    width: 100%
}

.sidebar .block-addbysku .fieldset .fields .field.qty .qty+.mage-error {
    width: 80px
}

.sidebar .block-addbysku .fieldset .fields .actions-toolbar {
    position: absolute;
    right: 0;
    top: 7px
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
    margin-bottom: 20px
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
    width: auto
}

.sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left
}

.sidebar .block-addbysku .action.add {
    display: inline-block;
    text-decoration: none;
    line-height: normal;
    padding: 4px;
    width: auto
}

.sidebar .block-addbysku .action.add>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: inherit;
    color: inherit;
    content: '\e61c';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.sidebar .block-addbysku .action.add:hover:before {
    color: inherit
}

.sidebar .block-addbysku .action.add:active:before {
    color: inherit
}

.sidebar .block-addbysku .action.links {
    display: inline-block;
    margin: 20px 0 0
}

.sidebar .block-addbysku .action.reset {
    display: block;
    margin: 10px 0
}

.sidebar .block-addbysku .action.remove {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.sidebar .block-addbysku .action.remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: inherit;
    color: #999;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.sidebar .block-addbysku .action.remove:hover:before {
    color: inherit
}

.sidebar .block-addbysku .action.remove:active:before {
    color: inherit
}

.sidebar .block-addbysku .action.remove:focus,
.sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none
}

.sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none
}

.sidebar .block-addbysku .action.remove.disabled,
.sidebar .block-addbysku .action.remove[disabled],
fieldset[disabled] .sidebar .block-addbysku .action.remove {
    pointer-events: none;
    opacity: .5
}

@media only screen and (max-width:768px) {
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto
    }
}

@media only screen and (max-width:767px) {
    .abs-visually-hidden-mobile-m {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-add-clearfix-mobile-m:before,
    .abs-add-clearfix-mobile-m:after,
    .account .column.main .block:not(.widget) .block-title:before,
    .account .column.main .block:not(.widget) .block-title:after {
        content: '';
        display: table
    }

    .abs-add-clearfix-mobile-m:after,
    .account .column.main .block:not(.widget) .block-title:after {
        clear: both
    }

    .abs-toggling-title-mobile,
    .block-wishlist-management .wishlist-select .wishlist-name,
    .block-collapsible-nav .title {
        border-top: 1px solid #ccc;
        cursor: pointer;
        margin-bottom: 0;
        position: relative;
        border-bottom: 1px solid #ccc;
        padding: 10px 40px 10px 15px;
        display: block;
        text-decoration: none
    }

    .block-wishlist-management .wishlist-select .wishlist-name:after,
    .block-collapsible-nav .title:after {
        position: absolute;
        right: 20px;
        top: 10px
    }

    .abs-toggling-title-mobile:after,
    .block-wishlist-management .wishlist-select .wishlist-name:after,
    .block-collapsible-nav .title:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 12px;
        line-height: inherit;
        color: inherit;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        margin: 3px 0 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .abs-toggling-title-mobile.active:after,
    .block-wishlist-management .wishlist-select .wishlist-name.active:after,
    .block-collapsible-nav .title.active:after {
        content: '\f077'
    }

    .abs-icon-add-mobile,
    .block-wishlist-management .wishlist-add.item .add {
        display: block;
        text-decoration: none
    }

    .abs-icon-add-mobile:before,
    .block-wishlist-management .wishlist-add.item .add:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 10px;
        color: inherit;
        content: '\e61c';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 5px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .abs-checkout-tooltip-content-position-top-mobile,
    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto
    }

    .field-tooltip .field-tooltip-content:before,
    .field-tooltip .field-tooltip-content:after {
        border: 10px solid transparent;
        height: 0;
        width: 0;
        margin-top: -21px;
        right: 10px;
        left: auto;
        top: 0
    }

    .field-tooltip .field-tooltip-content:before {
        border-bottom-color: #666
    }

    .field-tooltip .field-tooltip-content:after {
        border-bottom-color: #f4f4f4;
        top: 1px
    }

    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-main,
    .page-wrapper>.widget,
    .page-wrapper>.page-bottom,
    .block.category.event,
    .top-container {
        padding-left: 15px;
        padding-right: 15px
    }

    .account .sidebar-main,
    .cms-privacy-policy .sidebar-main {
        min-height: 41px;
        margin-bottom: 15px
    }

    .table-wrapper {
        border-top: 1px solid #ccc;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        position: relative
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) {
        border: none;
        display: block
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>thead>tr>th {
        display: none
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody {
        display: block
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr {
        display: block
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td,
    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th {
        border-bottom: none;
        display: block;
        padding: 5px 10px
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td[data-th]:before,
    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th[data-th]:before {
        padding-right: 10px;
        content: attr(data-th) ': ';
        display: inline-block;
        color: #111;
        font-weight: 700
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr>th {
        background-color: #f6f6f6
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td:first-child {
        padding-top: 15px
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td:last-child {
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) tbody>tr>td.col.qty {
        text-align: left
    }

    .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
        display: block
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
        padding-top: 20px
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .mark {
        box-sizing: border-box;
        float: left;
        text-align: left;
        width: 70%
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .amount {
        box-sizing: border-box;
        float: left;
        text-align: right;
        width: 30%
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .grand.totals {
        font-size: 1.6rem
    }

    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot .grand.totals .amount {
        padding-bottom: 10px
    }

    .data-table-definition-list thead {
        display: none
    }

    .data-table-definition-list tbody th {
        padding-bottom: 0
    }

    .data-table-definition-list tbody th,
    .data-table-definition-list tbody td {
        display: block;
        padding-left: 0;
        padding-right: 0
    }

    .navigation {
        padding: 0
    }

    .navigation .parent .level-top {
        position: relative;
        display: block;
        text-decoration: none
    }

    .navigation .parent .level-top:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .navigation .parent .level-top:after {
        position: absolute;
        right: 7px;
        top: -8px
    }

    .navigation .parent .level-top.ui-state-active:after {
        content: '\f077'
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        -webkit-transition: left .3s;
        -moz-transition: left .3s;
        -ms-transition: left .3s;
        transition: left .3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - 54px));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - 54px)
    }

    .nav-sections .switcher {
        border-top: 1px solid #d1d1d1;
        font-size: 1.6rem;
        font-weight: 700;
        margin: 0;
        padding: .8rem 3.5rem .8rem 2rem
    }

    .nav-sections .switcher .label {
        display: block;
        margin-bottom: 5px
    }

    .nav-sections .switcher:last-child {
        border-bottom: 1px solid #d1d1d1
    }

    .nav-sections .switcher-trigger strong {
        position: relative;
        display: block;
        text-decoration: none
    }

    .nav-sections .switcher-trigger strong:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 42px;
        line-height: inherit;
        color: inherit;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .nav-sections .switcher-trigger strong:after {
        position: absolute;
        right: -40px;
        top: -16px
    }

    .nav-sections .switcher-trigger.active strong:after {
        content: '\f077'
    }

    .nav-sections .switcher-dropdown {
        margin: 0;
        padding: 0;
        list-style: none none;
        display: none;
        padding: 10px 0
    }

    .nav-sections .switcher-options.active .switcher-dropdown {
        display: block
    }

    .nav-sections .header.links {
        margin: 0;
        padding: 0;
        list-style: none none;
        border-bottom: 1px solid #d1d1d1
    }

    .nav-sections .header.links li {
        font-size: 1.6rem;
        margin: 0
    }

    .nav-sections .header.links li.greet.welcome {
        border-top: 1px solid #d1d1d1;
        font-weight: 700;
        padding: .8rem 20px
    }

    .nav-sections .header.links li>a {
        border-top: 1px solid #d1d1d1
    }

    .nav-sections .header.links a,
    .nav-sections .header.links a:hover {
        color: #575757;
        text-decoration: none;
        display: block;
        font-weight: 700;
        padding: .8rem 20px
    }

    .nav-sections .header.links .header.links {
        border: 0
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%
    }

    .nav-before-open .page-wrapper {
        -webkit-transition: left .3s;
        -moz-transition: left .3s;
        -ms-transition: left .3s;
        transition: left .3s;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative
    }

    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%
    }

    .nav-open .page-wrapper {
        left: 80%;
        left: calc(100% - 54px)
    }

    .nav-open .nav-sections {
        -webkit-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        -ms-box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99
    }

    .nav-open .nav-toggle:after {
        background: rgba(0, 0, 0, 0.7);
        content: '';
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1
    }

    .nav-sections-items {
        position: relative;
        z-index: 1
    }

    .nav-sections-items:before,
    .nav-sections-items:after {
        content: '';
        display: table
    }

    .nav-sections-items:after {
        clear: both
    }

    .nav-sections-item-title {
        background: #e3e3e3;
        border: solid #d7d7d7;
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%
    }

    .nav-sections-item-title.active {
        background: transparent;
        border-bottom: 0
    }

    .nav-sections-item-title .nav-sections-item-switch:hover {
        text-decoration: none
    }

    .nav-sections-item-content {
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;
        padding: 25px 0
    }

    .nav-sections-item-content:before,
    .nav-sections-item-content:after {
        content: '';
        display: table
    }

    .nav-sections-item-content:after {
        clear: both
    }

    .nav-sections-item-content.active {
        display: block
    }

    .navigation {
        background: #f0f0f0;
        box-sizing: border-box
    }

    .navigation ul {
        margin: 0;
        padding: 0
    }

    .navigation li {
        margin: 0
    }

    .navigation a {
        display: block;
        padding-top: 10px;
        padding-right: 0;
        padding-bottom: 10px;
        padding-left: 15px
    }

    .navigation a,
    .navigation a:hover {
        color: #575757;
        text-decoration: none
    }

    .navigation .level0 {
        border-top: 1px solid #d1d1d1;
        font-size: 1.6rem
    }

    .navigation .level0>.level-top {
        font-weight: 700;
        padding: 8px 40px 8px 15px;
        text-transform: uppercase;
        word-wrap: break-word
    }

    .navigation .level0>.level-top:hover {
        color: #333
    }

    .navigation .level0.active .all-category .ui-state-focus {
        border-color: #4a4a4a;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: #333;
        padding-left: 7px;
        display: inline-block
    }

    .navigation .level0>.level1 {
        font-weight: 600
    }

    .navigation .level0.active>a:not(.ui-state-active),
    .navigation .level0.has-active>a:not(.ui-state-active) {
        border-color: #4a4a4a;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: #333
    }

    .navigation .level0.active>a:not(.ui-state-active) span:not(.ui-menu-icon),
    .navigation .level0.has-active>a:not(.ui-state-active) span:not(.ui-menu-icon) {
        margin-left: -8px
    }

    .navigation li.level0:last-child {
        border-bottom: 1px solid #d1d1d1
    }

    .navigation .submenu>li {
        word-wrap: break-word
    }

    .navigation .submenu>li>a:hover {
        color: #333
    }

    .navigation .submenu:not(:first-child) {
        font-weight: 400;
        line-height: 1.3;
        left: auto !important;
        overflow-x: hidden;
        padding: 0;
        position: relative;
        top: auto !important;
        transition: left .3s ease-out
    }

    .navigation .submenu:not(:first-child)>li>a {
        padding-left: 15px
    }

    .navigation .submenu:not(:first-child)>li:last-child {
        margin-bottom: 0
    }

    .navigation .submenu:not(:first-child) ul {
        display: block;
        padding-left: 15px
    }

    .navigation .submenu:not(:first-child) ul>li {
        margin: 0
    }

    .navigation .submenu:not(:first-child) ul>li a {
        color: #575757;
        display: block;
        line-height: normal
    }

    .navigation .submenu:not(:first-child) ul>li a:hover {
        color: #333
    }

    .navigation .submenu:not(:first-child).expanded {
        display: block !important;
        padding-right: 0;
        top: 0 !important
    }

    .navigation .submenu:not(:first-child) .active>a {
        border-color: #4a4a4a;
        border-style: solid;
        border-width: 0 0 0 8px;
        color: #333;
        padding-left: 7px
    }

    .navigation .submenu:not(:first-child) .level1.active>a {
        padding-left: 7px
    }

    .product.data.items .item.title>.switch {
        padding: 1px 15px 1px
    }

    .product.data.items>.item.content {
        padding: 10px 15px 30px
    }

    .breadcrumbs {
        display: none
    }

    .modal-popup.modal-slide {
        left: 44px;
        z-index: 900
    }

    .modal-popup.modal-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .modal-popup.modal-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        width: auto
    }

    .modal-popup.modal-slide .modal-inner-wrap {
        margin: 0;
        max-height: none
    }

    .custom-slide {
        bottom: 0;
        left: 0;
        min-width: 0;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s .3s, opacity .3s ease;
        transition: visibility 0s .3s, opacity .3s ease;
        left: 44px;
        z-index: 900
    }

    .custom-slide._show {
        visibility: visible;
        opacity: 1;
        -webkit-transition: opacity .3s ease;
        transition: opacity .3s ease
    }

    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    .custom-slide .modal-inner-wrap {
        background-color: #fff;
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        opacity: 1;
        pointer-events: auto
    }

    .custom-slide._show .modal-inner-wrap {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    .custom-slide .modal-inner-wrap {
        height: 100%;
        overflow-y: auto;
        position: static;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        width: auto
    }

    .custom-slide._show {
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        overflow-y: auto
    }

    .custom-slide .modal-inner-wrap {
        background-color: #fff;
        box-sizing: border-box;
        height: auto;
        min-height: 100%
    }

    body._has-modal-custom {
        height: 100vh;
        overflow: hidden;
        width: 100vw
    }

    body._has-modal-custom .modal-custom-overlay {
        background-color: rgba(51, 51, 51, 0.55)
    }

    .modal-popup {
        pointer-events: auto
    }

    .modal-popup.modal-slide .modal-inner-wrap[class] {
        background-color: #fff
    }

    .modal-popup.modal-slide._inner-scroll._show {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto
    }

    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
        height: auto;
        min-height: 100%
    }

    .am-porto-cmtb .filter.active .filter-current.filter-current.amshopby-filter-current+.block-actions {
        top: 187px;
        border: none;
        border-radius: inherit;
        background: #fff;
        box-shadow: none
    }

    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current {
        padding: 17px 30px;
        height: 140px
    }

    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current~.filter-options {
        top: 221px
    }

    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current .items {
        padding: 15px;
        border-width: 1px
    }

    .am-porto-cmtb .filter.active .filter-current.amshopby-filter-current .filter-current-subtitle {
        display: block;
        padding: 10px 15px
    }

    .am-porto-cmtb .filter.active .block-category-list .block-title,
    .am-porto-cmtb .filter.active .filter-options-title,
    .am-porto-cmtb .filter.active .filter .filter-current-subtitle,
    .am-porto-cmtb .filter.active .block-category-list .block-content,
    .am-porto-cmtb .filter.active .filter-options-content {
        padding-left: 15px;
        padding-right: 15px
    }

    .am-porto-cmtb .filter.active .filter-options-title:after {
        right: 15px
    }

    .filter-options-content .am-ranges .items.-am-multiselect .item [class*='am-filter-item'] {
        padding-left: 35px
    }

    .filter-options .am-labels-folding .item .am-collapse-icon~[class*='am-filter-item-'],
    .filter-options .am-labels-folding .item {
        padding-left: 35px
    }

    .filter-options .items:not(.items-children).-am-multiselect>.item.-is-by-click .items-children.-folding .item.-is-expanded,
    .filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
    .filter-options .items:not(.items-children)>.item.-is-by-click.-filter-parent~.item.-is-expanded {
        margin-left: 35px
    }

    .filter-options-item .filter-options-content .items [class*='am-filter-item'] {
        padding: 7px 0
    }

    .am-labels-folding .am-collapse-icon,
    .filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .am-collapse-icon,
    .filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .am-collapse-icon {
        margin: 7px 0
    }

    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~.am-input,
    .filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~.am-input,
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='radio'],
    .filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='radio'],
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='checkbox'],
    .filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='checkbox'],
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon~input[type='checkbox']+.amshopby-choice,
    .filter-options .amshopby-category-dropdown+form .item .am-collapse-icon~input[type='checkbox']+.amshopby-choice {
        left: 35px
    }

    .filter-options .items.-am-singleselect .item.-is-by-click .items-children.-folding .item .amshopby-filter-parent,
    .filter-options .amshopby-category-dropdown+form .items.-am-multiselect .item .items-children.-folding,
    .filter-options .am-category-wrapper .items .items-children.-folding,
    .filter-options .am-category-view .item a.amshopby-filter-parent {
        padding-left: 35px
    }

    .filter-options .am-category-view.-am-multiselect .item>.am-collapse-icon~a.amshopby-filter-parent {
        padding-left: 70px
    }

    .filter-options .am-category-view.-am-singleselect .am-collapse-icon {
        margin: 7px 0
    }

    .am-collapse-icon:after,
    .am-show-more:before {
        width: 20px
    }

    .filter-content .active .am-show-more:before {
        width: 12px
    }

    .filter-content .active .am-show-more.-active {
        margin-bottom: 10px
    }

    .amshopby-flyout-block {
        margin: 10px 0 25px
    }

    .amshopby-flyout-block .items.amshopby-fly-out-view .item>.amshopby-choice {
        padding-left: 35px
    }

    .filter-options input[type='checkbox'] {
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute
    }

    .filter-options input[type='checkbox']+.amshopby-choice {
        word-wrap: break-word;
        word-break: break-word;
        position: absolute;
        top: 5px;
        left: 0;
        display: block;
        margin: 0;
        padding: 0 0 0 35px;
        min-height: 25px;
        line-height: 1.2;
        cursor: pointer
    }

    .ie11 .filter-options input[type='checkbox']+.amshopby-choice {
        word-break: break-all
    }

    .filter-options input[type='checkbox']+.amshopby-choice:before,
    .filter-options input[type='checkbox']+.amshopby-choice:after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        border-radius: 2px;
        content: ''
    }

    .filter-options input[type='checkbox']+.amshopby-choice:before {
        border: 1px solid #e3e3e3;
        background: #fff
    }

    .filter-options input[type='checkbox']+.amshopby-choice:after {
        background: #1979c3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center / 25px;
        content: none
    }

    .filter-options input[type='checkbox']+.amshopby-choice:hover:before,
    .filter-options input[type='checkbox']:checked+.amshopby-choice:before {
        border-color: #1979c3
    }

    .filter-options input[type='checkbox']:checked+.amshopby-choice:after {
        content: ''
    }

    ._keyfocus .filter-options input[type='checkbox']:active+.amshopby-choice:before,
    ._keyfocus .filter-options input[type='checkbox']:focus+.amshopby-choice:before,
    .filter-options input[type='checkbox']:active+.amshopby-choice:before,
    .filter-options input[type='checkbox']:focus+.amshopby-choice:before {
        border-color: #1979c3
    }

    .filter-options input[type='checkbox']:not(:checked).mage-error+.amshopby-choice:before {
        border-color: #f23b3b
    }

    .contact-index-index .column:not(.sidebar-main) .form.contact {
        float: none;
        width: 100%
    }

    .contact-index-index .column:not(.sidebar-additional) .form.contact {
        float: none;
        width: 100%
    }

    .block-cart-failed .block-title {
        margin: 0 20px 20px
    }

    #custom-quote-form button {
        margin: 20px auto auto
    }

    .cart-summary {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 15px;
        z-index: 900;
        height: 40px;
        height: calc(60px + env(safe-area-inset-bottom));
        min-height: 40px;
        overflow: hidden;
        transition: .5s ease min-height
    }

    .cart-summary.open {
        min-height: 100%;
        overflow: auto;
        padding-bottom: 20px
    }

    .cart-summary.open .cart-summary-toggle:before {
        transform: rotate(180deg)
    }

    .cart-summary:not(.open) .cart-summary-toggle {
        padding-bottom: 20px;
        padding-bottom: calc(20px + env(safe-area-inset-bottom))
    }

    .cart-summary:not(.open)>div {
        display: none
    }

    .cart-summary-toggle {
        font-size: 20px;
        background-image: none;
        background: #ec6d26;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: block;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0 -15px;
        padding: 20px 15px;
        width: calc(100% + 30px);
        font-size: 2rem;
        line-height: 2rem;
        box-sizing: border-box;
        vertical-align: middle;
        display: inline-block;
        text-decoration: none;
        border-radius: 0
    }

    .cart-summary-toggle>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .cart-summary-toggle:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        content: '\f0d8';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 10px;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .cart-summary-toggle:hover:before {
        color: inherit
    }

    .cart-summary-toggle:active:before {
        color: inherit
    }

    .cart-summary-toggle:visited {
        color: #fff
    }

    .cart-summary-toggle:focus,
    .cart-summary-toggle:hover {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .cart-summary-toggle:active {
        background: #e43866;
        border: 0;
        color: #fff
    }

    .cart-summary-toggle.disabled,
    .cart-summary-toggle[disabled],
    fieldset[disabled] .cart-summary-toggle {
        opacity: .5;
        cursor: default;
        pointer-events: none
    }

    .cart-summary-toggle:before {
        transition: 1s ease transform
    }

    .cart-summary .checkout-methods-items {
        text-align: center;
        padding-top: 40px
    }

    .cart .cart-item:not(:last-child) {
        margin-bottom: 20px
    }

    .cart .parent-item-details {
        order: 1
    }

    .cart .cart-item-buttons {
        min-width: 100%;
        order: 3;
        justify-content: center;
        margin: 10px 0 20px
    }

    .cart .table-columns {
        order: 2
    }

    .cart .table-columns .table-column {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        width: calc(100% + 15px * 2)
    }

    .cart .table-columns .table-column:after {
        min-width: 15px
    }

    .cart .table.items .col.qty {
        text-align: center
    }

    .cart-container .form-cart .actions.main {
        text-align: center
    }

    .cart-discount {
        border-bottom: 1px solid #ccc
    }

    .cart.table-wrapper {
        border-top: 1px solid #ccc
    }

    .cart.table-wrapper thead .col.item,
    .cart.table-wrapper thead .col.qty,
    .cart.table-wrapper thead .col.price,
    .cart.table-wrapper thead .col.subtotal,
    .cart.table-wrapper thead .col.msrp {
        display: none
    }

    .cart.table-wrapper .col.qty,
    .cart.table-wrapper .col.price,
    .cart.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp {
        box-sizing: border-box;
        display: block;
        float: left;
        white-space: nowrap;
        width: 33%
    }

    .cart.table-wrapper .col.qty[data-th]:before,
    .cart.table-wrapper .col.price[data-th]:before,
    .cart.table-wrapper .col.subtotal[data-th]:before,
    .cart.table-wrapper .col.msrp[data-th]:before {
        content: attr(data-th);
        display: block;
        font-weight: 600;
        padding-bottom: 10px
    }

    .cart.table-wrapper .col.msrp {
        white-space: normal
    }

    .cart.table-wrapper .item .col.item {
        padding-bottom: 0
    }

    .cart.table-wrapper tbody>tr>td:last-child {
        border: 0
    }

    .cart-totals {
        padding-left: 15px;
        padding-right: 15px
    }

    .cart-totals .table-wrapper {
        border-top: 0
    }

    .cart-totals .totals tbody>tr:not(:last-child)>td:last-child {
        border: 0
    }

    .cart.table-wrapper .col.price,
    .order-items.table-wrapper .col.price,
    .cart.table-wrapper .col.qty,
    .order-items.table-wrapper .col.qty,
    .cart.table-wrapper .col.subtotal,
    .order-items.table-wrapper .col.subtotal,
    .cart.table-wrapper .col.msrp,
    .order-items.table-wrapper .col.msrp {
        text-align: left
    }

    .minicart-wrapper .action.showcart .text,
    .minicart-wrapper .action.showcart .counter.qty {
        display: none
    }

    .opc-main-wrapper.no-summary ol.opc li {
        margin-bottom: 20px
    }

    .opc-main-wrapper .minicart-items-wrapper {
        max-height: 500px;
        margin: 0 0 20px
    }

    .opc-totals {
        margin-bottom: 20px
    }

    .checkout-index-index .modal-popup .modal-footer .action-save-address {
        width: 100%
    }

    .checkout-index-index .modal-popup .modal-footer .action-hide-popup {
        margin-top: 20px
    }

    .field-tooltip .field-tooltip-content {
        right: -10px;
        top: 40px;
        left: auto
    }

    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address {
        margin: 20px -15px 15px;
        padding: 20px 15px
    }

    .opc-wrapper .form-login+.form-shipping-address {
        margin-top: -15px
    }

    .opc-wrapper .shipping-address-item {
        width: calc(100% - 10px * 2)
    }

    .opc-wrapper .action-select-shipping-item {
        float: none;
        margin-top: 10px;
        width: 100%
    }

    .opc-wrapper .action-show-popup {
        width: 100%
    }

    .opc-wrapper .methods-shipping {
        margin-top: 0
    }

    .opc-wrapper .edit-address-link {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 5px
    }

    .opc-wrapper .edit-address-link>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .opc-wrapper .edit-address-link:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: 20px;
        color: #333;
        content: '\f040';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .opc-wrapper .edit-address-link:hover:before {
        color: #333
    }

    .opc-wrapper .edit-address-link:active:before {
        color: #333
    }

    .opc-block-shipping-information .shipping-information {
        margin-bottom: 20px;
        padding-bottom: 0
    }

    .opc-block-shipping-information .shipping-information .shipping-information-flex {
        margin: 0 -20px
    }

    .opc-block-shipping-information .shipping-information .ship-to,
    .opc-block-shipping-information .shipping-information .ship-via {
        margin: 0 20px 20px;
        width: calc(50% - 20px * 2);
        min-width: 200px;
        max-width: 400px
    }

    .opc-block-shipping-information .shipping-information-title {
        font-size: 2.3rem
    }

    .opc-block-summary .product-item .product-item-details {
        width: 100%
    }

    .opc-block-summary .product-item .product-image-container {
        margin-bottom: 10px
    }

    .popup-authentication.modal-popup.modal-slide {
        left: 0
    }

    .popup-authentication .modal-content {
        padding-left: 15px;
        padding-right: 15px
    }

    .popup-authentication .block-customer-login {
        padding: 0;
        margin: 0
    }

    .block-authentication .aw-ca-creat-wrapper {
        padding-top: 20px
    }

    .checkout-payment-method .payment-methods {
        margin: 0 -15px
    }

    .checkout-payment-method .step-title {
        padding-left: 15px;
        padding-right: 15px
    }

    .checkout-payment-method .payment-method-title {
        padding: 15px
    }

    .checkout-payment-method .payment-method-content {
        padding: 0 15px 0
    }

    .checkout-payment-method .checkout-billing-address .action-cancel {
        margin-top: 10px
    }

    .checkout-payment-method .payment-option .payment-option-title {
        padding: 0 0 10px 0
    }

    .cart-discount .giftcard .content {
        padding-bottom: 35px
    }

    .cart-discount .actions-toolbar .secondary {
        bottom: 17px
    }

    .field.open-amount {
        width: 100%
    }

    .page-product-giftcard .product-info-main .product-info-stock-sku {
        display: block;
        padding-left: 10px;
        text-align: left
    }

    .gift-message .actions-toolbar .action-update {
        font-size: 2rem;
        padding: 15px;
        width: 100%
    }

    .gift-item-block {
        border-top: 1px solid #c1c1c1;
        border-bottom: 0
    }

    .gift-item-block .title:after {
        font-size: 1.2rem;
        position: absolute;
        right: 20px;
        top: 10px
    }

    .gift-item-block .content {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .cart.table-wrapper .gift-content {
        border-top: 1px solid #c1c1c1;
        margin-right: -15px;
        overflow: hidden;
        padding-left: 15px;
        padding-right: 15px
    }

    .cart .action-gift {
        display: inline-block;
        text-decoration: none
    }

    .cart .action-gift>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .cart .action-gift:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: 20px;
        color: #333;
        content: '\e62a';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .cart .action-gift:hover:after {
        color: #333
    }

    .cart .action-gift:active:after {
        color: #333
    }

    .cart .action-gift._active {
        border: 1px solid #c1c1c1;
        border-bottom-color: #fff;
        margin: -5px 14px -1px -15px;
        padding: 4px 10px 9px 14px
    }

    .cart .action-gift._active:after {
        color: #333
    }

    .gift-options-cart-item+.towishlist {
        left: 0;
        position: absolute
    }

    .cart.table-wrapper .action-giftregistry {
        display: inline-block;
        text-decoration: none
    }

    .cart.table-wrapper .action-giftregistry>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .cart.table-wrapper .action-giftregistry:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: 20px;
        color: #333;
        content: '\e62b';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .cart.table-wrapper .action-giftregistry:hover:before {
        color: #333
    }

    .cart.table-wrapper .action-giftregistry:active:before {
        color: #333
    }

    .gift-options-cart-item .gift-wrapping,
    .cart-gift-item .gift-wrapping {
        border-bottom: 1px solid #c1c1c1;
        margin-bottom: 20px;
        padding-bottom: 10px
    }

    .gift-wrapping-list {
        width: 100%
    }

    .gift-wrapping-summary+.gift-message-summary {
        padding-right: 0
    }

    .gift-options-cart-item {
        float: left;
        width: 100%
    }

    .gift-wrapping-preview img {
        width: 100%
    }

    .item-actions .actions-toolbar .gift-options .gift-wrapping,
    .item-actions .actions-toolbar .gift-options .gift-message {
        display: block
    }

    .product-add-form .table-wrapper.grouped {
        margin-left: -15px;
        margin-right: -15px
    }

    .product-add-form .table-wrapper.grouped .table.data.grouped tr td {
        padding: 5px 10px 5px 15px
    }

    .map-popup {
        max-width: 100%
    }

    .map-old-price,
    .map-show-info {
        display: inline-block
    }

    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
        margin-bottom: 10px
    }

    .block-wishlist-management {
        clear: both;
        margin-top: -21px
    }

    .block-wishlist-management .wishlist-select {
        margin: 0 -15px 20px
    }

    .block-wishlist-management .wishlist-select .wishlist-name {
        font-size: 1.6rem
    }

    .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #ccc;
        background: #f2f2f2;
        display: none;
        padding: 15px 0
    }

    .block-wishlist-management .wishlist-select-items.active {
        display: block
    }

    .block-wishlist-management .wishlist-select-items .item {
        margin: 3px 0 0
    }

    .block-wishlist-management .wishlist-select-items .item:first-child {
        margin-top: 0
    }

    .block-wishlist-management .wishlist-select-items .item.current {
        display: none
    }

    .block-wishlist-management .wishlist-select-items .item a {
        color: #333;
        display: block;
        padding: 5px 18px;
        text-decoration: none
    }

    .block-wishlist-management .wishlist-select-items .item a:hover {
        background: #e8e8e8
    }

    .block-wishlist-management .wishlist-title strong {
        font-size: 2.6rem
    }

    .block-wishlist-management .wishlist-info {
        margin-bottom: 15px
    }

    .block-wishlist-management .wishlist-toolbar-select,
    .block-wishlist-management .wishlist-toolbar-actions {
        margin-bottom: 15px
    }

    .products-grid.wishlist .product-item-checkbox {
        left: 0;
        position: absolute;
        top: 20px
    }

    .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
        margin-left: 25px
    }

    .page-multiple-wishlist .products-grid.wishlist .product-item-name,
    .page-multiple-wishlist .products-grid.wishlist .product-item-description,
    .page-multiple-wishlist .products-grid.wishlist .product-item .price-box,
    .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
        margin-left: 115px
    }

    .products-grid.wishlist .wishlist-dropdown {
        display: none
    }

    .multishipping-checkout-success .nav-toggle {
        display: block
    }

    .multishipping-checkout-success .logo {
        margin-left: 40px
    }

    .multicheckout .actions-toolbar>.primary {
        margin-right: 0
    }

    .block.newsletter input {
        font-size: 12px;
        padding-left: 30px
    }

    .block.newsletter .field .control:before {
        font-size: 13px
    }

    .form.send.friend .fieldset {
        padding-bottom: 5px
    }

    .form.send.friend .action.remove {
        margin-left: 0;
        right: 0;
        top: 100%
    }

    .products-grid.wishlist {
        margin-bottom: 30px;
        margin-right: 0
    }

    .products-grid.wishlist .product-item {
        padding: 20px 0 20px 0;
        position: relative
    }

    .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px
    }

    .products-grid.wishlist .product-item-name {
        font-size: 1.6rem
    }

    .products-grid.wishlist .product-item-actions {
        display: block;
        float: left
    }

    .products-grid.wishlist .product-item-actions .action {
        margin-right: 15px
    }

    .products-grid.wishlist .product-item-actions .action:last-child {
        margin-right: 0
    }

    .products-grid.wishlist .product-item-actions .action.edit,
    .products-grid.wishlist .product-item-actions .action.delete {
        margin-top: 7px
    }

    .products-grid.wishlist .product-item-name,
    .products-grid.wishlist .product-item-description,
    .products-grid.wishlist .product-item .price-box,
    .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px
    }

    .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px
    }

    .products-grid.wishlist .product-item .box-tocart .stock {
        margin-top: 7px
    }

    .products-grid.wishlist .product-item .giftregisty-dropdown,
    .products-grid.wishlist .product-item .field.qty {
        display: none
    }

    .products-grid.wishlist .product-image-container {
        max-width: 80px
    }

    .wishlist-index-index .product-item {
        width: 100%
    }

    .wishlist-index-index .product-item-info {
        width: auto
    }

    .cart.table-wrapper .towishlist {
        display: inline-block;
        text-decoration: none
    }

    .cart.table-wrapper .towishlist>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .cart.table-wrapper .towishlist:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: 20px;
        color: #333;
        content: '\e600';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .cart.table-wrapper .towishlist:hover:before {
        color: #333
    }

    .cart.table-wrapper .towishlist:active:before {
        color: #333
    }

    .cart.table-wrapper .towishlist:before {
        overflow: visible
    }

    .aw-ca-company-create .aw-ca__form .fieldset-wrapper {
        margin-bottom: 20px
    }

    .aw-ca-company-create .aw-ca__form .fieldset-wrapper .fieldset>.field {
        width: 100%
    }

    .product-items {
        margin-left: -15px;
        margin-right: -15px
    }

    .product-item {
        padding: 0 15px 15px;
        width: 50%
    }

    .product-item-actions .action {
        width: 100%;
        max-width: 130px
    }

    .product-item-actions .action,
    .product-item-actions .action.view {
        margin: 5px 0
    }

    .catalog-product-view .column.main {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .catalog-product-view .product.media {
        -ms-flex-order: -1;
        -webkit-order: -1;
        order: -1
    }

    .product-content-table tr th,
    .product-content-table tr td {
        padding: 10px
    }

    .product-content-table tr th {
        width: 100px
    }

    .product-info-top {
        flex-wrap: wrap
    }

    .product-info-top>div {
        width: 100%
    }

    .compare.wrapper,
    [class*='block-compare'] {
        display: none
    }

    .catalog-product_compare-index .columns .column.main {
        flex-basis: inherit
    }

    .block-search .search-tooltip {
        left: 20px
    }

    .block-search .search-tooltip>a {
        display: inline-block;
        text-decoration: none
    }

    .block-search .search-tooltip>a>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .block-search .search-tooltip>a:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 32px;
        color: #333;
        content: '\f05a';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .block-search .search-tooltip>a:hover:before {
        color: #333
    }

    .block-search .search-tooltip>a:active:before {
        color: #333
    }

    .block-search .search-tooltip>a:before {
        font-weight: bold
    }

    .block-search input {
        padding-left: 32px;
        border-radius: 16px
    }

    .block-search .action.search {
        display: inline-block;
        text-decoration: none
    }

    .block-search .action.search>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 32px;
        color: #e62645;
        content: '\f002';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .block-search .action.search:hover:before {
        color: #9b440e
    }

    .block-search .action.search:active:before {
        color: #e43866;
    }

    .block-search .action.search:before {
        font-weight: bold
    }

    .search-links {
        text-align: center
    }

    .search-autocomplete,
    .search-autocomplete ul:not(:empty) {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px
    }

    .search-autocomplete ul li .amount {
        right: 7px;
        top: 5px
    }

    .login-container {
        flex-wrap: wrap
    }

    .login-container-left,
    .login-container-right {
        width: 100%
    }

    .login-container .fieldset:after {
        text-align: center
    }

    .account .messages {
        margin-bottom: 0
    }

    .account .column.main .block:not(.widget) {
        margin-bottom: 20px
    }

    .account .column.main .block:not(.widget) .block-title>.action {
        float: right;
        margin-top: 5px
    }

    .account .column.main .block:not(.widget) .block-content .box {
        margin-bottom: 20px
    }

    .account .column.main .block:not(.widget) .block-content .box:last-child {
        margin-bottom: 0
    }

    .block-dashboard-addresses .block-addresses {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px
    }

    .block-dashboard-addresses .block-addresses>.box {
        flex-grow: 1;
        padding-left: 15px;
        padding-right: 15px
    }

    .control.captcha-image .captcha-img {
        display: block;
        margin-bottom: 10px
    }

    .customer-account-index .page-title-wrapper {
        position: relative
    }

    .form.search.advanced .field.price .with-addon .input-text {
        flex-basis: auto;
        width: 100%
    }

    .order-products-toolbar .pager .pages,
    .customer-addresses-toolbar .pager .pages,
    .order-products-toolbar .pager .limiter,
    .customer-addresses-toolbar .pager .limiter {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #ccc
    }

    body.filter-active .page-header {
        display: none
    }

    body.filter-active .page-wrapper {
        height: 0;
        margin-top: -999999em;
        visibility: hidden
    }

    body.filter-active .columns {
        z-index: 999
    }

    .layered-filter-block {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 20px
    }

    .layered-filter-block .filter-title button {
        background: none;
        -moz-box-sizing: content-box;
        box-shadow: none;
        line-height: inherit;
        padding: 0;
        text-shadow: none;
        font-weight: 400;
        background-image: none;
        background: #4a4a4a;
        border: 0;
        color: #fff;
        cursor: pointer;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0;
        padding: 15px;
        width: 100%;
        font-size: 1.7rem;
        line-height: 1.6rem;
        box-sizing: border-box;
        vertical-align: middle;
        display: inline-block;
        text-decoration: none;
        border-radius: 0
    }

    .layered-filter-block .filter-title button:focus,
    .layered-filter-block .filter-title button:hover {
        background: none;
        border: none
    }

    .layered-filter-block .filter-title button:active {
        background: none;
        border: none
    }

    .layered-filter-block .filter-title button.disabled,
    .layered-filter-block .filter-title button[disabled],
    fieldset[disabled] .layered-filter-block .filter-title button {
        pointer-events: none;
        opacity: .5
    }

    .layered-filter-block .filter-title button:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: inherit;
        color: inherit;
        content: '\f0d7';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 10px 0 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .layered-filter-block .filter-title button:hover:before {
        color: inherit
    }

    .layered-filter-block .filter-title button:active:before {
        color: inherit
    }

    .layered-filter-block .filter-title button:visited {
        color: #fff
    }

    .layered-filter-block .filter-title button:focus,
    .layered-filter-block .filter-title button:hover {
        background: #3d3d3d;
        border: 0;
        color: #fff
    }

    .layered-filter-block .filter-title button:active {
        background: #363636;
        border: 0;
        color: #fff
    }

    .layered-filter-block .filter-title button.disabled,
    .layered-filter-block .filter-title button[disabled],
    fieldset[disabled] .layered-filter-block .filter-title button {
        opacity: .5;
        cursor: default;
        pointer-events: none
    }

    .filter.active {
        position: relative;
        visibility: visible;
        z-index: 99
    }

    .filter.active .filter-options-item:last-child {
        margin-bottom: 40px
    }

    .filter.active .filter-title {
        height: 48px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2
    }

    .filter.active .filter-title strong {
        box-shadow: none;
        background: none;
        border: 0;
        color: transparent;
        left: auto;
        right: 3px;
        top: 10px;
        display: inline-block;
        text-decoration: none
    }

    .filter.active .filter-title strong:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: inherit;
        color: #7d7d7d;
        content: '\f2ed';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .filter.active .filter-title button:before {
        content: '\f0d8'
    }

    .filter.active .filter-subtitle {
        background: transparent;
        display: block;
        height: 50px;
        left: 0;
        line-height: 32px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1
    }

    .filter.active .filter-options {
        background: #fff;
        bottom: 0;
        display: block;
        left: 0;
        overflow: scroll;
        position: fixed;
        right: 0;
        top: 50px;
        z-index: 10
    }

    .filter .filter-subtitle {
        font-size: 20px;
        font-weight: 300
    }

    .filter-actions {
        margin: -35px -10px 25px
    }

    .filter-options-content {
        padding: 5px 10px
    }

    .filter .filter-current {
        border: solid #ccc;
        border-width: 1px 0;
        margin: 5px -10px 0
    }

    .filter .filter-current .items {
        display: none
    }

    .filter .filter-current-subtitle {
        position: relative;
        text-transform: uppercase;
        z-index: 1;
        display: block;
        text-decoration: none
    }

    .filter .filter-current-subtitle:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 13px;
        line-height: inherit;
        color: inherit;
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .filter .filter-current-subtitle:before {
        position: absolute;
        right: 10px;
        top: 10px
    }

    .filter .filter-current-subtitle:after {
        color: #7d7d7d;
        content: ' (' attr(data-count) ')';
        font-size: .9em
    }

    .filter .filter-current.active {
        padding-bottom: 30px
    }

    .filter .filter-current.active .block-subtitle:before {
        content: '\f077'
    }

    .filter .filter-current.active .items {
        display: block
    }

    .filter .filter-current.active+.block-actions {
        display: block
    }

    .filter .filter-current+.block-actions {
        display: none
    }

    .filter-no-options .filter-title:before {
        background: rgba(255, 255, 255, 0.5);
        content: '';
        display: block;
        height: 40px;
        left: 0;
        margin-top: -60px;
        position: relative;
        width: 75px;
        z-index: 99
    }

    .filter-no-options .filter-content {
        margin-bottom: 20px
    }

    .page-with-filter .columns .sidebar-main {
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0
    }

    .order-pager-wrapper .toolbar-amount {
        left: inherit;
        position: relative;
        text-align: center;
        top: inherit
    }

    .order-pager-wrapper .pages {
        text-align: center
    }

    .order-pager-wrapper .action.previous,
    .order-pager-wrapper .action.next {
        margin: 0
    }

    .block-collapsible-nav {
        left: 0;
        width: 100%;
        z-index: 5;
        margin-bottom: 0;
        position: absolute
    }

    .block-collapsible-nav .content {
        border-bottom: 1px solid #ccc;
        display: none
    }

    .block-collapsible-nav .content.active {
        display: block
    }

    .customer-welcome+.authorization-link {
        display: none
    }

    .nav-sections .switcher-trigger strong:after {
        font-size: 1.6rem;
        right: -25px;
        top: -2px
    }

    .navigation .parent .level-top:after {
        font-size: 1.6rem;
        right: 10px;
        top: 7px
    }

    .header.content {
        margin-bottom: 15px
    }

    .header.content .header-right {
        order: 2;
        min-height: 50px
    }

    .header.content .header-search {
        width: 100%;
        order: 3
    }

    .authorization-link>a {
        display: inline-block;
        text-decoration: none
    }

    .authorization-link>a>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .authorization-link>a:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 28px;
        color: #e62645;
        content: '\f2f6';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .authorization-link>a:hover:before {
        color: #9b440e
    }

    .authorization-link>a:active:before {
        color: #e43866;
    }

    .authorization-link>a::before {
        font-weight: bold
    }

    .my-account-link {
        font-size: 0;
        vertical-align: top;
        display: inline-block;
        text-decoration: none
    }

    .my-account-link>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .my-account-link:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 28px;
        color: #e62645;
        content: '\f007';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .my-account-link:hover:before {
        color: #9b440e
    }

    .my-account-link:active:before {
        color: inherit
    }

    .my-account-link:before {
        font-weight: bold
    }

    .logo {
        width: 75px
    }

    .cms-page-view .page-main {
        padding-top: 0;
        position: relative
    }

    .page-footer.footer-has-drawer {
        padding-bottom: 40px;
        padding-bottom: calc(60px + env(safe-area-inset-bottom))
    }

    .footer {
        text-align: center
    }

    .footer-top,
    .footer-row {
        flex-wrap: wrap
    }

    .footer-bottom>.footer-column:first-child {
        order: 2
    }

    .footer-column {
        width: 100%;
        border-top: 1px solid #ccc;
        padding: 0 5px
    }

    .footer-logo {
        width: 100%;
        margin: 15px
    }

    .footer-logo .fluorochem-logo {
        margin: 0 auto
    }

    body.no-scroll-mobile {
        overflow: hidden;
        -webkit-overflow-scrolling: touch
    }

    .cms-index-index .page.messages,
    .sketcher-index-index .page.messages {
        padding-left: 15px;
        padding-right: 15px
    }

    .homepage-logo {
        width: 160px
    }

    .homepage-search .product-search {
        max-width: 500px
    }

    .homepage-search .product-search-input-wrapper {
        margin: 25px 0 20px
    }

    .homepage-search .search-tooltip {
        left: 9px
    }

    .homepage-search .search-tooltip>a {
        display: inline-block;
        text-decoration: none
    }

    .homepage-search .search-tooltip>a>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .homepage-search .search-tooltip>a:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 40px;
        color: #333;
        content: '\f05a';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .homepage-search .search-tooltip>a:hover:before {
        color: #333
    }

    .homepage-search .search-tooltip>a:active:before {
        color: #333
    }

    .homepage-search input {
        height: 40px;
        border-radius: 20px
    }

    .homepage-search .search-autocomplete,
    .homepage-search .search-autocomplete ul:not(:empty) {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px
    }

    .homepage-search .action.search {
        display: inline-block;
        text-decoration: none;
        right: 15px
    }

    .homepage-search .action.search>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .homepage-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 40px;
        color: #e62645;
        content: '\f002';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .homepage-search .action.search:hover:before {
        color: #9b440e
    }

    .homepage-search .action.search:active:before {
        color: #e43866;
    }

    .concept-results-index .page-footer {
        padding-bottom: 40px;
        padding-bottom: calc(60px + env(safe-area-inset-bottom))
    }

    .sketcher-search-container {
        height: 450px
    }

    .sketcher-buttons {
        padding: 10px 0
    }

    .sketcher-buttons .sketcher-button .tooltip-content {
        display: none
    }

    .conceptSearch-table thead th {
        font-size: 1.4rem
    }

    .conceptSearch-table th,
    .conceptSearch-table td {
        padding: 10px 5px
    }

    .conceptSearch-table tbody td {
        vertical-align: top
    }

    .conceptSearch-table tbody td:first-child {
        font-size: 1.8rem
    }

    .dataTables_length {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid #ccc
    }

    .dataTables_length label {
        padding-right: 0.36em
    }

    .dataTables_paginate {
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid #ccc
    }

    .conceptSearch-buttons {
        padding: 10px 0
    }

    .conceptSearch-selected:not(:empty) {
        border-bottom: 1px solid #ccc
    }

    .conceptSearch-selected ul li {
        padding: 5px 10px 5px 5px
    }

    .conceptSearch-results {
        flex-wrap: wrap
    }

    .conceptSearch-results-mini {
        padding-left: 4px;
        border-radius: 16px
    }

    .conceptSearch-results-mini i {
        line-height: 30px;
        font-size: 22px;
        margin-right: 6px
    }

    .conceptSearch-sidebar {
        width: 100%
    }

    .conceptSearch-sidebar h2 {
        text-align: center
    }

    .conceptSearch-heads .conceptSearch-head>span {
        width: 100%
    }

    .conceptSearch-heads .conceptSearch-head-name {
        min-width: 75px
    }

    .conceptSearch-heads .conceptSearch-head img {
        width: 40px;
        height: 40px
    }

    .conceptSearch-heads .conceptSearch-head-count {
        min-width: 95px
    }

    .conceptSearch-heads .conceptSearch-head-image-popout {
        display: none
    }

    .conceptSearch-results-selected {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 15px;
        background: #fff;
        z-index: 900;
        height: 40px;
        height: calc(60px + env(safe-area-inset-bottom));
        min-height: 40px;
        overflow: hidden;
        transition: .5s ease min-height
    }

    .conceptSearch-results-selected.open {
        min-height: 100%;
        overflow: auto;
        padding-bottom: 20px
    }

    .conceptSearch-results-selected.open .conceptSearch-results-selected-toggle i {
        transform: rotate(180deg)
    }

    .conceptSearch-results-selected:not(.open) .conceptSearch-results-selected-toggle {
        padding-bottom: 20px;
        padding-bottom: calc(20px + env(safe-area-inset-bottom))
    }

    .conceptSearch-results-selected:not(.open)>div {
        display: none
    }

    .conceptSearch-results-selected h2 {
        display: none
    }

    .conceptSearch-results-selected-toggle {
        font-size: 20px;
        background-image: none;
        background: #4a4a4a;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: block;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 700;
        margin: 0 -15px;
        padding: 20px 15px;
        width: calc(100% + 30px);
        font-size: 2rem;
        line-height: 2rem;
        box-sizing: border-box;
        vertical-align: middle;
        border-radius: 0
    }

    .conceptSearch-results-selected-toggle:visited {
        color: #fff
    }

    .conceptSearch-results-selected-toggle:focus,
    .conceptSearch-results-selected-toggle:hover {
        background: #3d3d3d;
        border: 0;
        color: #fff
    }

    .conceptSearch-results-selected-toggle:active {
        background: #363636;
        border: 0;
        color: #fff
    }

    .conceptSearch-results-selected-toggle.disabled,
    .conceptSearch-results-selected-toggle[disabled],
    fieldset[disabled] .conceptSearch-results-selected-toggle {
        opacity: .5;
        cursor: default;
        pointer-events: none
    }

    .conceptSearch-results-selected-toggle i {
        transition: 1s ease transform;
        margin: 0 10px
    }

    .conceptSearch-results-selected>div {
        margin: 20px 0
    }

    .conceptSearch-results-selected ul {
        max-height: 300px
    }

    .conceptSearch-results-selected-stats {
        display: none
    }

    .conceptSearch-grid {
        margin-left: -10px;
        margin-right: -10px
    }

    .conceptSearch-grid h2 {
        text-align: center
    }

    .conceptSearch-grid-molecule {
        padding: 0 10px 20px;
        flex-basis: 50%
    }

    .conceptSearch-grid-molecule input {
        bottom: -60px
    }

    .conceptSearch-grid-molecule-name {
        font-size: 1.6rem
    }

    .conceptSearch-list-table-radar,
    .conceptSearch-list-columns button[data-column="radar"] {
        display: none !important
    }

    .conceptSearch-molecule-modal .content {
        flex-wrap: wrap
    }

    .conceptSearch-compare {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: white;
        z-index: 99;
        height: 100%;
        overflow: scroll;
        padding-top: 15px
    }

    .conceptSearch-compare-header {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px
    }

    .number-input-prepend,
    .number-input-append {
        display: none
    }
}

@media only screen and (max-width:767px) and (min-width:480px) {
    .product-item {
        width: 33.333%
    }
}

@media only screen and (max-width:639px) {

    .abs-visually-hidden-mobile,
    .table-downloadable-products .col.title:before,
    .table-giftregistry-items .col.product:before {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-add-clearfix-mobile:before,
    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody>tr:before,
    .abs-checkout-order-review tbody>tr:after,
    .table-giftregistry-items .col.product:before,
    .table-giftregistry-items .col.product:after,
    .multicheckout.order-review .data.table tbody>tr:before,
    .multicheckout.order-review .data.table tbody>tr:after {
        content: '';
        display: table
    }

    .abs-add-clearfix-mobile:after,
    .abs-checkout-order-review tbody>tr:after,
    .table-giftregistry-items .col.product:after,
    .multicheckout.order-review .data.table tbody>tr:after {
        clear: both
    }

    .multicheckout.order-review .data.table tbody>tr:not(:last-child) {
        border-bottom: 1px solid #ccc
    }

    .multicheckout.order-review .data.table tbody>tr>td.col.item:before {
        display: none
    }

    .multicheckout.order-review .data.table tbody>tr>td.col.qty,
    .multicheckout.order-review .data.table tbody>tr>td.col.price,
    .multicheckout.order-review .data.table tbody>tr>td.col.subtotal {
        box-sizing: border-box;
        float: left;
        text-align: center;
        white-space: nowrap;
        width: 33%
    }

    .multicheckout.order-review .data.table tbody>tr>td.col.qty[data-th]:before,
    .multicheckout.order-review .data.table tbody>tr>td.col.price[data-th]:before,
    .multicheckout.order-review .data.table tbody>tr>td.col.subtotal[data-th]:before {
        content: attr(data-th) ':';
        display: block;
        font-weight: 700;
        padding-bottom: 10px
    }

    .multicheckout.order-review .data.table tbody>tr>td.col:last-child {
        border: 0
    }

    .abs-checkout-order-review .product-item-name,
    .multicheckout.order-review .data.table .product-item-name {
        margin: 0
    }

    .abs-product-items-summary tbody .col {
        padding: 10px 0 0
    }

    .abs-product-items-summary tfoot .mark {
        padding-bottom: 5px;
        text-align: left
    }

    .abs-product-items-summary tfoot .amount {
        padding-top: 0;
        text-align: left
    }

    .abs-product-items-summary tfoot .grand.totals {
        font-size: 1.6rem
    }

    .abs-product-items-summary tfoot .grand.totals .mark {
        padding-bottom: 0;
        padding-top: 5px
    }

    .abs-product-items-summary tfoot .grand.totals .amount {
        padding-bottom: 10px;
        padding-top: 0
    }

    .abs-product-items-summary tfoot .grand.totals .amount strong {
        border-top: none
    }

    .abs-account-table-margin-mobile,
    .table-wrapper.balance-history,
    .table-wrapper.reward-history,
    .table-wrapper.orders-recent {
        margin-top: -20px
    }

    .abs-sidebar-totals-mobile th:before,
    .abs-sidebar-totals-mobile td:before,
    .account .table-billing-agreements .col.actions:before,
    .account .table-billing-agreements-related .col.actions:before,
    .account .table-giftregistry .col.actions:before,
    .table.grouped>tbody>tr>td:before,
    .account .table-reviews .col.actions:before,
    .table-wrapper.comparison .table-comparison>tbody>tr>th:before,
    .table-wrapper.comparison .table-comparison>tbody>tr>td:before,
    .account .table-order-items .col.actions:before,
    .account .table-order-items .col.options:before {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-no-display-s,
    .order-details-items .table-order-items .options-label {
        display: none
    }

    .abs-title-orders-mobile .page-main .page-title-wrapper .page-title,
    .magento-rma-returns-returns .page-main .page-title-wrapper .page-title,
    .magento-rma-returns-view .page-main .page-title-wrapper .page-title,
    .account .page-main .page-title-wrapper .page-title,
    [class^='sales-guest-'] .page-main .page-title-wrapper .page-title,
    .sales-guest-view .page-main .page-title-wrapper .page-title {
        display: block
    }

    .abs-title-orders-mobile .page-main .page-title-wrapper .order-status,
    .magento-rma-returns-returns .page-main .page-title-wrapper .order-status,
    .magento-rma-returns-view .page-main .page-title-wrapper .order-status,
    .account .page-main .page-title-wrapper .order-status,
    [class^='sales-guest-'] .page-main .page-title-wrapper .order-status,
    .sales-guest-view .page-main .page-title-wrapper .order-status {
        margin: -10px 0 10px
    }

    .abs-table-striped-mobile>tbody>tr>td:last-child,
    .account .data.table>tbody>tr>td:last-child {
        border: 0
    }

    .abs-no-border-top,
    .table-wrapper.balance-history,
    .table-wrapper.table-returns,
    .product.info.detailed .additional-attributes-wrapper,
    .table-wrapper.orders-recent,
    .order-details-items .table-wrapper {
        border-top: 0
    }

    .abs-no-border-bottom {
        border-bottom: 0
    }

    .abs-sidebar-totals-mobile .amount {
        text-align: right
    }

    .pages .pages-items {
        display: block;
        position: relative;
        white-space: normal;
        z-index: 1
    }

    .klarna-payments-method>.payment-method-title {
        padding: 6.5px 0 6.5px 15px
    }

    .form-new-agreement select {
        width: auto
    }

    .table-paypal-review-items .col.subtotal {
        text-align: left
    }

    .table-paypal-review-items .product-item-name {
        display: inline-block
    }

    .column .block-addbysku .sku {
        margin-right: -85px;
        padding-right: 90px
    }

    .column .block-addbysku .action.remove {
        display: inline-block;
        background-image: none;
        background: none;
        -moz-box-sizing: content-box;
        border: 0;
        box-shadow: none;
        line-height: inherit;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400
    }

    .column .block-addbysku .action.remove>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .column .block-addbysku .action.remove:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: inherit;
        color: #333;
        content: '\e604';
        font-family: 'Font Awesome 5 Pro';
        margin: -4px 0 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .column .block-addbysku .action.remove:hover:before {
        color: inherit
    }

    .column .block-addbysku .action.remove:active:before {
        color: inherit
    }

    .column .block-addbysku .action.remove:focus,
    .column .block-addbysku .action.remove:hover {
        background: none;
        border: none
    }

    .column .block-addbysku .action.remove:active {
        background: none;
        border: none
    }

    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        pointer-events: none;
        opacity: .5
    }

    .shipping-policy-block.field-tooltip {
        margin-bottom: 20px;
        position: relative;
        right: auto;
        top: auto
    }

    .shipping-policy-block.field-tooltip .field-tooltip-content {
        width: 300px;
        right: auto
    }

    .shipping-policy-block.field-tooltip .field-tooltip-content:before,
    .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto
    }

    .page-product-downloadable .product-options-wrapper {
        margin-bottom: 25px
    }

    .table-downloadable-products .product-name {
        display: block;
        margin-bottom: 5px
    }

    .page-product-downloadable .product-info-price~*:last-child:after {
        border-bottom: 1px solid #c1c1c1;
        content: '';
        display: block;
        height: 0;
        margin: 25px -10px;
        overflow: hidden;
        width: auto
    }

    .table-giftregistry-items .field.choice,
    .table-giftregistry-items .field.qty {
        display: inline-block
    }

    .form-giftregistry-search .fieldset {
        margin-bottom: 20px
    }

    .block-giftregistry-results .block-title {
        margin-bottom: 0
    }

    .block-giftregistry-results .table-wrapper {
        border-top: none
    }

    .block-giftregistry-shared-items .block-title {
        margin-bottom: 10px
    }

    .block-giftregistry-shared-items .table-wrapper {
        border-top: 0
    }

    .table.grouped>thead>tr>th {
        border-bottom: 1px solid #ccc;
        display: table-cell
    }

    .table.grouped>tbody {
        border-bottom: 1px solid #ccc
    }

    .table.grouped>tbody>tr>td {
        padding: 11px 10px;
        border: 0;
        display: table-cell
    }

    .table-wrapper .table.grouped:not(.totals):not(.cart):not(.table-comparison)>tbody>tr>td:last-child {
        border-bottom: 0
    }

    .page-product-grouped .minimal-price {
        margin: 0
    }

    .page-product-grouped .product-info-price {
        border: 0;
        margin-bottom: 0
    }

    .page-product-grouped .product-info-price .price-box {
        padding-bottom: 0
    }

    .form-add-invitations .action.remove {
        display: inline-block;
        text-decoration: none
    }

    .form-add-invitations .action.remove>span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .form-add-invitations .action.remove:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        line-height: inherit;
        color: #333;
        content: '\e604';
        font-family: 'Font Awesome 5 Pro';
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .form-add-invitations .action.remove:hover:before {
        color: inherit
    }

    .form-add-invitations .action.remove:active:before {
        color: inherit
    }

    .multicheckout .data.table .address:before {
        margin-bottom: 5px
    }

    .multicheckout .product-item-name,
    .multicheckout .price-including-tax,
    .multicheckout .price-excluding-tax {
        display: inline-block
    }

    .multicheckout .block-content .box:not(:last-child) {
        margin-bottom: 40px
    }

    .multicheckout .block-content .box:last-child {
        margin-bottom: 0
    }

    .multicheckout.order-review .box-items .data.table thead {
        display: block
    }

    .multicheckout.order-review .box-items .data.table thead tr {
        display: block
    }

    .multicheckout.order-review .box-items .data.table thead .col.item {
        display: block;
        padding: 0
    }

    .multicheckout.order-review .data.table.table-order-review>tbody>tr>td.col.subtotal {
        border-bottom: none
    }

    .multicheckout.order-review .data.table.table-order-review>tbody>tr>td.col.qty {
        text-align: center
    }

    .multicheckout .actions-toolbar .action {
        margin-bottom: 25px
    }

    .multicheckout .actions-toolbar>.primary {
        margin-bottom: 25px;
        margin-right: 0
    }

    .customer-review .product-name {
        margin-bottom: 5px
    }

    .customer-review .product-reviews-summary .rating-summary {
        display: block;
        margin-bottom: 5px
    }

    .form-create-return .additional .field:nth-last-child(2) {
        margin-bottom: 0
    }

    .form-create-return .additional .field:last-child {
        margin-top: 20px
    }

    .account .column.main .returns-details-items .block-returns-tracking .block-title>.action.track {
        display: block;
        float: none;
        margin: 10px 0 0
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
        display: none
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
        display: none
    }

    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
        display: block
    }

    .my-credit-cards .card-type img {
        vertical-align: middle
    }

    .products-grid.wishlist .product-item {
        border-bottom: 1px solid #e8e8e8
    }

    .products-grid.wishlist .product-item:first-child {
        border-top: 1px solid #e8e8e8
    }

    .toolbar-products {
        margin-bottom: 0
    }

    .product-social-links .action.tocompare {
        display: none
    }

    .product-info-price {
        margin: 0 -10px 0;
        width: calc(100% + 2*10px) !important
    }

    .product-info-price>*:first-child {
        padding-left: 10px
    }

    .product-info-price>*:last-child {
        padding-right: 10px
    }

    .table-wrapper.comparison .table-comparison>tbody>tr>th,
    .table-wrapper.comparison .table-comparison>tbody>tr>td {
        display: table-cell
    }

    .account .column.main,
    .account .sidebar-additional {
        margin: 0;
        padding: 0
    }

    .account .sidebar-main .account-nav {
        margin-bottom: 0
    }

    .table-wrapper.orders-recent .table-order-items.table tbody>tr>td.col {
        padding-left: 0
    }

    .order-details-items thead {
        display: block
    }

    .order-details-items thead tr {
        display: block
    }

    .order-pager-wrapper {
        display: block
    }

    .account .table-order-items tbody tr {
        display: block
    }

    .account .table-order-items .product-item-name {
        display: inline-block;
        margin: 0
    }

    .account .table-order-items .action.show {
        margin-top: 0
    }

    .order-details-items .order-title strong {
        display: block
    }

    .order-details-items .action {
        margin-top: 10px
    }

    .order-details-items .items-qty {
        display: inline-block;
        vertical-align: top
    }

    .order-details-items .col.price .price-including-tax,
    .order-details-items .col.subtotal .price-including-tax,
    .order-details-items .col.price .price-excluding-tax,
    .order-details-items .col.subtotal .price-excluding-tax {
        display: inline-block
    }

    .order-details-items .data.table .col.options {
        padding: 0 10px 15px
    }

    .order-details-items .data.table .col.options:before {
        display: none
    }

    .order-details-items .options-label+.item-options-container[data-th]:before,
    .order-details-items .item-options-container+.item-options-container[data-th]:before {
        content: attr(data-th) ':';
        display: block;
        font-weight: 700;
        padding-left: 10px
    }

    .order-details-items .options-label+.item-options-container .col,
    .order-details-items .item-options-container+.item-options-container .col {
        font-size: 1.2rem;
        padding: 0 10px
    }

    .order-details-items .options-label+.item-options-container .col:first-child,
    .order-details-items .item-options-container+.item-options-container .col:first-child {
        padding-top: 3px
    }

    .order-details-items .options-label+.item-options-container .col:last-child,
    .order-details-items .item-options-container+.item-options-container .col:last-child {
        padding-bottom: 20px
    }
}

@media only screen and (max-width:479px) {
    .tooltip.wrapper .tooltip.content {
        min-width: 10rem
    }

    .minicart-wrapper .block-minicart {
        width: 290px
    }

    .modal-popup .field-tooltip .field-tooltip-content {
        width: 200px
    }

    .footer-bottom.footer-row {
        padding-bottom: 80px
    }
}

@media all and (min-width:640px) {
    .abs-blocks-2columns-s {
        width: 48%
    }

    .abs-reset-left-margin-desktop-s,
    .column:not(.sidebar-main) .multicheckout .actions-toolbar,
    .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
        margin-left: 0
    }

    .abs-visually-hidden-desktop-s {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    .abs-add-clearfix-desktop-s:before,
    .abs-add-clearfix-desktop-s:after,
    .multicheckout .block-billing:before,
    .multicheckout .block-billing:after {
        content: '';
        display: table
    }

    .abs-add-clearfix-desktop-s:after,
    .multicheckout .block-billing:after {
        clear: both
    }

    .abs-add-box-sizing-desktop-s,
    .multicheckout .block-shipping .box,
    .multicheckout .block-billing .box-billing-address,
    .multicheckout .block-billing .box-billing-method {
        box-sizing: border-box
    }

    .abs-form-field-column-2-s .fieldset .field {
        padding: 0 12px 0 0;
        box-sizing: border-box;
        display: inline-block;
        width: 50%;
        vertical-align: top
    }

    .abs-form-field-column-2-s .fieldset .field+.fieldset {
        clear: both
    }

    .abs-form-field-column-2-s .fieldset .field .field {
        padding: 0;
        width: 100%
    }

    .abs-form-field-revert-column-1-s {
        width: 100%
    }

    .abs-action-print-s {
        display: inline-block;
        text-decoration: none
    }

    .abs-action-print-s:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 16px;
        color: inherit;
        content: '\e624';
        font-family: 'Font Awesome 5 Pro';
        margin: 0 4px 0 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: bold;
        overflow: hidden;
        speak: none;
        text-align: center
    }

    .ui-dialog.popup {
        width: 420px
    }

    .column .block-addbysku .action.remove {
        background-image: none;
        -moz-box-sizing: content-box;
        box-shadow: none;
        line-height: inherit;
        text-shadow: none;
        font-weight: 400;
        line-height: 1.42857143;
        margin: 0;
        padding: 0;
        color: #b74f0e;
        text-decoration: none;
        background: none;
        border: 0;
        display: inline;
        margin-top: -4px
    }

    .column .block-addbysku .action.remove:focus,
    .column .block-addbysku .action.remove:hover {
        background: none;
        border: none
    }

    .column .block-addbysku .action.remove:active {
        background: none;
        border: none
    }

    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        pointer-events: none;
        opacity: .5
    }

    .column .block-addbysku .action.remove:visited {
        color: #b74f0e;
        text-decoration: none
    }

    .column .block-addbysku .action.remove:hover {
        color: #b74f0e;
        text-decoration: underline
    }

    .column .block-addbysku .action.remove:active {
        color: #b74f0e;
        text-decoration: underline
    }

    .column .block-addbysku .action.remove:hover {
        color: #b74f0e
    }

    .column .block-addbysku .action.remove:hover,
    .column .block-addbysku .action.remove:active,
    .column .block-addbysku .action.remove:focus {
        background: none;
        border: 0
    }

    .column .block-addbysku .action.remove.disabled,
    .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
        color: #b74f0e;
        opacity: .5;
        cursor: default;
        pointer-events: none;
        text-decoration: underline
    }

    .catalogsearch-advanced-result .message.error {
        margin-top: 0
    }

    .multicheckout .actions-toolbar .secondary {
        float: none;
        margin-top: 11px;
        text-align: right
    }

    .multicheckout .actions-toolbar .secondary .action {
        margin-left: 0
    }

    .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left
    }

    .multicheckout .item-options {
        margin: 20px 0 0
    }

    .multicheckout .block-content .box {
        margin-bottom: 0
    }

    .multicheckout .block-shipping .box {
        float: left;
        width: 25%
    }

    .multicheckout .block-shipping .box-shipping-method {
        padding-left: 25px;
        padding-right: 25px;
        width: 50%
    }

    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
        display: none
    }

    .multicheckout .block-billing .box-billing-address {
        float: left;
        width: 25%
    }

    .multicheckout .block-billing .box-billing-method {
        float: left;
        padding-left: 25px;
        width: 50%
    }

    .multicheckout.form.address .table-wrapper .applicable {
        margin: 7px 0 0
    }

    .multicheckout.order-review .box-items {
        clear: left;
        float: none;
        padding-top: 40px;
        width: auto
    }

    .multicheckout.order-review .col.item {
        width: 75%
    }

    .multicheckout .methods-payment .item-content>.fieldset {
        width: auto
    }

    .multicheckout .methods-payment .item-content>.fieldset .field.cvv {
        display: inline-block;
        width: auto
    }

    .multicheckout .methods-payment .fieldset>.field:not(.choice)>.label {
        float: none;
        margin-bottom: 8px;
        text-align: left;
        width: auto
    }

    .multicheckout .methods-payment .fieldset>.field:not(.choice):not(.cvv) .control {
        width: 100%
    }

    .review-form {
        max-width: 500px
    }

    .review-ratings {
        float: left;
        margin-bottom: 0;
        max-width: 240px
    }

    .review-ratings~.review-content,
    .review-ratings~.review-details {
        margin-left: 280px
    }

    .review-toolbar {
        margin: 0 0 30px
    }

    .review-toolbar .pages {
        padding: 30px 0
    }

    .fieldset .review-legend.legend {
        margin-bottom: 30px
    }

    .review-item {
        padding: 30px 0
    }

    .review-title {
        margin: 0 0 30px
    }

    .wishlist-index-index .products-grid .product-item-actions {
        margin: 0
    }

    .order-details-items .order-title .action {
        margin: 12px 0 0 30px
    }

    .order-details-items .order-title .action.track {
        float: right
    }

    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.33333333%
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 4%)/3)
    }

    .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0
    }

    .conceptSearch-grid-molecule {
        flex-basis: 33.333%
    }
}

body.no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch
}

* {
    box-sizing: border-box
}

.header .compare,
.header .block-compare {
    display: none
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.page-wrapper {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right)
}

.fotorama__fullscreen .fotorama--fullscreen {
    background: rgba(0, 0, 0, 0.7) !important
}

.fotorama__fullscreen-icon {
    background-color: white !important
}

@media (prefers-reduced-motion:reduce) {
    * {
        animation: none !important;
        transition: none !important
    }
}

.cms-index-index .page-title-wrapper,
.sketcher-index-index .page-title-wrapper,
.concept-index-index .page-title-wrapper,
.concept-results-index .page-title-wrapper {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.cms-index-index .page-main,
.sketcher-index-index .page-main,
.concept-index-index .page-main,
.customer-account-login .page-main,
.checkout-onepage-success .page-main,
.cms-no-route .page-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative
}

.cms-index-index .columns,
.sketcher-index-index .columns,
.concept-index-index .columns,
.customer-account-login .columns,
.checkout-onepage-success .columns,
.cms-no-route .columns {
    width: 100%
}

.cms-index-index .page.messages,
.sketcher-index-index .page.messages,
.concept-index-index .page.messages,
.checkout-onepage-success .page.messages,
.cms-no-route .page.messages {
    position: absolute;
    max-width: 768px;
    width: 100%;
    top: 0
}

.homepage-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 80px 0
}

.homepage-logo-container {
    width: 100%;
    text-align: center
}

.homepage-search {
    width: 100%
}

.homepage-search .product-search {
    margin: 0 auto
}

.homepage-search .product-search-input-wrapper {
    position: relative
}

.homepage-search .search-tooltip {
    position: relative;
    position: absolute
}

.homepage-search .search-tooltip .tooltip-content {
    background: #fff;
    border-radius: 5px;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #333;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-bottom: 5px;
    bottom: 100%;
    left: 0
}

.homepage-search .search-tooltip .tooltip-content:after,
.homepage-search .search-tooltip .tooltip-content:before {
    border: solid transparent;
    content: '';
    height: 0;
    position: absolute;
    width: 0
}

.homepage-search .search-tooltip .tooltip-content:after {
    border-width: 5px;
    border-color: transparent
}

.homepage-search .search-tooltip .tooltip-content:before {
    border-width: 6px;
    border-color: transparent
}

.homepage-search .search-tooltip .tooltip-content:after,
.homepage-search .search-tooltip .tooltip-content:before {
    top: 100%
}

.homepage-search .search-tooltip .tooltip-content:after {
    border-top-color: #fff;
    left: 15px;
    margin-left: -5px
}

.homepage-search .search-tooltip .tooltip-content:before {
    border-top-color: #bbb;
    left: 15px;
    margin-left: -6px
}

.homepage-search .search-tooltip .tooltip-toggle {
    cursor: help
}

.homepage-search .search-tooltip .tooltip-toggle:hover+.tooltip-content,
.homepage-search .search-tooltip .tooltip-toggle:focus+.tooltip-content,
.homepage-search .search-tooltip:hover .tooltip-content {
    display: block
}

.homepage-search .search-tooltip .tooltip-content {
    left: -4px
}

.homepage-search input {
    padding: 0 36px
}

.homepage-search input[aria-haspopup="true"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 3px 1px #c1c1c1
}

.homepage-search .search-autocomplete ul:not(:empty) {
    overflow: hidden
}

.homepage-search .action.search {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    right: 14px;
    top: 0;
    z-index: 1
}

.homepage-search .action.search:focus,
.homepage-search .action.search:hover {
    background: none;
    border: none
}

.homepage-search .action.search:active {
    background: none;
    border: none
}

.homepage-search .action.search.disabled,
.homepage-search .action.search[disabled],
fieldset[disabled] .homepage-search .action.search {
    pointer-events: none;
    opacity: .5
}

.homepage-search-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.homepage-search-buttons .home-search-button {
    text-decoration: none;
    background-image: none;
    background: #e0e1e2;
    border: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 5px;
    text-align: center
}

.homepage-search-buttons .home-search-button:hover,
.homepage-search-buttons .home-search-button:active,
.homepage-search-buttons .home-search-button:focus {
    text-decoration: none
}

.homepage-search-buttons .home-search-button:visited {
    color: #333
}

.homepage-search-buttons .home-search-button:focus,
.homepage-search-buttons .home-search-button:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.homepage-search-buttons .home-search-button:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.homepage-search-buttons .home-search-button.disabled,
.homepage-search-buttons .home-search-button[disabled],
fieldset[disabled] .homepage-search-buttons .home-search-button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.homepage-search-buttons .home-search-button-primary {
    text-decoration: none;
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 5px;
    text-align: center
}

.homepage-search-buttons .home-search-button-primary:hover,
.homepage-search-buttons .home-search-button-primary:active,
.homepage-search-buttons .home-search-button-primary:focus {
    text-decoration: none
}

.homepage-search-buttons .home-search-button-primary:visited {
    color: #fff
}

.homepage-search-buttons .home-search-button-primary:focus,
.homepage-search-buttons .home-search-button-primary:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.homepage-search-buttons .home-search-button-primary:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.homepage-search-buttons .home-search-button-primary.disabled,
.homepage-search-buttons .home-search-button-primary[disabled],
fieldset[disabled] .homepage-search-buttons .home-search-button-primary {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.pillbox {
    display: flex;
    border: 2px solid #4a4a4a;
    border-radius: 5px
}

.pillbox label {
    flex-grow: 1;
    flex-basis: 0;
    position: relative
}

.pillbox label:not(:last-child) {
    border-right: 2px solid #4a4a4a
}

.pillbox label:first-child>span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.pillbox label:last-child>span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.pillbox label input {
    position: absolute;
    opacity: 0;
    bottom: 0;
    height: 0;
    width: 0;
    cursor: pointer
}

.pillbox label input:checked~span,
.pillbox label input:checked~span:hover,
.pillbox label input:checked~span:focus,
.pillbox label input:checked~span:active {
    background: #4a4a4a;
    color: #fff
}

.pillbox label input:focus~span {
    box-shadow: 0 0 3px 1px #222
}

.pillbox label>span {
    background-image: none;
    background: #fff;
    border: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 0;
    text-align: center
}

.pillbox label>span:visited {
    color: #333
}

.pillbox label>span:focus,
.pillbox label>span:hover {
    background: #f2f2f2;
    border: 0;
    color: #333
}

.pillbox label>span:active {
    background: #ebebeb;
    border: 0;
    color: #333
}

.pillbox label>span.disabled,
.pillbox label>span[disabled],
fieldset[disabled] .pillbox label>span {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.pillbox label>span,
.pillbox label>span:hover,
.pillbox label>span:focus,
.pillbox label>span:active {
    border-right: 2px solid #4a4a4a
}

.pillbox label>span:last-child {
    border-right: 0
}

.link-button {
    text-decoration: none;
    background-image: none;
    background: #e0e1e2;
    border: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 5px
}

.link-button:hover,
.link-button:active,
.link-button:focus {
    text-decoration: none
}

.link-button:visited {
    color: #333
}

.link-button:focus,
.link-button:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.link-button:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.link-button.disabled,
.link-button[disabled],
fieldset[disabled] .link-button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.sketcher-index-index .column.main {
    text-align: center
}

.sketcher-search-container {
    width: 100%;
    max-width: 1440px;
    border: 1px solid #e0e1e2;
    border-radius: 5px;
    resize: vertical;
    overflow: auto;
    margin: 0 auto
}

.sketcher-search-container .sketcher-search {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    display: block
}

.sketcher-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.sketcher-buttons .sketcher-button {
    margin: 5px
}

.sketcher-buttons .sketcher-button:not(.primary) {
    background: #1b1c1d;
    border: 0;
    color: #fff
}

.sketcher-buttons .sketcher-button:not(.primary):focus,
.sketcher-buttons .sketcher-button:not(.primary):hover {
    background: #343637;
    border: 0;
    color: #fff
}

.sketcher-buttons .sketcher-button:not(.primary):active {
    background: #404245;
    border: 0;
    color: #fff
}

.conceptSearch-loading.loading-icon {
    height: 100px;
    width: 100px
}

.conceptSearch-table {
    width: 100%;
    border-bottom: 1px solid #ccc
}

.conceptSearch-table thead th:first-child,
.conceptSearch-table tbody th:first-child,
.conceptSearch-table thead td:first-child,
.conceptSearch-table tbody td:first-child,
.conceptSearch-table thead th:last-child,
.conceptSearch-table tbody th:last-child,
.conceptSearch-table thead td:last-child,
.conceptSearch-table tbody td:last-child {
    width: 1px;
    white-space: nowrap
}

.conceptSearch-table thead th:last-child,
.conceptSearch-table tbody th:last-child,
.conceptSearch-table thead td:last-child,
.conceptSearch-table tbody td:last-child {
    text-align: right
}

.conceptSearch-table thead tr {
    border-bottom: 1px solid #ccc
}

.conceptSearch-table thead th {
    vertical-align: middle
}

.conceptSearch-table thead th .conceptSearch-list-table-filter-name {
    display: block;
    margin-bottom: 5px
}

.conceptSearch-table thead th .yadcf-filter-wrapper {
    margin-left: 0
}

.conceptSearch-table thead th .yadcf-filter-range-number-slider .ui-slider-handle {
    margin-top: -8px
}

.conceptSearch-table thead th:first-child {
    min-width: 90px
}

.conceptSearch-table thead th.sorting .conceptSearch-list-table-filter-name:after,
.conceptSearch-table thead th.sorting_asc .conceptSearch-list-table-filter-name:after,
.conceptSearch-table thead th.sorting_desc .conceptSearch-list-table-filter-name:after {
    margin-left: 5px
}

.conceptSearch-table thead th.sorting .conceptSearch-list-table-filter-name {
    display: block;
    text-decoration: none
}

.conceptSearch-table thead th.sorting .conceptSearch-list-table-filter-name:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 16px;
    color: #e0e1e2;
    content: '\f0dc';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-table thead th.sorting_asc .conceptSearch-list-table-filter-name {
    display: block;
    text-decoration: none
}

.conceptSearch-table thead th.sorting_asc .conceptSearch-list-table-filter-name:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 16px;
    color: #e62645;
    content: '\f0de';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-table thead th.sorting_desc .conceptSearch-list-table-filter-name {
    display: block;
    text-decoration: none
}

.conceptSearch-table thead th.sorting_desc .conceptSearch-list-table-filter-name:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 16px;
    color: #e62645;
    content: '\f0dd';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-table tbody td {
    vertical-align: top
}

.conceptSearch-table tbody td:first-child {
    vertical-align: middle
}

.conceptSearch-table tfoot td {
    position: relative
}

.dataTables_length select {
    display: inline-block;
    width: auto;
    margin: 0 5px;
    border-radius: 5px
}

.dataTables_paginate .paginate_button.previous {
    display: inline-block;
    text-decoration: none
}

.dataTables_paginate .paginate_button.previous>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.dataTables_paginate .paginate_button.previous:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 32px;
    color: #e62645;
    content: '\f104';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.dataTables_paginate .paginate_button.previous:hover:before {
    color: #9b440e
}

.dataTables_paginate .paginate_button.previous:active:before {
    color: #e43866;
}

.dataTables_paginate .paginate_button.next {
    display: inline-block;
    text-decoration: none
}

.dataTables_paginate .paginate_button.next>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.dataTables_paginate .paginate_button.next:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 32px;
    color: #e62645;
    content: '\f105';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.dataTables_paginate .paginate_button.next:hover:before {
    color: #9b440e
}

.dataTables_paginate .paginate_button.next:active:before {
    color: #e43866;
}

.dataTables_paginate span {
    margin-left: 6px
}

.dataTables_paginate span .paginate_button {
    cursor: pointer
}

.conceptSearch-buttons {
    text-align: center
}

.conceptSearch-selected ul {
    margin: 0;
    padding: 0;
    list-style: none none;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0 -5px
}

.conceptSearch-selected ul li {
    margin: 5px;
    background: #4a4a4a;
    color: #fff;
    border-radius: 5px
}

.conceptSearch-selected ul li>* {
    vertical-align: middle
}

.conceptSearch-selected ul li button {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    margin-right: 5px;
    padding: 5px
}

.conceptSearch-selected ul li button>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-selected ul li button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: inherit;
    color: #fff;
    content: '\f057';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-selected ul li button:hover:before {
    color: #f2f2f2
}

.conceptSearch-selected ul li button:active:before {
    color: #ebebeb
}

.conceptSearch-selected ul li button:visited {
    color: #333
}

.conceptSearch-selected ul li button:focus,
.conceptSearch-selected ul li button:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-selected ul li button:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-selected ul li button.disabled,
.conceptSearch-selected ul li button[disabled],
fieldset[disabled] .conceptSearch-selected ul li button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-selected ul li button:focus,
.conceptSearch-selected ul li button:hover {
    background: none;
    border: none
}

.conceptSearch-selected ul li button:active {
    background: none;
    border: none
}

.conceptSearch-selected ul li button.disabled,
.conceptSearch-selected ul li button[disabled],
fieldset[disabled] .conceptSearch-selected ul li button {
    pointer-events: none;
    opacity: .5
}

.conceptSearch-selected ul li button:focus:before {
    color: #ebebeb
}

.conceptSearch-results {
    display: flex
}

.conceptSearch-results--loading {
    padding: 100px 0
}

.conceptSearch-results--loading>*:not(.conceptSearch-loading) {
    display: none
}

.conceptSearch-results-mini {
    background: #fff;
    border: 1px solid #ccc;
    font-size: 14px;
    height: 32px;
    line-height: 1.428571429;
    padding: 0 4px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin-bottom: -1px
}

.conceptSearch-results-mini,
.conceptSearch-results-mini:hover,
.conceptSearch-results-mini:active,
.conceptSearch-results-mini:visited {
    color: #A5A5A5
}

.conceptSearch-results-mini span,
.conceptSearch-results-mini i {
    vertical-align: middle
}

.conceptSearch-results-mini i {
    color: #333
}

.conceptSearch-heads .conceptSearch-head {
    display: block;
    position: relative
}

.conceptSearch-heads .conceptSearch-head:first-child>span {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.conceptSearch-heads .conceptSearch-head:first-child input~span {
    border-top: 1px solid #ccc
}

.conceptSearch-heads .conceptSearch-head:last-child>span {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.conceptSearch-heads .conceptSearch-head input {
    position: absolute;
    opacity: 0;
    bottom: 0;
    height: 0;
    width: 0;
    cursor: pointer
}

.conceptSearch-heads .conceptSearch-head input~span {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    cursor: pointer
}

.conceptSearch-heads .conceptSearch-head input:checked~span {
    background: #4a4a4a;
    color: #fff;
    border-color: transparent
}

.conceptSearch-heads .conceptSearch-head input:focus~span {
    box-shadow: 0 0 3px 1px #222
}

.conceptSearch-heads .conceptSearch-head>span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px
}

.conceptSearch-heads .conceptSearch-head-image {
    width: 70px;
    height: 35px;
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain
}

.conceptSearch-heads .conceptSearch-head-image-popout {
    position: absolute;
    top: -75px;
    right: -135px;
    width: 200px;
    height: 200px;
    background: #fff;
    z-index: 99;
    border-radius: 50%;
    padding: 30px;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2)
}

.conceptSearch-heads .conceptSearch-head-image-popout:before,
.conceptSearch-heads .conceptSearch-head-image-popout:after {
    content: "";
    display: block;
    position: absolute;
    top: calc((100% - 40px) / 2);
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent
}

.conceptSearch-heads .conceptSearch-head-image-popout:before {
    left: -39px;
    border-right: 40px solid #ccc
}

.conceptSearch-heads .conceptSearch-head-image-popout:after {
    left: -37px;
    border-right: 40px solid #fff
}

.conceptSearch-heads .conceptSearch-head-image-popout>span {
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 5px
}

.conceptSearch-heads .conceptSearch-head .conceptSearch-head-count {
    text-align: right
}

.conceptSearch-results-selected ul {
    margin: 0;
    padding: 0;
    list-style: none none;
    margin: 0 0 10px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px
}

.conceptSearch-results-selected-molecule {
    margin: 0;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.conceptSearch-results-selected-molecule:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.conceptSearch-results-selected-molecule:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.conceptSearch-results-selected-molecule strong {
    margin-right: 5px
}

.conceptSearch-results-selected-molecule-remove {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    padding: 0 10px 0 30px;
    text-align: center
}

.conceptSearch-results-selected-molecule-remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-results-selected-molecule-remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: #e62645;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-results-selected-molecule-remove:hover:before {
    color: #9b440e
}

.conceptSearch-results-selected-molecule-remove:active:before {
    color: #e43866;
}

.conceptSearch-results-selected-molecule-remove:visited {
    color: #333
}

.conceptSearch-results-selected-molecule-remove:focus,
.conceptSearch-results-selected-molecule-remove:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-results-selected-molecule-remove:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-results-selected-molecule-remove.disabled,
.conceptSearch-results-selected-molecule-remove[disabled],
fieldset[disabled] .conceptSearch-results-selected-molecule-remove {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-results-selected-molecule-remove:focus,
.conceptSearch-results-selected-molecule-remove:hover {
    background: none;
    border: none
}

.conceptSearch-results-selected-molecule-remove:active {
    background: none;
    border: none
}

.conceptSearch-results-selected-molecule-remove.disabled,
.conceptSearch-results-selected-molecule-remove[disabled],
fieldset[disabled] .conceptSearch-results-selected-molecule-remove {
    pointer-events: none;
    opacity: .5
}

.conceptSearch-results-selected-molecule-remove,
.conceptSearch-results-selected-molecule-remove:hover,
.conceptSearch-results-selected-molecule-remove:active,
.conceptSearch-results-selected-molecule-remove:focus {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0.8) 100%)
}

.conceptSearch-results-selected-molecule:hover .conceptSearch-results-selected-molecule-remove,
.conceptSearch-results-selected-molecule:focus .conceptSearch-results-selected-molecule-remove,
.conceptSearch-results-selected-molecule:focus-within .conceptSearch-results-selected-molecule-remove {
    opacity: 1
}

.conceptSearch-results-selected-add {
    background-image: none;
    background: #e62645;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 10px 10px 0;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none
}

.conceptSearch-results-selected-add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: inherit;
    content: '\f07a';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-results-selected-add:hover:before {
    color: inherit
}

.conceptSearch-results-selected-add:active:before {
    color: inherit
}

.conceptSearch-results-selected-add:visited {
    color: #fff
}

.conceptSearch-results-selected-add:focus,
.conceptSearch-results-selected-add:hover {
    background: #e43866;
    border: 0;
    color: #fff
}

.conceptSearch-results-selected-add:active {
    background: #e43866;
    border: 0;
    color: #fff
}

.conceptSearch-results-selected-add.disabled,
.conceptSearch-results-selected-add[disabled],
fieldset[disabled] .conceptSearch-results-selected-add {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-results-selected-add:before {
    font-weight: normal
}

.conceptSearch-results-selected-compare {
    background-image: none;
    background: #1b1c1d;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 10px 10px 0;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none
}

.conceptSearch-results-selected-compare:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: inherit;
    content: '\f516';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-results-selected-compare:hover:before {
    color: inherit
}

.conceptSearch-results-selected-compare:active:before {
    color: inherit
}

.conceptSearch-results-selected-compare:visited {
    color: #fff
}

.conceptSearch-results-selected-compare:focus,
.conceptSearch-results-selected-compare:hover {
    background: #343637;
    border: 0;
    color: #fff
}

.conceptSearch-results-selected-compare:active {
    background: #404245;
    border: 0;
    color: #fff
}

.conceptSearch-results-selected-compare.disabled,
.conceptSearch-results-selected-compare[disabled],
fieldset[disabled] .conceptSearch-results-selected-compare {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-results-selected-compare:before {
    font-weight: normal
}

.conceptSearch-results-selected-message {
    margin-bottom: 20px
}

.conceptSearch-results-selected-message a,
.conceptSearch-results-selected-message a:hover,
.conceptSearch-results-selected-message a:active,
.conceptSearch-results-selected-message a:focus {
    color: inherit;
    font-weight: 700
}

.conceptSearch-results-selected-message i {
    margin-right: 5px
}

.conceptSearch-results-selected-message.success {
    color: #b5cc18
}

.conceptSearch-results-selected-all {
    background-image: none;
    background: #fff;
    border: 2px solid #4a4a4a;
    color: #4a4a4a;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 10px 10px 0;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none
}

.conceptSearch-results-selected-all:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: inherit;
    color: inherit;
    content: '\f058';
    font-family: 'Font Awesome 5 Pro';
    margin: 0 5px 0 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-results-selected-all:hover:before {
    color: inherit
}

.conceptSearch-results-selected-all:active:before {
    color: inherit
}

.conceptSearch-results-selected-all:visited {
    color: #4a4a4a
}

.conceptSearch-results-selected-all:focus,
.conceptSearch-results-selected-all:hover {
    background: #fff;
    border: 2px solid #3d3d3d;
    color: #3d3d3d
}

.conceptSearch-results-selected-all:active {
    background: #fff;
    border: 2px solid #363636;
    color: #363636
}

.conceptSearch-results-selected-all.disabled,
.conceptSearch-results-selected-all[disabled],
fieldset[disabled] .conceptSearch-results-selected-all {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-results-selected-all:before {
    font-weight: normal
}

.conceptSearch-main {
    width: 100%
}

.conceptSearch-grid {
    display: flex;
    flex-wrap: wrap
}

.conceptSearch-grid-molecule {
    position: relative
}

.conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-image,
.conceptSearch-grid-molecule:focus .conceptSearch-grid-molecule-image,
.conceptSearch-grid-molecule:focus-within .conceptSearch-grid-molecule-image,
.conceptSearch-grid-molecule:hover .conceptSearch-grid-molecule-info-container,
.conceptSearch-grid-molecule:focus .conceptSearch-grid-molecule-info-container,
.conceptSearch-grid-molecule:focus-within .conceptSearch-grid-molecule-info-container {
    border-color: #e0e1e2
}

.conceptSearch-grid-molecule input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    bottom: 40px;
    display: block;
    overflow: hidden;
    cursor: pointer;
    top: auto
}

.conceptSearch-grid-molecule input~span {
    cursor: pointer
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-image,
.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-info-container {
    border-color: #4a4a4a
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select {
    background-image: none;
    background: #4a4a4a;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select:visited {
    color: #fff
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select:focus,
.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select:hover {
    background: #3d3d3d;
    border: 0;
    color: #fff
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select:active {
    background: #363636;
    border: 0;
    color: #fff
}

.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select.disabled,
.conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select[disabled],
fieldset[disabled] .conceptSearch-grid-molecule input:checked~span>.conceptSearch-grid-molecule-button-select {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-grid-molecule input:focus~span>.conceptSearch-grid-molecule-button-select {
    box-shadow: 0 0 3px 1px #c1c1c1
}

.conceptSearch-grid-molecule>span {
    display: block;
    text-align: center
}

.conceptSearch-grid-molecule-image {
    display: block;
    width: 100%;
    padding-top: 100%;
    margin-bottom: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 2px solid transparent;
    border-radius: 5px
}

.conceptSearch-grid-molecule-info-container {
    display: none;
    overflow: hidden;
    width: 97%;
    margin: 0 auto;
    padding: 10px;
    background: #fff;
    border-radius: 0 5px 5px 0;
    position: absolute;
    top: 0;
    z-index: 99;
    border: 2px solid;
    right: calc(-1 * (97% - 10px - 2px));
    text-align: left
}

.conceptSearch-grid-molecule-info thead {
    font-weight: 700
}

.conceptSearch-grid-molecule-info thead>tr>td,
.conceptSearch-grid-molecule-info tbody>tr>td {
    padding: 0 0 5px;
    text-align: left
}

.conceptSearch-grid-molecule-info thead>tr>td:last-child,
.conceptSearch-grid-molecule-info tbody>tr>td:last-child {
    text-align: right
}

.conceptSearch-grid-molecule-info-availability {
    display: none
}

.conceptSearch-grid-molecule-name {
    font-weight: bold;
    display: block
}

.conceptSearch-grid-molecule-button {
    border-radius: 5px
}

.conceptSearch-grid-molecule-button-select {
    background-image: none;
    background: #e0e1e2;
    border: 0;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    min-width: 85px
}

.conceptSearch-grid-molecule-button-select:visited {
    color: #333
}

.conceptSearch-grid-molecule-button-select:focus,
.conceptSearch-grid-molecule-button-select:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-grid-molecule-button-select:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-grid-molecule-button-select.disabled,
.conceptSearch-grid-molecule-button-select[disabled],
fieldset[disabled] .conceptSearch-grid-molecule-button-select {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-grid-molecule-button-view {
    text-decoration: none;
    background-image: none;
    background: #333;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.conceptSearch-grid-molecule-button-view:hover,
.conceptSearch-grid-molecule-button-view:active,
.conceptSearch-grid-molecule-button-view:focus {
    text-decoration: none
}

.conceptSearch-grid-molecule-button-view:visited {
    color: #fff
}

.conceptSearch-grid-molecule-button-view:focus,
.conceptSearch-grid-molecule-button-view:hover {
    background: #000;
    border: 0;
    color: #fff
}

.conceptSearch-grid-molecule-button-view:active {
    background: #000;
    border: 0;
    color: #fff
}

.conceptSearch-grid-molecule-button-view.disabled,
.conceptSearch-grid-molecule-button-view[disabled],
fieldset[disabled] .conceptSearch-grid-molecule-button-view {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-list-radar-modal .modal-inner-wrap,
.conceptSearch-molecule-modal .modal-inner-wrap {
    max-width: 850px
}

.conceptSearch-list-radar-modal .modal-title,
.conceptSearch-molecule-modal .modal-title {
    font-weight: 700
}

.conceptSearch-list-radar-modal .action-close,
.conceptSearch-molecule-modal .action-close {
    margin: 8px
}

.conceptSearch-list-radar-modal .action-close::before,
.conceptSearch-molecule-modal .action-close::before {
    font-size: 23px;
    line-height: 24px
}

.conceptSearch-molecule-modal .content {
    display: flex
}

.conceptSearch-molecule-modal .content>* {
    flex-grow: 1
}

.conceptSearch-molecule-modal-image {
    text-align: center;
    padding: 40px
}

.conceptSearch-molecule-modal-image p {
    font-size: 1.8rem;
    font-weight: 700
}

.conceptSearch-molecule-modal-options h2 {
    font-size: 2.2rem
}

.conceptSearch-molecule-modal-options-buttons {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    justify-content: flex-end
}

.conceptSearch-molecule-modal-options-buttons button {
    margin: 5px
}

.conceptSearch-molecule-modal-info-container {
    margin: 0
}

.conceptSearch-molecule-modal-info thead {
    font-weight: 700
}

.conceptSearch-molecule-modal-info thead>tr>td,
.conceptSearch-molecule-modal-info tbody>tr>td {
    padding: 0 0 20px;
    text-align: left
}

.conceptSearch-molecule-modal-info thead>tr>td:last-child,
.conceptSearch-molecule-modal-info tbody>tr>td:last-child {
    text-align: right
}

.conceptSearch-list-table-wrapper {
    overflow-x: auto
}

.conceptSearch-list .conceptSearch-table.conceptSearch-list-table thead tr th {
    vertical-align: top
}

.conceptSearch-list .conceptSearch-table.conceptSearch-list-table thead th,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody th,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table thead td,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody td {
    white-space: nowrap
}

.conceptSearch-list .conceptSearch-table.conceptSearch-list-table thead th:last-child,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody th:last-child,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table thead td:last-child,
.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody td:last-child {
    width: auto;
    text-align: left
}

.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody td {
    vertical-align: middle
}

.conceptSearch-list .conceptSearch-table.conceptSearch-list-table tbody td a {
    display: block
}

.conceptSearch-list-table-image {
    height: 100%;
    width: auto;
    max-height: 50px;
    max-width: 50px;
    margin: 0 auto;
    border: 0;
    display: block;
    object-fit: contain;
    position: relative
}

.conceptSearch-list-table-image:after {
    width: 25px;
    content: "";
    display: block;
    position: absolute;
    height: 25px;
    top: calc((100% - 25px) / 2);
    left: calc((100% - 25px) / 2);
    background-image: url('../images/molecule.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain
}

.conceptSearch-list-table-radar {
    text-align: center
}

.conceptSearch-list-table-radar button {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.conceptSearch-list-table-radar button>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-list-table-radar button:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: #e62645;
    content: '\f610';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-list-table-radar button:hover:before {
    color: #9b440e
}

.conceptSearch-list-table-radar button:active:before {
    color: #e43866;
}

.conceptSearch-list-table-radar button:visited {
    color: #333
}

.conceptSearch-list-table-radar button:focus,
.conceptSearch-list-table-radar button:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-list-table-radar button:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-list-table-radar button.disabled,
.conceptSearch-list-table-radar button[disabled],
fieldset[disabled] .conceptSearch-list-table-radar button {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-list-table-radar button:focus,
.conceptSearch-list-table-radar button:hover {
    background: none;
    border: none
}

.conceptSearch-list-table-radar button:active {
    background: none;
    border: none
}

.conceptSearch-list-table-radar button.disabled,
.conceptSearch-list-table-radar button[disabled],
fieldset[disabled] .conceptSearch-list-table-radar button {
    pointer-events: none;
    opacity: .5
}

.conceptSearch-list-columns>div {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px
}

.conceptSearch-list-columns button {
    margin: 5px
}

.conceptSearch-list-columns button.active {
    background-image: none;
    background: #4a4a4a;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.conceptSearch-list-columns button.active:visited {
    color: #fff
}

.conceptSearch-list-columns button.active:focus,
.conceptSearch-list-columns button.active:hover {
    background: #3d3d3d;
    border: 0;
    color: #fff
}

.conceptSearch-list-columns button.active:active {
    background: #363636;
    border: 0;
    color: #fff
}

.conceptSearch-list-columns button.active.disabled,
.conceptSearch-list-columns button.active[disabled],
fieldset[disabled] .conceptSearch-list-columns button.active {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-list-radar-reference {
    margin: 0 5px
}

.conceptSearch-list-radar-reference.active {
    background-image: none;
    background: #4a4a4a;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 5px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle
}

.conceptSearch-list-radar-reference.active:visited {
    color: #fff
}

.conceptSearch-list-radar-reference.active:focus,
.conceptSearch-list-radar-reference.active:hover {
    background: #3d3d3d;
    border: 0;
    color: #fff
}

.conceptSearch-list-radar-reference.active:active {
    background: #363636;
    border: 0;
    color: #fff
}

.conceptSearch-list-radar-reference.active.disabled,
.conceptSearch-list-radar-reference.active[disabled],
fieldset[disabled] .conceptSearch-list-radar-reference.active {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-tree-container {
    border: 1px solid #ccc;
    border-radius: 5px
}

.conceptSearch-tree-container>svg {
    display: block;
    touch-action: none
}

.conceptSearch-tree-container>svg .node {
    cursor: pointer
}

.conceptSearch-tree-container>svg .node circle {
    fill: #fff;
    stroke: #4a4a4a;
    stroke-width: 3px
}

.conceptSearch-tree-container>svg .node text {
    font: 12px sans-serif
}

.conceptSearch-tree-container>svg .link {
    fill: none;
    stroke: #e0e1e2;
    stroke-width: 2px
}

.conceptSearch-tree-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 300px
}

.conceptSearch-tree-tooltip-image {
    width: 150px;
    min-height: 150px;
    margin-right: 10px;
    text-align: center
}

.conceptSearch-tree-tooltip-image img {
    width: auto;
    height: auto;
    max-height: 200px
}

.conceptSearch-tree-tooltip-info {
    flex-grow: 1
}

.conceptSearch-tree-tooltip-info>div {
    margin-bottom: 5px
}

.conceptSearch-tree-tooltip-info>div strong {
    display: block
}

.conceptSearch-tree-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: -5px;
    margin-right: -5px;
    padding-bottom: 150px
}

.conceptSearch-tree-actions button {
    margin: 10px 5px
}

.conceptSearch-tree-actions__sort {
    position: relative
}

.conceptSearch-tree-actions__sort:focus-within>button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.conceptSearch-tree-actions__sort:focus-within>button+ul {
    display: block
}

.conceptSearch-tree-actions__sort>button::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: inherit;
    color: inherit;
    content: '\f0d7';
    font-family: 'Font Awesome 5 Pro', sans-serif;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center;
    margin-left: 5px
}

.conceptSearch-tree-actions__sort>button:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.conceptSearch-tree-actions__sort>button:focus+ul {
    display: block
}

.conceptSearch-tree-actions__sort ul {
    display: none;
    position: absolute;
    margin: -10px 5px 0;
    padding: 0;
    list-style: none
}

.conceptSearch-tree-actions__sort li {
    margin: 0
}

.conceptSearch-tree-actions__sort li button {
    margin: 0;
    width: 100%;
    text-align: left;
    border-radius: 0;
    border-top: 1px solid #ccc
}

.conceptSearch-tree-actions__sort li:last-child button {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.conceptSearch-compare-header {
    display: flex;
    justify-content: space-between
}

.conceptSearch-compare-header h1 {
    margin-bottom: 0
}

.conceptSearch-compare-close {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 3px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.conceptSearch-compare-close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-compare-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: #e62645;
    content: '\f057';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-compare-close:hover:before {
    color: #9b440e
}

.conceptSearch-compare-close:active:before {
    color: #e43866;
}

.conceptSearch-compare-close:visited {
    color: #333
}

.conceptSearch-compare-close:focus,
.conceptSearch-compare-close:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-compare-close:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-compare-close.disabled,
.conceptSearch-compare-close[disabled],
fieldset[disabled] .conceptSearch-compare-close {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-compare-close:focus,
.conceptSearch-compare-close:hover {
    background: none;
    border: none
}

.conceptSearch-compare-close:active {
    background: none;
    border: none
}

.conceptSearch-compare-close.disabled,
.conceptSearch-compare-close[disabled],
fieldset[disabled] .conceptSearch-compare-close {
    pointer-events: none;
    opacity: .5
}

.conceptSearch-compare-main {
    overflow-x: auto;
    -webkit-overflow-scrolling: auto
}

.conceptSearch-compare-table:last-child {
    border-bottom: 1px solid #ccc
}

.conceptSearch-compare-table th::before,
.conceptSearch-compare-table-radar::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    z-index: -1;
    border-right: 1px solid #ccc
}

.conceptSearch-compare-table-image {
    text-align: center
}

.conceptSearch-compare-table-image:hover,
.conceptSearch-compare-table-image:focus,
.conceptSearch-compare-table-image:focus-within {
    position: relative
}

.conceptSearch-compare-table-image:hover .conceptSearch-compare-molecule-remove,
.conceptSearch-compare-table-image:focus .conceptSearch-compare-molecule-remove,
.conceptSearch-compare-table-image:focus-within .conceptSearch-compare-molecule-remove {
    display: block
}

.conceptSearch-compare-table-image img {
    max-height: 300px
}

.conceptSearch-compare-table-radar {
    z-index: 1
}

.conceptSearch-compare-table-radar .conceptSearch-compare-radar g.legend {
    display: none
}

.conceptSearch-compare-table-radar:hover .conceptSearch-compare-radar {
    transform: scale(2.2);
    padding: 10px 0 0 10px;
    margin-top: -10px;
    margin-left: -10px
}

.conceptSearch-compare-table-radar:hover .conceptSearch-compare-radar g.legend {
    display: initial
}

.conceptSearch-compare-table tbody:first-child tr:first-child td.conceptSearch-compare-table-padding {
    border-top: 1px solid #ccc
}

.conceptSearch-compare-table tr:first-child th,
.conceptSearch-compare-table tr:first-child td {
    padding-top: 10px
}

.conceptSearch-compare-table tr:first-child th:not(.conceptSearch-compare-table-padding),
.conceptSearch-compare-table tr:first-child td:not(.conceptSearch-compare-table-padding) {
    border-top: 1px solid #ccc;
    position: relative
}

.conceptSearch-compare-table tr:last-child th,
.conceptSearch-compare-table tr:last-child td {
    padding-bottom: 10px
}

.conceptSearch-compare-table th,
.conceptSearch-compare-table td {
    padding: 5px 10px
}

.conceptSearch-compare-table th {
    white-space: nowrap;
    min-width: 200px;
    width: 250px
}

.conceptSearch-compare-table td:not(:first-child) {
    min-width: 300px
}

.conceptSearch-compare-table td.conceptSearch-compare-table-padding {
    min-width: 0;
    width: 100%;
    background: #e0e1e2;
    padding: 0 !important;
    z-index: 1;
    position: relative;
    border-right: 0 !important
}

.conceptSearch-compare-radar {
    transition: .5s ease;
    transition-property: transform, padding, margin;
    transform: scale(1);
    transform-origin: top left;
    background: white
}

.conceptSearch-compare-radar svg {
    width: 100%;
    height: auto
}

.conceptSearch-compare-radar~p {
    text-align: center;
    margin: 100px 0 0 0;
    color: #7d7d7d
}

.conceptSearch-compare-group.open .conceptSearch-compare-group-toggle {
    transform: rotate(180deg);
    transform-origin: center
}

.conceptSearch-compare-group:first-child {
    position: relative;
    z-index: 1
}

.conceptSearch-compare-group:not(.open) tr:nth-child(n+2) {
    display: none
}

.conceptSearch-compare-group:not(.open) tr td,
.conceptSearch-compare-group:not(.open) tr th {
    padding-bottom: 10px
}

.conceptSearch-compare-group:not(.open) .conceptSearch-compare-group-header th,
.conceptSearch-compare-group:not(.open) .conceptSearch-compare-group-header td {
    border-right: 0
}

.conceptSearch-compare-group:not(.open) .conceptSearch-compare-group-name::before {
    border-right: 0
}

.conceptSearch-compare-group th:not(:nth-last-child(2)),
.conceptSearch-compare-group td:not(:nth-last-child(2)) {
    border-right: 1px solid #ccc
}

.conceptSearch-compare-group-toggle {
    vertical-align: middle;
    display: inline-block;
    text-decoration: none
}

.conceptSearch-compare-group-toggle>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-compare-group-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    line-height: inherit;
    color: inherit;
    content: '\f0d7';
    font-family: 'Font Awesome 5 Pro';
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-compare-group-name>div {
    display: flex;
    align-items: center;
    color: #e62645;
}

.conceptSearch-compare-group-name>div>span {
    font-size: 1.6rem;
    margin-left: 10px
}

.conceptSearch-compare-molecule-remove {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px
}

.conceptSearch-compare-molecule-remove>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.conceptSearch-compare-molecule-remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 19px;
    color: #e62645;
    content: '\f2ed';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.conceptSearch-compare-molecule-remove:hover:before {
    color: #9b440e
}

.conceptSearch-compare-molecule-remove:active:before {
    color: #e43866;
}

.conceptSearch-compare-molecule-remove:visited {
    color: #333
}

.conceptSearch-compare-molecule-remove:focus,
.conceptSearch-compare-molecule-remove:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.conceptSearch-compare-molecule-remove:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.conceptSearch-compare-molecule-remove.disabled,
.conceptSearch-compare-molecule-remove[disabled],
fieldset[disabled] .conceptSearch-compare-molecule-remove {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.conceptSearch-compare-molecule-remove:focus,
.conceptSearch-compare-molecule-remove:hover {
    background: none;
    border: none
}

.conceptSearch-compare-molecule-remove:active {
    background: none;
    border: none
}

.conceptSearch-compare-molecule-remove.disabled,
.conceptSearch-compare-molecule-remove[disabled],
fieldset[disabled] .conceptSearch-compare-molecule-remove {
    pointer-events: none;
    opacity: .5
}

@media only screen and (min-width:768px) and (max-width:calc(1024px - 1px)) {

    .conceptSearch-grid-molecule:nth-child(3n+0):hover .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(3n+0):focus .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(3n+0):focus-within .conceptSearch-grid-molecule-image {
        border-radius: 0 5px 5px 0
    }

    .conceptSearch-grid-molecule:nth-child(3n+0) .conceptSearch-grid-molecule-info-container {
        left: calc(-1 * (97% - 10px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

@media only screen and (min-width:1024px) and (max-width:calc(1440px - 1px)) {

    .conceptSearch-grid-molecule:nth-child(4n+0):hover .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(4n+0):focus .conceptSearch-grid-molecule-image,
    .conceptSearch-grid-molecule:nth-child(4n+0):focus-within .conceptSearch-grid-molecule-image {
        border-radius: 0 5px 5px 0
    }

    .conceptSearch-grid-molecule:nth-child(4n+0) .conceptSearch-grid-molecule-info-container {
        left: calc(-1 * (97% - 10px - 2px));
        border-radius: 5px 0 0 5px;
        right: auto
    }
}

.abs-product-link,
.product-item-name,
.product.name a {
    font-weight: 700
}

.abs-product-link>a,
.product-item-name>a,
.product.name a>a {
    color: #333;
    text-decoration: none
}

.abs-product-link>a:visited,
.product-item-name>a:visited,
.product.name a>a:visited {
    color: #333;
    text-decoration: underline
}

.abs-product-link>a:hover,
.product-item-name>a:hover,
.product.name a>a:hover {
    color: #333;
    text-decoration: underline
}

.abs-product-link>a:active,
.product-item-name>a:active,
.product.name a>a:active {
    color: #333;
    text-decoration: underline
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer
}

.custom-checkbox input:checked~.checkbox:after {
    content: "";
    position: absolute;
    border-radius: 0.35em;
    background: #e62645;
    height: 100%;
    width: 100%;
    border-color: white;
    border-style: solid;
    border-width: 0.17em;
    box-sizing: border-box
}

.custom-checkbox input:focus~.checkbox {
    box-shadow: 0 0 3px 1px #c1c1c1
}

.custom-checkbox .checkbox {
    position: relative;
    width: 1em;
    height: 1em;
    background: white;
    border: 1px solid #ccc;
    border-radius: 0.35em
}

.loading-icon {
    background-image: url('../images/loading.gif');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto
}

.loading-icon .text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.introjs-showElement {
    position: relative !important
}

.introjs-tooltipReferenceLayer .introjs-helperNumberLayer {
    background: white;
    color: #4a4a4a;
    text-shadow: none;
    border: 3px solid #4a4a4a;
    font-weight: 700;
    font-size: 17px;
    font-family: inherit
}

.introjs-tooltip {
    padding: 10px;
    border-radius: 5px;
    border: 0;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
    text-align: center
}

.introjs-tooltip .introjs-tooltipbuttons {
    display: flex;
    margin: 15px -5px 0;
    justify-content: center
}

.introjs-tooltip .introjs-toptooltipbuttons {
    text-align: right;
    margin-bottom: 5px
}

.introjs-tooltip .introjs-button.introjs-prevbutton,
.introjs-tooltip .introjs-button.introjs-nextbutton {
    background: none;
    -moz-box-sizing: content-box;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    background-image: none;
    background: #1b1c1d;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0 5px;
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    box-sizing: border-box;
    vertical-align: middle;
    border-radius: 5px
}

.introjs-tooltip .introjs-button.introjs-prevbutton:focus,
.introjs-tooltip .introjs-button.introjs-nextbutton:focus,
.introjs-tooltip .introjs-button.introjs-prevbutton:hover,
.introjs-tooltip .introjs-button.introjs-nextbutton:hover {
    background: none;
    border: none
}

.introjs-tooltip .introjs-button.introjs-prevbutton:active,
.introjs-tooltip .introjs-button.introjs-nextbutton:active {
    background: none;
    border: none
}

.introjs-tooltip .introjs-button.introjs-prevbutton.disabled,
.introjs-tooltip .introjs-button.introjs-nextbutton.disabled,
.introjs-tooltip .introjs-button.introjs-prevbutton[disabled],
.introjs-tooltip .introjs-button.introjs-nextbutton[disabled],
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-prevbutton,
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-nextbutton {
    pointer-events: none;
    opacity: .5
}

.introjs-tooltip .introjs-button.introjs-prevbutton:visited,
.introjs-tooltip .introjs-button.introjs-nextbutton:visited {
    color: #fff
}

.introjs-tooltip .introjs-button.introjs-prevbutton:focus,
.introjs-tooltip .introjs-button.introjs-nextbutton:focus,
.introjs-tooltip .introjs-button.introjs-prevbutton:hover,
.introjs-tooltip .introjs-button.introjs-nextbutton:hover {
    background: #343637;
    border: 0;
    color: #fff
}

.introjs-tooltip .introjs-button.introjs-prevbutton:active,
.introjs-tooltip .introjs-button.introjs-nextbutton:active {
    background: #404245;
    border: 0;
    color: #fff
}

.introjs-tooltip .introjs-button.introjs-prevbutton.disabled,
.introjs-tooltip .introjs-button.introjs-nextbutton.disabled,
.introjs-tooltip .introjs-button.introjs-prevbutton[disabled],
.introjs-tooltip .introjs-button.introjs-nextbutton[disabled],
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-prevbutton,
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-nextbutton {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.introjs-tooltip .introjs-button.introjs-prevbutton.introjs-disabled,
.introjs-tooltip .introjs-button.introjs-nextbutton.introjs-disabled,
.introjs-tooltip .introjs-button.introjs-prevbutton.introjs-disabled:hover,
.introjs-tooltip .introjs-button.introjs-nextbutton.introjs-disabled:hover,
.introjs-tooltip .introjs-button.introjs-prevbutton.introjs-disabled:focus,
.introjs-tooltip .introjs-button.introjs-nextbutton.introjs-disabled:focus,
.introjs-tooltip .introjs-button.introjs-prevbutton.introjs-disabled:active,
.introjs-tooltip .introjs-button.introjs-nextbutton.introjs-disabled:active {
    background: #e0e1e2
}

.introjs-tooltip .introjs-button.introjs-hidden {
    display: none
}

.introjs-tooltip .introjs-button.introjs-skipbutton {
    background: #e0e1e2;
    color: #333;
    cursor: pointer;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 3px;
    padding: 10px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400
}

.introjs-tooltip .introjs-button.introjs-skipbutton>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.introjs-tooltip .introjs-button.introjs-skipbutton:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 19px;
    color: #333;
    content: '\f00d';
    font-family: 'Font Awesome 5 Pro';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    overflow: hidden;
    speak: none;
    text-align: center
}

.introjs-tooltip .introjs-button.introjs-skipbutton:hover:before {
    color: #000
}

.introjs-tooltip .introjs-button.introjs-skipbutton:active:before {
    color: #000
}

.introjs-tooltip .introjs-button.introjs-skipbutton:visited {
    color: #333
}

.introjs-tooltip .introjs-button.introjs-skipbutton:focus,
.introjs-tooltip .introjs-button.introjs-skipbutton:hover {
    background: #cbcdce;
    border: 0;
    color: #333
}

.introjs-tooltip .introjs-button.introjs-skipbutton:active {
    background: #bec0c2;
    border: 0;
    color: #333
}

.introjs-tooltip .introjs-button.introjs-skipbutton.disabled,
.introjs-tooltip .introjs-button.introjs-skipbutton[disabled],
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-skipbutton {
    opacity: .5;
    cursor: default;
    pointer-events: none
}

.introjs-tooltip .introjs-button.introjs-skipbutton:focus,
.introjs-tooltip .introjs-button.introjs-skipbutton:hover {
    background: none;
    border: none
}

.introjs-tooltip .introjs-button.introjs-skipbutton:active {
    background: none;
    border: none
}

.introjs-tooltip .introjs-button.introjs-skipbutton.disabled,
.introjs-tooltip .introjs-button.introjs-skipbutton[disabled],
fieldset[disabled] .introjs-tooltip .introjs-button.introjs-skipbutton {
    pointer-events: none;
    opacity: .5
}

.widget.block.block-static-block {
    margin-bottom: 0
}

.am-slider:not(.-loaded) {
    visibility: visible !important;
    opacity: 1 !important
}
.checkout-button-wrapper {
    width: 100%;
  }

  .checkout-button-wrapper button {
    width: calc(50% - 10px);
  }
  .checkout-button-wrapper #punchout-form button:nth-of-type(2) {
    margin-left:20px;
  }
  .checkout-button-wrapper #punchout-form button:nth-of-type(2):before {
    content:'\f56c';
  }